import Vue from 'vue';
import CommSocket from '@/plugins/comm';
import '@mdi/font/css/materialdesignicons.css';
import VueApexCharts from 'vue-apexcharts';
import axios from 'axios';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueKonva from 'vue-konva';
import App from './App.vue';
import './registerServiceWorker';
import request from './util/request';
import dialog from './util/dialog';
import Error from './util/error';
import i18n from './i18n';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import config from './config/config.json';

// 설정
Vue.config.productionTip = false;

// i18n 기본언어
if (localStorage['BLITZadmin.locale'] == null) localStorage.setItem('BLITZadmin.locale', 'ko');

// 전역변수
Vue.prototype.$axios = axios;
Vue.prototype.$emitter = request.emitter;
Vue.prototype.$rest = request.rest;
Vue.prototype.$error = Error;
Vue.prototype.$dialog = dialog;
Vue.prototype.$checkPermission = (staffInfo, target) => {
  const [key, type] = target.split('.');
  if (
    staffInfo == null
    || staffInfo.permission == null
    || staffInfo.permission[key] == null
  ) return false;
  return staffInfo.permission[key][type];
};

// socket
Vue.use(CommSocket);

// chart
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

// google map
Vue.prototype.$googleMapOptions = Vue.observable({
  load: {
    key: config.server.google.map,
    language: localStorage['BLITZadmin.locale'],
    libraries: 'places',
  },
});
Vue.use(VueGoogleMaps, Vue.prototype.$googleMapOptions);

// 차고지 모니터링 캔버스
Vue.use(VueKonva);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
  watch: {
    '$i18n.locale': (value) => {
      const langMap = {
        en: 'en',
        jp: 'ja',
        ko: 'ko',
      };
      localStorage.setItem('BLITZadmin.locale', langMap[value]);
    },
  },
}).$mount('#app');
