<template>
  <div
    :class="
      statusFullscreen ?
      'status-full-screen'
      : 'using-status-wrap'
    "
  >
    <v-card
      elevation="7"
      style="height: 100%;"
    >
      <div class="monitoring_title d-flex justify-space-between" style="width: 100%;">
        <p>{{ $t('dashboard.liveChargers') }}</p>
        <v-tooltip top>
          <template v-slot:activator="{on, attrs}">
            <v-btn
              icon
              @click="showFullscreen('status')"
              :class="statusFullscreen ? 'hidden' : ''"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="white">mdi-fullscreen</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('garage.statusFullscreen') }}</span>
        </v-tooltip>
      </div>
      <ul
        v-if="evseList.length > 0"
        class="status_list_ul"
      >
        <li
          class="status_list_li"
          v-for="list in evseList"
          :key="list.dashboardEvseNumber"
          :class = "[
            list.chargepointOnline === true &&
            list.chargepointState.availability === 'Operative' &&
            list.state.availability === 'Operative' &&
            list.state.online === true &&
            list.state.status === 'Available' ?
            'available'  // 사용가능
            :
              list.chargepointOnline === true &&
              list.chargepointState.availability === 'Operative' &&
              list.state.availability === 'Operative' &&
              list.state.online === true &&
              [
                'Preparing', 'Charging', 'SuspendedEVSE', 'SuspendedEV', 'Finishing', 'Reserved'
              ].includes(list.state.status)
            ? 'inUse'  // 사용중
            : 'unavailable'  // 사용불가능 (위 두가지 조건 둘 다 충족 X)
          ]"
        >
          <p class="flex_center" style="height: 100%;">
            {{ list.dashboardEvseNumber }}
          </p>
        </li>
      </ul>
      <p
        v-else
        class="flex_center"
        style="
          height: calc(100% - 30px);
          color: #A9A9A9;
        "
      >
        {{ $t('common.noSelect') }}
      </p>
    </v-card>
    <DlgAlert
      v-if="statusFullscreen"
      class="fullscreen-exit"
    />
  </div>
</template>

<script>
import DlgAlert from '@/components/dialog/DlgAlert.vue';

export default {
  components: {
    DlgAlert,
  },
  props: {
    showFullscreen: {
      type: Function,
    },
    statusFullscreen: {
      type: Boolean,
      required: true,
    },
    evseList: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
  }),
  methods: {
  },
  watch: {
  },
};
</script>

<style lang="scss">
.using-status-wrap {
  height: 40%;
}
.hidden { display: none; }
.available { background-color: rgb(26, 140, 26); }
.inUse { background-color: #208EDF; }
.unavailable { background-color: rgb(230, 44, 44); }
.status_list_ul{
  color:white;
  border-collapse: collapse;
  &::-webkit-scrollbar {display: none;}
  .status_list_li{
    border:1px solid black;
    height: 40px;
    width: 10%;
    display: inline-block;
    text-align: center;
  }
}
.status-full-screen {
  .status_list_li {
    height: 80px;
  }
}
</style>
