import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './locales/en.json';
import ko from './locales/ko.json';
import jp from './locales/jp.json';

const langMap = {
  en: 'en',
  ja: 'jp',
  ko: 'ko',
};
Vue.use(VueI18n);

const messages = { en, ko, jp };

export default new VueI18n({
  locale: (langMap[localStorage['BLITZadmin.locale']] || 'ko'),
  messages,
  missing: (locale) => {
    const localeMessages = messages[locale];
    const key = 'common.cp_state.error.OtherError';
    const translation = key.split('.').reduce((acc, part) => {
      if (acc && acc[part] !== undefined) { return acc[part]; }
      return null;
    }, localeMessages);
    return translation;
  },
});
