export default {
  namespaced: true,
  state: {
    alert: {
      show: false,
      timeout: 4000,
      timeoutInterval: true,
      type: 'info',
      message: null,
    },
    progress: false,
    confirm: {
      show: false,
      callback: null,
      message: null,
      color: null,
      btnText: null,
    },
    viewLoad: false,
    system: {
      show: false,
      type: 'error',
      icon: 'mdi-connection',
      message: null,
    },
  },
  getters: {
    alert(state) {
      return state.alert;
    },
    progress(state) {
      return state.progress;
    },
    confirm(state) {
      return state.confirm;
    },
    viewLoad(state) {
      return state.viewLoad;
    },
    system(state) {
      return state.system;
    },
  },
  mutations: {
    alert(state, value) {
      state.alert = value;
    },
    progress(state, bool) {
      state.progress = bool;
    },
    confirm(state, value) {
      state.confirm = value;
    },
    viewLoad(state, value) {
      state.viewLoad = value;
    },
    system(state, value) {
      state.system = value;
    },
  },
  actions: {
    alert({ state, commit }, [type, message, timeout = 4000]) {
      commit('alert', { ...state.alert, show: false });
      setTimeout(() => {
        commit('alert', {
          ...state.alert,
          message,
          type,
          timeout,
          show: true,
        });
        if (timeout != null) {
          const startInterval = () => {
            commit('alert', { ...state.alert, timeoutInterval: false });
            const interval = setInterval(() => {
              commit('alert', { ...state.alert, timeout: state.alert.timeout - 100 });
              if (state.alert.timeout === 0) {
                commit('alert', {
                  ...state.alert,
                  show: false,
                  timeoutInterval: true,
                });
                clearInterval(interval);
              }
            }, 100);
          };
          if (state.alert.timeoutInterval) {
            startInterval();
          }
        }
      }, 400);
    },
  },
};
