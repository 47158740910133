export default {
  namespaced: true,
  state: {
    downloadTasks: [],
    // downloadTasks children
    // name: null, // 파일명
    // size: 0, // 파일의 전체용량
    // data: [], // 전송받은 데이터
    // progress: 0, // 현재진행률(%)
    // no: null, // 파일의 no
    uploadTasks: [],
  },
  getters: {
    downloadTasks(state) {
      return state.downloadTasks;
    },
    uploadTasks(state) {
      return state.uploadTasks;
    },
  },
  mutations: {
    downloadTasks(state, value) {
      state.downloadTasks = value;
    },
    pushDownloadTask(state, value) {
      state.downloadTasks.push(value);
    },
    uploadTasks(state, value) {
      state.uploadTasks = value;
    },
  },
  actions: {
    'socket.file.get.progress.start': ({ state, commit }, request) => {
      // 같은 파일이 downloadTasks에 없을때만 실행함
      const isExistTask = state.downloadTasks.findIndex((task) => task.no === request.no) > -1;
      if (!isExistTask) {
        // request =
        //   name, // 파일명
        //   size, // 파일의 용량
        //   data, // 전송받은 데이터
        //   progress, // 현재진행률(%)
        //   no, // 파일의 no
        commit('pushDownloadTask', {
          ...request,
          data: [request.data],
        });
      }
    },
    'socket.file.get.progress': ({ state }, request) => {
      // 같은 파일이 downloadTasks에 있을때만 실행함
      const currentIndex = state.downloadTasks.findIndex((task) => task.no === request.no);
      if (currentIndex > -1) {
        // request =
        //   data, // 전송받은 데이터
        //   progress, // 현재진행률(%)
        //   no, // 파일의 no
        state.downloadTasks[currentIndex].data.push(request.data);
        state.downloadTasks[currentIndex].progress = request.progress;
      }
    },
    'socket.file.get.progress.end': ({ state }, request) => {
      // 같은 파일이 downloadTasks에 있을때만 실행함
      const currentIndex = state.downloadTasks.findIndex((task) => task.no === request.no);
      if (currentIndex > -1) {
        // request =
        //   no, // 파일의 no
        state.downloadTasks[currentIndex].progress = 100;
      }
    },
  },
};
