<template>
  <div class="charger_section_wrap section_wrap">
    <SectionHeader
      :loading="loading"
      :refresh="getData"
      :buttons="buttons"
    />
    <SearchFilter
      :radios="radios"
      :filters.sync="options.filters"
      :loading="loading"
      :searchKeys="searchKeys"
      :callback="getData"
      :options.sync="options"
    />
    <ChargerModelsDataTable
      :items="items"
      :loading="loading"
      :options.sync="options"
      :pageOptions="pageOptions"
      :callback="getData"
    />
    <DlgCommonWrite
      :show.sync="writeDialog.show"
      :title="writeDialog.title"
      :sub="writeDialog.sub"
      :type="writeDialog.type"
      :formCheck="writeDialog.formCheck"
      @submit="writeDialog.submit"
      width="600"
    >
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('model.name') }}*
        </v-chip>
        <div style="width: 100%">
          <v-text-field
            :placeholder="$t('placeholders.cp.modelName')"
            v-model="writeDialog.form.name"
            outlined
            color="black"
            dense
            flat
            solo
            hide-details
          />
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('common.img') }}
        </v-chip>
        <div class="value">
          <input
            v-show="false"
            type="file"
            ref="fileInput"
            :multiple="maxCount > 1"
            @change="fileChange({
              event: $event,
              maxCount,
              maxSize,
              accepts: ['png', 'jpg', 'zip'],
              target: 'files',
            })"
          />
          <v-btn
            v-if="files.length < maxCount"
            elevation
            dense
            outlined
            @click="fileClick({
              target: 'files',
              ref: 'fileInput',
            })"
          >
            <v-icon class="mr-1">
              mdi-image-outline
            </v-icon>
            {{ $t('common.file') }}
          </v-btn>
          <div class="d-flex flex-wrap" v-if="files.length > 0">
            <div
              class="align_center"
              v-for="(file, index) in files"
              :key="index"
            >
              <img
                v-if="file.name.includes('.png') || file.name.includes('jpg')"
                :src="utilFile.arrayBufferToBase64(files[0].data)"
                style="width:50px"
              />
              <v-chip
                close
                class="mr-1 mt-1 mb-1 ml-1"
                @click:close="fileRemove({
                  target: 'files',
                  file,
                })"
              >
                <strong
                  style="
                    max-width:200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  "
                >
                  {{ file.name }}
                </strong>
                <span>({{ utilFile.byteTrans(file.size) }})</span>
              </v-chip>
            </div>
          </div>
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('model.description') }}
        </v-chip>
        <div style="width: 100%">
          <v-text-field
            class="input_text"
            dense
            outlined
            clearable
            v-model="writeDialog.form.description"
          />
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('model.kwh') }}*
        </v-chip>
        <div style="width: 100%">
          <v-text-field
            class="input_text"
            dense
            outlined
            clearable
            v-model="writeDialog.form.kwh"
          />
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('model.evseCount') }}*
        </v-chip>
        <v-text-field
          class="input_text"
          dense
          outlined
          clearable
          v-model="writeDialog.form.evseCount"
        />
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('model.type') }}*
        </v-chip>
        <div style="width: 100%">
          <v-select
            :placeholder="$t('placeholders.cp.type')"
            outlined
            dense
            :items="type"
            :value="writeDialog.form.type"
            @change="writeDialog.form.type = $event"
            v-model="initialType"
            hide-details
          >
          </v-select>
        </div>
      </div>
    </DlgCommonWrite>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import time from '@/util/time';
import utilFile from '@/util/file';
import * as XLSX from 'xlsx';
import SearchFilter from '@/components/common/SearchFilter.vue';
import SectionHeader from '@/components/common/SectionHeader.vue';
import DlgCommonWrite from '@/components/dialog/DlgCommonWrite.vue';
import ChargerModelsDataTable from './unit/ChargerModelsDataTable.vue';

export default {
  name: 'ChargerModelsSection',
  components: {
    DlgCommonWrite,
    ChargerModelsDataTable,
    SearchFilter,
    SectionHeader,
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    time,
    utilFile,
    currentStaffInfo: null,
    loading: false,
    maxCount: 1,
    maxSize: 10485760,
    files: [],
    radios: [
      {
        key: 'type',
        i18nKey: 'point.action',
        values: ['AC', 'DC'],
        conditions: ['eq', 'eq'],
        i18nLabels: ['common.AC', 'common.DC'],
      },
    ],
    searchKeys: [
      {
        i18nKey: 'model.name',
        key: 'name',
      },
      {
        i18nKey: 'model.evseCount',
        key: 'evseCount',
        type: 'number',
      },
    ],
    buttons: [
      {
        color: 'primary',
        icon: 'mdi-plus',
        click: 'openWriteDialog',
        i18nText: 'model.newModel',
        permission: 'charger_models.write',
      },
      {
        color: 'success',
        icon: 'mdi-microsoft-excel',
        click: 'downloadExcel',
        i18nText: 'btn.excelDownload',
      },
    ],
    options: {
      page: 1,
      itemsPerPage: 2,
      sortBy: ['created'],
      sortDesc: [true],
      filters: [],
    },
    pageOptions: {
      total: 0,
      pageCount: 0,
      itemsPerPageOptions: [10, 50],
    },
    items: [],
    createDialog: false,
    writeDialog: {
      show: false,
      title: '',
      sub: null,
      type: 'regist',
      formCheck: false,
      submit: () => {},
      form: {
        name: '',
        description: '',
        kwh: null,
        evseCount: null,
        type: null,
        thumbnail: {},
      },
    },
    initialType: null,
    type: [null, 'AC', 'DC'],
  }),
  methods: {
    async getData() {
      this.loading = true;
      try {
        const {
          itemsPerPage,
          filters,
        } = this.options;
        const { data: { total } } = await this.$emitter('model.summary.get', { filters });
        this.pageOptions.total = total;
        this.pageOptions.pageCount = Math.ceil(total / itemsPerPage);
        const resultData = await this.$emitter('model.list.get', this.options);
        this.items = resultData.items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    async openWriteDialog() {
      this.initWriteDialog();
      await this.getData();
      this.writeDialog.show = true;
      this.writeDialog.submit = this.saveNewData;
    },
    initWriteDialog() {
      this.writeDialog = {
        show: false,
        title: this.$t('cp.newCP'),
        sub: null,
        type: 'regist',
        formCheck: false,
        submit: () => {},
        form: {
          name: '',
          description: '',
          kwh: null,
          evseCount: null,
          type: null,
          thumbnail: {},
        },
      };
      this.initialType = null;
    },
    checkModelWriteValid(form) {
      const {
        name,
        kwh,
        evseCount,
        type,
      } = form;
      if (this.writeDialog.show !== true) return false;

      if (name == null || !/^(.{6,50})$/.test(name.trim())) return false;
      if (kwh == null) return false;
      if (evseCount == null) return false;
      if (type == null) return false;
      return true;
    },
    async saveNewData() {
      this.$dialog.progress(true);
      const {
        name,
        description,
        kwh,
        evseCount,
        type,
      } = this.writeDialog.form;
      try {
        await this.$emitter('model.add', {
          items: [{
            name,
            description,
            kwh: Number(kwh),
            evseCount: Number(evseCount),
            type,
            ...(this.files[0] != null && { thumbnail: this.files[0] }),
          }],
        }, this.$t('common.registCompleteMessage'));
        this.files = [];
        this.initWriteDialog();
        await this.getData();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    downloadExcel() {
      const excludedColumns = ['_id', 'updated', '__v', 'model_id', 'thumbnailPath'];
      const columnMappings = {
        name: `${this.$t('model.name')}`,
        description: `${this.$t('model.description')}`,
        kwh: `${this.$t('model.powerExcel')}`,
        voltage: `${this.$t('model.voltageExcel')}`,
        current: `${this.$t('model.currentExcel')}`,
        evseCount: `${this.$t('model.evseCount')}`,
        created: `${this.$t('common.registration')}`,
        totalChargePoints: `${this.$t('price_setting.chargepointCount')}`,
      };
      const filteredItems = this.items.map((item) => {
        const filteredItem = {};
        Object.keys(item).forEach((key) => {
          if (!excludedColumns.includes(key)) {
            const newColumnName = columnMappings[key] || key;
            if (key === 'created') {
              const createdDate = new Date(item[key]);
              const formattedCreatedDate = time.makeLocalTime(createdDate);
              filteredItem[newColumnName] = formattedCreatedDate;
            } else {
              filteredItem[newColumnName] = item[key];
            }
          }
        });
        return filteredItem;
      });
      const excelData = XLSX.utils.json_to_sheet(filteredItems);
      const columnWidths = [
        { wch: 20 },
        { wch: 50 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 20 },
        { wch: 15 },
      ];
      excelData['!cols'] = columnWidths;
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, excelData, 'charger_models_data');
      XLSX.writeFile(workBook, `charger_models_data_${time.makeAttachTime(new Date())}.xlsx`);
    },

    fileClick({
      target,
      maxCount,
      ref,
    }) {
      this.$refs[ref].value = null;
      if (this[target].length === maxCount) {
        this.$dialog.alert('error', `${this.$t('common.fileErrorFront')} ${maxCount} ${this.$t('common.fileErrorBack')}`);
      } else {
        this.$refs[ref].click();
      }
    },
    async fileChange({
      event,
      maxCount,
      maxSize,
      accepts,
      target,
    }) {
      this.$dialog.progress(true);
      const { files: selectedFiles } = event.target;
      if (selectedFiles.length + this[target].length > maxCount) {
        this.$dialog.alert('error', `${this.$t('common.fileErrorFront')} ${maxCount} ${this.$t('common.fileErrorBack')}`);
      } else if (selectedFiles.length > 0) {
        const filesArray = Object.values(selectedFiles);
        const invalidFileCheck = filesArray.reduce((acc, file) => {
          let isAccept = false;
          for (const accept of accepts) {
            if (file.name.includes(`.${accept}`)) isAccept = true;
          }
          if (isAccept === false && isAccept.length) acc.accept += 1;
          if (file.size > maxSize) acc.size += 1;
          return acc;
        }, {
          size: 0,
          accept: 0,
        });
        if (invalidFileCheck.accept > 0) {
          const types = accepts.length <= 0 ? accepts[0] : accepts.join(', ');
          this.$dialog.alert('error', `${this.$t('common.fileErrorExtensions')} [${types}]`);
        } else if (invalidFileCheck.size > 0) {
          this.$dialog.alert('error', `
            ${this.$t('common.fileErrorMaximumFront')} ${utilFile.byteTrans(maxSize)} ${this.$t('common.fileErrorMaximumBack')}
          `);
        } else {
          const map = filesArray.map((file) => utilFile.readFileToPayload(file));
          const filePayloads = await Promise.all(map);
          this[target] = [
            ...filePayloads,
            ...this[target],
          ];
        }
      }
      this.$dialog.progress(false);
    },
    fileRemove({
      target,
      file,
    }) {
      this[target] = this[target].filter((currentFile) => currentFile.name !== file.name);
    },
  },
  watch: {
    staffInfo: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value != null) {
          this.getData();
        }
      },
    },
    options: {
      deep: true,
      async handler() {
        if (this.staffInfo !== null) await this.getData();
      },
    },
    'writeDialog.form': {
      deep: true,
      handler(value) {
        this.writeDialog.formCheck = this.checkModelWriteValid(value);
      },
    },
  },
};
</script>
