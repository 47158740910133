<template>
  <div class="etc-wrap pa-2">
    <div
      class="d-flex flex-column justify-space-around"
      style="height: 100%;"
    >
      <div style="height: 49%;">
        <v-card style="height: 100%;">
          <p class="monitoring_title mb-4">
            {{ $t('dashboard.inUseCharger') }}
          </p>
          <v-data-table
            v-if="evseList.length !== 0"
            mobile-breakpoint="0"
            fixed-header
            style="
              max-height: calc(100% - 30px);
              overflow: auto;
              font-size: 8px;
            "
            :headers="usingHeader"
            :items="usingItems"
            disable-pagination
            hide-default-footer
            dense
          >
            <template v-slot:item.name="{ item }">
              {{ item.evse }}
            </template>
            <template v-slot:no-data>
              <p>{{ $t('common.noData') }}</p>
            </template>
          </v-data-table>
          <p
            v-else
            class="flex_center"
            style="
              height: calc(100% - 30px);
              color: #A9A9A9;
            "
          >
            {{ $t('common.noSelect') }}
          </p>
        </v-card>
      </div>
      <div style="height: 49%;">
        <v-card style="height: 100%;">
          <p class="monitoring_title mb-4">
            {{ $t('dashboard.under90') }}
          </p>
          <v-data-table
            v-if="evseList.length !== 0"
            mobile-breakpoint="0"
            fixed-header
            style="
              overflow: auto;
              max-height: calc(100% - 30px);
            "
            :headers="socHeader"
            :items="socItems"
            disable-pagination
            hide-default-footer
            dense
          >
            <template v-slot:no-data>
              <p>{{ $t('common.noData') }}</p>
            </template>
          </v-data-table>
          <p
            v-else
            class="flex_center"
            style="
              height: calc(100% - 30px);
              color: #A9A9A9;
            "
          >
            {{ $t('common.noSelect') }}
          </p>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import timeConvert from '@/util/time';

export default {
  props: {
    evseList: {
      type: Array,
      required: true,
    },
    getUseEvseList: {
      type: Array,
      required: true,
    },
    getEvseSocList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    usingHeader: [],
    socHeader: [],
    usingItems: [],
    socItems: [],
    options: {
      page: 1,
      itemsPerPage: 2,
      sortBy: ['sessionStartDate'],
      sortDesc: [true],
      filters: [],
    },
    transfromData: [],
  }),
  methods: {
    initUsingHeader() {
      this.usingHeader.push({
        text: 'No',
        value: 'dashboardEvseNumber',
        width: '3%',
        align: 'center',
        sortable: false,
      },
      {
        text: this.$t('point.evseNumber'),
        value: 'evse',
        width: '20%',
        align: 'center',
        sortable: false,
      },
      {
        text: this.$t('dashboard.route'),
        value: 'route',
        width: '20%',
        align: 'center',
        sortable: false,
      },
      {
        text: this.$t('dashboard.number'),
        value: 'car',
        width: '20%',
        align: 'center',
        sortable: false,
      },
      {
        text: this.$t('dashboard.startSoc'),
        value: 'soc',
        width: '17%',
        align: 'center',
        sortable: false,
      },
      {
        text: this.$t('dashboard.chargingTime'),
        value: 'time',
        width: '20%',
        align: 'center',
        sortable: false,
      });
    },
    initsocHeader() {
      this.socHeader.push({
        text: 'No',
        value: 'dashboardEvseNumber',
        width: '3%',
        align: 'center',
        sortable: false,
      },
      {
        text: this.$t('point.evseNumber'),
        value: 'evse',
        width: '20%',
        align: 'center',
        sortable: false,
      },
      {
        text: this.$t('dashboard.route'),
        value: 'route',
        width: '20%',
        align: 'center',
        sortable: false,
      },
      {
        text: this.$t('dashboard.number'),
        value: 'car',
        width: '20%',
        align: 'center',
        sortable: false,
      },
      {
        text: this.$t('dashboard.finishSoc'),
        value: 'soc',
        width: '17%',
        align: 'center',
        sortable: false,
      },
      {
        text: this.$t('dashboard.finishTime'),
        value: 'time',
        width: '20%',
        align: 'center',
        sortable: false,
      });
    },
    setUsingData() {
      this.usingItems = [];
      this.getUseEvseList.forEach((item) => {
        let chargingTime = '';
        if (item.transaction.date.charging != null) {
          chargingTime = timeConvert.makeCalcTime(
            item.transaction.date.charging.start, timeConvert.getTime(),
          );
          this.usingItems.push({
            dashboardEvseNumber: item.dashboardEvseNumber,
            evse: item.evseNumber,
            route: '노선',
            car: '12가 1234',
            soc: `${item.transaction.socInfo.start}%`,
            time: chargingTime,
          });
        }
      });
    },
    setSocData() {
      this.socItems = [];
      this.getEvseSocList.forEach((item) => {
        const stopTime = new Date(item.transaction.date.charging.stop);
        const krStopTime = new Intl.DateTimeFormat('ko-KR', {
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        }).format(stopTime);

        this.socItems.push({
          dashboardEvseNumber: item.dashboardEvseNumber,
          evse: item.evseNumber,
          route: '노선',
          car: '23나 3456',
          soc: `${item.transaction.socInfo.end}%`,
          time: krStopTime,
        });
      });
    },
    async getTransactionData() {
      this.items = [];
      this.loading = true;
      try {
        const res = await this.$emitter('transaction.list.get', this.options);
        this.transfromData = res.items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
  },
  watch: {
    staffInfo: {
      immediate: true,
      deep: true,
      async handler() {
        this.initUsingHeader();
        this.initsocHeader();
        this.getTransactionData();
      },
    },
    getUseEvseList: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.setUsingData();
        }
      },
    },
    getEvseSocList: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.setSocData();
        }
      },
    },
  },
};
</script>

<style lang="scss">
.etc-wrap {
  width: 20%;
  min-width: 350px;
}
.v-data-table__wrapper{
  overflow: unset !important;
  overflow-y: unset !important;

}
</style>
