<template>
  <div class="d-flex flex-column align-center">
    <div style="width: 100%;">
      <div class="mB10">
        <v-text-field
          outlined
          hide-details
          :label="$t('price_setting.priceName')"
          :value="form.name"
          @change="form.name = $event"
        />
      </div>
      <div
        class="d-flex flex-column"
        style="min-height: 600px;"
      >
        <div>
          <div class="align_center justify-space-between mB10">
            <h3>{{ $t('price_setting.selectMonth') }}</h3>
            <div class="d-flex">
              <div
                v-for="list in weatherColors"
                :key="list.color"
                class="align_center"
              >
                <v-btn
                  :color=list.color
                  depressed
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  @click="whichColor(list.text, list.value)"
                  class="clickedBtn"
                  style="margin-left: 30px;"
                  :class="{
                    'boxShadow': clickedWeatherNum == list.value,
                  }"
                ></v-btn>
                <p
                  class="pL10 smT"
                  :class="{
                    'pointText' : clickedWeatherNum == list.value,
                  }"
                >
                  {{ $t(`price_setting.${list.text}`) }}
                </p>
              </div>
            </div>
          </div>
          <div>
            <ul class="d-flex">
              <li
                v-for="(list, index) in newSeasonList"
                :key="index"
                class="monthList nDrag"
                :class="{
                  'selectSpring': list.season != null && list.season === 0,
                  'selectSummer': list.season != null && list.season === 1,
                  'selectWinter': list.season != null && list.season === 2,
                  'hoverSpring' : clickedWeatherNum === 0,
                  'hoverSummer' : clickedWeatherNum === 1,
                  'hoverWinter' : clickedWeatherNum === 2
                }"
                @click="selectSeason(index)"
              >
                {{ $t(`common.month.${index}`) }}
              </li>
            </ul>
          </div>
        </div>
        <div v-if="openSecondSection" class="mT50">
          <div class="d-flex justify-space-between">
            <h3>{{ $t('price_setting.selectTime') }}</h3>
            <div class="d-flex">
              <div
                v-for="list in rateUnitColors[clickedWeatherNum]"
                :key="list.value"
                class="align_center"
              >
                <v-btn
                  :color=list.color
                  depressed
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  @click="whichCostSort(list.value)"
                  style="margin-left: 30px;"
                  :class="{
                    'boxShadow': clickedLoadIndex == list.value,
                  }"
                ></v-btn>
                <p
                  class="costTitle mR10 smT pL10"
                  :class="{
                    'pointText' : clickedLoadIndex == list.value,
                  }"
                >
                  {{ $t(`price_setting.${list.text}`) }}
                </p>
                <div class="align_center">
                  <p class="smT mR10">
                    {{$t('price_setting.price')}}
                  </p>
                  <v-text-field
                    hide-details
                    v-model="list.cost"
                    @input="changeCostPrice(list.value, $event, true)"
                    type="number"
                    dense
                    style="width:80px;"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mT10">
            <ul class="d-flex">
              <li
                v-for="(season, seasonIndex) in newHourList[clickedWeatherNum]"
                :key="seasonIndex"
                class="hourList flex_center flex-column nDrag"
                :class="{
                  'selectLight': season.load === 0,
                  'selectMedium': season.load === 1,
                  'selectMaximum': season.load === 2,
                  'hoverLight' : clickedLoadIndex === 0,
                  'hoverMedium' : clickedLoadIndex === 1,
                  'hoverMaximum' : clickedLoadIndex === 2
                }"
                @click="selectTime(seasonIndex)"
              >
                <p>{{ $t(`common.time.${seasonIndex}`) }}</p>
              </li>
            </ul>
          </div>
        </div>
        <PriceTable
          v-if="openSecondSection"
          class="priceTable"
          :pricetable="{data : newSeasonList}"
          style="margin-top:50px;"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import PriceTable from '../../../charger/chargers/unit/PriceTable.vue';

export default {
  components: { PriceTable },
  props: {
    form: {
      type: Object,
      default: Object,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    clickedWeather: false,
    clickedWeatherNum: null,
    weatherColors: [
      { text: 'springFall', color: '#FFA07A', value: 0 },
      { text: 'summer', color: '#34b2a6', value: 1 },
      { text: 'winter', color: '#000080', value: 2 },
    ],
    periodWeathers: {
      springFall: { selectHour: null },
      summer: { selectHour: null },
      winter: { selectHour: null },
    },
    newSeasonList: [
      {
        season: null,
        times: [],
      },
      {
        season: null,
        times: [],
      },
      {
        season: null,
        times: [],
      },
      {
        season: null,
        times: [],
      },
      {
        season: null,
        times: [],
      },
      {
        season: null,
        times: [],
      },
      {
        season: null,
        times: [],
      },
      {
        season: null,
        times: [],
      },
      {
        season: null,
        times: [],
      },
      {
        season: null,
        times: [],
      },
      {
        season: null,
        times: [],
      },
      {
        season: null,
        times: [],
      },
    ],
    newHourList: [
      [
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
      ],
      [
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
      ],
      [
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
        { price: null, load: null },
      ],
    ],
    rateUnitColors: [
      [
        {
          text: 'lightLoad',
          color: '#ADD8E6',
          value: 0,
          cost: 0,
        },
        {
          text: 'mediumLoad',
          color: '#ecc13d',
          value: 1,
          cost: 0,
        },
        {
          text: 'maximumLoad',
          color: '#bb0303',
          value: 2,
          cost: 0,
        },
      ],
      [
        {
          text: 'lightLoad',
          color: '#ADD8E6',
          value: 0,
          cost: 0,
        },
        {
          text: 'mediumLoad',
          color: '#ecc13d',
          value: 1,
          cost: 0,
        },
        {
          text: 'maximumLoad',
          color: '#bb0303',
          value: 2,
          cost: 0,
        },
      ],
      [
        {
          text: 'lightLoad',
          color: '#ADD8E6',
          value: 0,
          cost: 0,
        },
        {
          text: 'mediumLoad',
          color: '#ecc13d',
          value: 1,
          cost: 0,
        },
        {
          text: 'maximumLoad',
          color: '#bb0303',
          value: 2,
          cost: 0,
        },
      ],
    ],
    clickedLoadIndex: null,

    clearFirstSection: false,
    openSecondSection: false,
    clearSecondSection: false,
  }),
  methods: {

    whichColor(text, value) {
      this.clickedWeather = text;
      this.clickedWeatherNum = value;
      this.clickedLoadIndex = 0;
    },
    whichCostSort(loadIndex) {
      this.clickedLoadIndex = loadIndex;
    },

    selectSeason(index) {
      if (this.clickedWeatherNum !== null) {
        if (this.newSeasonList[index].season == null && this.clickedWeatherNum != null) {
          this.newSeasonList[index].season = Number(this.clickedWeatherNum);
        } else if (this.newSeasonList[index].season != null && this.clickedWeatherNum != null) {
          this.newSeasonList[index].season = Number(this.clickedWeatherNum);
        } else {
          this.newSeasonList[index].season = null;
          this.$dialog.alert('error', this.$t('price_setting.notSelectSeason'));
        }
        const checkMonthFill = this.newSeasonList.every((item) => item.season !== null);
        if (checkMonthFill) {
          this.openSecondSection = true;
        }
      } else {
        this.$dialog.alert('error', this.$t('price_setting.notSelectSeason'));
      }
    },
    selectTime(index) {
      if (this.clickedLoadIndex !== null) {
        const eachList = this.newHourList[this.clickedWeatherNum][index];
        const colorsListValue = this.rateUnitColors[this.clickedWeatherNum][this.clickedLoadIndex];
        if (colorsListValue.cost !== null && colorsListValue.cost !== '') {
          eachList.load = Number(colorsListValue.value);
          eachList.price = Number(colorsListValue.cost);
        } else {
          this.$dialog.alert('error', this.$t('price_setting.notWriteCost'));
        }
        this.newSeasonList.map((tt) => {
          switch (tt.season) {
            case 0: tt.times.splice(0, tt.times.length, ...this.newHourList[0]); break;
            case 1: tt.times.splice(0, tt.times.length, ...this.newHourList[1]); break;
            case 2: tt.times.splice(0, tt.times.length, ...this.newHourList[2]); break;
            default: break;
          }
          return tt;
        });
        this.form.data.splice(0, this.form.data.length, ...this.newSeasonList);
        this.insertToFormData();
      } else if (this.clickedLoadIndex === null) {
        this.$dialog.alert('error', this.$t('price_setting.notSelectCost'));
      }
    },

    changeCostPrice(index, value, event) {
      if (event) {
        this.clickedLoadIndex = index;
        this.newHourList[this.clickedWeatherNum].forEach((aa) => {
          if (aa.load === index) {
            aa.price = Number(value);
            this.rateUnitColors[this.clickedWeatherNum][index].cost = Number(value);
          }
        });
      }
    },

    insertToFormData() {
      this.form.data = this.newSeasonList;
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.clickedWeatherNum = null;
          this.newSeasonList = [
            {
              season: null,
              times: [],
            },
            {
              season: null,
              times: [],
            },
            {
              season: null,
              times: [],
            },
            {
              season: null,
              times: [],
            },
            {
              season: null,
              times: [],
            },
            {
              season: null,
              times: [],
            },
            {
              season: null,
              times: [],
            },
            {
              season: null,
              times: [],
            },
            {
              season: null,
              times: [],
            },
            {
              season: null,
              times: [],
            },
            {
              season: null,
              times: [],
            },
            {
              season: null,
              times: [],
            },
          ];
          this.clearFirstSection = false;
          this.openSecondSection = false;
          this.clickedLoadIndex = null;
          this.rateUnitColors = [
            [
              {
                text: 'lightLoad',
                color: '#ADD8E6',
                value: 0,
                cost: 0,
              },
              {
                text: 'mediumLoad',
                color: '#ecc13d',
                value: 1,
                cost: 0,
              },
              {
                text: 'maximumLoad',
                color: '#bb0303',
                value: 2,
                cost: 0,
              },
            ],
            [
              {
                text: 'lightLoad',
                color: '#ADD8E6',
                value: 0,
                cost: 0,
              },
              {
                text: 'mediumLoad',
                color: '#ecc13d',
                value: 1,
                cost: 0,
              },
              {
                text: 'maximumLoad',
                color: '#bb0303',
                value: 2,
                cost: 0,
              },
            ],
            [
              {
                text: 'lightLoad',
                color: '#ADD8E6',
                value: 0,
                cost: 0,
              },
              {
                text: 'mediumLoad',
                color: '#ecc13d',
                value: 1,
                cost: 0,
              },
              {
                text: 'maximumLoad',
                color: '#bb0303',
                value: 2,
                cost: 0,
              },
            ],
          ];
          this.newHourList = [
            [
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
            ],
            [
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
            ],
            [
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
              { price: null, load: null },
            ],
          ];
        }
      },
    },
    form: {
      immediate: true,
      deep: true,
      handler() {},
    },
  },
};
</script>

<style scoped lang="scss">
.monthList{
  width:70px;
  border:1px solid #8b8b8b;
  height:30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-right:none;
  &+&{
    border-right:none;
  }
  &:first-child{
    border-radius: 4px 0 0 4px;
  }
  &:last-child{
    border-right:1px solid #8b8b8b;
    border-radius: 0 4px 4px 0;
  }
}
.hourList{
  width:52.9px;
  height:25px;
  border:1px solid #8b8b8b;
  cursor: pointer;
  max-width: 1180px;
  border-right:none;
  &+&{
    border-right:none;
  }
  &:first-child{
    border-radius: 4px 0 0 4px;
  }
  &:last-child{
    border-right:1px solid #8b8b8b;
    border-radius: 0 4px 4px 0;
  }
}
.costTitle{
  width: 70px;
}
.hoverSpring{
  &:hover {
    background-color: #bf7558;
    color: white;
  }
}
.hoverSummer{
  &:hover {
    background-color: #1d6961;
    color: white;
  }
}
.hoverWinter{
  &:hover {
    background-color: #011c36;
    color: white;
  }
}
.hoverLight{
  &:hover {
    background-color: #6d8a93;
    color: white;
  }
}
.hoverMedium{
  &:hover {
    background-color: #927412;
    color: white;
  }
}
.hoverMaximum{
  &:hover {
    background-color: #850f0f;
    color: white;
  }
}
</style>
