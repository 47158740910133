<template>
  <v-dialog
    v-model="show"
    width="auto"
    @click:outside="init()"
  >
    <div :style="styles">
      <v-card class="pa-4" :style="{ width: `${width}px` }" style="box-shadow: none;">
        <p class="tit mb-2">
          {{ title }}
          <span class="sub" v-if="sub !== null">{{ sub }}</span>
        </p>
        <slot/>
        <div class="d-flex justify-end pt-2" v-if="type !== 'view'">
          <v-btn
            depressed
            class="mr-2"
            @click="init"
          >
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            depressed
            :disabled="!formCheck"
            @click="submit"
          >
            {{ $t(`common.${type}`) }}
          </v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'DlgCommonWrite',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    sub: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'regist',
    },
    formCheck: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '800',
    },
    styles: {
      type: String,
    },
  },
  data: () => ({
  }),
  methods: {
    init() {
      this.$emit('update:show', false);
    },
    submit() {
      this.$emit('submit');
    },
  },
};
</script>
