<template>
  <div class="monitoring_wrap pa-2">
    <div
      v-if="settingGarage"
      class="align_center justify-space-between btn_wrap"
    >
      <div class="align_center">
        <v-tooltip
          bottom
          color="black"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              elevation
              outlined
              :disabled="evseList.length === 0"
              @click="addSections('zone')"
            >
              <v-icon color="black">mdi-dots-square</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('garage.zone') }}</span>
        </v-tooltip>
        <v-tooltip
          bottom
          color="black"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              elevation
              outlined
              :disabled="evseList.length === 0"
              @click="addSections('pb')"
            >
              <v-icon color="black">mdi-lightning-bolt</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('garage.powerBank') }}</span>
        </v-tooltip>
        <v-tooltip
          bottom
          color="black"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              elevation
              outlined
              :disabled="evseList.length === 0"
              @click="addSections('dp')"
            >
              <v-icon color="black">mdi-ev-station</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('garage.dispenser') }}</span>
        </v-tooltip>
        <v-tooltip
          bottom
          color="black"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              elevation
              outlined
              :disabled="evseList.length === 0"
              @click="nameField = !nameField"
              class="mR10"
            >
              <v-icon color="black">mdi-text-shadow</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('garage.text') }}</span>
        </v-tooltip>
        <div v-if="nameField">
          <v-text-field
            background-color="white"
            filled
            rounded
            dense
            append-icon="mdi-plus-circle"
            v-model="name"
            hide-details
            @keyup.enter="addSections('text', name)"
            @click:append="addSections('text', name)"
          ></v-text-field>
        </div>
      </div>
      <v-btn
        icon
        outlined
        @click="saveGarage"
        :disabled="evseList.length === 0"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </div>
    <div
      v-else
      class="d-flex justify-end"
    >
      <v-btn
        icon
        outlined
        @click="goSetting"
        :disabled="evseList.length === 0"
      >
        <v-icon>mdi-content-save-edit</v-icon>
      </v-btn>
    </div>
    <div
      class="konva_wrap"
      ref="konva"
      @wheel="handleWheel"
    >
      <v-stage
        ref="stage"
        :config="stageSize"
        @mousedown="handleStageMouseDown"
      >
        <v-layer ref="layer" :draggable="settingGarage">
          <!-- Zone -->
          <v-rect
            v-for="item in rectanglesZone"
            :draggable="settingGarage"
            :key="item.id"
            :config="item"
            :dash="[20, 11]"
            @mousedown="handleTransformEnd"
            @transformend="handleTransformEnd"
            @dragmove="handleTransformEnd"
            @dragend="clearGuides"
            :cornerRadius="10"
          />
          <!-- PB -->
          <v-rect
            v-for="item in rectanglesPb"
            :draggable="settingGarage"
            :key="item.id"
            :config="item"
            @transformend="handleTransformEnd"
            @dragmove="handleTransformEnd"
            @dragend="clearGuides"
            :cornerRadius="10"
          />
          <!-- DP -->
          <v-rect
            v-for="item in rectanglesDp"
            :draggable="settingGarage"
            :key="item.id"
            :config="item"
            :dash="[5, 5]"
            @transformend="handleTransformEnd"
            @dragmove="handleTransformEnd"
            @dragend="clearGuides"
            :cornerRadius="10"
          />
          <!-- nameGroup -->
          <v-text
            v-for="item in nameGroup"
            :draggable="settingGarage"
            :key="item.id"
            :config="item"
            @transformend="handleTransformEnd"
            @dragmove="handleTransformEnd"
            @dragend="clearGuides"
          />
          <!-- EVSE -->
          <v-group v-for="(evseGroupInfo) in evseRects" :key="evseGroupInfo.id">
            <v-group
              ref="evseGroup"
              :draggable="settingGarage"
              @dragstart="moveIdx(evseGroupInfo, $event)"
              @mouseenter="cursor"
              @mouseleave="defaultCursor"
              @transformend="handleTransformEnd"
              @dragmove="handleTransformEnd"
              @dragend="clearGuides"
              :config="evseGroupInfo"
            >
              <v-rect
                v-for="item in evseGroupInfo.evseRect"
                :key="item.id"
                :config="item"
                :cornerRadius="10"
                :resizeEnabled="false"
                @mouseover="showTooltip($event, item)"
              />
              <v-rect
                v-for="item in evseGroupInfo.evseCenter"
                :key="item.id"
                :config="item"
                :resizeEnabled="false"
              />
              <v-text
                v-for="item in evseGroupInfo.dashboardNumber"
                :key="item.id"
                :config="item"
              />
              <v-text
                v-for="item in evseGroupInfo.carNumber"
                :key="item.id"
                :config="item"
              />
              <v-image
                v-for="item in evseGroupInfo.evseIcon"
                :key="item.id"
                :config="item"
              />
              <v-text
                v-for="item in evseGroupInfo.evseSoc"
                :key="item.id"
                :config="item"
              />
              <v-text
                v-for="item in evseGroupInfo.evseChargeM"
                :key="item.id"
                :config="item"
              />
              <v-text
                v-for="item in evseGroupInfo.evseStatus"
                :key="item.id"
                :config="item"
              />
            </v-group>
          </v-group>
          <v-transformer ref="transformer"/>
        </v-layer>
      </v-stage>
    </div>
  </div>
</template>

<script>
import Konva from 'konva';
import timeConvert from '@/util/time';
import chargerIconOn from '@/assets/images/garage-preparing.png';
import chargerIconInUse from '@/assets/images/garage-charging.png';
import chargerIconFinish from '@/assets/images/garage-finishing.png';
import chargerIconError from '@/assets/images/garage-error.png';

export default {
  props: {
    leftSideWidthSize: {
      type: Number,
      required: true,
    },
    selectedArea: {
      type: String,
    },
    evseList: {
      type: Array,
    },
    getMonitoringLocation: {
      type: Array,
    },
    settingGarage: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    const imageUrls = [chargerIconOn, chargerIconInUse, chargerIconFinish, chargerIconError];

    imageUrls.forEach((url) => {
      const image = new window.Image();
      image.src = url;
      image.onload = () => { this.chargerIcons.push(image); };
    });
  },
  mounted() {
    // tooltip
    const tooltipLayer = new Konva.Layer();

    this.tooltip = new Konva.Label({
      opacity: 0.75,
      visible: false,
      listening: false,
    });

    this.tooltip.add(
      new Konva.Tag({
        fill: 'black',
        pointerDirection: 'down',
        pointerWidth: 10,
        pointerHeight: 10,
        lineJoin: 'round',
        shadowColor: 'black',
        shadowBlur: 10,
        shadowOffsetX: 10,
        shadowOffsetY: 10,
        shadowOpacity: 0.5,
      }),
    );

    const text = new Konva.Text({
      text: '',
      fontFamily: 'Pretendard',
      fontSize: 15,
      padding: 5,
      fill: 'white',
    });

    this.tooltip.add(text);
    tooltipLayer.add(this.tooltip);

    this.$refs.stage.getNode().add(tooltipLayer);
  },
  data: () => ({
    stageSize: {
      width: 3000,
      height: 3000,
    },
    stageScale: 1,
    scaleBy: 1.1,
    nameField: false,
    name: '',
    rectanglesZone: [],
    rectanglesPb: [],
    rectanglesDp: [],
    nameGroup: [],
    selectedEvseName: '',
    selectedZoneName: '',
    selectedPbName: '',
    selectedDpName: '',
    evseRects: [],
    chargerIcons: [],
    GUIDELINE_OFFSET: 5,
    tooltip: null,
  }),
  methods: {
    arrangeRects() {
      if (this.getMonitoringLocation && this.getMonitoringLocation !== null) {
        const isMatched = this.evseList.some(
          (a) => this.getMonitoringLocation[0].some((b) => a.evseNumber === b.id),
        );
        if (isMatched) {
          const rectsArrays = [
            this.rectanglesZone, this.rectanglesPb, this.rectanglesDp, this.nameGroup,
          ];
          for (let i = 1; i < this.getMonitoringLocation.length; i += 1) {
            rectsArrays[i - 1].push(...this.getMonitoringLocation[i]);
          }
        }
      }
    },
    addSections(type, name) {
      const common = {
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
        stroke: 'black',
        strokeScaleEnabled: false,
      };
      if (type === 'zone') {
        this.rectanglesZone.push({
          ...common,
          id: `zone${this.rectanglesZone.length + 1}`,
          name: `zone${this.rectanglesZone.length + 1}`,
          x: 5,
          y: 40,
          width: 900,
          height: 380,
          strokeWidth: 3,
        });
        this.selectedZoneName = `zone${this.rectanglesZone.length + 1}`;
      } else if (type === 'pb') {
        this.rectanglesPb.push({
          ...common,
          id: `pb${this.rectanglesPb.length + 1}`,
          name: `pb${this.rectanglesPb.length + 1}`,
          x: 15,
          y: 50,
          width: 400,
          height: 330,
          strokeWidth: 3,
          fill: '#fff',
        });
        this.selectedPbName = `pb${this.rectanglesPb.length + 1}`;
      } else if (type === 'dp') {
        this.rectanglesDp.push({
          ...common,
          id: `dp${this.rectanglesDp.length + 1}`,
          name: `dp${this.rectanglesDp.length + 1}`,
          x: 25,
          y: 60,
          width: 160,
          height: 200,
        });
        this.selectedDpName = `dp${this.rectanglesDp.length + 1}`;
      } else if (type === 'text') {
        this.nameGroup.push({
          ...common,
          id: `name${this.nameGroup.length + 1}`,
          name: `name${this.nameGroup.length + 1}`,
          x: 50,
          y: 15,
          text: name,
          fontSize: 18,
          strokeWidth: 0.5,
        });
        this.selectedTextName = `name${this.nameGroup.length + 1}`;
        this.nameField = !this.nameField;
        this.name = '';
      }
      this.updateTransformer(type);
    },
    async saveGarage() {
      try {
        this.$emit('update:settingGarage', false);
        const evseLocation = this.evseRects.map((item) => ({
          id: item.id,
          name: item.name,
          x: item.x,
          y: item.y,
          rotation: item.rotation,
          scaleX: item.scaleX,
          scaleY: item.scaleY,
        }));
        await this.$emitter('area.garage.update', {
          _id: this.selectedArea,
          value: [
            evseLocation,
            this.rectanglesZone,
            this.rectanglesPb,
            this.rectanglesDp,
            this.nameGroup,
          ],
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    goSetting() {
      this.$emit('update:settingGarage', true);
    },
    // cursor
    cursor() {
      if (this.settingGarage) this.$refs.stage.getNode().container().style.cursor = 'pointer';
    },
    defaultCursor() {
      if (this.settingGarage) this.$refs.stage.getNode().container().style.cursor = 'default';
      this.hideTooltip();
    },
    // evse rect group
    async makeEvseRects() {
      this.evseList.forEach((list, idx) => {
        let timeDuration = '';
        if (list.transaction !== undefined) {
          if (list.transaction.status === 'Charging') {
            if (list.transaction.date.charging.start != null) {
              timeDuration = timeConvert.makeCalcTime(
                list.transaction.date.charging.start, timeConvert.getTime(),
              );
            }
          }
        }
        const getEvseLocationItem = this.getMonitoringLocation !== null
          ? this.getMonitoringLocation[0].find((item) => item.id === list.evseNumber) : '';
        const common = {
          scaleX: 1,
          scaleY: 1,
          stroke: 'black',
          strokeScaleEnabled: false,
          draggable: false,
        };
        this.evseRects.push({
          id: list.evseNumber,
          list,
          name: `evse${idx + 1}`,
          x: getEvseLocationItem ? getEvseLocationItem.x : 0,
          y: getEvseLocationItem ? getEvseLocationItem.y : 0,
          rotation: getEvseLocationItem ? getEvseLocationItem.rotation : 0,
          scaleX: getEvseLocationItem ? getEvseLocationItem.scaleX : 1,
          scaleY: getEvseLocationItem ? getEvseLocationItem.scaleY : 1,
          evseRect: [{
            ...common,
            id: `evse${this.evseRects.length + 1}`,
            name: `evse${idx + 1}`,
            x: this.evseRects.length > 0
              ? (this.evseRects[this.evseRects.length - 1].evseRect[0].x + 75)
              % (this.stageSize.width - 49) : 15,
            y: this.evseRects.length > 0
              ? Math.floor(this.evseRects.length
              / Math.floor((this.stageSize.width - 46) / 75)) * 180 + 45
              : 45,
            rotation: 0,
            width: 75,
            height: 180,
            fill: this.isAbleToCharge(list)[1],
            evseNumber: list.evseNumber,
          }],
          evseCenter: [{
            ...common,
            id: `evseCenter${this.evseRects.length + 1}`,
            name: `evse${idx + 1}`,
            x: this.evseRects.length > 0
              ? (this.evseRects[this.evseRects.length - 1].evseRect[0].x + 75)
              % (this.stageSize.width - 49) : 15,
            y: this.evseRects.length > 0
              ? Math.floor(this.evseRects.length
              / Math.floor((this.stageSize.width - 46) / 75)) * 180 + 73.5
              : 73.5,
            rotation: 0,
            width: 75,
            height: 123,
            ...common,
            fill: 'white',
          }],
          dashboardNumber: [{
            id: `dashboardNumber${this.evseRects.length + 1}`,
            name: `evse${idx + 1}`,
            x: this.evseRects.length > 0
              ? this.evseRects[this.evseRects.length - 1].evseRect[0].x + 83 : 23,
            y: this.evseRects.length > 0
              ? this.evseRects[this.evseRects.length - 1].evseRect[0].y + 10 : 55,
            width: 60,
            text: list.dashboardEvseNumber,
            fontSize: 12,
            fontStyle: 'bold',
            align: 'center',
            fill: 'white',
            evseNumber: list.evseNumber,
          }],
          carNumber: [{
            id: `carNumber${this.evseRects.length + 1}`,
            name: `evse${idx + 1}`,
            x: this.evseRects.length > 0
              ? this.evseRects[this.evseRects.length - 1].evseRect[0].x + 82 : 22,
            y: this.evseRects.length > 0
              ? this.evseRects[this.evseRects.length - 1].evseRect[0].y + 40 : 85,
            width: 60,
            text: (this.isAbleToCharge(list)[0] === 'garage.charging'
              || this.isAbleToCharge(list)[0] === 'garage.finish') ? '12가 1234' : '',
            fontSize: 12,
            fontStyle: 'bold',
            align: 'center',
          }],
          evseIcon: [{
            id: `evseIcon${this.evseRects.length + 1}`,
            name: `evse${idx + 1}`,
            x: this.evseRects.length > 0
              ? this.evseRects[this.evseRects.length - 1].evseRect[0].x + 93 : 33,
            y: this.evseRects.length > 0
              ? this.evseRects[this.evseRects.length - 1].evseRect[0].y + 70 : 115,
            width: 40,
            height: 40,
            image: this.chargerIcon(this.isAbleToCharge(list)[0]),
            align: 'center',
          }],
          evseSoc: [{
            id: `evseSoc${this.evseRects.length + 1}`,
            name: `evse${idx + 1}`,
            x: this.evseRects.length > 0
              ? this.evseRects[this.evseRects.length - 1].evseRect[0].x + 86 : 26,
            y: this.evseRects.length > 0
              ? this.evseRects[this.evseRects.length - 1].evseRect[0].y + 118 : 163,
            width: 60,
            text: (this.isAbleToCharge(list)[0] === 'garage.charging'
              || this.isAbleToCharge(list)[0] === 'garage.finish')
              && list.transaction ? `${list.transaction.meter.soc}%` : '',
            fontSize: this.isAbleToCharge(list)[0] === 'garage.finish' ? 15 : 20,
            fontStyle: 'bold',
            align: 'center',
          }],
          evseChargeM: [{
            id: `evseChargeM${this.evseRects.length + 1}`,
            name: `evse${idx + 1}`,
            x: this.evseRects.length > 0
              ? this.evseRects[this.evseRects.length - 1].evseRect[0].x + 86 : 26,
            y: this.evseRects.length > 0
              ? this.evseRects[this.evseRects.length - 1].evseRect[0].y + 135 : 170,
            width: 60,
            text: this.isAbleToCharge(list)[0] === 'garage.finish'
              && list.transaction ? `${list.transaction.chargedMeter / 1000}kWh` : '',
            fontSize: 13,
            fontStyle: 'bold',
            align: 'center',
          }],
          evseStatus: [{
            id: `evseStatus${this.evseRects.length + 1}`,
            name: `evse${idx + 1}`,
            x: this.evseRects.length > 0
              ? this.evseRects[this.evseRects.length - 1].evseRect[0].x + 83 : 23,
            y: this.evseRects.length > 0
              ? this.evseRects[this.evseRects.length - 1].evseRect[0].y + 161 : 206,
            width: 60,
            text: this.isAbleToCharge(list)[0] === 'garage.charging' ? timeDuration : `${this.$t(this.isAbleToCharge(list)[0])}`,
            fontSize: 12,
            align: 'center',
            fill: 'white',
          }],
        });
        this.selectedEvseName = this.evseRects[idx].name;
        this.updateTransformer('evse');
      });
    },
    isAbleToCharge(evse) {
      const { chargepointOnline, chargepointState, state } = evse;
      const isOperative = chargepointOnline && chargepointState.availability === 'Operative'
        && state.availability === 'Operative' && state.online === true;
      if (isOperative) {
        if (state.status === 'Available') return ['garage.standBy', '#1A8C3C'];
        if (['Charging', 'SuspendedEVSE', 'SuspendedEV'].includes(state.status)) return ['garage.charging', '#218edf'];
        if (['Preparing', 'Reserved'].includes(state.status)) return ['garage.ready', '#218edf'];
        if (state.status === 'Finishing') return ['garage.finish', '#e99c17'];
      }
      return ['garage.failure', '#D41414'];
    },
    chargerIcon(status) {
      if (status === 'garage.standBy') return this.chargerIcons[0];
      if (status === 'garage.failure') return this.chargerIcons[3];
      if (status === 'garage.finish') return this.chargerIcons[2];
      return this.chargerIcons[1];
    },
    // 스테이지에서 마우스 클릭 또는 터치 이벤트가 발생했을 때 호출
    handleStageMouseDown(e) {
      // 스테이지 빈 곳 클릭하면 선택된 사각형 해제 (e.target.getStage()이 스테이지)
      if (e.target === e.target.getStage()) {
        this.selectedEvseName = '';
        this.updateTransformer('evse');
        this.clearGuides();
        return;
      }

      // 도형조작 도구 클릭 시 추가적인 동작 막기 위함
      const clickedOnTransformer = e.target.getParent().className === 'Transformer';
      if (clickedOnTransformer) return;

      if (this.settingGarage) {
        // 도형 클릭 시 선택
        const name = e.target.name();
        const rectEvse = this.evseRects.find((r) => r.name === name);
        const rectZone = this.rectanglesZone.find((r) => r.name === name);
        const rectPb = this.rectanglesPb.find((r) => r.name === name);
        const rectDp = this.rectanglesDp.find((r) => r.name === name);
        const textName = this.nameGroup.find((t) => t.name === name);
        const updateSelection = (type) => {
          this.updateTransformer(type);
          this.selectedEvseName = type === 'evse' ? name : '';
          this.selectedZoneName = type === 'zone' ? name : '';
          this.selectedPbName = type === 'pb' ? name : '';
          this.selectedDpName = type === 'dp' ? name : '';
          this.selectedTextName = type === 'name' ? name : '';
          window.addEventListener('keyup', this.deleteRectangle);
        };

        if (rectEvse) updateSelection('evse');
        else if (rectZone) updateSelection('zone');
        else if (rectPb) updateSelection('pb');
        else if (rectDp) updateSelection('dp');
        else if (textName) updateSelection('name');
        else {
          this.selectedEvseName = '';
          this.selectedZoneName = '';
          this.selectedPbName = '';
          this.selectedDpName = '';
          this.selectedTextName = '';
        }
      }
    },
    // 도형 상태관리
    updateTransformer(type) {
      if (this.settingGarage) {
        // 트랜스포머(도형을 선택하고 조작하기 위한 도구), 스테이지 정보 가져오기
        const transformerNode = this.$refs.transformer.getNode();
        const stage = transformerNode.getStage();
        const degree = Array.from({ length: 12 }, (_, i) => (i + 1) * 30);
        let selectedNode = '';
        // 스테이지에 선택된 도형이 있는지 확인
        switch (type) {
          case 'evse': selectedNode = stage.findOne(`.${this.selectedEvseName}`); break;
          case 'zone': selectedNode = stage.findOne(`.${this.selectedZoneName}`); break;
          case 'pb': selectedNode = stage.findOne(`.${this.selectedPbName}`); break;
          case 'dp': selectedNode = stage.findOne(`.${this.selectedDpName}`); break;
          case 'name': selectedNode = stage.findOne(`.${this.selectedTextName}`); break;
          default: break;
        }
        // 트랜스포머에 연결이 되어있는지 확인, 있으면 함수 종료
        if (selectedNode === transformerNode.nodes()) return;
        // 선택한 도형이 존재하면 트랜스포머를 해당 도형에 연결, 없으면 트랜스포어의 모든 노드(모든 그래픽 객체) 제거
        transformerNode.nodes(selectedNode ? [selectedNode] : []);
        // evse rect 제외 크기 변경 가능
        transformerNode.resizeEnabled(['zone', 'pb', 'dp', 'name'].includes(type));
        // rotation 각도 설정
        transformerNode.rotationSnaps(degree);
      }
    },
    // wheel 스크롤, 확대
    handleWheel(event) {
      const stage = this.$refs.stage.getNode();
      const oldScale = stage.scaleX();

      const mousePointTo = {
        x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
      };

      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
        const newScale = event.deltaY > 0 ? oldScale * this.scaleBy : oldScale / this.scaleBy;
        this.stageScale = newScale;
        stage.scale({ x: newScale, y: newScale });
      } else if (event.shiftKey) {
        stage.x(stage.x() + event.deltaX);
        stage.y(stage.y() + event.deltaY);
      } else {
        stage.y(stage.y() + event.deltaY);
      }

      const newPos = {
        x: -(mousePointTo.x - stage.getPointerPosition().x / stage.scaleX()) * stage.scaleX(),
        y: -(mousePointTo.y - stage.getPointerPosition().y / stage.scaleY()) * stage.scaleY(),
      };
      stage.position(newPos);
      stage.batchDraw();
    },
    moveIdx(evseGroupInfo) {
      const itemIndex = this.evseRects.findIndex((i) => i.id === evseGroupInfo.id);
      if (itemIndex !== -1) {
        const selectedItem = this.evseRects.splice(itemIndex, 1)[0];
        this.evseRects.push(selectedItem);
      }
    },
    // 변형이 끝났을 때 호출되며, 선택된 도형 속성 업데이트
    handleTransformEnd(e) {
      if (this.settingGarage) {
        const updateRect = (items, name) => {
          const targetItem = items.find((i) => i.name === name);
          if (targetItem) {
            targetItem.x = e.target.x();
            targetItem.y = e.target.y();
            targetItem.rotation = e.target.rotation();
            targetItem.scaleX = e.target.scaleX();
            targetItem.scaleY = e.target.scaleY();
          }
        };
        if (this.selectedEvseName) updateRect(this.evseRects, this.selectedEvseName);
        if (this.selectedZoneName) updateRect(this.rectanglesZone, this.selectedZoneName);
        if (this.selectedPbName) updateRect(this.rectanglesPb, this.selectedPbName);
        if (this.selectedDpName) updateRect(this.rectanglesDp, this.selectedDpName);
        if (this.selectedTextName) updateRect(this.nameGroup, this.selectedTextName);
        // guide
        this.clearGuides();

        const lineGuideStops = this.getLineGuideStops(e.target);
        const itemBounds = this.getObjectSnappingEdges(e.target);
        const guides = this.getGuides(lineGuideStops, itemBounds);

        if (!guides.length) return;

        this.drawGuides(guides);

        const absPos = e.target.absolutePosition();
        guides.forEach((lg) => {
          switch (lg.snap) {
            case 'start': {
              switch (lg.orientation) {
                case 'V': absPos.x = lg.lineGuide + lg.offset; break;
                case 'H': absPos.y = lg.lineGuide + lg.offset; break;
                default: break;
              }
              break;
            }
            case 'center': {
              switch (lg.orientation) {
                case 'V': absPos.x = lg.lineGuide + lg.offset; break;
                case 'H': absPos.y = lg.lineGuide + lg.offset; break;
                default: break;
              }
              break;
            }
            case 'end': {
              switch (lg.orientation) {
                case 'V': absPos.x = lg.lineGuide + lg.offset; break;
                case 'H': absPos.y = lg.lineGuide + lg.offset; break;
                default: break;
              }
              break;
            }
            default: break;
          }
        });
        e.target.absolutePosition(absPos);
      }
    },
    clearGuides() {
      this.$refs.layer.getNode().find('.guid-line').forEach((l) => l.destroy());
      // this.$refs.transformer.getNode().nodes([]);
    },
    getLineGuideStops(target) {
      const vertical = [0, this.stageSize.width / 2, this.stageSize.width];
      const horizontal = [0, this.stageSize.height / 2, this.stageSize.height];
      const rects = this.$refs.layer.getNode().children;

      /* eslint no-underscore-dangle: 0 */
      rects.forEach((guideItem) => {
        if (guideItem._id + 1 === target._id) {
          return;
        }
        const box = guideItem.getClientRect();
        vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
        horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
      });
      return {
        vertical: vertical.flat(),
        horizontal: horizontal.flat(),
      };
    },
    getObjectSnappingEdges(target) {
      const box = target.getClientRect(); // 직사각형 {x, y, 너비, 높이}를 반환
      const absPos = target.absolutePosition(); // 절대위치

      return {
        vertical: [
          {
            guide: Math.round(box.x),
            offset: Math.round(absPos.x - box.x),
            snap: 'start',
          },
          {
            guide: Math.round(box.x + box.width / 2),
            offset: Math.round(absPos.x - box.x - box.width / 2),
            snap: 'center',
          },
          {
            guide: Math.round(box.x + box.width),
            offset: Math.round(absPos.x - box.x - box.width),
            snap: 'end',
          },
        ],
        horizontal: [
          {
            guide: Math.round(box.y),
            offset: Math.round(absPos.y - box.y),
            snap: 'start',
          },
          {
            guide: Math.round(box.y + box.height / 2),
            offset: Math.round(absPos.y - box.y - box.height / 2),
            snap: 'center',
          },
          {
            guide: Math.round(box.y + box.height),
            offset: Math.round(absPos.y - box.y - box.height),
            snap: 'end',
          },
        ],
      };
    },
    getGuides(lineGuideStops, itemBounds) {
      const resultV = [];
      const resultH = [];

      lineGuideStops.vertical.forEach((lineGuide) => {
        itemBounds.vertical.forEach((itemBound) => {
          const diff = Math.abs(lineGuide - itemBound.guide);
          if (diff < this.GUIDELINE_OFFSET) {
            resultV.push({
              lineGuide,
              diff,
              snap: itemBound.snap,
              offset: itemBound.offset,
            });
          }
        });
      });

      lineGuideStops.horizontal.forEach((lineGuide) => {
        itemBounds.horizontal.forEach((itemBound) => {
          const diff = Math.abs(lineGuide - itemBound.guide);
          if (diff < this.GUIDELINE_OFFSET) {
            resultH.push({
              lineGuide,
              diff,
              snap: itemBound.snap,
              offset: itemBound.offset,
            });
          }
        });
      });

      const guides = [];

      const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
      const minH = resultH.sort((a, b) => a.diff - b.diff)[0];
      if (minV) {
        guides.push({
          lineGuide: minV.lineGuide,
          offset: minV.offset,
          orientation: 'V',
          snap: minV.snap,
        });
      }
      if (minH) {
        guides.push({
          lineGuide: minH.lineGuide,
          offset: minH.offset,
          orientation: 'H',
          snap: minH.snap,
        });
      }
      return guides;
    },
    drawGuides(guides) {
      guides.forEach((lg) => {
        if (lg.orientation === 'H') {
          const line = new Konva.Line({
            points: [-6000, 0, 6000, 0],
            stroke: 'rgb(0, 161, 255)',
            strokeWidth: 1,
            name: 'guid-line',
            dash: [4, 6],
          });
          this.$refs.layer.getNode().add(line);
          line.absolutePosition({
            x: 0,
            y: lg.lineGuide,
          });
        } else if (lg.orientation === 'V') {
          const line = new Konva.Line({
            points: [0, -6000, 0, 6000],
            stroke: 'rgb(0, 161, 255)',
            strokeWidth: 1,
            name: 'guid-line',
            dash: [4, 6],
          });
          this.$refs.layer.getNode().add(line);
          line.absolutePosition({
            x: lg.lineGuide,
            y: 0,
          });
        }
      });
    },
    deleteRectangle(event) {
      if (event.key === 'Delete') {
        let selectedItem;
        const selectedName = this.selectedZoneName
        || this.selectedPbName || this.selectedDpName || this.selectedTextName;

        if (this.selectedZoneName) selectedItem = this.rectanglesZone;
        else if (this.selectedPbName) selectedItem = this.rectanglesPb;
        else if (this.selectedDpName) selectedItem = this.rectanglesDp;
        else if (this.selectedTextName) selectedItem = this.nameGroup;

        if (selectedItem) {
          const selectedIndex = selectedItem.findIndex((item) => item.name === selectedName);
          this.$delete(selectedItem, selectedIndex);
        }
      }
    },
    // tooltip
    updateTooltip(x, y, text) {
      this.tooltip.position({ x, y });
      this.tooltip.getChildren()[1].text(text);
    },
    showTooltip(e, item) {
      const rect = e.target;
      if (rect) {
        const stage = this.$refs.stage.getNode();
        const mousePos = stage.getPointerPosition();

        const x = (mousePos.x - stage.x()) / stage.scaleX();
        const y = (mousePos.y - stage.y()) / stage.scaleY() - 10;

        this.updateTooltip(x, y, item.evseNumber);
        this.tooltip.visible(true);
        this.$refs.stage.getNode().batchDraw();
      }
    },
    hideTooltip() {
      this.tooltip.visible(false);
      this.$refs.stage.getNode().batchDraw();
    },

  },
  watch: {
    evseList: {
      immediate: true,
      handler(val) {
        if (val != null) {
          [
            this.evseRects,
            this.rectanglesZone,
            this.rectanglesPb,
            this.rectanglesDp,
            this.nameGroup,
          ] = [[], [], [], [], []];
        }
        this.makeEvseRects();
        if (this.getMonitoringLocation !== null) this.arrangeRects();
      },
    },
  },
};
</script>

<style lang="scss">
.monitoring_wrap {
  height: 100vh;
  width: 60%;
  min-width: 700px;
  background-color: #e4e4e4;
  button {background-color: #fff;}
  .btn_wrap{ height:3%; }
  .konva_wrap {
    height: 97%;
    overflow: auto;
    .konva {width: 100%; height: 100%;}
  }
}
</style>
