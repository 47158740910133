<template>
  <NoticeSection/>
</template>

<script>
import NoticeSection from '@/components/community/notice/NoticeSection.vue';

export default {
  name: 'Notice',
  components: { NoticeSection },
};
</script>
