<template>
  <div class="fqa_ist">
    <v-tabs v-model="tab" class="d-flex justify-center">
      <v-tab
        v-for="tab in tabList"
        :key="tab.value"
      >
        {{ $t(`faq.${tab.name}`) }}
      </v-tab>
    </v-tabs>
    <div class="mt-10 listBox" v-if="items.length > 0">
      <ul v-for="(list, idx) in items" :key="idx">
        <li class="list_li">
          <div class="d-flex justify-space-between">
            <h4>
              <span v-if="list.title.match(/\[(.*?)\]\s+(.*)/)">
                [{{ $t(`faq.${list.title.match(/\[(.*?)\]\s+(.*)/)[1]}`) }}]
                {{ list.title.match(/\[(.*?)\]\s+(.*)/)[2] }}
              </span>
            </h4>
            <div>
              <v-btn icon small>
                <v-icon
                  small
                  @click="updateFAQ(list)"
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn icon small>
                <v-icon
                  small
                  @click="deleteFAQ(list._id)"
                >
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </div>
          <p>{{ list.content }}</p>
        </li>
      </ul>
    </div>
    <DlgCommonWrite
      :show.sync="editDialog.show"
      :title="editDialog.title"
      :sub="editDialog.sub"
      :type="editDialog.type"
      :formCheck="editDialog.formCheck"
      @submit="editDialog.submit"
    >
    <div class="align_center mb-4">
      <v-chip class="mr-2 flex-shrink-0" label color="primary">
        {{ $t('faq.category') }}
      </v-chip>
      <div style="width: 100%">
        <v-select
          :items="selectCategory"
          :item-text="categoryName"
          item-value="value"
          outlined
          dense
          hide-details
          :value="categoryValue"
          @change="changeCategory($event)"
          style="width:250px"
        ></v-select>
      </div>
    </div>
    <div class="align_center mb-4">
      <v-chip class="mr-2 flex-shrink-0" label color="primary">
        {{ $t('common.title') }}
      </v-chip>
      <div style="width: 100%">
        <v-text-field
          v-model="titleValue"
          @input="changeTitle($event, true)"
          outlined
          color="black"
          dense
          flat
          solo
          hide-details
        />
      </div>
    </div>
    <div class="align_center mb-4">
      <v-chip class="mr-2 flex-shrink-0" label color="primary">
        {{ $t('common.content') }}
      </v-chip>
      <div style="width: 100%">
        <v-textarea
          :value="editDialog.form.content"
          @input="changeContent($event, true)"
          outlined
          name="input-7-4"
          no-resize
          height="250px"
          hide-details
        />
      </div>
    </div>
  </DlgCommonWrite>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import DlgCommonWrite from '@/components/dialog/DlgCommonWrite.vue';

export default {
  name: 'FAQList',
  components: {
    DlgCommonWrite,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
    items: {
      type: Array,
      default: Array,
      required: true,
    },
    selectCategory: {
      type: Array,
      default: Array,
      required: true,
    },
    currentTab: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    tabList: [
      { name: 'all', value: 'all' },
      { name: 'charger', value: 'charger' },
      { name: 'payment', value: 'payment' },
      { name: 'certification', value: 'certification' },
      { name: 'page', value: 'page' },
    ],
    actionStatus: false,
    dialog: false,
    idValue: null,
    categoryValue: null,
    titleValue: null,
    contentValue: null,
    tab: 0,
    editDialog: {
      show: false,
      title: '',
      sub: null,
      type: 'update',
      formCheck: false,
      submit: () => {},
      form: {
        category: null,
        title: null,
        content: null,
      },
    },
    changeTabClose: 0,
  }),
  methods: {
    changeCategory(ee) {
      this.categoryValue = ee;
    },
    editFQA(list) {
      this.titleValue = list.q;
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    categoryName(item) {
      return this.$t(`faq.${item.name}`);
    },
    async updateFAQ(faq) {
      this.editDialog.form = faq;
      // eslint-disable-next-line prefer-destructuring
      this.categoryValue = (this.editDialog.form.title).match(/\[(.*?)\]\s+(.*)/)[1];
      // eslint-disable-next-line prefer-destructuring
      this.titleValue = (this.editDialog.form.title).match(/\[(.*?)\]\s+(.*)/)[2];
      this.contentValue = this.editDialog.form.content;
      this.idValue = this.editDialog.form._id;
      this.editDialog.show = true;
      this.editDialog.submit = this.saveEditFQAData;
    },
    initEditDialog() {
      this.editDialog = {
        show: false,
        title: this.$t('faq.editFaq'),
        sub: null,
        type: 'update',
        formCheck: false,
        submit: () => {},
        form: {
          category: null,
          title: null,
          content: null,
        },
      };
      this.titleValue = null;
      this.contentValue = null;
    },
    async saveEditFQAData() {
      this.$dialog.progress(true);
      const correctTitle = `[${this.categoryValue}] ${this.titleValue}`;
      try {
        await this.$emitter('post.update', {
          _id: this.idValue,
          title: correctTitle,
          content: this.contentValue,
        }, `${this.$t('common.registCompleteMessage')}`);
        this.initEditDialog();
        this.callback();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    changeTitle(value, event) {
      if (event) {
        this.titleValue = value;
      }
    },
    changeContent(value, event) {
      if (event) {
        this.contentValue = value;
      }
    },
    checkFAQEditValid() {
      if (this.editDialog.show !== true) return false;
      if (this.titleValue === null || this.titleValue === '') return false;
      if (this.contentValue === null || this.contentValue === '') return false;
      return true;
    },
    async deleteFAQ(item) {
      try {
        await this.$dialog.confirm({
          show: true,
          message: this.$t('common.delete'),
          color: 'error',
          btnText: this.$t('btn.delete'),
          callback: () => {
            const choiceIndex = this.items.indexOf(item);
            this.items.splice(choiceIndex, 1);
            this.$dialog.confirm();
            this.$dialog.alert('success', this.$t('common.applyCompleteMessage'));
            this.$emitter('post.delete', {
              _ids: [
                { _id: item },
              ],
            });
            this.callback();
          },
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
  },
  watch: {
    tab: {
      immediate: true,
      handler(value) {
        this.$emit('update:currentTab', this.tabList[value].value);
      },
    },
    'editDialog.form': {
      deep: true,
      handler(value) {
        this.editDialog.formCheck = this.checkFAQEditValid(value);
      },
    },
    titleValue: {
      deep: true,
      handler(value) {
        this.editDialog.formCheck = this.checkFAQEditValid(value);
      },
    },
    contentValue: {
      deep: true,
      handler(value) {
        this.editDialog.formCheck = this.checkFAQEditValid(value);
      },
    },
  },
};
</script>

<style lang="scss">
.fqa_ist{
  height: 80%;
  .listBox{
    height:100%;
    overflow-y: auto;
    overflow-x: hidden;
    .list_li{
      min-height: 100px;
      max-height: 150px;
      overflow-y: auto;
      overflow-x: hidden;
      border-bottom:1px solid #ddd;
      &:last-child{
        border-bottom: none;
      }
    }
  }
}
.no-focus{
  pointer-events: none;
}
</style>
