<template>
  <div class="outer_wrap">
    <div class="inner_wrap">
      <div class="title" v-if="companyInfo != null">
        <div class="company">
          <img v-if="logoSrc != null" :src="logoSrc" alt="logo">
          <p>{{ companyInfo.name }}</p>
        </div>
        <v-menu
          rounded="lg"
          offset-x
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="px-2"
            >
              <v-icon color="primary" size="25">mdi-translate</v-icon>
            </div>
          </template>
          <v-list dense>
            <v-list-item-group
              :value="$i18n.availableLocales.indexOf($i18n.locale)"
              color="success"
            >
              <v-list-item
                v-for="locale in $i18n.availableLocales"
                :key="locale"
                class="list-row pl-3"
                @click="$i18n.locale = locale"
              >
                <span class="list-row">{{ $t(`language.${locale}`, locale) }}</span>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
      <div class="box">
        <div class="form_wrap">
          <v-form
            class="form"
            @submit.prevent="signin"
          >
            <v-text-field
              autofocus
              :label="$t('admin_setting.adminId')"
              outlined
              color="primary"
              hide-details
              class="mb-5"
              v-model="user.id"
            ></v-text-field>
            <v-text-field
              :label="$t('admin_setting.adminPw')"
              outlined
              color="primary"
              type="password"
              hide-details
              v-model="user.password"
            ></v-text-field>
            <v-btn
              type="submit"
              block
              depressed
              color="primary"
              x-large
              class="mt-6 mb-2"
              :disabled="invalid"
            >
              {{ $t('btn.login') }}
            </v-btn>
          </v-form>
        </div>
        <p class="bottom_msg">
          Copyright © BLITZ technology. All rights reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import utilFile from '@/util/file';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SignBox',
  data: () => ({
    utilFile,
    user: {
      id: '',
      password: '',
    },
    invalid: true,
    logoSrc: null,
  }),
  computed: {
    ...mapGetters({
      companyInfo: 'auth/companyInfo',
    }),
  },
  methods: {
    ...mapActions({
      alert: 'dialog/alert',
      authorization: 'auth/authorization',
    }),
    async signin() {
      try {
        this.$dialog.progress(true);
        const { id, password } = this.user;
        const { token } = await this.$emitter('staff.token.get', {
          id,
          password,
        });
        localStorage.setItem(`BLITZadmin.${this.companyInfo.code}.token`, token);
        await this.authorization();
        await this.$router.push('/');
        this.$dialog.progress(false);
      } catch (error) {
        this.$dialog.progress(false);
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    checkLogoSrc(src) {
      const image = new Image();
      image.src = src;
      image.onload = () => {
        this.logoSrc = src;
      };
    },
  },
  watch: {
    companyInfo: {
      immediate: true,
      handler(value) {
        if (value != null) this.checkLogoSrc(this.utilFile.imagePathToSrc(value.logoPath));
      },
    },
    user: {
      deep: true,
      handler() {
        const id = this.user.id.trim();
        const password = this.user.password.trim();
        if (id === '' || password === '') {
          this.invalid = true;
        } else {
          this.invalid = false;
        }
      },
    },
  },
};
</script>
<style lang="scss">
.outer_wrap {
  width: 100vw !important;
  height: 100vh;
  .inner_wrap {
    .box {
      input {
        border: 0px !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.outer_wrap {
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #fafafa;
  flex-wrap: wrap;
  .inner_wrap {
    width: 400px;
    .title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .company{
        display: flex;
        flex-shrink: 0;
        align-items: center;
        img {
          width: 30px;
          margin-right: 4px;
        }
        p{
          width: 300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 700;
          font-size: 1.2rem;
        }
      }
    }
    .box {
      width: 100%;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: -5px -5px 20px #fff, 5px 5px 20px #BABECC;
      padding:30px;
      padding-bottom:20px;
      margin-bottom: 90px;

      .form_wrap{
        display: flex;
        justify-content: center;
        .form{
          width: 100%;
        }
      }
      .bottom_msg {
        width: 100%;
        text-align: center;
        margin-top:14px;
        color:#555;
        font-weight: 300;
        font-size: .8rem;
        margin-bottom: 8px;
      }
    }
  }
}
</style>
