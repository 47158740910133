<template>
  <UserPaymentSection/>
</template>

<script>
import UserPaymentSection from '@/components/user/userPayment/UserPaymentSection.vue';

export default {
  name: 'UserPayment',
  components: { UserPaymentSection },
};
</script>
