<template>
  <div class="section_wrap">
    <SectionHeader
      :loading="loading"
      :refresh="getData"
      :buttons="buttons"
    />
    <SearchFilter
      :filters.sync="options.filters"
      :loading="loading"
      :searchKeys="searchKeys"
      :callback="getData"
      :options.sync="options"
    />
    <ChargerAreaDataTable
      :items="items"
      :loading="loading"
      :options.sync="options"
      :pageOptions="pageOptions"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import time from '@/util/time';
import * as XLSX from 'xlsx';
import SearchFilter from '@/components/common/SearchFilter.vue';
import SectionHeader from '@/components/common/SectionHeader.vue';
import ChargerAreaDataTable from './unit/ChargerAreaDataTable.vue';

export default {
  /* eslint no-underscore-dangle: 0 */
  name: 'ChargerAreaSection',
  components: {
    ChargerAreaDataTable,
    SearchFilter,
    SectionHeader,
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    time,
    items: [],
    filters: [],
    loading: false,
    searchKeys: [
      {
        i18nKey: 'area.areaName',
        key: 'name',
      },
      {
        i18nKey: 'area.areaAddress',
        key: 'address',
      },
    ],
    options: {
      page: 1,
      itemsPerPage: 2,
      sortBy: ['created'],
      sortDesc: [true],
      filters: [],
    },
    pageOptions: {
      total: 0,
      pageCount: 0,
      itemsPerPageOptions: [10, 50],
    },
    buttons: [
      {
        color: 'success',
        icon: 'mdi-microsoft-excel',
        click: 'downloadExcel',
        i18nText: 'btn.excelDownload',
      },
    ],
  }),
  methods: {
    async getData() {
      this.loading = true;
      try {
        const {
          itemsPerPage,
          filters,
        } = this.options;
        const { data: { total } } = await this.$emitter('area.summary.get', { filters });
        this.pageOptions.total = total;
        this.pageOptions.pageCount = Math.ceil(total / itemsPerPage);
        const resultData = await this.$emitter('area.list.get', this.options);
        const newResultData = resultData.items.map((obj) => (
          obj.area !== undefined ? { ...obj, area: obj.area } : { ...obj, area: {} }
        ));
        this.items = newResultData.map((item) => {
          if (item.chargepoints.length > 0) {
            return {
              areaId: item._id,
              areaName: item.name,
              areaAddress: item.address,
              created: time.makeLocalTime(item.created),
              connectCp: item.chargepoints,
              cnt: item.chargepoints.length,
            };
          }
          return {
            areaId: item._id,
            areaName: item.name,
            areaAddress: item.address,
            created: time.makeLocalTime(item.created),
            connectCp: {},
            cnt: 0,
          };
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    downloadExcel() {
      const excludedColumns = ['areaId'];
      const columnMappings = {
        areaName: `${this.$t('area.areaName')}`,
        areaAddress: `${this.$t('area.areaAddress')}`,
        created: `${this.$t('common.registration')}`,
        cnt: `${this.$t('area.cnt')}`,
        connectCp: `${this.$t('area.connect')}`,
      };
      const filteredItems = this.items.map((item) => {
        const filteredItem = {};
        Object.keys(item).forEach((key) => {
          if (!excludedColumns.includes(key)) {
            const newColumnName = columnMappings[key] || key;
            if (key === 'created') {
              const createdDate = new Date(item[key]);
              const formattedCreatedDate = time.makeLocalTime(createdDate);
              filteredItem[newColumnName] = formattedCreatedDate;
            } else if (key === 'connectCp') {
              const cpArr = item.connectCp.map((cp) => cp.cpCode);
              filteredItem[newColumnName] = cpArr.join(', \n');
            } else {
              filteredItem[newColumnName] = item[key];
            }
          }
        });
        return filteredItem;
      });
      const excelData = XLSX.utils.json_to_sheet(filteredItems);
      const columnWidths = [
        { wch: 20 },
        { wch: 50 },
        { wch: 20 },
        { wch: 50 },
        { wch: 25 },
      ];
      excelData['!cols'] = columnWidths;

      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, excelData, 'charger_area_data');
      XLSX.writeFile(workBook, `charger_area_data_${time.makeAttachTime(new Date())}.xlsx`);
    },
  },
  watch: {
    staffInfo: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value != null) {
          this.getData();
        }
      },
    },
    options: {
      deep: true,
      async handler() {
        if (this.staffInfo !== null) await this.getData();
      },
    },
  },
};
</script>
