<template>
  <div class="company_info_wrap d-flex flex-column align-center justify-center">
    <div class="info_input_box" v-if="companyInfoCk">
      <!-- 이미지 -->
      <div class="input_line d-flex">
        <div class="wd150">
          <p class="companyEditText">
            {{ $t('common.logo') }}
          </p>
        </div>
        <div class="align_center">
          <input
            v-show="false"
            type="file"
            ref="fileInput"
            accept="image/*"
            :multiple="maxCount > 1"
            @change="fileChange({
              event: $event,
              maxCount,
              maxSize,
              accepts: ['png', 'jpg'],
              target: 'files',
            })"
          />
          <v-btn
            v-if="files.length < maxCount"
            elevation
            dense
            outlined
            class="pl-2 mb-2"
            @click="fileClick({
              target: 'files',
              ref: 'fileInput',
            })"
          >
            <v-icon class="mr-1">
              mdi-image-outline
            </v-icon>
            {{ $t('common.img') }}
          </v-btn>
          <div
            class="d-flex flex-wrap"
            v-if="files.length > 0 && !files[0].includes('default_logo')"
          >
            <div
              class="align_center"
              v-for="(file, index) in files"
              :key="index"
            >
              <img
                :src="utilFile.arrayBufferToBase64(files[0].data)"
              />
              <v-chip
                close
                class="ma-1"
                @click:close="fileRemove({
                  target: 'files',
                  file,
                })"
              >
                <strong>{{ file.name }}</strong>
                <span>({{ utilFile.byteTrans(file.size) }})</span>
              </v-chip>
            </div>
          </div>
          <div
            class="d-flex flex-wrap"
            v-if="files.length > 0 && files[0].includes('default_logo')"
          >
            <div
              class="align_center"
              v-for="(file, index) in files"
              :key="index"
            >
              <img :src="utilFile.imagePathToSrc(file)"/>
              <v-chip
                close
                class="ma-1"
                @click:close="fileRemove({
                  target: 'files',
                  file,
                })"
              >
                <strong>{{ file.split('/')[3] }}</strong>
              </v-chip>
            </div>
          </div>
        </div>
      </div>
      <div class="input_line d-flex">
        <div class="wd150">
          <p class="companyEditText">
            {{ $t('common.companyName') }}
          </p>
        </div>
        <div>
          <v-text-field
            class="setting_modal_input"
            outlined
            clearable
            v-model="companyName"
            :placeholder= "$t('common.companyName')"
            hide-details
          />
        </div>
      </div>
      <div class="input_line d-flex">
        <div class="wd150">
          <p class="companyEditText">
            {{ $t('common.companyAddress') }}
          </p>
        </div>
        <div>
          <v-text-field
            class="setting_modal_input"
            outlined
            clearable
            v-model="companyAddress"
            :placeholder= "$t('common.companyAddress')"
            hide-details
          />
        </div>
      </div>
      <div class="input_line d-flex">
        <div class="wd150">
          <p class="companyEditText">
            {{ $t('common.companyTel') }}
          </p>
        </div>
        <div>
          <v-text-field
            class="setting_modal_input"
            outlined
            clearable
            v-model="companyTel"
            :placeholder= "$t('common.companyTel')"
            hide-details
          />
        </div>
      </div>
      <div class="input_line d-flex">
        <div class="wd150">
          <p class="companyEditText">
            {{ $t('company_setting.ceoName') }}
          </p>
        </div>
        <div>
          <v-text-field
            class="setting_modal_input"
            outlined
            clearable
            v-model="ceoName"
            :placeholder= "$t('company_setting.ceoName')"
            hide-details
          />
        </div>
      </div>
      <div class="input_line d-flex">
        <div class="wd150">
          <p class="companyEditText">
            {{ $t('company_setting.companyNo') }}
          </p>
        </div>
        <div>
          <v-text-field
            class="setting_modal_input"
            outlined
            clearable
            v-model="taxId"
            :placeholder= "$t('company_setting.companyNo')"
            hide-details
          />
        </div>
      </div>
      <div class="input_line d-flex">
        <div class="wd150">
          <p class="companyEditText">
            {{ $t('company_setting.mosNum') }}
          </p>
        </div>
        <div>
          <v-text-field
            class="setting_modal_input"
            outlined
            clearable
            v-model="mosNum"
            :placeholder= "$t('company_setting.mosNum')"
            hide-details
          />
        </div>
      </div>
      <div class="input_line d-flex">
        <div class="wd150">
          <p class="companyEditText">
            {{ $t('company_setting.email') }}
          </p>
        </div>
        <div>
          <v-text-field
            class="setting_modal_input"
            outlined
            clearable
            v-model="email"
            :placeholder= "$t('company_setting.email')"
            hide-details
          />
        </div>
      </div>
      <div class="input_line d-flex">
        <div class="wd150">
          <p class="companyEditText">
            {{ $t('company_setting.currency') }}
          </p>
        </div>
        <!-- 통화 수정 -->
        <v-radio-group
          row
          class="align_center mt-6"
          :value="currency"
          @change="currency = $event"
        >
          <v-radio v-for="current in currencies"
            :key="current"
            :label="$t(`common.${current}`)"
            :value="current"
          ></v-radio>
        </v-radio-group>
      </div>
    </div>
    <div class="info_btn_box d-flex justify-center">
      <v-btn
        @click="init"
        depressed
      >
        {{ $t('btn.cancel') }}
      </v-btn>
      <v-btn
        color="primary"
        @click="updateCompanyInfo"
        depressed
        :disabled="
          companyName == null
          || companyName.length < 1
          || companyName.length > 48
        "
        class="mL10"
      >
        {{ $t('btn.edit') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import utilFile from '@/util/file';

export default {
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
      companyInfo: 'auth/companyInfo',
    }),
  },
  data: () => ({
    utilFile,
    companyName: null,
    companyAddress: null,
    companyTel: null,
    ceoName: null,
    taxId: null,
    email: null,
    mosNum: null,
    chargeRate: 0,
    maxCount: 1,
    maxSize: 10485760,
    files: [],
    currencies: ['krw', 'usd', 'jpy'],
    currency: null,
    companyInfoCk: false,
  }),
  methods: {
    ...mapMutations({
      setCompanyInfo: 'auth/companyInfo',
    }),
    init() {
      this.$emit('update:show', false);
      this.companyName = this.companyInfo.name;
      this.companyAddress = this.companyInfo.address;
      this.companyTel = this.companyInfo.tel;
      this.currency = this.companyInfo.currencyUnit;
      this.ceoName = this.companyInfo.ceoName;
      this.mosNum = this.companyInfo.mosNum;
      this.taxId = this.companyInfo.taxId;
      this.email = this.companyInfo.email;
      this.files = [];
      this.companyInfoCk = false;
    },

    async getCompanyInfo() {
      try {
        const { item: companyInfo } = await this.$emitter('sysconf.company.get', {});
        this.setCompanyInfo(companyInfo);
        if (companyInfo != null) {
          this.companyName = companyInfo.name;
          this.companyAddress = companyInfo.address;
          this.companyTel = companyInfo.tel;
          this.chargeRate = companyInfo.chargeRate;
          this.currency = companyInfo.currencyUnit;
          this.ceoName = companyInfo.ceoName;
          this.mosNum = companyInfo.mosNum;
          this.taxId = companyInfo.taxId;
          this.email = companyInfo.email;
          this.companyInfoCk = true;
        }
        if (this.companyInfo.logoPath !== null) this.files = [companyInfo.logoPath];
      } catch (error) {
        console.error(error);
        this.$dialog.progress(false);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },

    fileClick({
      target,
      maxCount,
      ref,
    }) {
      this.$refs[ref].value = null;
      if (this[target].length === maxCount) {
        this.$dialog.alert('error',
          `${this.$t('common.fileErrorFront')} ${maxCount} ${this.$t('common.fileErrorBack')}`);
      } else {
        this.$refs[ref].click();
      }
    },
    async fileChange({
      event,
      maxCount,
      maxSize,
      accepts,
      target,
    }) {
      this.$dialog.progress(true);
      const { files: selectedFiles } = event.target;
      if (selectedFiles.length + this[target].length > maxCount) {
        this.$dialog.alert('error',
          `${this.$t('common.fileErrorFront')} ${maxCount} ${this.$t('common.fileErrorBack')}`);
      } else if (selectedFiles.length > 0) {
        const filesArray = Object.values(selectedFiles);
        const invalidFileCheck = filesArray.reduce((acc, file) => {
          let isAccept = false;
          for (const accept of accepts) {
            if (file.name.includes(`.${accept}`)) isAccept = true;
          }
          if (isAccept === false && isAccept.length) acc.accept += 1;
          if (file.size > maxSize) acc.size += 1;
          return acc;
        }, {
          size: 0,
          accept: 0,
        });
        if (invalidFileCheck.accept > 0) {
          const types = accepts.length <= 0 ? accepts[0] : accepts.join(', ');
          this.$dialog.alert('error', `${this.$t('common.fileErrorExtensions')} [${types}]`);
        } else if (invalidFileCheck.size > 0) {
          this.$dialog.alert('error', `
            ${this.$t('common.fileErrorMaximumFront')} ${utilFile.byteTrans(maxSize)} ${this.$t('common.fileErrorMaximumBack')}
          `);
        } else {
          const map = filesArray.map((file) => utilFile.readFileToPayload(file));
          const filePayloads = await Promise.all(map);
          this[target] = [
            ...filePayloads,
            ...this[target],
          ];
        }
      }
      this.$dialog.progress(false);
    },
    async fileDownload(no) {
      try {
        const file = await this.$emitter('file.get', { no });
        await utilFile.writeFileFromPayload(file.item);
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    fileRemove({
      target,
      file,
    }) {
      this[target] = this[target].filter((currentFile) => currentFile.name !== file.name);
    },

    async updateCompanyInfo() {
      try {
        this.$dialog.progress(true);
        const updateData = {
          name: this.companyName,
          address: this.companyAddress,
          tel: this.companyTel,
          logoPath: this.files.length > 0 ? this.files[0] : null,
          currencyUnit: this.currency,
          mosNum: this.mosNum,
          taxId: this.taxId,
          ceoName: this.ceoName,
          email: this.email,
        };
        await this.$emitter('sysconf.company.update', updateData, `${this.$t('common.updateCompleteMessage')}`);
        this.getCompanyInfo();
        this.$dialog.progress(false);
      } catch (error) {
        console.error(error);
        this.$dialog.progress(false);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
  },
  watch: {
    staffInfo: {
      immediate: true,
      deep: true,
      async handler() {
        this.getCompanyInfo();
      },
    },
    '$i18n.locale': {
      handler() {
        this.getCompanyInfo();
      },
    },
  },
};
</script>

<style lang="scss">
.company_info_wrap{
  height:100%;
  .info_input_box{
    height:90%;
    padding:1%;
    overflow-y: auto;
    .input_line {
      height: 60px;
      line-height: 60px;
      + .input_line{
      margin-top:20px;
    }
    }
  }
  .info_btn_box{
    height:5%;
    >button{
      height: 100%;
    }
  }
  img{
    width:40px;
  }
  .companyEditText{
    line-height: 40px;
    position:relative;
    display: inline-block;
    font-weight: bold;
  }
}
</style>
