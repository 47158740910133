<template>
  <div class="search-wrap">
    <div class="filter-box" v-if="radios.length > 0 || selects.length > 0">
      <div class="filter-box-row">
        <div
          class="filter-box-wrap"
          v-for="(radio, idx) of radios"
          :key="idx"
        >
          <v-chip label class="px-2 mr-2">{{ $t(radio.i18nKey) }}</v-chip>
          <v-radio-group
            :disabled="loading"
            class="radio-box-group"
            row
            hide-details
            value="all"
            @change="updateFilters($event, radio.key, 'radio', radio.isSingle)"
          >
            <v-radio
              :label="$t('common.all')"
              value="all"
            />
            <v-radio
              v-for="value, index of radio.values"
              :key="index"
              :label="$t(radio.i18nLabels[index])"
              :value="conditions(radio, index, value)"
            />
          </v-radio-group>
        </div>
      </div>
      <div class="filter-box-row">
        <div
          class="filter-box-wrap"
          v-for="select of selects"
          :key="select.key"
        >
          <v-chip label class="px-2 mr-2">{{ $t(select.i18nKey) }}</v-chip>
          <v-select
            @change="updateFilters($event, select.key, 'select', select.isSingle)"
            value="all"
            return-object
            :disabled="select.permission ? loading : true"
            :items="[
              select.permission ?
              { text: $t('common.all'), value: 'all' }
              : { text: $t(select.i18nLabels[0]), value: 'all'},
              ...select.values.map((value, index) => ({
                text: select.isDirectLabel === true
                  ? select.i18nLabels[index]
                  : $t(select.i18nLabels[index]),
                value: {
                  where: 'and',
                  key: select.key,
                  condition: select.conditions[index],
                  value,
                },
              })),
            ]"
            outlined
            hide-details
            dense
          />
        </div>
      </div>
    </div>
    <div class="text-box">
      <div class="text-input-wrap">
        <v-text-field
          @keydown="$event.key === 'Enter' ? updateSearchFilters() : ''"
          v-model="searchText"
          class="text-input"
          color="black"
          dense
          flat
          solo
          hide-details
          :placeholder="placeholder"
        />
      </div>
      <v-btn
        depressed
        color="primary"
        class="search-btn"
        :loading="loading"
        @click="updateSearchFilters"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchFilter',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
    radios: {
      type: Array,
      default: Array,
    },
    selects: {
      type: Array,
      default: Array,
    },
    searchKeys: {
      type: Array,
      default: Array,
    },
    callback: {
      type: Function,
      default: () => {},
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    searchText: '',
    placeholder: null,
  }),
  methods: {
    updateFilters(current, key, type, isSingle) {
      const currentValue = type === 'radio' ? current : current.value;
      const dupplicatedFilter = [...this.filters];

      dupplicatedFilter[0].filters = dupplicatedFilter[0].filters
        .filter((row) => row.key !== key);

      if (currentValue !== 'all') {
        if (!isSingle) {
          dupplicatedFilter[1] = this.makeSearchFilters(currentValue);
        }
        dupplicatedFilter[0].filters.push(currentValue.length > 1 ? currentValue[0] : currentValue);
      }
      this.options.page = 1;
      this.$emit('update:filters', dupplicatedFilter);
    },
    updateSearchFilters() {
      const dupplicatedFilter = [...this.filters];
      dupplicatedFilter[1] = this.makeSearchFilters();
      this.options.page = 1;
      this.$emit('update:filters', dupplicatedFilter);
    },
    makeSearchFilters(value) {
      const filter = {
        where: 'and',
        filters: [],
      };
      if (this.searchText != null || this.searchText.trim().length > 0) {
        const searchValue = this.searchText.trim();
        const isNumber = !Number.isNaN(Number(searchValue));
        this.searchKeys.forEach((row) => {
          if (row.type === 'number') {
            filter.filters.push({
              where: 'or',
              condition: 'eq',
              key: row.key,
              value: isNumber ? Number(searchValue) : 0,
            });
          } else {
            filter.filters.push({
              where: 'or',
              condition: 'inc',
              key: row.key,
              value: searchValue,
            });
          }
        });
        if (value !== undefined && value.length > 1) {
          value[1].forEach((item) => {
            filter.filters.push(item);
          });
        }
      }
      return filter;
    },
    conditions(radio, index, value) {
      if (radio.addFilters) {
        const normal = {
          where: 'and',
          key: radio.key,
          condition: radio.conditions[index],
          value,
        };
        const overlap = [];
        radio.addFilters.forEach((item) => {
          overlap.push(
            {
              where: 'or',
              key: item.key,
              condition: item.conditions,
              value: item.values,
            },
          );
        });
        return [normal, overlap];
      }
      return {
        where: 'and',
        key: radio.key,
        condition: radio.conditions[index],
        value,
      };
    },
  },
  watch: {
    selects: {
      deep: true,
      handler() {},
    },
    '$i18n.locale': {
      immediate: true,
      handler() {
        this.placeholder = [...new Set(this.searchKeys.map((row) => this.$t(row.i18nKey)))].join(', ');
        const initFilters = [
          {
            where: 'and',
            filters: [],
          },
          {
            where: 'and',
            filters: [],
          },
        ];
        if (this.filters.length > 0) initFilters.push(...this.filters);
        this.$emit('update:filters', initFilters);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.search-wrap {
  margin-bottom: 10px;
  .filter-box {
    margin-bottom: 15px;
    padding: 15px;
    padding-bottom: 0px;
    border: 1px solid #eee;
    border-radius: 4px;
    width: 100%;
    .filter-box-row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .filter-box-wrap {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        margin-bottom: 15px;
        margin-right: 10px;
      }
    }
  }
  .text-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .text-input-wrap {
      width: 400px;
      .text-input {
        border: 1px solid #aaa;
      }
    }
    .search-btn {
      margin-left: -4px;
      border-radius: 0px 4px 4px 0px;
      height: 40px;
    }
  }
}
</style>
