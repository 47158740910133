<template>
  <div class="sign_section_wrap">
    <SignBox/>
  </div>
</template>

<script>
import SignBox from './unit/SignBox.vue';

export default {
  name: 'SignSection',
  components: {
    SignBox,
  },
};
</script>

<style>
.sign_section_wrap{
  height:100vh;
  width:100vw;
}
</style>
