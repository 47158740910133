<template>
  <AdminSection/>
</template>

<script>
import AdminSection from '@/components/setting/admin/AdminSection.vue';

export default {
  name: 'Admin',
  components: { AdminSection },
};
</script>
