<template>
  <div v-if="pricetable !== null && convertData.length > 0" class="price_table_wrap">
    <p class="pl-1 pb-1 table-header">{{ $t('cp.priceTable.seasonTime') }}</p>
    <v-simple-table dense class="price_table">
      <template v-slot:default>
        <thead>
          <tr>
            <th style="width: 16%"></th>
            <th
              style="width: 28%"
              class="text-center"
              v-for="(row, season) in convertData" :key="season"
            >
              {{ $t(`common.season.${season}`) }}
              <p>{{ row.months.map((month => $t(`common.month.${month}`))).join(', ') }}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in [0, 1, 2]" :key="i">
            <th class="text-center">
              {{ $t(`common.load.${i}`) }}
            </th>
            <td
              v-for="(row, season) in convertData"
              :key="season" style="white-space: pre-line;"
              class="text-center"
            >
              <span class="smT">{{ makeConvertTimesString(row.loads[i].times) }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <p class="pl-1 pb-1 mt-4 table-header">{{ $t('cp.chargeRateMethods.kwhAmount') }}</p>
    <v-simple-table dense class="price_table">
      <template v-slot:default>
        <thead>
          <tr>
            <th style="width: 16%"></th>
            <th
              style="width: 28%;"
              class="text-center"
              v-for="(row, season) in convertData" :key="season"
            >
              {{ $t(`common.season.${season}`) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in [0, 1, 2]" :key="i">
            <th class="text-center">
              {{ $t(`common.load.${i}`) }}
            </th>
            <td
              v-for="(row, season) in convertData"
              :key="season"
              class="text-center smT"
            >
              <span class="smT">
                {{
                  row.loads[i].price == null
                  ? '-'
                  : `${$t(`common.${companyInfo.currencyUnit}`)} ${row.loads[i].price}`
                }}
              </span>
              <div
                v-show="currentData.disable === false"
                :class="!currentData.disable && 'd-flex'"
                class="align-center mb-1 justify-center"
                v-for="(currentData, grade) in membershipPrice"
                :key="grade"
              >
                <v-chip x-small label class="px-1 mr-2" outlined>
                  {{ $t(`userGrades.${grade}`) }}
                </v-chip>
                <span class="mr-1">
                  {{ `${$t(`common.${companyInfo.currencyUnit}`)} ` }}
                </span>
                <span
                  v-if="currentData.method === 'fix'"
                  :class="Number(Number(currentData.value).toFixed(1)) <= 0 && 'error--text'"
                  class="smT"
                >
                  {{
                    Number(Number(currentData.value).toFixed(1)) > 0
                    ? Number(Number(currentData.value).toFixed(1))
                    : 0
                  }}
                </span>
                <span
                  v-else-if="currentData.method === 'rate'"
                  :class="
                    Number((Number(currentData.value) * (row.loads[i].price / 100)).toFixed(1)) <= 0
                    && 'error--text'
                  "
                  class="smT"
                >
                  {{
                    Number((Number(currentData.value) * (row.loads[i].price / 100)).toFixed(1)) > 0
                    ? Number((Number(currentData.value) * (row.loads[i].price / 100)).toFixed(1))
                    : 0
                  }}
                </span>
                <span
                  v-else-if="currentData.method === 'amount'"
                  :class="
                    Number((Number(currentData.value) + row.loads[i].price).toFixed(1)) <= 0
                    && 'error--text'
                  "
                  class="smT"
                >
                  {{
                    Number((Number(currentData.value) + row.loads[i].price).toFixed(1)) > 0
                    ? Number((Number(currentData.value) + row.loads[i].price).toFixed(1))
                    : 0
                  }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import format from '@/util/format';

export default {
  name: 'PriceTable',
  components: {
  },
  props: {
    pricetable: {
      type: Object,
      default: null,
      validator: (value) => value === null || typeof value === 'object',
    },
    membershipPrice: {
      type: Array,
      default: null,
      validator: (value) => value === null || Array.isArray(value),
    },
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
      companyInfo: 'auth/companyInfo',
    }),
  },
  data: () => ({
    format,
    convertData: [],
  }),
  methods: {
    makeConvertData() {
      this.convertData = this.pricetable.data.reduce((acc, row, month) => {
        for (let i = 0; i < row.times.length; i += 1) {
          const { load, price } = row.times[i];
          if (load != null) {
            if (acc[row.season].months.length === 0) {
              if (
                acc[row.season].loads[load].price === null
              ) acc[row.season].loads[load].price = price;
              acc[row.season].loads[load].times.push(i);
            } else {
              break;
            }
          }
        }
        acc[row.season].months.push(month);
        return acc;
      }, [
        {
          months: [],
          loads: {
            0: {
              times: [],
              price: null,
            },
            1: {
              times: [],
              price: null,
            },
            2: {
              times: [],
              price: null,
            },
          },
        },
        {
          months: [],
          loads: {
            0: {
              times: [],
              price: null,
            },
            1: {
              times: [],
              price: null,
            },
            2: {
              times: [],
              price: null,
            },
          },
        },
        {
          months: [],
          loads: {
            0: {
              times: [],
              price: null,
            },
            1: {
              times: [],
              price: null,
            },
            2: {
              times: [],
              price: null,
            },
          },
        },
      ]);
    },
    makeConvertTimesString(times) {
      if (times.length === 0) return '-';
      let result = '';
      let startRange = times[0];
      for (let i = 1; i < times.length; i += 1) {
        if (times[i] !== times[i - 1] + 1) {
          result += `${startRange === times[i - 1]
            ? `${this.$t(`common.time.${startRange}`)}-${this.$t(`common.time.${startRange + 1}`)}`
            : `${this.$t(`common.time.${startRange}`)}-${this.$t(`common.time.${times[i - 1] + 1}`)}`}\n`;
          startRange = times[i];
        }
      }
      result += (startRange === times[times.length - 1]
        ? `${this.$t(`common.time.${startRange}`)}-${this.$t(`common.time.${startRange + 1}`)}`
        : `${this.$t(`common.time.${startRange}`)}-${this.$t(`common.time.${times[times.length - 1] + 1}`)}`);
      return result;
    },
  },
  watch: {
    pricetable: {
      immediate: true,
      handler(value) {
        if (value !== null) this.makeConvertData();
      },
    },
  },
};
</script>

<style lang="scss">
.price_table_wrap {
  > .table-header {
    font-size: .9rem;
    font-weight: bold;
    color: #333;
  }
  > .price_table {
    border: 1px solid #ddd;
    > .v-data-table__wrapper {
      > table {
        margin-top: 0px;
        > tbody, thead {
          > tr {
            > th {
              color: #333;
              p {
                color: #666;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
}
</style>
