<template>
  <div class="total-wrap">
    <CommonTotalChart
      :key=chartKey
      :chartTitle="'dashboard.dateCardSales'"
      :chart="'bar'"
      :options="options"
      :form="'amount'"
    />
  </div>
</template>

<script>
import CommonTotalChart from './CommonTotalChart.vue';

export default {
  components: { CommonTotalChart },
  data: () => ({
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
        },
      },
      dataLabels: {
        enabled: false,
      },
    },
    chartKey: 0,
  }),
  props: {
    refreshChart: {
      type: Boolean,
    },
  },
  methods: {
    reloadChart() {
      this.chartKey += 1;
    },
  },
  watch: {
    refreshChart: {
      handler(val) {
        if (val) this.reloadChart();
      },
    },
  },
};
</script>

<style lang="scss">
.total-wrap{
  width: 49.5%;
}
</style>
