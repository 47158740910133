<template>
  <ChargingCostSection/>
</template>

<script>
import ChargingCostSection from '@/components/setting/chargingCost/ChargingCostSection.vue';

export default {
  name: 'company',
  components: { ChargingCostSection },
};
</script>
