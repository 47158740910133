<template>
  <ChargerModelsSection/>
</template>

<script>
import ChargerModelsSection from '@/components/charger/chargerModels/ChargersModelsSection.vue';

export default {
  name: 'Charger',
  components: { ChargerModelsSection },
};
</script>
