<template>
  <div>
    <div class="align_center justify-space-between">
      <div class="body-2">
        <b v-if="pageOptions.total <= 0">0</b>
        <b v-else class="primary--text">
          {{
            options.page === 1
            ? items.length
            : ((options.page - 1) * options.itemsPerPage) + items.length
          }}
        </b>
        /
        <span>{{ pageOptions.total }}</span>
      </div>
      <v-btn-toggle
        class="my-2"
        v-if="pageOptions.itemsPerPageOptions != null && pageOptions.itemsPerPageOptions.length > 0"
        @change="updateOptions('itemsPerPage', $event)"
        mandatory
        dense
      >
        <v-btn v-for="number of pageOptions.itemsPerPageOptions" :key="number">
          {{ `${number}${$t('dataTable.itemsPerPageOption')}` }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-simple-table class="data_table">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-left"
              style="width:60%;"
            >
              {{ $t('price_setting.priceName') }}
            </th>
            <th
              class="text-left"
              style="width:20%; text-align: center !important;"
            >
              {{ $t('price_setting.chargepointCount') }}
            </th>
            <th class="text-left">
              {{ $t('common.registration') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(list, index) in items"
            :key="index"
            @mouseover="hoverList(index)"
            @mouseleave="hoverState = null"
          >
            <td
              class="titleClick"
              @click="clickedName(list._id)"
            >
              {{ list.name }}
            </td>
            <td style="text-align: center;">
              {{ list.chargepointCount }}
            </td>
            <td class="align_center justify-space-between">
              {{ time.makeLocalTime(list.created) }}
              <div
                v-if="hoverState == index
                && $checkPermission(staffInfo, 'charging_cost.write')"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small>
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="duplicationClick(list)"
                      >
                        mdi-file
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('btn.duplication')}}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      @click="deleteClick(list)"
                    >
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('btn.delete')}}</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-pagination
      small
      depressed
      v-show="pageOptions.pageCount > 0"
      :value="options.page"
      :length="pageOptions.pageCount"
      :total-visible="10"
      @input="updateOptions('page', $event)"
    ></v-pagination>
    <DlgCommonWrite
      :show.sync="editDialog.show"
      :title="editDialog.title"
      :sub="editDialog.sub"
      :type="editDialog.type"
      :formCheck="editDialog.formCheck"
      @submit="editDialog.submit"
      width="1300"
    >
      <CostEditModal
        :form.sync="editDialog.form"
        :show="editDialog.show"
      />
    </DlgCommonWrite>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapGetters } from 'vuex';
import time from '@/util/time';
import CostEditModal from './CostEditModal.vue';
import DlgCommonWrite from '../../../dialog/DlgCommonWrite.vue';

export default {
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
    items: {
      type: Array,
      default: Array,
      required: true,
    },
    pageOptions: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  components: {
    DlgCommonWrite,
    CostEditModal,
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    time,
    clickedList: null,
    clickedListName: null,
    editDialog: {
      show: false,
      title: '',
      sub: null,
      type: 'update',
      formCheck: false,
      submit: () => {},
      form: {
        _id: null,
        name: null,
        data: [],
      },
    },
    seasonList: [],
    hoverState: null,
    costList: [],
  }),
  methods: {
    initHeaders() {
      this.headers = [
        {
          text: this.$t('price_setting.priceName'),
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('common.registration'),
          value: 'updated',
          align: 'center',
        },
      ];
    },

    async clickedName(value) {
      this.initEditDialog();
      try {
        const { item } = await this.$emitter('pricetable.get', {
          _id: value,
        });
        this.editDialog.form = {
          _id: item._id,
          name: item.name,
          data: item.data,
        };
        this.editDialog.show = true;
        this.editDialog.submit = this.saveEditCost;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },

    initEditDialog() {
      this.editDialog = {
        show: false,
        title: this.$t('price_setting.priceTable'),
        sub: null,
        type: 'update',
        formCheck: false,
        submit: () => {},
        form: {
          _id: null,
          name: null,
          data: [],
        },
      };
    },
    async saveEditCost() {
      try {
        await this.$emitter('pricetable.update', {
          _ids: [
            { _id: this.editDialog.form._id },
          ],
          name: this.editDialog.form.name,
          data: this.editDialog.form.data,
        }, this.$t('common.updateCompleteMessage'));
        this.initEditDialog();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.commentValue = null;
      await this.callback();
    },
    checkEditValid(form) {
      const {
        name,
      } = form;
      if (this.editDialog.show !== true) return false;
      if (name === null) return false;
      return true;
    },
    async duplicationClick(value) {
      const callback = async () => {
        this.$dialog.progress(true);
        try {
          await this.$emitter('pricetable.add', {
            items: [{
              name: `${value.name}_copy`,
              data: value.data,
            }],
          }, this.$t('common.applyCompleteMessage'));
          this.$dialog.confirm();
          this.$dialog.alert('success', `${this.$t('common.applyCompleteMessage')}`);
          await this.callback();
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
        this.$dialog.progress(false);
      };
      await this.$dialog.confirm({
        show: true,
        message: `
          ${this.$t('price_setting.priceName')} : ${value.name}<br/><br/>
          ${this.$t('price_setting.duplicationCostBoard')}
        `,
        color: 'primary',
        btnText: this.$t('btn.duplication'),
        callback,
      });
    },
    async deleteClick(value) {
      const callback = async () => {
        this.$dialog.progress(true);
        try {
          await this.$emitter('pricetable.delete', {
            _ids: [
              { _id: value._id },
            ],
          }, this.$t('common.applyCompleteMessage'));
          this.$dialog.confirm();
          this.$dialog.alert('success', `${this.$t('common.applyCompleteMessage')}`);
          await this.callback();
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
        this.$dialog.progress(false);
      };
      await this.$dialog.confirm({
        show: true,
        message: `
          ${this.$t('price_setting.priceName')} : ${value.name}<br/><br/>
          ${this.$t('common.delete')}
        `,
        color: 'error',
        btnText: this.$t('btn.delete'),
        callback,
      });
    },
    hoverList(index) {
      this.hoverState = index;
    },
    updateOptions(key, value) {
      let options = {
        ...this.options,
        [key]: value,
      };
      if (key === 'itemsPerPage') {
        options = {
          ...this.options,
          page: 1,
          itemsPerPage: this.pageOptions.itemsPerPageOptions[value],
        };
      }
      this.$emit('update:options', options);
    },
  },
  watch: {
    staffInfo: {
      immediate: true,
      async handler() {
        this.initHeaders();
      },
    },
    '$i18n.locale': {
      handler() {
        this.initHeaders();
      },
    },
    'editDialog.form': {
      deep: true,
      handler(value) {
        this.editDialog.formCheck = this.checkEditValid(value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.titleClick{
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
</style>
