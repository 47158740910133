<template>
  <div class="d-flex justify-space-between pb-2 align-end">
    <div class="header-title">
      <p class="category-name">
        {{
          $route.meta.category != null
          ? $t(`menu.${$route.meta.category}`)
          : ''
        }}
      </p>
      <p class="page-name align_center">
        {{ $t(`menu.${$route.meta.key}`) }}
        <v-tooltip top color="black" v-if="refresh != null">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="refresh"
              :loading="loading"
              icon
              class="mt-1"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.refresh') }}</span>
        </v-tooltip>
      </p>
    </div>
    <div class="d-flex" v-if="buttons != null">
      <div
        v-for="button, index in buttons"
        :key="index"
      >
        <v-tooltip
          v-if="button.permission == null || $checkPermission(staffInfo, button.permission)"
          top color="black"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-2 ml-2"
              @click="clickEvent(button.click)"
              :loading="loading"
              :color="button.color"
              depressed
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="24">{{ button.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t(button.i18nText) }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SectionHeader',
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  props: {
    refresh: {
      type: Function,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default: Array,
    },
  },
  data: () => ({
  }),
  methods: {
    clickEvent(event) {
      if (this.$parent[event]) this.$parent[event]();
    },
  },
  watch: {
  },
};
</script>

<style scoped lang="scss">
.header-title {
  .category-name {
    font-size: .8rem;
    color: #aaa;
    height: 20px;
  }
  .page-name {
    font-size: 1.6rem;
    font-weight: bold;
    color: #333;
  }
}
</style>
