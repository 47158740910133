  <template>
  <div class="cost_wrap section_wrap">
    <SectionHeader
      :loading="loading"
      :buttons="buttons"
      :refresh="getPriceData"
    />
    <SearchFilter
      :filters.sync="options.filters"
      :loading="loading"
      :searchKeys="searchKeys"
      :callback="getPriceData"
      :options.sync="options"
    />
    <DlgCommonWrite
      :show.sync="writeDialog.show"
      :title="writeDialog.title"
      :sub="writeDialog.sub"
      :type="writeDialog.type"
      :formCheck="writeDialog.formCheck"
      @submit="writeDialog.submit"
      width="1300"
    >
      <CostCreateModal
        :show="writeDialog.show"
        :form.sync="writeDialog.form"
      />
    </DlgCommonWrite>
    <div class="cost_box_list">
      <CostList
        :loading="loading"
        :callback="getPriceData"
        :items.sync="items"
        :options.sync="options"
        :pageOptions="pageOptions"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SectionHeader from '@/components/common/SectionHeader.vue';
import DlgCommonWrite from '@/components/dialog/DlgCommonWrite.vue';
import SearchFilter from '@/components/common/SearchFilter.vue';
import CostCreateModal from './unit/CostCreateModal.vue';
import CostList from './unit/CostList.vue';

export default {
  components: {
    SectionHeader,
    DlgCommonWrite,
    SearchFilter,
    CostCreateModal,
    CostList,
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    loading: false,
    items: [],
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['created'],
      sortDesc: [true],
      filters: [],
    },
    pageOptions: {
      total: 0,
      pageCount: 0,
      itemsPerPageOptions: [10, 50],
    },
    searchKeys: [
      {
        i18nKey: 'price_setting.priceName',
        key: 'name',
      },
    ],
    buttons: [
      {
        color: 'primary',
        icon: 'mdi-plus',
        click: 'openWriteDialog',
        i18nText: 'price_setting.priceNew',
        permission: 'charging_cost.write',
      },
    ],
    writeDialog: {
      show: false,
      title: '',
      sub: null,
      type: 'regist',
      formCheck: false,
      submit: () => {},
      form: {
        name: null,
        data: [],
      },
    },
  }),
  methods: {
    async getPriceData() {
      this.loading = true;
      try {
        const {
          itemsPerPage,
          filters,
        } = this.options;
        const { data: { total } } = await this.$emitter('pricetable.summary.get', { filters });
        this.pageOptions.total = total;
        this.pageOptions.pageCount = Math.ceil(total / itemsPerPage);
        const resultData = await this.$emitter('pricetable.list.get', this.options);
        this.items = resultData.items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },

    async openWriteDialog() {
      this.initWriteDialog();
      this.writeDialog.show = true;
      this.writeDialog.submit = this.addPricetable;
    },

    initWriteDialog() {
      this.writeDialog = {
        show: false,
        title: this.$t('price_setting.priceNew'),
        sub: null,
        type: 'regist',
        formCheck: false,
        submit: () => {},
        form: {
          name: null,
          data: [],
        },
      };
    },

    async addPricetable() {
      this.$dialog.progress(true);
      const {
        name,
        data,
      } = this.writeDialog.form;
      try {
        await this.$emitter('pricetable.add',
          {
            items: [{
              name,
              data,
            }],
          }, this.$t('common.registCompleteMessage'));
        this.initWriteDialog();
        await this.getPriceData();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },

    checkCostWriteValid(form) {
      const {
        name,
      } = form;
      if (this.writeDialog.show !== true) return false;
      if (name === null) return false;
      return true;
    },
  },
  watch: {
    staffInfo: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value != null) {
          this.getPriceData();
        }
      },
    },
    options: {
      deep: true,
      async handler() {
        if (this.staffInfo !== null) await this.getPriceData();
      },
    },
    'writeDialog.form': {
      deep: true,
      handler(value) {
        this.writeDialog.formCheck = this.checkCostWriteValid(value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.cost_wrap{
  .cost_box_list{
    height: calc(100vh - 180px);
  }
}
</style>
