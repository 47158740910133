<template>
  <ChargersSection/>
</template>

<script>
import ChargersSection from '@/components/charger/chargers/ChargersSection.vue';

export default {
  name: 'Charger',
  components: { ChargersSection },
};
</script>
