<template>
  <div class="data_table_wrap">
    <div class="align_center justify-space-between">
      <div class="body-2">
        <b v-if="pageOptions.total <= 0">0</b>
        <b v-else class="primary--text">
          {{
            options.page === 1
            ? items.length
            : ((options.page - 1) * options.itemsPerPage) + items.length
          }}
        </b>
        /
        <span>{{ pageOptions.total }}</span>
      </div>
      <v-btn-toggle
        class="my-2"
        v-if="pageOptions.itemsPerPageOptions != null && pageOptions.itemsPerPageOptions.length > 0"
        @change="updateOptions('itemsPerPage', $event)"
        mandatory
        dense
      >
        <v-btn v-for="number of pageOptions.itemsPerPageOptions" :key="number">
          {{ `${number}${$t('dataTable.itemsPerPageOption')}` }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-data-table
      mobile-breakpoint="0"
      fixed-header
      :headers="headers"
      :items="items"
      :loading="loading"
      disable-pagination
      hide-default-footer
      dense
      sort-by="type"
      class="data_table"
    >
      <template v-slot:top>
        <v-dialog
          v-model="setDialog"
          max-width="600px"
        >
          <v-card>
            <v-container style="padding:5%;">
              <v-row
                style="display: flex; flex-direction: column;"
              >
                <h3>
                  {{editedItem.id}}({{ editedItem.name }}) {{ $t('common.setting') }}
                </h3>
                <v-divider></v-divider>
                <div
                  class="flex_center"
                  style="flex-direction: column;"
                >
                  <div style="height:100%;">
                    <div class="align_center mT10">
                      <v-chip @click="pwReset">
                        <strong>{{ $t('common.passwordRe') }}</strong>
                        <span class="smT">({{ $t('common.passwordReDes') }})</span>
                      </v-chip>
                    </div>
                  </div>
                  <div style="height:100%;">
                    <div class="align_center mT10">
                      <v-chip @click="permissionReset">
                        <strong>{{ $t('common.authorityRe') }}</strong>
                        <span class="smT">({{ $t('common.authorityReDes') }})</span>
                      </v-chip>
                    </div>
                  </div>
                </div>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <div>
          <v-icon
            @click="setItem(item)"
          >
            mdi-cog
          </v-icon>
        </div>
      </template>
      <template v-slot:item.id="{item}">
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{format.makeMaskName(item.id)}}
            </span>
          </template>
          <span>{{ item.id }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.name="{item}">
        <div class="align_center">
          <p style="min-width:100px;">
            {{ item.name }}
          </p>
          <v-menu
            rounded="lg"
            transition="slide-y-transition"
            offset-x
            :close-on-content-click="false"
            width="300"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    icon
                    class="ml-1"
                    @click="currentStaffName = item.name"
                  >
                    <v-icon small color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ `${$t('admin_setting.adminName')} ${$t('common.update')}` }}</span>
              </v-tooltip>
            </template>
            <v-list class="d-flex">
              <v-text-field
                color="black"
                dense
                flat
                solo
                hide-details
                autofocus
                v-model="currentStaffName"
                :placeholder="$t('placeholders.cp.cpId')"
                @keydown="$event.key === 'Enter'
                  ? updateStaff('name', item, { name: currentStaffName }) : ''"
              />
              <v-btn
                depressed
                class="mr-2"
                color="primary"
                @click="updateStaff('name', item, { name: currentStaffName })"
              >
                {{ $t('common.update') }}
              </v-btn>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:item.type="{item}">
        <div class="align_center">
          <p style="min-width:70px;">
            {{$t(`staffType.${item.type}`)}}
          </p>
          <v-menu
            v-if="$checkPermission(staffInfo, 'admin_settings.write')"
            rounded="lg"
            transition="slide-y-transition"
            offset-x
            max-height="300"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    icon
                  >
                    <v-icon small color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ `${$t('admin_setting.adminType')} ${$t('common.update')}` }}</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item
                v-for="list in typeList"
                :key="list"
                @click="updateStaff('type', item, { type: list })"
              >
                <span
                  class="text-subtitle-2 mr-2"
                  :class="list == item.type ? 'select_item':''"
                >
                  {{$t(`staffType.${list}`)}}
                </span>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:item.manageAreaInfo="{item}">
        <div class="d-flex align-center">
          <div class="d-flex flex-column" style="width: 60%;">
            <div v-if="item.manageAreaInfo.isMaster">
              <p>{{ $t('admin_setting.workplace.entire') }}</p>
            </div>
            <div
              v-else
              v-for="list, idx in item.manageAreaInfo.areas"
              :key="idx"
            >
              <p class="py-1">{{ list.name }}</p>
            </div>
          </div>
          <v-tooltip top color="black" style="width: 30%;">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="openUpdateWorkplace(item)"
              >
                <v-icon small color="primary">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('admin_setting.workplace.update') }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.approve="{item}">
        <v-tooltip
          top
          color="black"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-if="item.approve"
              label
              small
              outlined
              color="info"
              class="px-2"
              v-bind="attrs"
              v-on="on"
              @click="updateStaff('approve', item, { approve: false })"
            >
              {{ $t(`common.${item.approve}`) }}
            </v-chip>
            <v-chip
              v-else
              label
              small
              outlined
              color="error"
              class="px-2"
              v-bind="attrs"
              v-on="on"
              @click="updateStaff('approve', item, { approve: true })"
            >
              {{ $t(`common.${item.approve}`) }}
            </v-chip>
          </template>
          <span>
            {{ `${$t('admin_setting.adminApprove')} ${$t('common.update')}` }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.updated="{item}">
        {{ time.makeLocalTime(item.updated) }}
      </template>
      <template v-slot:no-data>
        <p>{{ $t('common.noData') }}</p>
      </template>
    </v-data-table>
    <v-pagination
      small
      depressed
      v-show="pageOptions.pageCount > 0"
      :value="options.page"
      :length="pageOptions.pageCount"
      :total-visible="10"
      @input="updateOptions('page', $event)"
    ></v-pagination>
    <v-dialog
      v-model="openWorkspaceList"
      width="500"
      @click:outside="workplaceDigInit()"
    >
      <v-card>
        <div class="d-flex pt-4 px-6 align-center">
          <p class="tit pb-0">{{ $t('admin_setting.workplace.update') }}</p>
          <p class="sub pl-2">{{ `(${selectedStaff.name})` }}</p>
        </div>
        <v-list class="d-flex flex-column">
          <v-list-item>
            <v-icon
              :color="allSelected ? 'primary' : ''"
              @click="toggleAll"
              style="cursor: pointer"
            >
              {{ allSelected ? 'mdi-close-box' : 'mdi-checkbox-blank-outline' }}
            </v-icon>
            <v-list-item-title style="padding-left: 6px;">
              {{ allSelected
                ? `${$t('admin_setting.workplace.deselect')}`
                : `${$t('admin_setting.workplace.selectAll')}` }}
            </v-list-item-title>
            <v-list-item class="flex-end">
              <v-switch
                v-model="showWorkPlaceList"
                inset
                :label="$t('admin_setting.workplace.master')"
                hide-details
              ></v-switch>
            </v-list-item>
          </v-list-item>
        </v-list>
        <v-divider class="mt-1"></v-divider>
        <v-list
          v-if="!showWorkPlaceList"
          class="workplace-dialog"
        >
          <v-list-item
            v-for="workplace, idx in workplaceList"
            :key="idx"
          >
            <v-checkbox
              v-model="selectedWorkplaces"
              :value="workplace._id"
              :label="workplace.name"
              hide-details
            >
            </v-checkbox>
          </v-list-item>
        </v-list>
        <div class="d-flex justify-end pa-4">
          <v-btn depressed class="mr-2" @click="workplaceDigInit">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn color="primary" depressed @click="updateStaff('workplace')">
            {{ $t(`common.update`) }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapGetters, mapMutations } from 'vuex';
import time from '@/util/time';
import format from '@/util/format';

export default {
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
    items: {
      type: Array,
      default: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    pageOptions: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    workplaceList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    time,
    format,
    editDialog: false,
    setDialog: false,
    currentType: null,
    currentApprove: null,
    headers: [],
    currentStaffName: null,
    editedItem: {
      id: '',
      name: '',
      type: '',
      approve: '',
      created: '',
    },
    editedIndex: -1,
    typeList: [0, 1, 2],
    approveList: [
      { text: 'true', value: true },
      { text: 'false', value: false },
    ],
    openWorkspaceList: false,
    selectedWorkplaces: [],
    selectedStaff: '',
    allSelected: false,
    showWorkPlaceList: false,
  }),
  methods: {
    ...mapMutations({
      confirm: 'dialog/confirm',
    }),
    changePageNum(value) {
      this.$emit('update:page', value);
    },
    selectType(event) {
      this.currentType = event;
    },
    selectApprove(event) {
      this.currentApprove = event;
    },
    editItem(item) {
      this.editDialog = true;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.currentType = item.type;
    },
    editClose() {
      this.editDialog = false;
      this.callback();
    },

    async updateStaff(type, staff, argument) {
      this.$dialog.progress(true);
      try {
        if (type === 'workplace') {
          await this.$emitter('staff.update', {
            _ids: [{ _id: this.selectedStaff._id }],
            'manageAreaInfo.isMaster': this.allSelected,
            'manageAreaInfo.area_ids': this.selectedWorkplaces,
          });
          this.openWorkspaceList = false;
        } else {
          await this.$emitter('staff.update', {
            _ids: [
              { _id: staff._id },
            ],
            ...argument,
          }, this.$t('common.updateCompleteMessage'));
        }
        await this.callback();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },

    async pwReset() {
      try {
        await this.$dialog.confirm({
          show: true,
          message: `${this.$t('admin_setting.wantResetPw')}`,
          color: 'error',
          btnText: `${this.$t('btn.check')}`,
          callback: () => {
            this.$dialog.confirm();
            this.$dialog.alert('success', `${this.$t('common.applyCompleteMessage')}`);
            this.$emitter('staff.password.reset', {
              _id: this.editedItem._id,
            });
          },
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    async permissionReset() {
      try {
        await this.$dialog.confirm({
          show: true,
          message: `${this.$t('admin_setting.wantResetPermission')}`,
          color: 'error',
          btnText: `${this.$t('btn.check')}`,
          callback: () => {
            this.$dialog.confirm();
            this.$dialog.alert('success', `${this.$t('common.applyCompleteMessage')}`);
            this.$emitter('staff.permission.reset', {
              _id: this.editedItem._id,
            });
          },
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },

    setItem(item) {
      this.setDialog = true;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
    },
    initHeaders() {
      this.headers = [
        {
          text: this.$t('admin_setting.adminId'),
          align: 'left',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$t('admin_setting.adminName'),
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('admin_setting.adminType'),
          value: 'type',
        },
        {
          text: this.$t('admin_setting.workplace.title'),
          value: 'manageAreaInfo',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('admin_setting.adminApprove'),
          value: 'approve',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('common.registration'),
          value: 'updated',
          align: 'center',
        },
        {
          text: this.$t('common.function'),
          value: 'actions',
          sortable: false,
          align: 'center',
        },
      ];
    },
    updateOptions(key, value) {
      let options = {
        ...this.options,
        [key]: value,
      };
      if (key === 'itemsPerPage') {
        options = {
          ...this.options,
          page: 1,
          itemsPerPage: this.pageOptions.itemsPerPageOptions[value],
        };
      }
      this.$emit('update:options', options);
    },
    openUpdateWorkplace(item) {
      this.openWorkspaceList = true;
      this.selectedStaff = item;
      this.showWorkPlaceList = false;
      if (item.manageAreaInfo.isMaster) {
        this.showWorkPlaceList = true;
        this.workplaceList.forEach((place) => {
          this.selectedWorkplaces.push(place._id);
        });
      } else this.selectedWorkplaces = [];
      item.manageAreaInfo.areas.forEach((place) => {
        if (!this.selectedWorkplaces.includes(place._id)) {
          this.selectedWorkplaces.push(place._id);
        }
      });
    },
    toggleAll() {
      if (this.allSelected) {
        this.selectedWorkplaces = [];
      } else {
        this.selectedWorkplaces = this.workplaceList.map((w) => w._id);
      }
    },
    workplaceDigInit() {
      this.openWorkspaceList = false;
      this.selectedWorkplaces = [];
    },
  },
  watch: {
    staffInfo: {
      immediate: true,
      async handler() {
        this.initHeaders();
      },
    },
    '$i18n.locale': {
      handler() {
        this.initHeaders();
      },
    },
    sort: {
      deep: true,
      handler(value) {
        this.options.page = 1;
        if (value.sortBy == null && value.sortDesc == null) {
          this.$emit('update:options', {
            ...this.options,
            sortBy: [],
            sortDesc: [],
          });
        } else if (value.sortBy != null && value.sortDesc != null) {
          this.$emit('update:options', {
            ...this.options,
            sortBy: [value.sortBy],
            sortDesc: [value.sortDesc],
          });
        }
      },
    },
    selectedWorkplaces: {
      handler(val) {
        this.allSelected = val.length === this.workplaceList.length;
      },
    },
    showWorkPlaceList: {
      handler(val) {
        if (val) this.allSelected = val;
      },
    },
  },
};
</script>

<style lang="scss">
.workplace-dialog {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-height: 300px;
  overflow-y: auto;
}
</style>
