/* eslint-disable no-console */
import Vue from 'vue';
import { register } from 'register-service-worker';
import i18n from './i18n';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.info(
        'App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      console.info('Service worker has been registered.');
    },
    cached() {
      console.info('Content has been cached for offline use.');
    },
    updatefound() {
      console.info('New content is downloading.');
      Vue.prototype.$dialog.progress(true);
      Vue.prototype.$dialog.alert('info', i18n.t('common.updateRefreshMessage'));
    },
    updated(registration) {
      console.info('New content is available; please refresh.');
      Vue.prototype.$dialog.progress(true);
      const worker = registration.waiting;
      if (worker) {
        worker.addEventListener('statechange', (event) => {
          if (event.target.state === 'activated') {
            Vue.prototype.$dialog.alert('info', i18n.t('common.updateRefreshMessage'));
            setTimeout(() => {
              window.location.reload(true);
            }, 2000);
          }
        });
        worker.postMessage({ action: 'SKIP_WAITING' });
      }
    },
    offline() {
      console.info('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
