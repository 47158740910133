<template>
  <v-row justify="center">
    <v-dialog
      scrollable
      v-model="show"
      max-width="300px"
      @click:outside="init()"
    >
      <v-card>
        <v-card-text class="pt-6 msg" v-html="message">
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-2">
          <v-spacer></v-spacer>
          <v-btn
            depressed
            @click="init()"
          >
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn
            :color="color"
            depressed
            @click="callback"
          >
            {{ btnText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'DlgConfirm',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    btnText: {
      type: String,
      default: '확인',
    },
    color: {
      type: String,
      default: 'primary',
    },
    message: {
      type: String,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
    }),
    init() {
      this.$emit('update:show', false);
    },
  },
};
</script>

<style scoped lang="scss">
.msg{
  font-size: 1rem;
  color: #333 !important;
  font-weight: 500;
}
</style>
