import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ko from 'vuetify/lib/locale/ko';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#0069B8',
        secondary: '#E9E9E9',
        accent: '#AA6063',
        error: '#f73b3b',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#ebb000',
      },
    },
  },
  lang: {
    locales: { ko },
    current: 'ko',
  },
  buttons: {
    textTransform: 'none',
  },
});
