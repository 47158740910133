<template>
  <UserChargeSection/>
</template>

<script>
import UserChargeSection from '@/components/user/userCharge/UserChargeSection.vue';

export default {
  name: 'UserCharge',
  components: { UserChargeSection },
};
</script>
