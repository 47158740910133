<template>
  <MapSection/>
</template>

<script>
import MapSection from '@/components/monitoring/map/MapSection.vue';

export default {
  name: 'Map',
  components: { MapSection },
};
</script>
