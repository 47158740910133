<template>
  <FAQSection/>
</template>

<script>
import FAQSection from '@/components/community/faq/FAQSection.vue';

export default {
  name: 'FAQ',
  components: { FAQSection },
};
</script>
