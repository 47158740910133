<template>
  <div class="section_wrap">
    <SectionHeader
      :refresh="getData"
    />
    <MapBox
      :markers="items"
      style="height: calc(100vh - 130px);"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SectionHeader from '@/components/common/SectionHeader.vue';
import MapBox from './unit/MapBox.vue';

export default {
  name: 'MapSection',
  components: {
    MapBox,
    SectionHeader,
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    items: [],
  }),
  methods: {
    async getData() {
      this.$dialog.progress(true);
      try {
        const { items } = await this.$emitter('area.list.get', {
          page: 1,
          itemsPerPage: 0,
          sortBy: ['activeEvseCount'],
          sortDesc: [true],
          filters: [],
        });
        this.items = items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
  },
  watch: {
    staffInfo: {
      immediate: true,
      handler(value) {
        if (value != null) this.getData();
      },
    },
    '$i18n.locale': {
      immediate: true,
      handler(value) {
        const langMap = {
          en: 'en',
          jp: 'ja',
          ko: 'ko',
        };
        if (langMap[value] !== this.$googleMapOptions.load.language) window.location.reload();
      },
    },
  },
};
</script>
