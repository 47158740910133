<template>
  <div class="energy-wrap">
    <CommonTotalChart
      :key=chartKey
      :chartTitle="'dashboard.electricalEnergy'"
      :chart="'area'"
      :options="options"
      :form="'energy'"
    />
  </div>
</template>

<script>
import CommonTotalChart from './CommonTotalChart.vue';

export default {
  components: { CommonTotalChart },
  data: () => ({
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 4,
        hover: {
          size: 7,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    },
    chartKey: 0,
  }),
  props: {
    refreshChart: {
      type: Boolean,
    },
  },
  methods: {
    reloadChart() {
      this.chartKey += 1;
    },
  },
  watch: {
    refreshChart: {
      handler(val) {
        if (val) this.reloadChart();
      },
    },
  },
};
</script>

<style lang="scss">
.energy-wrap{
  width:49.5%;
}
</style>
