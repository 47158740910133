<template>
  <div class="section_wrap" style="height: calc(100%; - 160px)">
    <SectionHeader/>
    <div class="explanation_input_box" style="height: 10%;">
      <h3 class="pb-5">{{ $t('mypage.title') }}</h3>
    </div>
    <div class="d-flex flex-column align-center" style="height: 100%;">
      <div class="pa-15 d-flex justify-space-around align-center" style="height: 80%; width: 100%;">
        <div>
          <div class="pb-5">
            <p class="pb-3 font-weight-bold">{{ $t('user.name') }}</p>
            <v-text-field
              class="input_text"
              dense
              outlined
              clearable
              v-model="editedItem.name"
            />
          </div>
          <div class="pb-5">
            <p class="pb-3 font-weight-bold">{{ $t('user.email') }}</p>
            <v-text-field
              class="input_text"
              dense
              outlined
              clearable
              v-model="editedItem.email"
              :rules="rules('email')"
              placeholder="ex) blitztechnology@co.kr"
            />
          </div>
          <div class="pt-8">
            <div class="d-flex justify-space-between align-center">
              <p class="font-weight-bold">{{ $t('user.pw') }}</p>
              <v-btn
                class="font-weight-bold"
                text
                :color="pwChange ? 'error' : 'primary'"
                @click="pwChange= !pwChange"
              >{{ $t('mypage.updatePW') }}</v-btn>
            </div>
            <div class="pb-5">
              <p class="pb-3">{{ $t('mypage.currentPw') }}</p>
              <v-text-field
                class="input_text"
                v-model="originalPwValue"
                dense
                outlined
                clearable
                :disabled="!pwChange"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
              />
              <v-btn
                v-if="!isCorrecPW"
                class="pl-0"
                text
                color="error"
                :disabled="!originalPwValue"
                @click="checkOriginPw"
              >{{ $t('mypage.verifyPw') }}</v-btn>
            </div>
            <div class="pb-5">
              <p class="pb-3">{{ $t('mypage.changePw') }}</p>
              <v-text-field
                class="input_text"
                v-model="changePwValue"
                dense
                outlined
                clearable
                :disabled="!pwChange"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
              />
            </div>
            <div class="pb-5">
              <p class="pb-3">{{ $t('mypage.confirmChangePw') }}</p>
              <v-text-field
                class="input_text"
                v-model="confirmPwValue"
                dense
                outlined
                clearable
                :disabled="!pwChange"
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show3 ? 'text' : 'password'"
                @click:append="show3 = !show3"
              />
              <p
                class="v-messages theme--light error--text pl-3"
                v-if="confirmPwValue.length > 0 && !isMatch(changePwValue, confirmPwValue)"
              >{{ $t('mypage.notMatchPw') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-center">
        <v-btn
          color="primary"
          @click="saveChangeMyInfo"
          depressed
          :disabled="!checkField()"
        >
          {{ $t('btn.save') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapGetters } from 'vuex';
import SectionHeader from '@/components/common/SectionHeader.vue';
import fileConverter from '@/util/file';

export default {
  name: 'Mypage',
  components: {
    SectionHeader,
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
      companyInfo: 'auth/companyInfo',
    }),
    rules() {
      return (type) => {
        const email = [(v) => /^[-0-9A-Za-z!#$%&'*+/=?^_`{|}~.]+@[-0-9A-Za-z!#$%&'*+/=?^_`{|}~]+[.]{1}[0-9A-Za-z]/.test(v) || '이메일 주소를 확인해주세요'];
        if (type === 'email') return email;
        return '';
      };
    },
  },
  data: () => ({
    fileConverter,
    pwChange: false,
    originalPwValue: null,
    changePwValue: '',
    confirmPwValue: '',
    show1: false,
    show2: false,
    show3: false,
    editedItem: {
      _id: '',
      id: '',
      name: '',
      email: '',
      pw: '',
    },
    loading: false,
    isCorrecPW: false,
  }),
  methods: {
    isMatch(password, passwordConfirm) {
      return password === passwordConfirm;
    },

    checkField() {
      if (this.pwChange) {
        if (
          this.editedItem.name
          && this.originalPwValue
          && this.isCorrecPW
          && this.changePwValue
          && this.confirmPwValue
        ) {
          return true;
        }
      } else if (this.editedItem.name) return true;
      return false;
    },

    async checkOriginPw() {
      try {
        const resTokenGet = await this.$emitter('staff.token.get', {
          id: this.editedItem.id,
          password: this.originalPwValue,
        });
        if (resTokenGet.result === 'success') {
          const resTokenVerify = await this.$emitter('staff.token.verify', {
            token: resTokenGet.token,
          });
          if (resTokenVerify.result === 'success') {
            this.isCorrecPW = true;
            this.$dialog.alert('success', this.$t('mypage.matchPw'));
          }
        }
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },

    async saveChangeMyInfo() {
      if (this.pwChange) {
        try {
          await this.$emitter('staff.profile.update', {
            name: this.editedItem.name,
            email: this.editedItem.email,
            password: this.changePwValue,
          });
          this.$dialog.alert('success', this.$t('mypage.modified'));
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
      } else {
        try {
          await this.$emitter('staff.profile.update', {
            name: this.editedItem.name,
            email: this.editedItem.email,
          });
          this.$dialog.alert('success', this.$t('mypage.modified'));
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
      }
    },
  },
  watch: {
    staffInfo: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val != null) {
          this.editedItem = {
            _id: val._id,
            id: val.id,
            name: val.name,
            email: val.email,
          };
        }
      },
    },
  },
};
</script>

<style>
.v-messages {
  display: block
}
</style>
