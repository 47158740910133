/* eslint-disable no-param-reassign */
import Dashboard from '@/views/Home.vue';
import SignIn from '@/views/sign/Sign.vue';
import Map from '@/views/monitoring/map/Map.vue';
import ChargerArea from '@/views/charger/chargerArea/ChargerArea.vue';
import ChargerModels from '@/views/charger/chargerModels/ChargerModels.vue';
import Chargers from '@/views/charger/chargers/Chargers.vue';
import Users from '@/views/user/users/Users.vue';
import UserCharge from '@/views/user/userCharge/UserCharge.vue';
import UserPayment from '@/views/user/userPayment/UserPayment.vue';
// import VerificationOptions from
// '@/views/verification/verificationOptions/VerificationOptions.vue';
// import RFCard from '@/views/verification/rfCard/RFCard.vue';
// import Pg from '@/views/payment/pg/Pg.vue';
// import Paymentmethod from '@/views/payment/paymentMethod/PaymentMethod.vue';
import Notice from '@/views/community/notice/Notice.vue';
import FAQ from '@/views/community/faq/FAQ.vue';
import Problem from '@/views/community/problem/Problem.vue';
import Popup from '@/views/community/popup/Popup.vue';
import Admin from '@/views/setting/admin/Admin.vue';
import Company from '@/views/setting/company/Company.vue';
import ChargingCost from '@/views/setting/chargingCost/ChargingCost.vue';
import PostView from '@/components/common/PostView.vue';
import Mypage from '@/components/common/Mypage.vue';
import Garage from '@/components/monitoring/garage/GarageSection.vue';
// import SmsHistory from '@/views/setting/smsHistory/SmsHistory.vue';

export default [
  {
    path: '/sign',
    name: 'Sign',
    component: SignIn,
  },
  {
    path: '/',
    component: Dashboard,
    meta: {
      category: 'monitoring',
      key: 'dashboard',
    },
  },
  {
    path: '/monitoring/garage',
    component: Garage,
    meta: {
      category: 'monitoring',
      key: 'garage_monitoring',
    },
  },
  {
    path: '/monitoring/map',
    name: 'Map',
    meta: {
      category: 'monitoring',
      key: 'charger_locations_map',
    },
    component: Map,
  },
  {
    path: '/charger/chargers',
    name: 'Charger',
    meta: {
      category: 'charger',
      key: 'chargers',
    },
    component: Chargers,
  },
  {
    path: '/charger/model',
    name: 'Charger Models',
    meta: {
      category: 'charger',
      key: 'charger_models',
    },
    component: ChargerModels,
  },
  {
    path: '/charger/area',
    name: 'ChargerArea',
    meta: {
      category: 'charger',
      key: 'charging_areas',
    },
    component: ChargerArea,
  },
  {
    path: '/user/users',
    name: 'User',
    meta: {
      category: 'user',
      key: 'users',
    },
    component: Users,
  },
  {
    path: '/user/charge-logs',
    name: 'UserCharge',
    meta: {
      category: 'user',
      key: 'charge_logs',
    },
    component: UserCharge,
  },
  {
    path: '/user/payment-logs',
    name: 'UserPayment',
    meta: {
      category: 'user',
      key: 'payment_logs',
    },
    component: UserPayment,
  },
  // {
  //   path: '/verification/authentication-options',
  //   name: 'VerificationOptions',
  //   meta: {
  //     category: 'verification',
  //     key: 'paymentauthenticationOptionsLogs',
  //   },
  //   component: VerificationOptions,
  // },
  // {
  //   path: '/verification/rf-issuance',
  //   name: 'RF Card Issuance History',
  //   meta: {
  //     category: 'verification',
  //     key: 'rfIssuance',
  //   },
  //   component: RFCard,
  // },
  // {
  //   path: '/payment/methods',
  //   name: 'Paymentmethod',
  //   meta: {
  //     category: 'payment',
  //     key: 'paymentMethods',
  //   },
  //   component: Paymentmethod,
  // },
  // {
  //   path: '/payment/pg',
  //   name: 'PG & Payment Logs',
  //   meta: {
  //     category: 'payment',
  //     key: 'pg',
  //   },
  //   component: Pg,
  // },
  {
    path: '/community/faq',
    name: 'FAQ',
    meta: {
      category: 'community',
      key: 'faq',
    },
    component: FAQ,
  },
  {
    path: '/popup',
    name: 'popup',
    meta: {
      category: 'community',
      key: 'popup',
    },
    component: Popup,
  },
  {
    path: '/community/notice',
    name: 'Notice',
    meta: {
      category: 'community',
      key: 'notice',
    },
    component: Notice,
  },
  {
    path: '/community/:board/:no',
    name: 'Post',
    meta: {
      category: 'community',
    },
    component: PostView,
    beforeEnter: (to, from, next) => {
      const { board } = to.params;
      to.meta.key = board;
      next();
    },
  },
  {
    path: '/community/problem',
    name: 'Problem Reports',
    meta: {
      category: 'community',
      key: 'problem',
    },
    component: Problem,
  },
  // {
  //   path: '/community/problem/:number',
  //   name: 'Problem Reports',
  //   meta: {
  //     category: 'community',
  //     key: 'problem',
  //   },
  //   component: PostView,
  // },
  {
    path: '/settings/admin',
    name: 'Admin Settings',
    meta: {
      category: 'settings',
      key: 'admin_settings',
    },
    component: Admin,
  },
  {
    path: '/settings/company',
    name: 'Edit Company Profile',
    meta: {
      category: 'settings',
      key: 'company_profile',
    },
    component: Company,
  },
  {
    path: '/settings/charging-cost',
    name: 'Edit Charging Cost',
    meta: {
      category: 'settings',
      key: 'charging_cost',
    },
    component: ChargingCost,
  },
  {
    path: '/mypage',
    name: 'Mypage',
    meta: {
      key: 'mypage',
    },
    component: Mypage,
  },
];
