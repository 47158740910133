<template>
  <div class="accrue_graph_wrap">
    <v-card
      style="height: 100%;"
      elevation="7"
    >
      <p class="monitoring_title">
        {{ $t('dashboard.accrueGraph') }}
      </p>
      <div
        style="height: calc(100% - 30px); overflow-y: auto; padding: 0 10px 0 0"
      >
        <apexchart
          v-if="lastMonthData.length > 0"
          type="line"
          height="260"
          :options="options"
          :series="series"
          style="margin-top: 5px;"
        />
        <p
          v-else
          class="flex_center"
          style="color: #A9A9A9; height: 100%;"
        >
          {{ $t('common.noSelect') }}
        </p>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  props: {
    lastMonthData: {
      type: Array,
      required: true,
    },
    selectedArea: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    options: {
      chart: {
        id: 'totalAmount',
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [],
        stepSize: 20,
        tickAmount: 10,
        labels: {
          style: {
            fontSize: '10px',
          },
        },
      },
      markers: {
        size: 4,
      },
    },
    series: [
      {
        name: 'lightLoad',
        data: [],
      },
      {
        name: 'mediumLoad',
        data: [],
      },
      {
        name: 'maximumLoad',
        data: [],
      },
      {
        name: 'total',
        data: [],
      },
    ],
  }),
  methods: {
    ...mapMutations({
      confirm: 'dialog/confirm',
    }),
    totalCount() {
      for (let i = 0; i < this.lastMonthData.length; i += 1) {
        this.options.xaxis.categories.push(i + 1);
        this.series[0].data.push(this.lastMonthData[i].data.light);
        this.series[1].data.push(this.lastMonthData[i].data.medium);
        this.series[2].data.push(this.lastMonthData[i].data.maximum);
        this.series[3].data.push(this.lastMonthData[i].data.total);
      }
      for (let i = 0; i < 4; i += 1) {
        this.series[i].name = this.$t(`price_setting.${this.series[i].name}`);
      }
    },
    reset() {
      this.options.xaxis.categories = [];
      this.series = [
        {
          name: 'lightLoad',
          data: [],
        },
        {
          name: 'mediumLoad',
          data: [],
        },
        {
          name: 'maximumLoad',
          data: [],
        },
        {
          name: 'total',
          data: [],
        },
      ];
    },
  },

  watch: {
    lastMonthData: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value.length > 0) {
          this.reset();
          this.totalCount();
        }
      },
    },
    selectedArea: {
      deep: true,
      handler(value) {
        if (value !== null) {
          this.reset();
        }
      },
    },
  },
};
</script>

<style lang="scss">
.accrue_graph_wrap {
  height: 29%;
  .apexcharts-legend-text{
    padding-left: 3px;
  }
}
</style>
