<template>
  <DashboardSection/>
</template>

<script>
import DashboardSection from '@/components/monitoring/dashboard/DashboardSection.vue';

export default {
  name: 'Home',
  components: { DashboardSection },
};
</script>
