<template>
  <CompanySection/>
</template>

<script>
import CompanySection from '@/components/setting/company/CompanySection.vue';

export default {
  name: 'company',
  components: { CompanySection },
};
</script>
