<template>
  <div class="data_table_wrap">
    <div class="align_center justify-space-between">
      <div class="body-2">
        <b v-if="pageOptions.total <= 0">0</b>
        <b v-else class="primary--text">
          {{
            options.page === 1
            ? items.length
            : ((options.page - 1) * options.itemsPerPage) + items.length
          }}
        </b>
        /
        <span>{{ pageOptions.total }}</span>
      </div>
      <v-btn-toggle
        class="my-2"
        v-if="pageOptions.itemsPerPageOptions != null && pageOptions.itemsPerPageOptions.length > 0"
        @change="updateOptions('itemsPerPage', $event)"
        mandatory
        dense
      >
        <v-btn v-for="number of pageOptions.itemsPerPageOptions" :key="number">
          {{ `${number}${$t('dataTable.itemsPerPageOption')}` }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-data-table
      mobile-breakpoint="0"
      fixed-header
      :headers="headers"
      :items="items"
      :loading="loading"
      :options="options"
      @update:sort-by="sort.sortBy = $event"
      @update:sort-desc="sort.sortDesc = $event"
      disable-pagination
      hide-default-footer
      dense
      class="data_table"
    >
      <template v-slot:item.connectCP="{ item }">
        <div v-for="(cp, idx) in item.connectCp" :key="idx">
          <v-chip
            label
            x-small
            class="px-1 mr-1"
            :color="cp.model.type === 'DC' ? 'success' : 'accent'"
          >
            {{ cp.model.type }}
          </v-chip>
          <v-chip
            label
            :color=isAbleToCharge(cp)[0]
            outlined
            x-small
            class="px-1"
          >
            {{ $t(isAbleToCharge(cp)[1]) }}
          </v-chip>
          <span class="pl-3 font-weight-bold">{{ cp.cpCode }}</span>
        </div>
      </template>
      <template v-slot:item.created="{ item }">
        {{ time.makeLocalTime(item.created) }}
      </template>
      <template v-slot:no-data>
        <p>{{ $t('common.noData') }}</p>
      </template>
    </v-data-table>
    <v-pagination
      small
      depressed
      v-show="pageOptions.pageCount > 0"
      :value="options.page"
      :length="pageOptions.pageCount"
      :total-visible="10"
      @input="updateOptions('page', $event)"
    ></v-pagination>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapMutations } from 'vuex';
import time from '@/util/time';

export default {
  props: {
    items: {
      type: Array,
      default: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    pageOptions: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    time,
    headers: [],
    sort: {
      sortBy: [],
      sortDesc: [],
    },
  }),
  created() {
    this.headers = this.getHeader();
  },
  methods: {
    ...mapMutations({
      confirm: 'dialog/confirm',
    }),
    getHeader() {
      return [
        {
          text: this.$t('area.areaName'),
          value: 'areaName',
          width: '20%',
        },
        {
          text: this.$t('area.areaAddress'),
          value: 'areaAddress',
          sortable: false,
          width: '20%',
        },
        {
          text: this.$t('area.cnt'),
          value: 'cnt',
          width: '20%',
          align: 'center',
        },
        {
          text: this.$t('area.connect'),
          value: 'connectCP',
          sortable: false,
          width: '20%',
        },
        {
          text: this.$t('common.registration'),
          align: 'center',
          value: 'created',
          width: '20%',
        },
      ];
    },
    changePageNum(value) {
      this.$emit('update:page', value);
    },

    updateOptions(key, value) {
      let options = {
        ...this.options,
        [key]: value,
      };
      if (key === 'itemsPerPage') {
        options = {
          ...this.options,
          page: 1,
          itemsPerPage: this.pageOptions.itemsPerPageOptions[value],
        };
      }
      this.$emit('update:options', options);
    },
    isAbleToCharge(cp) {
      if (
        cp.isOnline === true
        && cp.state.availability === 'Operative'
        && cp.state.status === 'Available'
      ) return ['success', 'common.cp_state.state.available']; // 사용가능
      if (
        cp.isOnline === true
        && cp.state.availability === 'Operative'
        && [
          'Preparing', 'Charging', 'SuspendedEVSE', 'SuspendedEV', 'Finishing', 'Reserved',
        ].includes(cp.state.status)
      ) return ['info', 'cp.operation']; // 사용중
      return ['error', 'common.cp_state.state.unavailable']; // 사용불가
    },
  },
  watch: {
    sort: {
      deep: true,
      handler(value) {
        this.options.page = 1;
        if (value.sortBy == null && value.sortDesc == null) {
          this.$emit('update:options', {
            ...this.options,
            sortBy: [],
            sortDesc: [],
          });
        } else if (value.sortBy != null && value.sortDesc != null) {
          this.$emit('update:options', {
            ...this.options,
            sortBy: [value.sortBy],
            sortDesc: [value.sortDesc],
          });
        }
      },
    },
    '$i18n.locale': {
      handler() {
        this.headers = this.getHeader();
      },
    },
  },
};
</script>
