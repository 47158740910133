import store from '@/store';

const alert = (type, message, timeout = null) => {
  const param = timeout == null ? [type, message] : [type, message, timeout];
  store.dispatch('dialog/alert', param);
};

const confirm = (payload = null) => {
  if (payload === null) {
    const before = store.getters['dialog/confirm'];
    store.commit('dialog/confirm', {
      ...before,
      show: false,
    });
  } else {
    store.commit('dialog/confirm', payload);
  }
};

const progress = (show) => {
  store.commit('dialog/progress', show);
};

export default {
  alert,
  confirm,
  progress,
};
