<template>
  <div class="nav_wrap">
    <v-app id="inspire">
      <v-card height="100vh">
        <v-toolbar flat style=" height:8%;">
          <a
            @click="$router.push('/')"
            class="incomeLogo flex_center"
          >
            <div style="width: 100%;" class="flex_center">
              <img
                v-if="companyImg !== null"
                ref="image"
                :src="utilFile.imagePathToSrc(companyImg)"
                alt="company_logo"
                style="width: 30px; height: 30px;"
              />
              <v-icon small color="#0069B8" v-else>
                mdi-cog
              </v-icon>
              <p class="px-1">{{ companyName }}</p>
              <span style="font-weight:600;">ADMIN</span>
            </div>
          </a>
        </v-toolbar>
        <v-list flat style="height:92%">
          <v-list-item-group
            v-for="(menu, idx) in menuList"
            :key="idx"
            v-model="menu.active"
            no-action
            class="nav_group"
          >
            <v-subheader>{{ $t(`menu.${menu.key}`) }}</v-subheader>
            <v-list-item
              v-for="(childrenMenu, index) in menu.children"
              v-show="
                childrenMenu.key !== 'popup'
                && childrenMenu.key !== 'payment_methods'
                && childrenMenu.key !== 'pg'
              "
              :key="index"
              :to="childrenMenu.path"
              :class="{clickMenu:$route.meta.key === childrenMenu.key}"
            >
              <v-list-item-icon>
                <v-icon
                  v-text="'mdi-'+childrenMenu.icon"
                  style="color:#757575;"
                />
              </v-list-item-icon>
              <v-list-item-content style="padding:0; height:24px;">
                <v-list-item-title style="height:100%; font-size: 14px; line-height: 22px;">
                  {{ $t(`menu.${childrenMenu.key}`) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import utilFile from '@/util/file';

export default {
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
      companyInfo: 'auth/companyInfo',
    }),
  },
  mounted() {
    this.getCompanyInfo();
  },
  data: () => ({
    utilFile,
    menuList: [],
    companyName: '',
    companyImg: null,
    loginAdminName: null,
    staff: {},
    hoverState: false,
    langList: [
      {
        value: '한국어',
        select: 'ko',
      },
      {
        value: '日本語',
        select: 'jp',
      },
      {
        value: 'English',
        select: 'en',
      },
    ],
  }),
  methods: {
    ...mapMutations({
      setCompanyInfo: 'auth/companyInfo',
    }),
    setMenu() {
      this.menuList = [];
      this.permissionToMenus(this.staffInfo.permission).forEach((parentsList) => {
        if (
          parentsList.name !== 'Verification'
        ) {
          this.menuList.push(parentsList);
        }
      });
    },
    async getCompanyInfo() {
      try {
        const { item: companyInfo } = await this.$emitter('sysconf.company.get', {});
        this.setCompanyInfo(companyInfo);
        if (companyInfo) {
          this.companyName = companyInfo.name.toUpperCase();
          this.companyImg = companyInfo.logoPath;
        }
      } catch (error) {
        this.init();
        console.error(error);
        this.$dialog.progress(false);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },

    permissionToMenus(permissions) {
      const result = [];
      const categoryMap = {};
      for (const permissionKey of Object.keys(permissions)) {
        const {
          categoryKey,
          categoryName,
          key,
          name,
          icon,
          path,
          read,
          write,
        } = permissions[permissionKey];
        if (read) {
          if (categoryMap[categoryKey] == null) {
            categoryMap[categoryKey] = {
              key: categoryKey,
              name: categoryName,
              children: [],
            };
            result.push(categoryMap[categoryKey]);
          }
          categoryMap[categoryKey].children.push({
            key,
            name,
            icon,
            path,
            write,
          });
        }
      }
      return result;
    },
    handleMouseOver(res) {
      this.hoverState = res;
    },
  },
  watch: {
    route: {
      deep: true,
      immediate: true,
      handler() {
      },
    },
    'staffInfo.permission': {
      immediate: true,
      deep: true,
      handler(value) {
        if (value != null) this.setMenu();
      },
    },
    staffInfo: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value != null) {
          this.loginAdminName = value.name;
          this.staff = value;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.nav_wrap{
  height:100vh;
  width: 250px;
  border-right:1px solid #ddd;
  .v-toolbar__content{
    height: 100% !important;
  }
  .incomeLogo{
    width:100%;
    height:100%;
    font-size: 15px;
    border-bottom:1px solid #B0B0B0;
    text-align: center;
  }
  .v-list {
    height: 87%;
    overflow-y: auto;
  }
  .v-list-item:first-child{
    cursor:default;
  }
  .clickMenu{
    background-color: #0069B8;
    color:white !important;
    .v-icon{
      color:white !important;
    }
  }
  .nav_group{
    margin-bottom: 10px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
