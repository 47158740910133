<template>
  <SignSection/>
</template>

<script>
import SignSection from '@/components/sign/SignSection.vue';

export default {
  name: 'Sign',
  components: { SignSection },
  methods: {
  },
};
</script>
