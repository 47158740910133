<template>
  <div class="charger_section_wrap section_wrap">
    <SectionHeader
      :loading="loading"
      :refresh="getAdminData"
      :buttons="buttons"
    />
    <SearchFilter
      :radios="radios"
      :filters.sync="options.filters"
      :loading="loading"
      :searchKeys="searchKeys"
      :callback="getAdminData"
      :options.sync="options"
    />
    <AdminDataTable
      :items="items"
      :loading="loading"
      :options.sync="options"
      :pageOptions="pageOptions"
      :callback="getAdminData"
      :workplaceList="workplaceList"
    />
    <DlgCommonWrite
      :show.sync="writeDialog.show"
      :title="writeDialog.title"
      :sub="writeDialog.sub"
      :type="writeDialog.type"
      :formCheck="writeDialog.formCheck"
      @submit="writeDialog.submit"
    >
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('admin_setting.adminId') }}*
        </v-chip>
        <div style="width: 100%">
          <v-text-field
            class="input_text"
            dense
            outlined
            clearable
            v-model="writeDialog.form.id"
            autofocus
            :placeholder="$t('placeholders.setting.id')"
          />
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('admin_setting.adminPw') }}*
        </v-chip>
        <div style="width: 100%">
          <v-text-field
            class="input_text"
            dense
            outlined
            clearable
            v-model="writeDialog.form.password"
            autofocus
            :placeholder="$t('placeholders.setting.pw')"
          />
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('admin_setting.adminName') }}*
        </v-chip>
        <div style="width: 100%">
          <v-text-field
            class="input_text"
            dense
            outlined
            clearable
            v-model="writeDialog.form.name"
            autofocus
            :placeholder="$t('placeholders.setting.name')"
          />
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('admin_setting.adminType') }}*
        </v-chip>
        <div style="width: 100%">
          <v-radio-group
            row
            style="width:400px; height:30px; margin:0;"
            v-model="writeDialog.form.type"
          >
            <v-radio
              v-for="list in typeList"
              :key="list"
              :label="$t(`staffType.${list}`)"
              :value="list"
              @click="clickRadios(list, 'staff')"
            ></v-radio>
          </v-radio-group>
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip
          class="mr-2 flex-shrink-0"
          label
          color="primary">{{ $t('admin_setting.workplace.title') }}</v-chip>
        <v-radio-group
          row
          hide-details
          v-model="writeDialog.form.workplaceType"
        >
          <v-radio
            v-for="list in workplaceTypeList"
            :key="list"
            :value="list"
            :label="$t(`admin_setting.workplace.${list}`)"
            @click="clickRadios(list, 'workplace')"
          ></v-radio>
        </v-radio-group>
      </div>
      <div
        class="align_center mb-4"
        v-if="writeDialog.form.workplaceType === 'part'"
      >
        <v-chip
          class="mr-2 flex-shrink-0"
          label
          color="primary">{{ $t('admin_setting.workplace.place') }}</v-chip>
        <v-select
          class="align_center"
          outlined
          hide-details
          dense
          :label="$t('admin_setting.workplace.select')"
          :items="workplaceList"
          item-text="name"
          item-value="_id"
          multiple
          v-model="writeDialog.form.selectWorkplace"
        >
        </v-select>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('admin_setting.adminEmail') }}
        </v-chip>
        <v-text-field
          class="input_text"
          dense
          outlined
          clearable
          v-model="writeDialog.form.email"
          :placeholder="$t('placeholders.setting.email')"
        />
      </div>
    </DlgCommonWrite>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchFilter from '@/components/common/SearchFilter.vue';
import SectionHeader from '@/components/common/SectionHeader.vue';
import DlgCommonWrite from '@/components/dialog/DlgCommonWrite.vue';
import AdminDataTable from './unit/AdminDataTable.vue';

export default {
  name: 'AdminSection',
  components: {
    AdminDataTable,
    SearchFilter,
    SectionHeader,
    DlgCommonWrite,
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    items: [],
    total: 0,
    pageCount: 0,
    page: 1,
    itemsPerPage: 10,
    filters: [],
    loading: false,
    radios: [
      {
        key: 'type',
        i18nKey: 'admin_setting.adminType',
        values: [0, 1, 2],
        conditions: ['eq', 'eq', 'eq'],
        i18nLabels: ['staffType.0', 'staffType.1', 'staffType.2'],
      },
      {
        key: 'approve',
        i18nKey: 'admin_setting.adminApprove',
        values: [true, false],
        conditions: ['eq', 'eq'],
        i18nLabels: ['common.true', 'common.false'],
      },
    ],
    searchKeys: [
      {
        i18nKey: 'admin_setting.adminName',
        key: 'name',
      },
    ],
    options: {
      page: 1,
      itemsPerPage: 2,
      sortBy: ['created'],
      sortDesc: [true],
      filters: [],
    },
    pageOptions: {
      total: 0,
      pageCount: 0,
      itemsPerPageOptions: [10, 50],
    },
    buttons: [
      {
        color: 'primary',
        icon: 'mdi-plus',
        click: 'openWriteDialog',
        i18nText: 'cp.newCP',
        permission: 'chargers.write',
      },
    ],
    writeDialog: {
      show: false,
      title: '',
      sub: null,
      type: 'regist',
      formCheck: false,
      submit: () => {},
      form: {
        id: null,
        name: null,
        password: null,
        email: null,
        type: '',
        workplaceType: '',
        selectWorkplace: [],
      },
    },
    typeList: [0, 1, 2],
    typeValue: 0,
    workplaceTypeList: ['all', 'part'],
    workplaceList: [],
  }),
  methods: {
    clickRadios(value, type) {
      this.writeDialog.form[type === 'staff' ? 'type' : 'workplaceType'] = value;
    },
    initWriteDialog() {
      this.writeDialog = {
        show: false,
        title: this.$t('admin_setting.adminNew'),
        sub: null,
        type: 'regist',
        formCheck: false,
        submit: () => {},
        form: {
          id: null,
          name: null,
          password: null,
          email: null,
          type: 0,
          workplaceType: 'all',
          selectWorkplace: [],
        },
      };
    },
    async openWriteDialog() {
      this.initWriteDialog();
      this.writeDialog.show = true;
      this.writeDialog.submit = this.addAdmin;
      this.writeDialog.title = this.$t('admin_setting.adminNew');
    },
    async addAdmin() {
      this.$dialog.progress(true);
      const workplaceType = this.writeDialog.form.workplaceType === 'all';
      try {
        await this.$emitter('staff.add', {
          items: [{
            id: this.writeDialog.form.id,
            password: this.writeDialog.form.password,
            name: this.writeDialog.form.name,
            type: this.writeDialog.form.type,
            email: this.writeDialog.form.email,
            'manageAreaInfo.isMaster': workplaceType,
            'manageAreaInfo.area_ids': this.writeDialog.form.selectWorkplace,
          }],
        }, this.$t('common.registCompleteMessage'));
        this.initWriteDialog();
        await this.getAdminData();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    checkAdminWriteValid(form) {
      const {
        id,
        password,
        name,
        type,
        email,
      } = form;
      if (this.writeDialog.show !== true) return false;
      if (
        id == null
        || id === ''
        || !/^[a-zA-Z0-9]{3,15}$/.test(id)
      ) return false;
      if (
        password == null
        || password === ''
        || !/^(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~-]{6,30}$/.test(password)
      ) return false;
      if (
        name == null
        || name === ''
        || !/^[a-zA-Z가-힣]{1,24}$/.test(name)
      ) return false;
      if (type == null || type === '') return false;
      if (email !== null) {
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) return false;
      }
      return true;
    },
    async getAdminData() {
      this.loading = true;
      try {
        const {
          itemsPerPage,
          filters,
        } = this.options;
        const { data: { total } } = await this.$emitter('staff.summary.get', { filters });
        this.pageOptions.total = total;
        this.pageOptions.pageCount = Math.ceil(total / itemsPerPage);
        const resultData = await this.$emitter('staff.list.get', this.options);
        this.items = resultData.items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    async getWorkplaceList() {
      try {
        const res = await this.$emitter('area.list.get', {
          page: 1,
          itemsPerPage: 0,
        });
        this.workplaceList = res.items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
  },
  watch: {
    staffInfo: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value != null) {
          this.getAdminData();
          this.getWorkplaceList();
        }
      },
    },
    options: {
      deep: true,
      async handler() {
        if (this.staffInfo !== null) await this.getAdminData();
      },
    },
    'writeDialog.form': {
      deep: true,
      handler(value) {
        this.writeDialog.formCheck = this.checkAdminWriteValid(value);
      },
    },
  },
};
</script>
