<template>
  <div>
    <SectionHeader
      :loading="loading"
      class="px-8"
      :refresh="allRefresh"
    />
    <div class="dashboard_section_wrap mx-8">
      <div class="dashboard_amount_box align_center px-2">
        <div class="amount_top_left_box">
          <v-card
            class="dashboard_amount_cards"
            v-for="(list, idx) in amountData"
            :key="idx"
            outlined
          >
            <v-icon
              large
              class="mx-4"
              :color="list.color"
              style="width: 10%;"
            >
              {{ list.icon }}
            </v-icon>
            <div class="count_box">
              <p class="amount_title align_center">
                {{ $t(`dashboard.${list.title}`) }}
              </p>
              <p
                class="amount_count"
                v-if="list.title !== 'charger'"
              >
                {{ list.countValue }}
              </p>
              <div
                v-if="list.title === 'charger'"
                style="width: 100%; display: flex;"
              >
                <div
                  v-for="(list) in amountChargerData"
                  :key="list.title"
                  style="width: 33%;"
                >
                  <p class="smT">
                    {{$t(`common.cp_state.state.${list.title}`)}}
                  </p>
                  <p style="font-size: 34px;">{{ list.value }}</p>
                </div>
              </div>
            </div>
          </v-card>
        </div>
        <div class="amount_top_right_box">
          <MapBox
            v-if="listItem.length > 0"
            :markers="listItem"
          />
        </div>
      </div>
      <div class="dashboard_graph_box pt-5">
        <CardSalesChart :refreshChart.sync="refreshChart"/>
        <ElectricalEnergyChart :refreshChart.sync="refreshChart"/>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
/* eslint-disable no-param-reassign */
import { mapGetters } from 'vuex';
import time from '@/util/time';
import SectionHeader from '../../common/SectionHeader.vue';
import CardSalesChart from './unit/CardSalesChart.vue';
import ElectricalEnergyChart from './unit/ElectricalEnergyChart.vue';
import MapBox from '../map/unit/MapBox.vue';

export default {
  name: 'DashboardSection',
  components: {
    SectionHeader,
    CardSalesChart,
    ElectricalEnergyChart,
    MapBox,
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    time,
    filters: [],
    loading: false,
    amountData: [
      {
        title: 'chargerArea',
        countValue: null,
        icon: 'mdi-ev-station',
        color: 'blue',
      },
      {
        title: 'charger',
        countValue: null,
        icon: 'mdi-ev-plug-chademo',
        color: 'green',
      },
      {
        title: 'membershipAmount',
        countValue: null,
        icon: 'mdi-account',
        color: 'black',
      },
      {
        title: 'issueAmount',
        countValue: null,
        icon: 'mdi-message-alert',
        color: 'red',
      },
    ],
    amountChargerData: [
      {
        title: 'total',
        value: null,
      },
      {
        title: 'inUse',
        value: null,
      },
      {
        title: 'unavailable',
        value: null,
      },
    ],
    listItem: [],
    refreshChart: false,
  }),
  methods: {
    updateRefreshChart(value) {
      if (value !== this.refreshChart) {
        this.refreshChart = value;
      }
    },
    async getAreaData() {
      this.loading = true;
      try {
        const areaRes = await this.$emitter('area.summary.get', {});
        this.amountData.forEach((list) => {
          if (list.title === 'chargerArea') {
            list.countValue = areaRes.data.total;
          }
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    async getAreaListData() {
      this.$dialog.progress(true);
      try {
        const { items } = await this.$emitter('area.list.get', {
          page: 1,
          itemsPerPage: 0,
          sortBy: ['activeEvseCount'],
          sortDesc: [true],
          filters: [],
        });
        this.listItem = items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    async getCpCount() {
      try {
        const res = await this.$emitter('dashboard.cp.summary.get', {});
        this.amountChargerData.forEach((item) => {
          if (Object.hasOwn(res.data, item.title)) {
            item.value = res.data[item.title];
          }
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    async getUserData() {
      this.loading = true;
      try {
        const userRes = await this.$emitter('user.summary.get', []);
        this.amountData.forEach((list) => {
          if (list.title === 'membershipAmount') {
            list.countValue = userRes.data.total;
          }
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    async getIssueData() {
      this.loading = true;
      try {
        const today = new Date().toISOString().substring(0, 10);
        const userRes = await this.$emitter('post.summary.get', {
          filters: [
            {
              where: 'and',
              condition: 'eq',
              key: 'boardCode',
              value: 'problem',
            },
            {
              where: 'and',
              condition: 'gte',
              key: 'created',
              value: today,
            },
          ],
        });
        this.amountData.forEach((list) => {
          if (list.title === 'issueAmount') {
            list.countValue = userRes.data.total;
          }
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    async allRefresh() {
      try {
        await this.getAreaData();
        await this.getCpCount();
        await this.getAreaListData();
        await this.getUserData();
        await this.getIssueData();
      } catch (error) {
        console.error(error);
      }
      this.refreshChart = !this.refreshChart;
    },
  },
  watch: {
    staffInfo: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value != null) {
          this.allRefresh();
        }
      },
    },
  },
};
</script>

<style lang="scss">
.dashboard_section_wrap{
  display: flex;
  flex-direction: column;
  height: calc(100% - 82px);
  justify-content: space-evenly;
  .dashboard_amount_box{
    width:100%;
    height: calc(60% - 100px);
    min-height: 550px;
    justify-content: space-between;
    .amount_top_left_box{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 330px;
      height: 100%;
      .dashboard_amount_cards{
        height: 20%;
        width: 330px;
        display: flex;
        align-items: center;
        overflow-y: auto;
        .count_box{
          width: 90%;
        }
      }
    }
    .amount_top_right_box{
      width:calc(100% - 350px);
      height: 100%;
    }
  }
  .dashboard_graph_box{
    height: 40%;
    min-height: 430px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
