var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.statusFullscreen ?
    'status-full-screen'
    : 'using-status-wrap'},[_c('v-card',{staticStyle:{"height":"100%"},attrs:{"elevation":"7"}},[_c('div',{staticClass:"monitoring_title d-flex justify-space-between",staticStyle:{"width":"100%"}},[_c('p',[_vm._v(_vm._s(_vm.$t('dashboard.liveChargers')))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({on, attrs}){return [_c('v-btn',_vm._g(_vm._b({class:_vm.statusFullscreen ? 'hidden' : '',attrs:{"icon":""},on:{"click":function($event){return _vm.showFullscreen('status')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-fullscreen")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('garage.statusFullscreen')))])])],1),(_vm.evseList.length > 0)?_c('ul',{staticClass:"status_list_ul"},_vm._l((_vm.evseList),function(list){return _c('li',{key:list.dashboardEvseNumber,staticClass:"status_list_li",class:[
          list.chargepointOnline === true &&
          list.chargepointState.availability === 'Operative' &&
          list.state.availability === 'Operative' &&
          list.state.online === true &&
          list.state.status === 'Available' ?
          'available'  // 사용가능
          :
            list.chargepointOnline === true &&
            list.chargepointState.availability === 'Operative' &&
            list.state.availability === 'Operative' &&
            list.state.online === true &&
            [
              'Preparing', 'Charging', 'SuspendedEVSE', 'SuspendedEV', 'Finishing', 'Reserved'
            ].includes(list.state.status)
          ? 'inUse'  // 사용중
          : 'unavailable'  // 사용불가능 (위 두가지 조건 둘 다 충족 X)
        ]},[_c('p',{staticClass:"flex_center",staticStyle:{"height":"100%"}},[_vm._v(" "+_vm._s(list.dashboardEvseNumber)+" ")])])}),0):_c('p',{staticClass:"flex_center",staticStyle:{"height":"calc(100% - 30px)","color":"#A9A9A9"}},[_vm._v(" "+_vm._s(_vm.$t('common.noSelect'))+" ")])]),(_vm.statusFullscreen)?_c('DlgAlert',{staticClass:"fullscreen-exit"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }