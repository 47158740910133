<template>
  <div class="faq_box_wrap section_wrap">
    <SectionHeader
      :loading="loading"
      :refresh="getData"
      :buttons="buttons"
    />
    <div class="explanation_input_box">
    </div>
    <FAQList
      :items.sync="items"
      :currentTab.sync="currentTab"
      :loading="loading"
      :selectCategory="selectCategory"
      :callback="getData"
    />
    <DlgCommonWrite
      :show.sync="writeDialog.show"
      :title="writeDialog.title"
      :sub="writeDialog.sub"
      :type="writeDialog.type"
      :formCheck="writeDialog.formCheck"
      @submit="writeDialog.submit"
    >
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{$t('faq.category')}}*
        </v-chip>
        <div style="width: 100%">
          <v-select
            :placeholder="$t('placeholders.faq.category')"
            :items="selectCategory"
            :item-text="categoryName"
            item-value="value"
            outlined
            dense
            hide-details
            v-model="writeDialog.form.category"
            style="width:250px"
          ></v-select>
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{$t('faq.question')}}*
        </v-chip>
        <div style="width: 100%">
          <v-text-field
            v-model="writeDialog.form.title"
            outlined
            color="black"
            dense
            flat
            solo
            hide-details
          />
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{$t('common.content')}}*
        </v-chip>
        <div style="width: 100%">
          <v-textarea
            v-model="writeDialog.form.content"
            outlined
            name="input-7-4"
            no-resize
            height="250px"
            hide-details
          />
        </div>
      </div>
    </DlgCommonWrite>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SectionHeader from '../../common/SectionHeader.vue';
import DlgCommonWrite from '../../dialog/DlgCommonWrite.vue';
import FAQList from './unit/FAQList.vue';

export default {
  name: 'CompanySection',
  components: {
    SectionHeader,
    FAQList,
    DlgCommonWrite,
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
    selectCategory() {
      return [
        ...this.category,
      ];
    },
  },
  data: () => ({
    items: [],
    total: 0,
    loading: false,
    currentTab: 'all',
    buttons: [
      {
        color: 'primary',
        icon: 'mdi-plus',
        click: 'openWriteDialog',
        i18nText: 'btn.new',
        permission: 'faq.write',
      },
    ],
    writeDialog: {
      show: false,
      title: '',
      sub: null,
      type: 'regist',
      formCheck: false,
      submit: () => {},
      form: {
        category: null,
        title: null,
        content: null,
      },
    },
    categoryValue: '',
    category: [
      { name: 'charger', value: 'charger' },
      { name: 'payment', value: 'payment' },
      { name: 'certification', value: 'certification' },
      { name: 'page', value: 'page' },
    ],
  }),
  methods: {
    categoryName(item) {
      return this.$t(`faq.${item.name}`);
    },
    async getData() {
      this.loading = true;
      try {
        const filters = [
          {
            where: 'and',
            condition: 'eq',
            key: 'boardCode',
            value: 'faq',
          },
        ];
        if (this.currentTab !== 'all') {
          filters.push({
            where: 'and',
            condition: 'inc',
            key: 'title',
            value: `[${this.currentTab}]`,
          });
        }
        const { data: { total } } = await this.$emitter('post.summary.get', { filters });
        this.total = total;
        const resultData = await this.$emitter('post.list.get', {
          page: 1,
          itemsPerPage: 0,
          filters,
          sortBy: ['title'],
          sortDesc: [false],
        });
        this.items = resultData.items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    initWriteDialog() {
      this.writeDialog = {
        show: false,
        title: this.$t('faq.newFaq'),
        sub: null,
        type: 'regist',
        formCheck: false,
        submit: () => {},
        form: {
          category: '',
          title: null,
          content: null,
        },
      };
    },
    async openWriteDialog() {
      this.initWriteDialog();
      this.writeDialog.show = true;
      this.writeDialog.submit = this.saveNewFQAData;
    },
    async saveNewFQAData() {
      this.$dialog.progress(true);
      const {
        category,
        title,
        content,
      } = this.writeDialog.form;
      if (this.titleValue !== null) {
        try {
          await this.$emitter('post.add', {
            items: [{
              boardCode: 'faq',
              title: `[${category}] ${title}`,
              content,
            }],
          }, `${this.$t('common.registCompleteMessage')}`);
          this.initWriteDialog();
          await this.getData();
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
        this.$dialog.progress(false);
      }
    },
    checkFQAWriteValid(form) {
      const {
        category,
        title,
        content,
      } = form;
      if (this.writeDialog.show !== true) return false;
      if (category === '') return false;
      if (title == null) return false;
      if (content == null) return false;
      return true;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getData();
      },
    },
    staffInfo: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value != null) {
          this.getData();
        }
      },
    },
    currentTab: {
      handler() {
        this.getData();
      },
    },
    'writeDialog.form': {
      deep: true,
      handler(value) {
        this.writeDialog.formCheck = this.checkFQAWriteValid(value);
      },
    },
  },
};
</script>

<style lang="scss">
.faq_box_wrap{
  height:calc(100% - 32px);
}
</style>
