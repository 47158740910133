<template>
  <div class="section_wrap">
    <SectionHeader
      :loading="loading"
      :refresh="getCPData"
      :buttons="buttons"
    />
    <SearchFilter
      :radios="radios"
      :selects="selects"
      :filters.sync="options.filters"
      :loading="loading"
      :searchKeys="searchKeys"
      :callback="getCPData"
      :options.sync="options"
    />
    <ChargersDataTable
      :items="items"
      :loading="loading"
      :options.sync="options"
      :pageOptions="pageOptions"
      :callback="getCPData"
    />
    <DlgCommonWrite
      :show.sync="writeDialog.show"
      :title="writeDialog.title"
      :sub="writeDialog.sub"
      :type="writeDialog.type"
      :formCheck="writeDialog.formCheck"
      @submit="writeDialog.submit"
      width="670"
      >
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('cp.cpId') }}*
        </v-chip>
        <div style="width: 100%">
          <v-text-field
            :placeholder="$t('placeholders.cp.cpId')"
            v-model="writeDialog.form.cpId"
            outlined
            color="black"
            dense
            flat
            solo
            hide-details
          />
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('cp.cpName') }}*
        </v-chip>
        <div style="width: 100%">
          <v-text-field
            :placeholder="$t('placeholders.cp.cpName')"
            v-model="writeDialog.form.cpName"
            outlined
            color="black"
            dense
            flat
            solo
            hide-details
          />
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('cp.model') }}*
        </v-chip>
        <div style="width: 100%">
          <v-select
            :placeholder="$t('placeholders.cp.model')"
            outlined
            dense
            :items="models"
            item-text="name"
            item-value="_id"
            hide-details
            :value="writeDialog.form.model_id"
            @change="writeDialog.form.model_id = $event"
          >
            <template #item="{ item }">
              <span class="text-subtitle-2 mr-2">{{ item.name }}</span>
              <v-chip x-small label class="px-1 mr-1" color="warning">
                {{ item.kwh }}kW
              </v-chip>
              <v-chip
                x-small
                label
                class="px-1 mr-1"
                :color="item.type === 'DC' ? 'success' : 'accent'"
              >
                {{ item.type }}
              </v-chip>
            </template>
          </v-select>
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('cp.area') }}
        </v-chip>
        <div style="width: 100%">
          <v-select
            :placeholder="$t('placeholders.cp.area')"
            outlined
            dense
            :items="areas"
            item-text="name"
            item-value="_id"
            hide-details
            :value="writeDialog.form.area_id"
            @change="writeDialog.form.area_id = $event"
          >
            <template #item="{ item }">
              <div>
                <span class="text-subtitle-2 mr-2">{{ item.name }}</span>
                <p style="font-size: .65rem; color: #888;">{{ item.address }}</p>
              </div>
            </template>
          </v-select>
        </div>
      </div>
      <div
        class="align_center mb-4"
        v-if="writeDialog.form.area_id === false"
      >
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('area.areaAddress') }}
        </v-chip>
        <div style="width: 100%" class="align_center">
          <GmapAutocomplete
            :placeholder="$t('placeholders.gampAutoComplete')"
            @place_changed="writeDialog.form.area.geolocation = {
              position: {
                lat: $event.geometry.location.lat(),
                lng: $event.geometry.location.lng(),
              },
              address: $event.formatted_address,
            }"
            style="
              border:1px solid #888;
              width: 100%;
              padding: 10px;
              height:40px;
              border-radius:3px;
            "
          />
        </div>
      </div>
      <div
        class="align_center mb-4"
        v-if="writeDialog.form.area_id === false"
      >
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('area.areaName') }}
        </v-chip>
        <div style="width: 100%">
          <v-text-field
            :placeholder="$t('placeholders.cp.cpName')"
            v-model="writeDialog.form.area.name"
            outlined
            color="black"
            dense
            flat
            solo
            hide-details
          />
        </div>
      </div>
      <v-card
        style="width: 100%"
        elevation="0"
        outlined
        class="pa-3 mb-3 flex-shirink-0"
      >
        <p class="pl-1 pb-1">{{ $t('cp.amountByGrade.amountByGrade') }}*</p>
        <div class="d-flex flex-wrap" style="gap: 10px">
          <v-card
            style="
              width: calc(50% - 5px);
              overflow: hidden;
              transition: .3s ease-in-out;
            "
            disable
            elevation="0"
            class="pa-3"
            outlined
            v-for="(row, grade) in writeDialog.form.membershipPrice"
            :key="grade"
            :style="row.disable ? 'background-color: #f5f5f5' : ''"
          >
            <div class="align_center justify-space-between">
              <v-chip
                label
                small
                class="px-2 mr-2 flex-shrink-0"
                :disabled="row.disable"
                outlined
              >
                {{ $t(`userGrades.${grade}`) }}test
              </v-chip>
              <v-switch
                :input-value="!row.disable"
                @change="row.disable = !row.disable"
                dense
                hide-details
                inset
                style="margin-right: -14px;"
                :disabled="writeDialog.form.method === 'view'"
                v-if="grade !== 0"
              />
            </div>
            <div class="align_center mt-2">
              <p
                style="width:72px; text-align: left;"
                class="flex-shrink-0 small-text mr-2 pl-1"
              >
                {{ $t('cp.amountByGrade.method') }}
              </p>
              <v-select
                dense
                hide-details
                outlined
                v-model="row.method"
                :items="memberMethods"
                :placeholder="
                  `${$t('cp.amountByGrade.method')} ${$t('faq.default')}`
                "
                item-value="method"
                :disabled="row.disable || writeDialog.form.method === 'view'"
              >
                <template #item="{ item }">
                  <span class="small-text" v-if="item === 'amount'">
                    {{ $t('cp.amountByGrade.amountAdjust') }}
                  </span>
                  <span class="small-text" v-else>
                    {{ $t(`cp.amountByGrade.${item}`) }}
                  </span>
                </template>
                <template #selection="{ item }">
                  <span class="small-text" v-if="item === 'amount'">
                    {{ $t('cp.amountByGrade.amountAdjust') }}
                  </span>
                  <span class="small-text" v-else>
                    {{ $t(`cp.amountByGrade.${item}`) }}
                  </span>
                </template>
              </v-select>
            </div>
            <div class="align_center mt-2">
              <p
                style="width:72px; text-align: left;"
                class="flex-shrink-0 small-text mr-2 pl-1"
              >
                <span v-if="row.method === 'rate'">
                  {{ $t('cp.amountByGrade.rate') }}(%)
                </span>
                <span v-else>
                  {{
                    `${$t('cp.amountByGrade.amount')}(${$t(`common.${companyInfo.currencyUnit}`)})`
                  }}
                </span>
              </p>
              <v-text-field
                v-model="row.value"
                type="number"
                dense
                hide-details
                outlined
                number
                :disabled="row.disable || writeDialog.form.method === 'view'"
              />
            </div>
          </v-card>
        </div>
      </v-card>
      <v-card
        style="width: 100%"
        elevation="0"
        outlined
        class="pa-3 flex-shirink-0"
      >
        <div class="pl-1 pb-1 align_center justify-space-between">
          {{ $t('cp.chargeRate') }}*
          <div class="align_center">
            <v-radio-group
              row
              class="align_center mt-2"
              style="margin-bottom: -15px;"
              :value="writeDialog.form.chargeRateMethod"
              @change="writeDialog.form.chargeRateMethod = $event"
              :disabled="writeDialog.form.method === 'view'"
            >
              <v-radio v-for="method in chargeRateMethods"
                :key="method"
                :label="$t(`cp.chargeRateMethods.${method}`)"
                :value="method"
                dense
                flat
              ></v-radio>
            </v-radio-group>
            <v-text-field
              style="margin-bottom: -1px; width: 180px;"
              v-if="writeDialog.form.chargeRateMethod === 'min'"
              :placeholder="
                `${$t('cp.chargeRateMethods.minAmount')}(${
                  $t(`common.${companyInfo.currencyUnit}`)
                })`
              "
              type="number"
              dense
              hide-details
              outlined
              number
              v-model="writeDialog.form.chargeRatePerMin"
              :disabled="writeDialog.form.method === 'view'"
            />
            <v-select
              style="margin-bottom: -1px; width: 180px;"
              v-else-if="writeDialog.form.chargeRateMethod === 'kwh'"
              dense
              hide-details
              outlined
              :value="basicPricetable"
              @change="writeDialog.form.pricetable = $event"
              item-text="name"
              item-value="_id"
              :items="pricetables"
              :placeholder="
                `${$t('cp.priceTable.ChargingPriceTable')} ${$t('faq.default')}`
              "
              return-object
              :disabled="writeDialog.form.method === 'view'"
            >
              <template #item="{ item }">
                <span class="small-text">
                  {{ item.name }}
                </span>
              </template>
            </v-select>
          </div>
        </div>
        <PriceTable
          v-if="writeDialog.form.chargeRateMethod === 'kwh'"
          class="pt-2"
          :pricetable="writeDialog.form.pricetable"
          :membershipPrice="writeDialog.form.membershipPrice"
        />
      </v-card>
    </DlgCommonWrite>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapGetters } from 'vuex';
import time from '@/util/time';
import * as XLSX from 'xlsx';
import SearchFilter from '@/components/common/SearchFilter.vue';
import SectionHeader from '@/components/common/SectionHeader.vue';
import DlgCommonWrite from '@/components/dialog/DlgCommonWrite.vue';
import ChargersDataTable from './unit/ChargersDataTable.vue';
import PriceTable from './unit/PriceTable.vue';

export default {
  name: 'ChargersSection',
  components: {
    ChargersDataTable,
    SearchFilter,
    SectionHeader,
    DlgCommonWrite,
    PriceTable,
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
      companyInfo: 'auth/companyInfo',
    }),
  },
  data: () => ({
    time,
    basicPricetable: [],
    loading: false,
    items: [],
    pricetables: [],
    models: [],
    areas: [],
    radios: [
      {
        key: 'version.ocpp',
        i18nKey: 'cp.ocppVer',
        values: ['ocpp1.6', 'ocpp2.0.1'],
        conditions: ['eq', 'eq'],
        i18nLabels: ['common.16', 'common.201'],
      },
      {
        key: 'isOnline',
        i18nKey: 'cp.online',
        values: [true, false],
        conditions: ['eq', 'eq'],
        i18nLabels: ['common.online', 'common.offline'],
      },
      {
        key: 'isOnline',
        i18nKey: 'transaction.status',
        values: [true, false],
        conditions: ['eq', 'eq'],
        i18nLabels: [
          'common.cp_state.state.available',
          'common.cp_state.state.unavailable',
        ],
        addFilters: [
          {
            key: 'state.status',
            values: 'Available',
            conditions: 'ne',
          },
          {
            key: 'state.availability',
            values: 'Operative',
            conditions: 'ne',
          },
        ],
      },
    ],
    selects: [
      {
        key: 'model_id',
        i18nKey: 'model.name',
        values: [],
        conditions: [],
        i18nLabels: [],
        isDirectLabel: true,
        permission: true,
      },
      {
        key: 'area_id',
        i18nKey: 'area.areaName',
        values: [],
        conditions: [],
        i18nLabels: [],
        isDirectLabel: true,
        permission: true,
      },
    ],
    searchKeys: [
      {
        i18nKey: 'cp.cpCode',
        key: 'cpCode',
        type: 'number',
      },
      {
        i18nKey: 'cp.cpId',
        key: 'cpId',
      },
      {
        i18nKey: 'area.areaName',
        key: 'area.name',
      },
      {
        i18nKey: 'area.areaAddress',
        key: 'area.address',
      },
    ],
    options: {
      page: 1,
      itemsPerPage: 2,
      sortBy: ['created'],
      sortDesc: [true],
      filters: [],
    },
    pageOptions: {
      total: 0,
      pageCount: 0,
      itemsPerPageOptions: [10, 50],
    },
    buttons: [
      {
        color: 'primary',
        icon: 'mdi-plus',
        click: 'openWriteDialog',
        i18nText: 'cp.newCP',
        permission: 'chargers.write',
      },
      {
        color: 'success',
        icon: 'mdi-microsoft-excel',
        click: 'downloadExcel',
        i18nText: 'btn.excelDownload',
      },
    ],
    writeDialog: {
      show: false,
      title: '',
      sub: null,
      type: 'regist',
      formCheck: false,
      submit: () => {},
      form: {
        cpName: '',
        cpId: '',
        area_id: null,
        model_id: null,
        area: {
          name: null,
          platform: 'google',
          geolocation: null,
        },
        membershipPrice: [],
        chargeRateMethod: 'kwh',
        chargeRatePerMin: 0,
        pricetable: null,
      },
    },
    memberMethods: [
      'rate',
      'amount',
      'fix',
    ],
    chargeRateMethods: [
      'kwh',
      'min',
    ],
  }),
  methods: {
    // TODO: 수정 필요
    async initbasicPricetable() {
      const oldestData = this.pricetables[(this.pricetables).length - 1];
      if (oldestData) {
        this.basicPricetable = oldestData;
        this.writeDialog.form.pricetable = this.basicPricetable;
      }
    },

    async addCP() {
      this.$dialog.progress(true);
      const {
        cpName,
        cpId,
        area_id: areaOid,
        model_id: modelOid,
        pricetable,
        chargeRateMethod,
        chargeRatePerMin,
        membershipPrice,
        area,
      } = this.writeDialog.form;
      try {
        await this.$emitter(
          'cp.add',
          {
            items: [{
              cpId,
              cpName,
              membershipPrice,
              chargeRateMethod,
              model_id: modelOid,
              ...((areaOid !== false) && { area_id: areaOid }),
              ...((areaOid === false) && { area }),
              ...((chargeRateMethod === 'min') && { chargeRatePerMin }),
              ...((chargeRateMethod === 'kwh') && { pricetable_id: pricetable._id }),
            }],
          }, this.$t('common.registCompleteMessage'),
        );
        this.initWriteDialog();
        await this.getCPData();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    initWriteDialog() {
      this.writeDialog = {
        show: false,
        title: this.$t('cp.newCP'),
        sub: null,
        type: 'regist',
        formCheck: false,
        submit: () => {},
        form: {
          cpName: '',
          cpId: '',
          area_id: null,
          price_id: null,
          model_id: null,
          area: {
            name: null,
            platform: 'google',
            geolocation: null,
          },
          membershipPrice: [
            { method: 'rate', value: 100, disable: false },
            { method: 'rate', value: 100, disable: true },
            { method: 'rate', value: 100, disable: true },
          ],
          chargeRateMethod: 'kwh',
          chargeRatePerMin: 0,
          pricetable: null,
        },
      };
    },
    async openWriteDialog() {
      this.initWriteDialog();
      await this.getPriceData();
      await this.getModelData();
      await this.getAreaData();
      this.writeDialog.show = true;
      this.writeDialog.submit = this.addCP;
    },
    checkChargerWriteValid(form) {
      const {
        cpName,
        cpId,
        model_id: modelOid,
        chargeRateMethod,
        chargeRatePerMin,
        pricetable,
      } = form;
      if (this.writeDialog.show !== true) return false;
      if (cpName == null && !/^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/|\\-]{12,50}$/.test(cpName.trim())) return false;
      if (cpId == null && !/^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/|\\-]{12,50}$/.test(cpId.trim())) return false;
      if (modelOid == null) return false;
      if (chargeRateMethod === 'min' && chargeRatePerMin <= 0) return false;
      if (chargeRateMethod === 'kwh' && pricetable == null) return false;
      return true;
    },
    getEvseStatusText(evse) {
      if (
        evse.state.online === false
        || evse.state.availability !== 'Operative'
        || evse.state.status.includes(['Unavailable', 'Faulted'])
      ) {
        return this.$t('common.cp_state.state.unavailable');
      }
      if (
        [
          'Preparing', 'Charging', 'SuspendedEVSE', 'SuspendedEV', 'Finishing', 'Reserved',
        ].includes(evse.state.status)
      ) {
        return this.$t('cp.operation');
      }
      if (evse.state.status === 'Available') return this.$t('common.cp_state.state.available');
      return null;
    },
    async downloadExcel() {
      this.$dialog.progress(true);
      try {
        // 1. 데이터 새로 불러옴 (현재 필터 + 전체 페이지)
        const { items } = await this.$emitter('cp.list.get', {
          ...this.options,
          page: 1,
          itemsPerPage: 0,
        });

        // 2. 데이터가 없을 경우 에러처리
        // eslint-disable-next-line no-throw-literal
        if (items.length <= 0) throw { code: 204 };

        // 3. 출력할 필드의 이름 및 값 정의
        const convertItems = items.map((cp) => {
          // evse 정보
          let evse = this.$t('common.emptyInfo');
          if (Object.values(cp.evse).length > 0) {
            evse = Object.values(cp.evse).map((currentEvse) => {
              const evseStatus = this.getEvseStatusText(currentEvse);
              return evseStatus == null ? currentEvse.evseNumber : `${currentEvse.evseNumber} (${evseStatus})`;
            }).join('\r\n');
          }
          return {
            // 번호
            [this.$t('cp.cpCode')]: cp.cpCode,
            // ID
            [this.$t('cp.cpId')]: cp.cpId,
            // 충전기명
            [this.$t('cp.cpName')]: cp.cpName,
            // 모델명
            [this.$t('model.name')]: cp.model.name,
            // 최대 전력
            [this.$t('model.kwh')]: `${cp.model.kwh}kWh`,

            // 사업장명
            [this.$t('area.areaName')]: cp.area ? cp.area.name : '',
            // 사업장주소
            [this.$t('area.areaAddress')]: cp.area ? cp.area.address : '',

            // TODO: 충전요금 추가 필요함. 나중에 하는걸로...

            // 충전기 상태
            [this.$t('cp.cpStatus')]: cp.state.availability !== 'Operative'
              || cp.state.status !== 'Available'
              || cp.isOnline !== true ? this.$t('common.error') : this.$t('common.normal'),
            Evse: evse,
          };
        });
        // 4. 엑셀파일 내보내기
        const excelData = XLSX.utils.json_to_sheet(convertItems);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, excelData, 'chargers_data');
        XLSX.writeFile(workBook, `chargers_data_${time.makeAttachTime(new Date())}.xlsx`);
        this.$dialog.progress(false);
      } catch (error) {
        console.error(error.code);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        this.$dialog.progress(false);
      }
    },
    async getCPData() {
      this.loading = true;
      try {
        const {
          itemsPerPage,
          filters,
        } = this.options;
        const { data: { total } } = await this.$emitter('cp.summary.get', { filters });
        this.pageOptions.total = total;
        this.pageOptions.pageCount = Math.ceil(total / itemsPerPage);

        const { items } = await this.$emitter('cp.list.get', this.options);
        this.items = items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    async getPriceData() {
      try {
        const { items } = await this.$emitter('pricetable.list.get', {
          itemsPerPage: 0,
          page: 1,
          sortBy: ['created'],
          sortDesc: [false],
        });
        this.pricetables = items;
        this.initbasicPricetable();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    async getModelData() {
      try {
        const { items } = await this.$emitter('model.list.get', {
          itemsPerPage: 0,
          page: 1,
          sortBy: ['created'],
          sortDesc: [false],
        });
        this.models = items;
        const modelSelectFilter = items.reduce((acc, row) => {
          acc.i18nLabels.push(row.name);
          acc.values.push(row._id);
          acc.conditions.push('eq');
          return acc;
        }, {
          i18nLabels: [],
          values: [],
          conditions: [],
        });
        this.$set(this.selects, 0, {
          ...this.selects[0],
          ...modelSelectFilter,
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    async getAreaData() {
      try {
        const { items } = await this.$emitter('area.list.get', {
          itemsPerPage: 0,
          page: 1,
          sortBy: ['created'],
          sortDesc: [false],
        });
        this.areas = [
          { name: this.$t('area.newArea'), _id: false },
          ...items,
        ];
        const areaSelectFilter = items.reduce((acc, row) => {
          acc.i18nLabels.push(row.name);
          acc.values.push(row._id);
          acc.conditions.push('eq');
          return acc;
        }, {
          i18nLabels: [],
          values: [],
          conditions: [],
        });
        this.$set(this.selects, 1, {
          ...this.selects[1],
          ...areaSelectFilter,
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
  },
  watch: {
    staffInfo: {
      deep: true,
      immediate: true,
      async handler(value) {
        if (value != null) {
          await this.getCPData();
          await this.getModelData();
          await this.getAreaData();
        }
      },
    },
    options: {
      deep: true,
      async handler() {
        if (this.staffInfo !== null) await this.getCPData();
      },
    },
    'writeDialog.form': {
      deep: true,
      handler(value) {
        this.writeDialog.formCheck = this.checkChargerWriteValid(value);
      },
    },
  },
};
</script>
