<template>
  <div class="protect_info_wrap d-flex flex-column justify-center align-center">
    <div class="info_input_box" style="width: 100%;">
      <div class="input_line d-flex justify-center" style="width: 100%;">
        <div class="wd150">
          <p class="editText">
            {{ $t('company_setting.protectInfo') }}
          </p>
        </div>
        <v-col
          cols="8"
        >
          <v-textarea
            outlined
            no-resize
            height="400px"
            v-model="policyValue"
          ></v-textarea>
        </v-col>
      </div>
    </div>
    <div class="info_btn_box">
      <v-btn @click="init" class="mR10" depressed>
        {{ $t('btn.cancel') }}
      </v-btn>
      <v-btn
        @click="saveProtect"
        color="primary"
        depressed
      >
        {{ $t('btn.edit') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      companyInfo: 'auth/companyInfo',
    }),
  },
  mounted() {
    this.getCompanyInfo();
  },
  data: () => ({
    policyValue: null,
  }),
  methods: {
    ...mapMutations({
      setCompanyInfo: 'auth/companyInfo',
    }),
    init() {
      this.$emit('update:show', false);
      this.files = [];
      this.companyName = null;
      this.policyValue = this.companyInfo.privacyPolicy;
    },
    async getCompanyInfo() {
      try {
        const { item: companyInfo } = await this.$emitter('sysconf.company.get', {});
        this.setCompanyInfo(companyInfo);
        this.policyValue = companyInfo.privacyPolicy;
      } catch (error) {
        console.error(error);
        this.$dialog.progress(false);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.init();
    },
    async saveProtect() {
      try {
        await this.$emitter('sysconf.company.update', {
          privacyPolicy: this.policyValue,
        }, `${this.$t('common.updateCompleteMessage')}`);
      } catch (error) {
        console.error(error);
        this.$dialog.progress(false);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.getCompanyInfo();
      this.init();
    },
  },
};
</script>

<style lang="scss">
.protect_info_wrap{
  height:100%;
  .info_input_box{
    height:90%;
    padding:1%;
    overflow-y: auto;
    .input_line +  .input_line{
      margin-top:20px;
    }
  }
  .info_btn_box{ height:10%; }
  .editText{
    line-height: 40px;
    position:relative;
    display: inline-block;
    font-weight: bold;
  }
}
</style>
