<template>
  <PopupSection/>
</template>

<script>
import PopupSection from '@/components/community/popup/PopupSection.vue';

export default {
  name: 'UserPayment',
  components: { PopupSection },
};
</script>
