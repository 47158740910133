<template>
  <v-dialog
    v-model="show"
    width="1000"
    @click:outside="init()"
    @keydown="$event.key === 'Escape' ? init() : ''"
  >
    <v-card class="log-wrap" style="width: 100%;">
      <div class="d-flex">
        <p class="tit">
          {{ $t('cp.faultLog.title') }}
          <span class="sub ml-1">{{ cpId }}</span>
        </p>
        <v-btn
          icon
          color="primary"
          depressed
          small
          :disabled="loading"
          @click="getFaultLog()"
        >
          <v-icon small class="mr-1">mdi-refresh</v-icon>
        </v-btn>
      </div>
      <div class="align_center justify-space-between mb-1">
        <div class="align_center">
          <v-btn
            icon
            color="black"
            @click="moveMonth('prev')"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <div>
            <date-picker
              v-model="viewMonth"
              type="month"
              @input="handleDatePickerChange"
              :disabled-date="date => date > new Date()"
              :clearable="false"
            ></date-picker>
          </div>
          <v-btn
            icon
            color="black"
            @click="moveMonth('next')"
            :disabled="
              (viewMonth.getFullYear() === new Date().getFullYear()) &&
              (viewMonth.getMonth() + 1 === new Date().getMonth() + 1)
            "
          >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        </div>
        <v-btn
          @click="batch"
          text
        >
          {{ $t('cp.faultLog.batch') }}
          <v-icon>mdi-playlist-check</v-icon>
        </v-btn>
      </div>
      <v-data-table
        mobile-breakpoint="0"
        fixed-header
        :headers="headers"
        :items="faultList"
        :loading="loading"
        disable-pagination
        hide-default-footer
        dense
        class="data_table"
        checkbox-color="primary"
        item-key="_id"
        :options="options"
      >
        <template v-slot:item.case.manual="{ item }">
          {{ item.case.manual[`${language}`] }}
        </template>
        <template v-slot:item.completeStaffInfo="{ item }">
          <p v-if="item.isCompleted && item.completeStaffInfo">
            {{ item.completeStaffInfo[0].name }}
          </p>
          <p v-else>{{ $t('cp.faultLog.undisposed') }}</p>
        </template>
        <template v-slot:item.completed="{ item }">
          <p v-if="item.isCompleted && item.completed">
            {{ timeConvert.makeLocalTime(item.completed) }}
          </p>
          <p v-else>{{ $t('cp.faultLog.undisposed') }}</p>
        </template>
        <template v-slot:item.memo="{ item }">
          <div v-if="item.memo">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <p
                  v-bind="attrs"
                  v-on="on"
                  class="memo-line"
                  @click="openwritememoContent($event, item, 'update')"
                >{{ item.memo }}</p>
              </template>
              <span>{{ $t('cp.faultLog.viewDetail') }}</span>
            </v-tooltip>
          </div>
          <div v-else>
            <v-icon
              small
              @click="openwritememoContent($event, item, 'new')"
            >mdi-plus-circle</v-icon>
          </div>
        </template>
        <template v-slot:item.created="{ item }">
          {{ timeConvert.makeLocalTime(item.created) }}
        </template>
        <template v-slot:no-data>
          <p>{{ $t('common.noData') }}</p>
        </template>
      </v-data-table>
    </v-card>
    <v-menu
      v-model="writememoContent"
      :close-on-content-click="false"
      :nudge-width="300"
      :position-x="menuX"
      :position-y="menuY"
    >
      <v-card>
        <v-card-text>
          <v-icon
            v-if="updateFaultMemo"
            small
            class="d-flex justify-end pb-2"
            style="width: 100%;"
            @click="editMemo"
          >mdi-pencil</v-icon>
          <div v-if="updateFaultMemo">
            <div
              class="pa-2 rounded-br-0 memo-content"
              v-html="selectFaultCase.memo.includes('\n')
                ? selectFaultCase.memo.replace(/\n/g, '<br>')
                : selectFaultCase.memo"
            ></div>
          </div>
          <div v-else>
            <v-textarea
              class="fault-memo"
              v-model="memoContent"
              :placeholder="$t('cp.faultLog.limit')"
              outlined
              rows="4"
              background-color="grey lighten-5"
              clearable
              counter
              maxlength="2000"
            ></v-textarea>
            <v-btn
              depressed
              color="primary"
              x-small
              :disabled="!memoContent || memoContent.length === 0"
              style="width: 100%;"
              @click="saveFaultMemo"
            >{{ $t('common.regist') }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-dialog>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { navigateMonth } from '@/util/calendar';
import timeConvert from '@/util/time';

export default {
  components: { DatePicker },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
    },
    cpId: {
      type: String,
    },
    staffInfo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    timeConvert,
    viewMonth: new Date(),
    faultList: [],
    loading: false,
    headers: null,
    from: {
      CSMS: 'CSMS',
      ChargePoint: 'CP',
    },
    options: {
      page: 1,
      itemsPerPage: 0,
      sortBy: ['created'],
      sortDesc: [true],
    },
    filters: [
      {
        where: 'and',
        condition: 'gte',
        key: 'created',
        value: `${timeConvert.getMonth2()}-01 00:00:00`,
      },
      {
        where: 'and',
        condition: 'lte',
        key: 'created',
        value: `${timeConvert.getLastDay()} 23:59:59`,
      },
    ],
    writememoContent: false,
    menuX: 0,
    menuY: 0,
    memoContent: '',
    selectFaultCase: {},
    updateFaultMemo: false,
    dateType: '',
    language: '',
  }),
  mounted() {
    this.language = localStorage.getItem('BLITZadmin.locale');
  },
  methods: {
    init() {
      this.$emit('update:show', false);
      this.$emit('update:id', null);
    },
    initData() {
      this.faultList = [];
      this.headers = [
        {
          text: this.$t('cp.faultLog.case'),
          align: 'center',
          sortable: true,
          value: 'case.code',
          width: '13%',
        },
        {
          text: this.$t('cp.faultLog.manual'),
          align: 'center',
          value: 'case.manual',
          width: '20%',
        },
        {
          text: this.$t('cp.faultLog.staff'),
          align: 'center',
          sortable: true,
          value: 'completeStaffInfo',
          width: '13%',
        },
        {
          text: this.$t('cp.faultLog.handleDate'),
          align: 'center',
          sortable: true,
          value: 'completed',
          width: '18%',
        },
        {
          text: this.$t('cp.faultLog.memo'),
          align: 'center',
          sortable: true,
          value: 'memo',
          width: '16%',
        },
        {
          text: this.$t('cp.faultLog.created'),
          align: 'center',
          sortable: true,
          value: 'created',
          width: '19%',
        },
      ];
    },
    async getFaultLog() {
      this.loading = true;
      try {
        const res = await this.$emitter('faultlog.list.get', {
          ...this.options,
          filters: [
            ...this.filters,
            {
              where: 'and',
              condition: 'eq',
              key: 'chargepoint_id',
              value: this.id,
            },
          ],
        });
        this.faultList = res.items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    async batch() {
      try {
        await this.$emitter('faultlog.complete', {
          chargepoint_id: this.id,
        }, this.$t('common.batch'));
        this.getFaultLog();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    formattedDate(month) {
      return month.toString().padStart(2, '0');
    },
    moveMonth(type) {
      this.dateType = type;
      const formattedDate = `${this.viewMonth.getFullYear()}-${this.formattedDate((this.viewMonth.getMonth() + 1), type)}`;
      let newYear; let newMonth; let newDateStr = ''; let lastDay = '';
      if (this.dateType === 'prev' || this.dateType === 'next') {
        newDateStr = navigateMonth(type, formattedDate, 0);
        [newYear, newMonth] = newDateStr.split('-');
        lastDay = timeConvert.getLastDay(newYear, newMonth);
        this.viewMonth = new Date(newYear, newMonth - 1);
        this.$nextTick(() => {
        });
      } else {
        [newYear, newMonth] = formattedDate.split('-');
        lastDay = timeConvert.getLastDay(newYear, newMonth);
      }
      const newFilters = [
        { ...this.filters[0], value: type ? `${newDateStr}-01 00:00:00` : `${formattedDate}-01 00:00:00` },
        { ...this.filters[1], value: `${lastDay} 23:59:59` },
      ];
      this.filters = newFilters;
    },
    handleDatePickerChange() {
      this.dateType = 'picker';
    },
    openwritememoContent(e, item, type) {
      this.writememoContent = true;
      this.selectFaultCase = item;
      this.menuX = e.clientX;
      this.menuY = e.clientY;
      if (type === 'update') {
        this.updateFaultMemo = true;
        this.memoContent = this.selectFaultCase.memo;
      } else {
        this.memoContent = '';
      }
    },
    editMemo() {
      this.updateFaultMemo = false;
      this.memoContent = this.selectFaultCase.memo;
    },
    async saveFaultMemo() {
      try {
        await this.$emitter('faultlog.memo.update', {
          _id: this.selectFaultCase._id,
          memo: this.memoContent,
        }, this.$t('common.registCompleteMessage'));
        this.writememoContent = false;
        this.getFaultLog();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
  },
  watch: {
    id: {
      immediate: true,
      async handler(val) {
        if (val != null) {
          this.initData();
          await this.getFaultLog();
        }
      },
    },
    filters: {
      handler(val) {
        if (val !== null) this.getFaultLog();
      },
    },
    viewMonth: {
      handler(val) {
        if (val !== null && this.dateType === 'picker') this.moveMonth();
      },
    },
    writememoContent: {
      handler(val) {
        if (!val) {
          this.memoContent = '';
          this.updateFaultMemo = false;
        }
      },
    },
    show: {
      handler(val) {
        if (!val) {
          this.filters[0].value = `${timeConvert.getMonth2()}-01 00:00:00`;
          this.filters[1].value = `${timeConvert.getLastDay()} 23:59:59`;
          this.viewMonth = new Date();
        }
      },
    },
    '$i18n.locale': {
      handler(val) {
        this.language = val;
      },
    },
  },
};
</script>

<style lang="scss">
.mx-datepicker {
  width: 110px;
  .mx-input { border: none; box-shadow: none; cursor: pointer; }
}
.memo-content, .memo-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}
.memo-content {
  overflow-y: scroll;
  border: 1px solid #f4f2f2;
  max-height: 122px;
}
.memo-line { -webkit-line-clamp: 2; cursor: pointer;}
.fault-memo {
  .v-text-field__slot {font-size: 13px;}
  .v-input__append-inner button { font-size: 20px;}
}
</style>
