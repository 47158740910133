/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Error from '../util/error';
import i18n from '../i18n';

function emitter(eventName, body) {
  return new Promise((resolve, reject) => {
    (new Vue()).$socket.emit(eventName, body, (response) => {
      if (response.result === 'success') {
        resolve(response);
      } else {
        reject(response);
      }
    });
  });
}

export default {
  namespaced: true,
  state: {
    initialized: false,
    staffInfo: null,
    companyInfo: null,
  },
  getters: {
    staffInfo(state) {
      return state.staffInfo;
    },
    companyInfo(state) {
      return state.companyInfo;
    },
    initialized(state) {
      return state.initialized;
    },
  },
  mutations: {
    initialized(state, value) {
      state.initialized = value;
    },
    staffInfo(state, value) {
      state.staffInfo = value;
    },
    companyInfo(state, value) {
      state.companyInfo = value;
    },
  },
  actions: {
    // 로그아웃
    async signout({ state, dispatch }) {
      try {
        await emitter('staff.signout', {});
        localStorage.removeItem(`BLITZadmin.${state.companyInfo.code}.token`);
        dispatch('authorization');
      } catch (error) {
        dispatch('dialog/alert', ['error', Error.makeErrorMessage(error)], { root: true });
        console.error(error);
      }
    },
    // 회사정보
    async initialization({ commit, dispatch }) {
      try {
        const response = await emitter('sysconf.company.get', {});
        commit('companyInfo', response.item);
        await dispatch('authorization');
      } catch (error) {
        dispatch('dialog/alert', ['error', Error.makeErrorMessage(error)], { root: true });
        console.error(error);
      }
    },
    // 사용자 정보
    async authorization({ state, commit, dispatch }) {
      const tokenPath = `BLITZadmin.${state.companyInfo.code}.token`;
      const token = localStorage.getItem(tokenPath);
      if (token) {
        try {
          const response = await emitter('staff.token.verify', { token });
          const { staff } = response;
          commit('staffInfo', staff);
          console.info('User Verified.');
          if (i18n.locale === 'en') {
            dispatch('dialog/alert', ['success', `Welcome, ${staff.name}`], { root: true });
          } else if (i18n.locale === 'ko') {
            dispatch('dialog/alert', ['success', `${staff.name}님, 환영합니다.`], { root: true });
          } else if (i18n.locale === 'ja') {
            dispatch('dialog/alert', ['success', `ようこそ、${staff.name}。`], { root: true });
          }
        } catch (error) {
          commit('staffInfo', null);
          localStorage.removeItem(tokenPath);
        }
      } else { // 토큰이 없을 경우
        commit('staffInfo', null);
      }
      console.info('App Initialized.');
      commit('initialized', true);
      commit('dialog/progress', false, { root: true });
    },
  },
};
