<template>
  <div class="dlg-file-wrap">
    <div
      class="file-row"
      v-for="upload in uploads"
      :key="upload.no"
    >
      <div class="file-info">
        <div class="align_center justify-space-between">
          <v-chip label x-small outlined class="px-1 mr-1">
            {{ $t('common.upload') }}
          </v-chip>
        </div>
        <div class="align_center justify-space-between pt-1 pb-1">
          <div class="align_center">
            <p class="small-text mr-2">
              {{ upload.name }}
              <span class="x-small-text ml-1">
                {{ utilFile.byteTrans(upload.size) }}
              </span>
            </p>
          </div>
        </div>
        <v-progress-linear
          indeterminate
          color="success"
        />
      </div>
    </div>
    <div
      class="file-row"
      v-for="download in downloads"
      :key="download.no"
    >
      <div class="file-info">
        <div class="align_center justify-space-between">
          <v-chip label x-small outlined class="px-1 mr-1">
            {{ $t('btn.download') }}
          </v-chip>
          <v-btn
            icon
            depressed
            :disabled="download.progress !== 100"
            color="black"
            small
            @click="deleteDownloadFile(download)"
            style="margin-top: -10px; margin-right: -10px;"
            class="mb-2"
          >
            <v-icon size="14">mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="align_center justify-space-between">
          <div class="align_center">
            <p class="small-text mr-2">
              {{ download.name }}
              <span class="x-small-text ml-1">
                {{ utilFile.byteTrans(download.size) }}
              </span>
            </p>
          </div>
          <v-btn
            icon
            depressed
            :disabled="download.progress !== 100"
            small
            @click="downloadFile(download)"
            color="primary"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>
        <v-progress-linear
          v-show="download.progress !== 100"
          rounded
          :value="20"
          :buffer-value="100"
          color="primary"
          class="mt-2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import utilFile from '@/util/file';

export default {
  name: 'DlgFileTask',
  computed: {
    ...mapGetters({
      downloads: 'file/downloadTasks',
      uploads: 'file/uploadTasks',
    }),
  },
  methods: {
    ...mapMutations({
      downloadsCng: 'file/downloadTasks',
      uploadsCng: 'file/uploadTasks',
    }),
    downloadFile(item) {
      utilFile.writeFileFromPayload(item);
    },
    deleteDownloadFile(item) {
      this.downloadsCng(this.downloads.filter((row) => row.no !== item.no));
    },
  },
  data: () => ({
    utilFile,
  }),
};
</script>

<style scoped lang="scss">
.dlg-file-wrap {

  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 300;
  .file-row {
    padding: 8px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid #ccc;
    margin-top: 8px;
    display: flex;
    align-items: center;
    .file-info {
      width: 450px;
    }
  }
}
</style>
