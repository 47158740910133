<template>
  <div
    class="align_center flex-column"
    style="height: 100%;"
  >
    <div style="width: 100%;">
      <div class="mB10">
        <v-text-field
          hide-details
          outlined
          :label="$t('price_setting.priceName')"
          v-model="form.name"
        />
      </div>
      <div
        class="d-flex flex-column"
        style="min-height: 600px;"
      >
        <div>
          <div class="align_center justify-space-between mB10">
            <h3>{{ $t('price_setting.selectMonth') }}</h3>
            <div class="d-flex">
              <div
                v-for="season in seasons"
                :key="season.color"
                class="align_center"
              >
                <v-btn
                  :color=season.color
                  depressed
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  @click="whichColor(season.num)"
                  class="clickedBtn"
                  style="margin-left: 30px;"
                  :class="{
                    'boxShadow': clickedWeatherNum == season.num,
                  }"
                ></v-btn>
                <p
                  class="pL10 smT"
                  :class="{
                    'pointText' : clickedWeatherNum == season.num,
                  }"
                >
                  {{ $t(`price_setting.${season.text}`) }}
                </p>
              </div>
            </div>
          </div>
          <div>
            <ul class="d-flex">
              <li
                v-for="row, index in form.data"
                :key="index"
                class="monthList nDrag"
                @click="selectMonth(row)"
                :class="{
                  'selectSpring': seasons[row.season].num === 0,
                  'selectSummer': seasons[row.season].num === 1,
                  'selectWinter': seasons[row.season].num === 2,
                  'hoverSpring' : clickedWeatherNum === 0,
                  'hoverSummer' : clickedWeatherNum === 1,
                  'hoverWinter' : clickedWeatherNum === 2
                }"
              >
                {{ $t(`common.month.${index}`) }}
              </li>
            </ul>
          </div>
        </div>
        <div
          style="margin-top: 50px;"
          v-if="clickedWeatherNum !== null"
        >
          <div class="d-flex justify-space-between">
            <h3>{{ $t('price_setting.selectTime') }}</h3>
            <div class="align_center">
              <div
                v-for="(list, idx) in loadSortOfCose[clickedWeatherNum]"
                :key="idx"
                class="align_center"
              >
                <v-btn
                  depressed
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  @click="changeSortOfCost(idx)"
                  :color= "idx === 0 ? '#ADD8E6' : idx === 1 ? '#ecc13d' : '#bb0303'"
                  style="margin-left: 30px;"
                  :class="{
                    'boxShadow': clickedCostNum == idx && clickedCostNum !== null,
                  }"
                ></v-btn>
                <p
                  class="costTitle mR10 smT pL10"
                  :class="{
                    'pointText' : clickedCostNum == idx,
                  }"
                >
                  {{ $t(`price_setting.sort.${idx}`) }}
                </p>
                <div class="align_center">
                  <p class="smT mR10">
                    {{$t('price_setting.price')}}
                  </p>
                  <v-text-field
                    v-if="list.langth !== 0"
                    hide-details
                    :value="list.cost"
                    @input="changeCostPrice(idx, $event, true)"
                    type="number"
                    style="width:80px;"
                    dense
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mT10">
            <ul class="d-flex">
              <li
                v-for="(hour, index) in hourList[clickedWeatherNum]"
                :key="index"
                class="hourList flex_center flex-column nDrag"
                :class="{
                  'selectLight': hour.load === 0 ,
                  'selectMedium': hour.load === 1,
                  'selectMaximum': hour.load === 2,
                  'hoverLight' : clickedCostNum === 0,
                  'hoverMedium' : clickedCostNum === 1,
                  'hoverMaximum' : clickedCostNum === 2
                }"
                @click="selectTime(index)"
              >
                <p>
                  {{ $t(`common.time.${index}`) }}
                </p>
              </li>
            </ul>
          </div>
          <PriceTable
            class="priceTable"
            :pricetable="{data : form.data}"
            style="margin-top:50px;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import PriceTable from '../../../charger/chargers/unit/PriceTable.vue';

export default {
  components: { PriceTable },
  props: {
    form: {
      type: Object,
      default: Object,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    clickedWeatherNum: 0,
    clickedCostNum: 0,
    seasons: [
      { text: 'springFall', color: '#FFA07A', num: 0 },
      { text: 'summer', color: '#34b2a6', num: 1 },
      { text: 'winter', color: '#000080', num: 2 },
    ],
    loadSortOfCose: [
      [
        {
          cost: 0,
        },
        {
          cost: 0,
        },
        {
          cost: 0,
        },
      ],
      [
        {
          cost: 0,
        },
        {
          cost: 0,
        },
        {
          cost: 0,
        },
      ],
      [
        {
          cost: 0,
        },
        {
          cost: 0,
        },
        {
          cost: 0,
        },
      ],
    ],
    hourList: [],
  }),
  methods: {
    selectMonth(clickedRow) {
      if (this.clickedWeatherNum !== null) {
        clickedRow.season = this.clickedWeatherNum;
      } else {
        this.$dialog.alert('error', this.$t('price_setting.notSelectSeason'));
      }
    },

    whichColor(num) {
      this.clickedWeatherNum = num;
      const lightLoadInfo = this.hourList[num].find((info) => info.load === 0);
      const mediumLoadInfo = this.hourList[num].find((info) => info.load === 1);
      const maximumLoadInfo = this.hourList[num].find((info) => info.load === 2);
      this.loadSortOfCose[num][0].cost = lightLoadInfo.price;
      this.loadSortOfCose[num][1].cost = mediumLoadInfo.price;
      this.loadSortOfCose[num][2].cost = maximumLoadInfo.price;
      this.clickedCostNum = 0;
    },

    getPriceData() {
      for (let i = 0; i < this.hourList.length; i += 1) {
        const lightLoadInfo = this.hourList[i].find((info) => info.load === 0);
        const mediumLoadInfo = this.hourList[i].find((info) => info.load === 1);
        const maximumLoadInfo = this.hourList[i].find((info) => info.load === 2);
        this.loadSortOfCose[i][0].cost = lightLoadInfo.price;
        this.loadSortOfCose[i][1].cost = mediumLoadInfo.price;
        this.loadSortOfCose[i][2].cost = maximumLoadInfo.price;
      }
    },

    changeCostPrice(index, value, event) {
      if (event) {
        this.clickedCostNum = index;
        this.hourList[this.clickedWeatherNum].forEach((hour) => {
          if (hour.load === index) {
            hour.price = Number(value);
            this.loadSortOfCose[this.clickedWeatherNum][index].cost = Number(value);
          }
          this.form.data.map((list) => {
            switch (list.season) {
              case 0: list.times.splice(0, list.times.length, ...this.hourList[0]); break;
              case 1: list.times.splice(0, list.times.length, ...this.hourList[1]); break;
              case 2: list.times.splice(0, list.times.length, ...this.hourList[2]); break;
              default: break;
            }
            return list;
          });
        });
      }
    },

    selectTime(timeIndex) {
      const clickedTime = this.hourList[this.clickedWeatherNum][timeIndex];
      const clickedCost = this.loadSortOfCose[this.clickedWeatherNum][this.clickedCostNum];
      if (clickedCost.cost !== '' && clickedCost.cost !== null) {
        clickedTime.load = this.clickedCostNum;
        clickedTime.price = clickedCost.cost;
        this.form.data.map((list) => {
          switch (list.season) {
            case 0: list.times.splice(0, list.times.length, ...this.hourList[0]); break;
            case 1: list.times.splice(0, list.times.length, ...this.hourList[1]); break;
            case 2: list.times.splice(0, list.times.length, ...this.hourList[2]); break;
            default: break;
          }
          return list;
        });
      } else {
        this.$dialog.alert('error', this.$t('price_setting.notWriteCost'));
      }
    },

    changeSortOfCost(value) {
      this.clickedCostNum = value;
    },

    makeHourList() {
      const findZero = this.form.data.find((item) => item.season === 0);
      const findOne = this.form.data.find((item) => item.season === 1);
      const findTwo = this.form.data.find((item) => item.season === 2);
      this.hourList[0] = findZero.times;
      this.hourList[1] = findOne.times;
      this.hourList[2] = findTwo.times;
      this.getPriceData();
    },
  },

  watch: {
    hourList: {
      immediate: true,
      handler(value) {
        if (value !== null) {
          this.makeHourList();
        }
      },
    },
    show: {
      immediate: true,
      handler(value) {
        if (value) {
          this.hourList = [];
          this.clickedWeatherNum = 0;
          this.makeHourList();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.monthList{
  width: 70px;
  height: 30px;
  border:1px solid #8b8b8b;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-right:none;
  &+&{
    border-right:none;
  }
  &:first-child{
    border-radius: 4px 0 0 4px;
  }
  &:last-child{
    border-right:1px solid #8b8b8b;
    border-radius: 0 4px 4px 0;
  }
}
.hourList{
  width:52.9px;
  height:22px;
  border:1px solid #8b8b8b;
  cursor: pointer;
  max-width: 1180px;
  border-right:none;
  &+&{
    border-right:none;
  }
  &:first-child{
    border-radius: 4px 0 0 4px;
  }
  &:last-child{
    border-right:1px solid #8b8b8b;
    border-radius: 0 4px 4px 0;
  }
}
.costTitle{
  width: 70px;
}
.hoverSpring{
  &:hover {
    background-color: #bf7558;
    color: white;
  }
}
.hoverSummer{
  &:hover {
    background-color: #1d6961;
    color: white;
  }
}
.hoverWinter{
  &:hover {
    background-color: #011c36;
    color: white;
  }
}
.hoverLight{
  &:hover {
    background-color: #6d8a93;
    color: white;
  }
}
.hoverMedium{
  &:hover {
    background-color: #927412;
    color: white;
  }
}
.hoverMaximum{
  &:hover {
    background-color: #850f0f;
    color: white;
  }
}
</style>
