<template>
  <ChargerAreaSection/>
</template>

<script>
import ChargerAreaSection from '@/components/charger/chargerArea/ChargerAreaSection.vue';

export default {
  name: 'Charger',
  components: { ChargerAreaSection },
};
</script>
