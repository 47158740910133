import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';

dayjs.extend(utc);
dayjs.extend(duration);

const dateFormats = {
  date: 'YYYY-MM-DD',
  month: 'YYYY.MM',
  month2: 'YYYY-MM',
  year: 'YYYY',
  attachDate: 'YYYYMMDD',
  min: 'YYYY-MM-DD HH:mm',
  sec: 'YYYY-MM-DD HH:mm:ss',
  hhmmss: 'HH:mm:ss',
  timestamp: 'YYYY-MM-DDTHH:mm:ss.000Z',
  zuluTimestamp: 'YYYY-MM-DDTHH:mm:ss.000[Z]',
};

function timeGetter({ date = new Date(), type, format = dateFormats.timestamp }) {
  if (type === 'utc') {
    const formatConvert = format === dateFormats.timestamp ? dateFormats.zuluTimestamp : format;
    return dayjs.utc(date).format(formatConvert);
  }
  return dayjs(date).format(format);
}

export default {
  dayjs,
  getDate(type = 'local') {
    return timeGetter({ type, format: dateFormats.date });
  },
  getMonth(type = 'local') {
    return timeGetter({ type, format: dateFormats.month });
  },
  getMonth2(type = 'local') {
    return timeGetter({ type, format: dateFormats.month2 });
  },
  getYear(type = 'local') {
    return timeGetter({ type, format: dateFormats.year });
  },
  getTime(type = 'local') {
    return timeGetter({ type, format: dateFormats.sec });
  },
  getTimestamp(type = 'local') {
    return timeGetter({ type });
  },
  makeDate(date, type = 'local') {
    return timeGetter({ date, type, format: dateFormats.date });
  },
  makeTime(date, type = 'local') {
    return timeGetter({ date, type, format: dateFormats.sec });
  },
  makeTimestamp(date, type = 'local') {
    return timeGetter({ date, type });
  },
  makeAttachTime(date) {
    return timeGetter({ date, type: 'local', format: dateFormats.attachDate });
  },
  makeLocalTimeMin(date) {
    return timeGetter({ date, type: 'local', format: dateFormats.min });
  },
  makeLocalTime(date) {
    return timeGetter({ date, type: 'local', format: dateFormats.sec });
  },
  makeLocalHMS(date) {
    return timeGetter({ date, type: 'local', format: dateFormats.hhmmss });
  },
  makeLocalTimestamp(date) {
    return timeGetter({ date, type: 'local', format: dateFormats.timestamp });
  },
  getCurrentDataFromPriceTable(pricetable) {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentHour = currentDate.getHours();
    return pricetable.data[currentMonth].times[currentHour];
  },
  makeCalcTime(start, end) {
    const startT = new Date(start);
    const endT = new Date(end);
    if (startT < endT) {
      const diff = dayjs(endT).diff(dayjs(startT), 'millisecond');
      const timeDuration = diff / 1000; // 초 단위로 변환

      const formattedDiff = [
        Math.floor(timeDuration / 3600).toString().padStart(2, '0'), // 시간
        Math.floor((timeDuration % 3600) / 60).toString().padStart(2, '0'), // 분
      ].join(':');

      return formattedDiff;
    }
    return '00 : 00';
  },
  getLastDay(year, month) {
    if (year === undefined && month === undefined) {
      const date = this.getMonth2().split('-');
      const lastDay = new Date(date[0], date[1], 0);
      return `${this.makeDate(lastDay)}`;
    }
    const lastDay = new Date(year, month, 0);
    return `${this.makeDate(lastDay)}`;
  },
};
