<template>
  <div class="section_wrap">
    <SectionHeader
      :loading="loading"
      :refresh="getData"
    />
    <SearchFilter
      :radios="radios"
      :selects="selects"
      :filters.sync="options.filters"
      :loading="loading"
      :searchKeys="searchKeys"
      :callback="getData"
      :options.sync="options"
    />
    <UsersDataTable
      :items="items"
      :loading="loading"
      :options.sync="options"
      :pageOptions="pageOptions"
      :callback="getData"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchFilter from '@/components/common/SearchFilter.vue';
import SectionHeader from '@/components/common/SectionHeader.vue';
import UsersDataTable from './unit/UsersDataTable.vue';

export default {
  name: 'UsersSection',
  components: {
    UsersDataTable,
    SearchFilter,
    SectionHeader,
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    loading: false,
    radios: [
      {
        key: 'address',
        i18nKey: 'user.address',
        values: [null, null],
        conditions: ['some', 'none'],
        i18nLabels: ['common.regist', 'common.unregistered'],
      },
      {
        key: 'idTag',
        i18nKey: 'user.idTag',
        values: [null, null],
        conditions: ['some', 'none'],
        i18nLabels: ['common.regist', 'common.unregistered'],
      },
    ],
    selects: [
      {
        key: 'lastTransactionStatus',
        i18nKey: 'user.lastTransactionStatus',
        values: [
          undefined,
          'Accepted',
          'Charging',
          'ChargeStop',
          'ChargeFinished',
          'PaymentCompleted',
          'PaymentRequired',
          'SessionCompleted',
        ],
        conditions: ['none', 'eq', 'eq', 'eq', 'eq', 'eq', 'eq', 'eq'],
        i18nLabels: [
          'transaction.statuses.undefined',
          'transaction.statuses.Accepted',
          'transaction.statuses.Charging',
          'transaction.statuses.ChargeStop',
          'transaction.statuses.ChargeFinished',
          'transaction.statuses.PaymentCompleted',
          'transaction.statuses.PaymentRequired',
          'transaction.statuses.SessionCompleted',
        ],
        permission: true,
      },
      {
        key: 'type',
        i18nKey: 'user.email',
        values: ['email', 'google', 'apple'],
        conditions: ['eq', 'eq', 'eq'],
        i18nLabels: ['user.types.email', 'user.types.google', 'user.types.apple'],
        permission: true,
      },
    ],
    searchKeys: [
      {
        i18nKey: 'user.address',
        key: 'address.zipCode',
      },
      {
        i18nKey: 'user.address',
        key: 'address.address',
      },
      {
        i18nKey: 'user.address',
        key: 'address.detailAddress',
      },
      {
        i18nKey: 'user.name',
        key: 'name',
      },
      {
        i18nKey: 'user.phone',
        key: 'phone',
      },
      {
        i18nKey: 'user.email',
        key: 'email',
      },
      {
        i18nKey: 'user.idTag',
        key: 'idTag.idTag',
      },
    ],
    options: {
      page: 1,
      itemsPerPage: 2,
      sortBy: ['created'],
      sortDesc: [true],
      filters: [],
    },
    pageOptions: {
      total: 0,
      pageCount: 0,
      itemsPerPageOptions: [10, 50],
    },
    items: [],
  }),
  methods: {
    async getData() {
      this.loading = true;
      this.items = [];
      try {
        const {
          itemsPerPage,
          filters,
        } = this.options;
        const { data: { total } } = await this.$emitter('user.summary.get', { filters });
        this.pageOptions.total = total;
        this.pageOptions.pageCount = Math.ceil(total / itemsPerPage);
        const { items } = await this.$emitter('user.list.get', this.options);
        this.items = items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
  },
  watch: {
    staffInfo: {
      immediate: true,
      async handler(value) {
        if (value != null) await this.getData();
      },
    },
    options: {
      deep: true,
      async handler() {
        if (this.staffInfo !== null) await this.getData();
      },
    },
  },
};
</script>
