<template>
  <v-app id="app">
    <DlgProgressCircle/>
    <DlgAlert/>
    <DlgConfirm
      :show.sync="confirm.show"
      :callback="confirm.callback"
      :message="confirm.message"
      :btnText="confirm.btnText"
      :color="confirm.color"
    />
    <DlgSystemBar/>
    <DlgFileTask/>
    <div class="app_wrap">
      <div class="app_nav" v-if="$route.name !== 'Sign'">
        <Nav/>
      </div>
      <div :class="$route.name !== 'Sign' && 'app_body'">
        <TopButtonGroup v-if="$route.name !== 'Sign'"/>
        <router-view class="common_web"/>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import DlgProgressCircle from '@/components/dialog/DlgProgressCircle.vue';
import DlgAlert from '@/components/dialog/DlgAlert.vue';
import DlgConfirm from '@/components/dialog/DlgConfirm.vue';
import DlgSystemBar from '@/components/dialog/DlgSystemBar.vue';
import DlgFileTask from '@/components/dialog/DlgFileTask.vue';
import Nav from '@/components/common/Nav.vue';
import TopButtonGroup from '@/components/common/TopButtonGroup.vue';
import '@/assets/style/common.css';

export default {
  components: {
    DlgAlert,
    DlgProgressCircle,
    DlgConfirm,
    DlgSystemBar,
    Nav,
    TopButtonGroup,
    DlgFileTask,
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      confirm: 'dialog/confirm',
    }),
  },
};
</script>

<style lang="scss">
.app_wrap{
  display: flex;
  height: 100%;
  width: 100vw;
  .app_nav{
    height:100%;
  }
  .app_body{
    width:calc(100% - 250px);
    height:100vh;
    overflow-x: auto;
    .common_web {
      width:100%;
      height: calc(100vh - 32px);
      overflow-x: auto;
      // overflow-y: auto;
      min-width: 1024px;
    }
  }
}
</style>
