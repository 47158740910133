/* eslint-disable no-param-reassign */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import routes from './routes';
import i18n from '../i18n';

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {},
});

async function beforEachCallback(to, from, next) {
  // 로그인한 계정정보
  const staffInfo = store.getters['auth/staffInfo'];
  const verified = staffInfo !== null;
  // 허용되지 않은 경로 블럭
  if (!to.matched.length) {
    store.dispatch('dialog/alert', ['error', i18n.t('common.errorMessages.invalidAccess')]);
    // 이전페이지가 존재하지 않을 경우 홈으로
    if (!from.matched.length) next('/');
    else next(false); // 아닐 경우 현재 페이지 유지

  // 계정정보가 없는 경우
  } else if (!verified) {
    if (to.path !== '/sign') {
      // 로그인페이지가 아닐 경우 로그인페이지로 이동
      next('/sign');
    } else {
      next();
    }
  // 계정정보가 있을 경우
  } else if (verified) {
    // 로그인페이지일 경우 홈으로 이동
    if (to.path === '/sign') {
      next('/');
    // 마이페이지인 경우 패스
    } else if (to.path === '/mypage') {
      next();
    } else {
      let chackPath = to.path;
      if (/^\/community\/\w+\/\d+$/.test(to.path)) {
        const pathArray = to.path.split('/');
        pathArray.length -= 1;
        chackPath = pathArray.join('/');
      }
      const permission = staffInfo ? Object.values(staffInfo.permission) : null;

      const { read } = permission == null || !permission.some((row) => row.path === chackPath)
        ? { read: false }
        : permission.filter((row) => row.path === chackPath)[0];
      // 권한이 없을 경우 홈으로 이동
      if (!read) {
        store.dispatch('dialog/alert', ['error', i18n.t('common.errorMessages.invalidAccess')]);
        next('/');
      } else {
        next();
      }
    }
  }
}

// 페이지 이동전 감지
router.beforeEach(async (to, from, next) => {
  // 앱 초기화가 되지 않았을 때
  if (!store.getters['auth/initialized']) {
    // 앱 초기화 완료 감지
    const unwatch = await store.watch((_, getters) => getters['auth/initialized'], async () => {
      await beforEachCallback(to, from, next);
      // 감지 해제
      await unwatch();
    });
  } else {
    await beforEachCallback(to, from, next);
  }
});

router.checkAuth = async () => {
  const connected = store.getters['auth/connected'];
  const initialized = store.getters['auth/initialized'];

  // 소켓 접속 + 앱 초기화가 된 경우만 실행
  if (connected && initialized) {
    const to = router.currentRoute;
    const verified = store.getters['auth/verified'];
    // 1. 로그인정보가 없을경우
    if (verified === false) {
      await router.push('/sign');
    // 2. 로그인 정보가 있는데 로그인 페이지로 접근한 경우
    } else if (to.path === '/sign') {
      await router.push('/monitoring/map');
    } else if (to.path !== '/mypage') {
      let chackPath = to.path;
      if (/^\/community\/\w+\/\d+$/.test(to.path)) {
        const pathArray = to.path.split('/');
        pathArray.length -= 1;
        chackPath = pathArray.join('/');
      }
      const staffInfo = store.getters['auth/staffInfo'];
      const permission = staffInfo ? Object.values(staffInfo.permission) : null;

      const { read } = permission == null || !permission.some((row) => row.path === chackPath)
        ? { read: false }
        : permission.filter((row) => row.path === chackPath)[0];
      if (!read) {
        store.dispatch('dialog/alert', ['error', '접근 권한이 없습니다.']);
        router.back();
      }
    }
  }
};

Vue.use(VueRouter);

export default router;
