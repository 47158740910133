<template>
  <div v-if="item !== null" style="padding:2%;">
    <div class="section_box_wrap problem_box" v-if="$route.path.includes('/community/problem')">
      <div>
        <v-btn
          icon
          @click="comebackList()"
        >
          <v-icon>
            mdi-keyboard-backspace
          </v-icon>
        </v-btn>
      </div>
      <div>
        <div
          class="align_center justify-space-between"
          style="height: 60px;"
        >
          <h3>{{ item.title }}</h3>
          <p class="smT">{{ time.makeLocalTimeMin(item.created) }}</p>
        </div>
        <div
          class="registrationFile d-flex flex-column"
          v-if="item.files.length > 0"
          style="width:100%; min-height: 100px;"
        >
          <input
            v-show="false"
            type="file"
            ref="fileInput"
            :multiple="maxCount > 3"
            @change="fileChange({
              event: $event,
              maxCount,
              maxSize,
              accepts: ['png', 'jpg', 'zip'],
              target: 'files',
            })"
          />
          <v-btn
            v-if="files.length < maxCount && item.files.length == 0"
            elevation
            dense
            outlined
            class="pl-2 mr-5"
            @click="fileClick({
              target: 'files',
              ref: 'fileInput',
            })"
          >
            <v-icon class="mr-1">
              mdi-image-outline
            </v-icon>
            {{ $t('common.file') }}
          </v-btn>
          <div
            v-if="item.files.length > 0"
          >
            <div
              class="align_center justify-space-between imgList"
              v-for="(file, index) in item.files"
              :key="index"
            >
              <div class="align_center">
                <img
                  v-if="file.name.includes('.png') || file.name.includes('jpg')"
                  :src="utilFile.imagePathToSrc(item.files[index].path)"
                  style="width:70px;"
                />
                <div class="mL10">
                  <strong>{{ file.name }}</strong>
                  <span>({{ utilFile.byteTrans(file.size) }})</span>
                </div>
              </div>
              <v-icon
                @click="handleDownload(file.no)"
                color="black"
              >
                mdi-download
              </v-icon>
            </div>
          </div>
        </div>
        <div>
          <div class="post_viewing mT10">
            <Editor
              v-if="disabledStatus"
              ref="editor"
              height="450px"
              initialEditType="wysiwyg"
              :initialValue="item.content"
              :options="option"
            />
            <div
              v-if="!disabledStatus"
              style="font-size:16px; width: 100%;"
            >
              <Viewer
                ref="viewer"
                :initialValue="item.content"
                class="viewer_box_problem"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section_box_wrap" v-if="$route.path.includes('/community/notice')">
      <div>
        <v-btn
          icon
          @click="comebackList()"
        >
          <v-icon>
            mdi-keyboard-backspace
          </v-icon>
        </v-btn>
      </div>
      <div>
        <div class="align_center justify-space-between" style="height: 60px;">
          <h3>{{ item.title }}</h3>
          <p class="smT">{{ time.makeLocalTimeMin(item.created) }}</p>
        </div>
        <div>
          <div
            class="registrationFile d-flex flex-column"
            v-if="item.files.length > 0"
            style="width:100%; min-height: 100px;"
          >
            <input
              v-show="false"
              type="file"
              ref="fileInput"
              :multiple="maxCount > 3"
              @change="fileChange({
                event: $event,
                maxCount,
                maxSize,
                accepts: ['png', 'jpg', 'zip'],
                target: 'files',
              })"
            />
            <v-btn
              v-if="files.length < maxCount && item.files.length == 0"
              elevation
              dense
              outlined
              class="pl-2 mr-5"
              @click="fileClick({
                target: 'files',
                ref: 'fileInput',
              })"
            >
              <v-icon class="mr-1">
                mdi-image-outline
              </v-icon>
              {{ $t('common.file') }}
            </v-btn>
            <div
              v-if="item.files.length > 0"
            >
              <div
                class="align_center justify-space-between imgList"
                v-for="(file, index) in item.files"
                :key="index"
              >
                <div class="align_center">
                  <img
                    v-if="file.name.includes('.png') || file.name.includes('jpg')"
                    :src="utilFile.imagePathToSrc(item.files[index].path)"
                    style="width:70px;"
                  />
                  <div class="mL10">
                    <strong>{{ file.name }}</strong>
                    <span>({{ utilFile.byteTrans(file.size) }})</span>
                  </div>
                </div>
                <v-icon
                  @click="handleDownload(file.no)"
                  color="black"
                >
                  mdi-download
                </v-icon>
              </div>
            </div>
          </div>
          <div class="post_viewing_notice mT10">
            <div style="font-size:16px; width: 100%;">
              <div class="d-flex justify-end">
                <v-btn
                  v-if="
                    this.item.creatorStaffInfo.no === staffInfo.no
                    || staffInfo.type === 0
                  "
                  style="cursor: pointer;"
                  @click="editPost(item)"
                  small
                  class="mR10 mB10"
                >
                  {{ $t('btn.edit') }}
                </v-btn>
                <v-btn
                  v-if="
                    item.creatorStaffInfo.no === staffInfo.no
                    || staffInfo.type === 0
                  "
                  style="cursor: pointer;"
                  @click="deletePost(item)"
                  small
                >
                  {{ $t('btn.delete') }}
                </v-btn>
              </div>
              <Viewer
                ref="viewer"
                :initialValue="item.content"
                class="viewer_box_notice"
              />
              <div class="flex_center" style="margin:20px auto; width:60%;">
                <v-text-field
                  dense
                  v-model="commentValue"
                  hide-details
                  place
                  :placeholder="$t('common.enterComment')"
                />
                <v-btn
                  @click="saveComment"
                  style="width:10%; margin-left:20px;"
                >
                  {{ $t('btn.save') }}
                </v-btn>
              </div>
              <p
                class="smT"
                style="text-align: right;"
                v-if="commentData.length > 0"
              >
              {{ $t('common.total') }} {{commentData.length}}
              </p>
              <p
                v-if="commentData.length === 0"
                style="text-align: center;"
              >
                {{ $t('common.noComment') }}
              </p>
              <div
                style="
                  min-height: 250px;
                  max-height: 250px;
                  overflow-y: auto;
                  overflow-x: hidden;
                "
                class="mT10"
              >
                <div
                  v-for="(comment, idx) in commentData"
                  :key="idx"
                >
                  <ul v-if="commentData.length > 0">
                    <li class="comment_list">
                      <div
                        v-if="Object.keys(comment).includes('creatorUserInfo')"
                        style="display: flex; justify-content: space-between;"
                      >
                        <div>
                          <span>{{ comment.content }}</span>
                        </div>
                        <div>
                          <span class="smT">
                            {{ format.makeMaskName(comment.creatorUserInfo.name) }}
                          </span>
                          <v-icon
                            color="primary"
                            style="cursor: pointer;"
                            @click="deleteComment(comment)"
                            small
                          >
                            mdi-delete
                          </v-icon>
                        </div>
                      </div>
                      <div
                        v-if="Object.keys(comment).includes('creatorStaffInfo')"
                        style="display: flex; justify-content: space-between;"
                      >
                        <div>
                          <v-text-field
                            v-if="editCommentState && idx === selectIndex"
                            v-model="editCommentValue"
                            style="width:700px;"
                            outlined
                            dense
                          />
                          <span v-else>{{ comment.content }}</span>
                        </div>
                        <div>
                          <span class="smT mR10">{{ comment.creatorStaffInfo.name }}</span>
                          <v-icon
                            v-if="editCommentState && idx === selectIndex"
                            color="primary"
                            style="cursor: pointer;"
                            @click="SaveEditComment(comment)"
                            small
                          >
                            mdi-check
                          </v-icon>
                          <v-icon
                            v-if="
                              comment.creatorStaffInfo.name === staffInfo.name
                              && idx !== selectIndex
                            "
                            color="primary"
                            style="cursor: pointer;"
                            @click="editComment(comment, idx)"
                            small
                          >
                            mdi-pencil
                          </v-icon>
                          <v-icon
                            v-if="
                              comment.creatorStaffInfo.name === staffInfo.name
                              || staffInfo.type === 0
                            "
                            color="primary"
                            style="cursor: pointer;"
                            @click="deleteComment(comment)"
                            small
                          >
                            mdi-delete
                          </v-icon>
                          </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DlgCommonWrite
      :show.sync="editDialog.show"
      :title="editDialog.title"
      :sub="editDialog.sub"
      :type="editDialog.type"
      :formCheck="editDialog.formCheck"
      @submit="editDialog.submit"
    >
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('common.title') }}*
        </v-chip>
        <div>
          <v-text-field
            :placeholder="$t('placeholders.board.title')"
            v-model="titleValue"
            outlined
            dense
            style="width:700px;"
            maxlength="64"
            hide-details
          />
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('common.img') }}
        </v-chip>
        <div class="align_center justify-space-between" style="width:100%;">
          <div>
            <input
              v-show="false"
              type="file"
              ref="fileInput"
              :multiple="maxCount > 1"
              @change="fileChange({
                event: $event,
                maxCount,
                maxSize,
                accepts: ['png', 'jpg', 'zip'],
                target: 'files',
              })"
            />
            <v-btn
              v-if="imgActive && registrationImg.length < maxCount || files.length < 0"
              elevation
              dense
              outlined
              @click="fileClick({
                target: 'files',
                ref: 'fileInput',
              })"
            >
              <v-icon class="mr-1">
                mdi-image-outline
              </v-icon>
              {{ $t('common.file') }}
            </v-btn>
            <div class="registrationFile"
              v-if="files.length > 0"
            >
              <div
                class="align_center"
                v-for="(file, index) in files"
                :key="index"
              >
                <img
                  v-if="file.name.includes('.png') || file.name.includes('jpg') || imgActive"
                  :src="!imgActive ? utilFile.imagePathToSrc(file.path)
                    : utilFile.arrayBufferToBase64(registrationImg[index].data)"
                  style="width:40px"
                />
                <v-chip
                  :close="registrationImg.length > 0"
                  class="mr-3 my-1 ml-1"
                  @click:close="fileRemove({
                    target: 'files',
                    file,
                  })"
                >
                  <strong>{{ file.name }}</strong>
                  <span>({{ utilFile.byteTrans(file.size) }})</span>
                </v-chip>
              </div>
            </div>
          </div>
          <div>
            <v-btn
              color="rgba(150,150,150)"
              small
              @click="editImgBtn"
              v-if="!imgActive"
              hide-details
            >
              {{ `${$t('common.img')} ${$t('btn.edit')}` }}
            </v-btn>
          </div>
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('common.content') }}*
        </v-chip>
        <div style="width: 100%">
          <Editor
            ref="editor"
            height="450px"
            initialEditType="wysiwyg"
            :initialValue="contentValue"
            :options="{
              language: 'ko',
            }"
            @change="handleEditorChange()"
          />
        </div>
      </div>
    </DlgCommonWrite>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapGetters, mapMutations } from 'vuex';
import time from '@/util/time';
import utilFile from '@/util/file';
import { Editor, Viewer } from '@toast-ui/vue-editor';
import DlgCommonWrite from '@/components/dialog/DlgCommonWrite.vue';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import '@toast-ui/editor/dist/i18n/ko-kr';
import format from '@/util/format';

export default {
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  components: {
    Editor,
    Viewer,
    DlgCommonWrite,
  },
  data: () => ({
    time,
    utilFile,
    format,
    maxCount: 3,
    maxSize: 198743680,
    files: [],
    disabledStatus: false,
    option: {
      language: 'ko',
      hooks: {
        addImageBlobHook: async (blob, callback) => {
          const fileURL = await utilFile.fileToBase64(blob);
          callback(fileURL, blob.name);
        },
      },
    },
    commentValue: null,
    commentData: [],
    item: null,
    loading: false,
    total: null,
    editDialog: {
      show: false,
      title: '',
      sub: null,
      type: 'update',
      formCheck: false,
      submit: () => {},
      form: {
        title: null,
        content: null,
      },
    },
    contentValue: null,
    titleValue: null,
    editCommentValue: null,
    editCommentState: false,
    selectIndex: -1,
    imgActive: false,
    registrationImg: [],
  }),
  methods: {
    ...mapMutations({
      push: 'router/push',
    }),

    comebackList() {
      this.$router.back();
    },
    async handleDownload(no) {
      try {
        const { item } = await this.$emitter('file.read', { no });
        if (item.data != null) {
          await utilFile.writeFileFromPayload(item);
        }
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },

    editImgBtn() {
      this.files = [];
      this.imgActive = true;
    },

    async saveComment() {
      if (this.commentValue !== null) {
        try {
          await this.$emitter('comment.add', {
            items: [{
              postNo: this.item.no,
              content: this.commentValue,
            }],
          }, `${this.$t('common.registCompleteMessage')}`);
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
        this.commentValue = null;
        this.getCommentData();
      }
    },
    handleAll() {
      this.files = [];
    },

    async saveEditPost() {
      this.$dialog.progress(true);
      this.contentValue = this.$refs.editor.invoke('getMarkdown');
      try {
        if (this.imgActive) {
          await this.$emitter('post.update', {
            _id: this.item._id,
            title: this.titleValue,
            content: this.contentValue,
            files: this.registrationImg,
          });
          this.imgActive = false;
        } else {
          await this.$emitter('post.update', {
            _id: this.item._id,
            title: this.titleValue,
            content: this.contentValue,
            files: null,
          });
        }
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.commentValue = null;
      this.editDialog.show = false;
      this.$dialog.progress(false);
      window.location.reload();
    },

    editComment(item, idx) {
      this.editCommentState = true;
      this.selectIndex = idx;
      this.editCommentValue = item.content;
    },
    async SaveEditComment(item) {
      if (this.editCommentValue !== null) {
        try {
          await this.$emitter('comment.update', {
            _id: item._id,
            content: this.editCommentValue,
          }, `${this.$t('common.updateCompleteMessage')}`);
          this.editCommentState = false;
          this.selectIndex = -1;
          this.getCommentData();
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
      }
    },

    async deletePost(item) {
      const callback = async () => {
        try {
          await this.$emitter('post.delete', {
            _ids: [
              { _id: item._id },
            ],
          });
          this.$dialog.confirm();
          this.$dialog.alert('success', `${this.$t('common.deleteCompleteMessage')}`);
          this.$router.back();
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
      };
      this.$dialog.confirm({
        show: true,
        message: `${this.$t('common.delete')}`,
        color: 'error',
        btnText: `${this.$t('btn.delete')}`,
        callback,
      });
    },
    async deleteComment(item) {
      const callback = async () => {
        try {
          await this.$emitter('comment.delete', {
            _ids: [
              { _id: item._id },
            ],
          });
          this.$dialog.confirm();
          this.$dialog.alert('success', `${this.$t('common.deleteCompleteMessage')}`);
          this.getCommentData();
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
      };
      this.$dialog.confirm({
        show: true,
        message: `${this.$t('common.delete')}`,
        color: 'error',
        btnText: `${this.$t('btn.delete')}`,
        callback,
      });
    },

    initEditDialog() {
      this.editDialog = {
        show: false,
        title: '',
        sub: null,
        type: 'update',
        formCheck: false,
        submit: () => {},
        form: {
          title: null,
          content: null,
        },
      };
      this.imgActive = false;
    },

    handleEditorChange() {
      const editorInstance = this.$refs.editor.invoke('getMarkdown');
      this.contentValue = editorInstance;
    },
    async editPost(clicked) {
      this.initEditDialog();
      this.editDialog.form = clicked;
      this.titleValue = this.editDialog.form.title;
      this.contentValue = this.editDialog.form.content;
      this.files = clicked.files;
      this.editDialog.show = true;
      this.editDialog.submit = this.saveEditPost;
    },

    checkPostEditValid() {
      if (this.editDialog.show !== true) return false;
      if (this.titleValue == null || this.titleValue === '') return false;
      if (this.contentValue == null || this.contentValue === '') return false;
      return true;
    },

    fileClick({
      target,
      maxCount,
      ref,
    }) {
      this.$refs[ref].value = null;
      if (this[target].length === maxCount) {
        this.$dialog.alert('error', `${this.$t('common.fileErrorFront')} ${maxCount} ${this.$t('common.fileErrorBack')}`);
      } else {
        this.$refs[ref].click();
      }
    },
    async fileChange({
      event,
      maxCount,
      maxSize,
      accepts,
      target,
    }) {
      this.$dialog.progress(true);
      const { files: selectedFiles } = event.target;
      if (selectedFiles.length + this[target].length > maxCount) {
        this.$dialog.alert('error', `${this.$t('common.fileErrorFront')} ${maxCount} ${this.$t('common.fileErrorBack')}`);
      } else if (selectedFiles.length > 0) {
        const filesArray = Object.values(selectedFiles);
        const invalidFileCheck = filesArray.reduce((acc, file) => {
          let isAccept = false;
          for (const accept of accepts) {
            if (file.name.includes(`.${accept}`)) isAccept = true;
          }
          if (isAccept === false && isAccept.length) acc.accept += 1;
          if (file.size > maxSize) acc.size += 1;
          return acc;
        }, {
          size: 0,
          accept: 0,
        });
        if (invalidFileCheck.accept > 0) {
          const types = accepts.length <= 0 ? accepts[0] : accepts.join(', ');
          this.$dialog.alert('error', `${this.$t('common.fileErrorExtensions')} [${types}]`);
        } else if (invalidFileCheck.size > 0) {
          this.$dialog.alert('error', `
            ${this.$t('common.fileErrorMaximumFront')} ${utilFile.byteTrans(maxSize)} ${this.$t('common.fileErrorMaximumBack')}
          `);
        } else {
          const map = filesArray.map((file) => utilFile.readFileToPayload(file));
          const filePayloads = await Promise.all(map);
          this[target] = [
            ...filePayloads,
            ...this[target],
          ];
          this.registrationImg = this[target];
        }
      }
      this.$dialog.progress(false);
    },
    fileRemove({
      target,
      file,
    }) {
      this.imgActive = true;
      this[target] = this[target].filter((currentFile) => currentFile.name !== file.name);
      this.registrationImg = this[target];
    },

    async getPostData() {
      this.loading = false;
      try {
        const postData = await this.$emitter('post.get', {
          no: Number(this.$route.params.no),
        });
        if (postData.item.boardCode === this.$route.params.board) {
          this.item = postData.item;
          this.editDialog.form.title = this.item.title;
          this.editDialog.form.content = this.item.content;
          this.getCommentData(this.item);
          this.loading = true;
        } else {
          this.$router.back();
        }
      } catch (error) {
        console.error(error);
        this.$router.back();
      }
    },
    async getCommentData() {
      try {
        const commentResult = await this.$emitter('comment.list.get', {
          page: 1,
          itemsPerPage: 0,
          filters: [
            {
              where: 'and',
              condition: 'eq',
              key: 'postNo',
              value: this.item.no,
            },
          ],
          sortBy: ['created'],
          sortDesc: [true],
        });
        this.commentData = commentResult.items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },

    removeOneFile(selectFile) {
      this.files = this.files
        .filter((file) => file.name !== selectFile.name && file.size !== selectFile.size);
    },
  },
  watch: {
    staffInfo: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value != null) {
          this.getPostData();
        }
      },
    },
    $route: {
      handler() {
        this.getPostData();
        this.getCommentData();
      },
    },
    'editDialog.form': {
      deep: true,
      handler(v) {
        this.editDialog.formCheck = this.checkPostEditValid(v);
      },
    },
    titleValue: {
      deep: true,
      handler(value) {
        this.editDialog.formCheck = this.checkPostEditValid(value);
      },
    },
    contentValue: {
      deep: true,
      handler(value) {
        this.editDialog.formCheck = this.checkPostEditValid(value);
      },
    },
  },
};
</script>

<style lang="scss">
.imgList{
  margin-bottom:10px;
}
.viewer_box_problem{
  min-height: 550px;
  max-height: 550px;
  overflow-y: auto;
  background-color: #eeeeee;
  padding:5px;
  border-radius: 10px;
}
.viewer_box_notice{
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
  background-color: #eeeeee;
  padding:5px;
  border-radius: 10px;
}
.comment_list{
  height: 50px;
  line-height: 50px;
}
</style>
