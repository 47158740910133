<template>
  <div id="garage_section_wrap" class="overflow-hidden">
    <div class="d-flex garage_section" @keyup.esc="$router.go(-1)" tabindex="0">
      <div
        class="garage_wrap pa-2 h-100"
        ref="leftSide"
      >
        <div class="align_center justify-space-between">
          <v-tooltip right>
            <template v-slot:activator="{on, attrs}">
                <v-btn
                  icon
                  class="mb-3"
                  @click="$router.push('/')"
                >
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-home
                  </v-icon>
                </v-btn>
            </template>
            <span>{{ $t('garage.main') }}</span>
          </v-tooltip>
          <div v-if="selectedArea !== null">
            <v-btn
              icon
              class="mb-3"
              @click="refreshData"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </div>
        </div>
        <v-select
          style="height: 5%;"
          :items="items"
          item-text="name"
          item-value="_id"
          :placeholder="$t('garage.select')"
          hide-details
          outlined
          dense
          v-model="selectedArea"
          autofocus
        />
        <div class="pt-2 d-flex flex-column justify-space-around" style="height: 90%;">
          <AccrueTable
            :selectedArea="selectedArea"
            :yesterdayData = "yesterdayData"
            :todayData = "todayData"
          />
          <AccrueGraph
            :lastMonthData = "lastMonthData"
            :selectedArea = "selectedArea"
          />
          <LiveStatus
            :showFullscreen="showFullscreen"
            :statusFullscreen="statusFullscreen"
            :evseList="evseList"
          />
        </div>
      </div>
      <Monitoring
        :leftSideWidthSize="leftSideWidthSize"
        :selectedArea="selectedArea"
        :evseList="evseList"
        :getMonitoringLocation="getMonitoringLocation"
        :settingGarage.sync="settingGarage"
      />
      <ChargerInfo
        :evseList="evseList"
        :getUseEvseList="getUseEvseList"
        :getEvseSocList="getEvseSocList"
      />
    </div>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapGetters, mapMutations } from 'vuex';
import time from '@/util/time';
import AccrueTable from './unit/AccrueTable.vue';
import AccrueGraph from './unit/AccrueGraph.vue';
import LiveStatus from './unit/LiveStatus.vue';
import Monitoring from './unit/Monitoring.vue';
import ChargerInfo from './unit/ChargerInfo.vue';

export default {
  name: 'HomeSection',
  components: {
    AccrueTable,
    AccrueGraph,
    Monitoring,
    ChargerInfo,
    LiveStatus,
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    time,
    items: [],
    statusFullscreen: false,
    leftSideWidthSize: 0,
    selectedArea: null,
    evseList: [],
    lastMonthData: [],
    yesterdayData: {},
    todayData: {},
    settingGarage: false,
    getMonitoringLocation: [],
    getUseEvseList: [],
    getEvseSocList: [],
  }),
  methods: {
    ...mapMutations({
      confirm: 'dialog/confirm',
    }),

    async getAreaData() {
      try {
        const res = await this.$emitter('area.list.get', {
          page: 1,
          itemsPerPage: 0,
        });
        this.items = res.items;
        this.selectedArea = res.items[0]._id;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    async getMeterData(areaId) {
      this.$dialog.progress(true);
      // this.lastMonthData = [];
      try {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        const currentDate = new Date().getDate();

        let startYear = currentYear;
        let startMonth = currentMonth - 1;
        if (currentMonth === 1) {
          startYear = currentYear - 1;
          startMonth = 12;
        }
        // 지난달 데이터
        const monthRes = await this.$emitter('dashboard.daily.meter.graph.get', {
          area_id: areaId,
          start: {
            year: startYear,
            month: startMonth,
            date: 1,
          },
          end: {
            year: startYear,
            month: startMonth,
            date: 31,
          },
        });
        this.lastMonthData = monthRes.data;

        // 전일 데이터
        const yesterdayRes = await this.$emitter('dashboard.daily.meter.graph.get', {
          area_id: areaId,
          start: {
            year: currentYear,
            month: currentMonth,
            date: currentDate - 1,
          },
          end: {
            year: currentYear,
            month: currentMonth,
            date: currentDate,
          },
        });
        this.yesterdayData = yesterdayRes.data[0].data;

        // 금일 데이터
        const todayRes = await this.$emitter('dashboard.daily.meter.graph.get', {
          area_id: areaId,
          start: {
            year: currentYear,
            month: currentMonth,
            date: currentDate,
          },
          end: {
            year: currentYear,
            month: currentMonth,
            date: currentDate + 1,
          },
        });
        this.todayData = todayRes.data[0].data;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    async selectedAreaList(areaId) {
      try {
        const getGarage = await this.$emitter('area.get', { _id: areaId });
        this.getMonitoringLocation = getGarage.item.garage;
        const res = await this.$emitter('dashboard.evse.list.get', { area_id: areaId });
        this.evseList = res.items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    showFullscreen(type) {
      this.$dialog.alert('info', `${this.$t('garage.fullscreenExit')}`);
      if (type === 'status') this.statusFullscreen = !this.statusFullscreen;
      if (this.statusFullscreen) {
        window.addEventListener('keyup', this.handleKeyUp);
      } else {
        window.removeEventListener('keyup', this.handleKeyUp);
      }
    },
    handleKeyUp(event) {
      if (event.key === 'Escape') {
        this.statusFullscreen = false;
        window.removeEventListener('keyup', this.handleKeyUp);
      }
    },
    async getUseEvse(id) {
      try {
        const res = await this.$emitter('dashboard.use.evse.list.get', { area_id: id });
        this.getUseEvseList = res.items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    // 22시 ~ 06시 사이 soc 90% 미만
    async getEvseSoc(id) {
      const currentDate = new Date();
      try {
        const res = await this.$emitter('dashboard.soc.evse.list.get', {
          area_id: id,
          utcOffset: currentDate.getTimezoneOffset() / 60,
          year: currentDate.getFullYear(),
          month: currentDate.getMonth() + 1,
          date: currentDate.getDate(),
          startHour: 10,
          duration: 8,
          endSoc: 90,
        });
        this.getEvseSocList = res.items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    refreshData() {
      this.selectedAreaList(this.selectedArea);
      this.getMeterData(this.selectedArea);
      this.getUseEvse(this.selectedArea);
      this.getEvseSoc(this.selectedArea);
      this.settingGarage = false;
    },
  },
  watch: {
    staffInfo: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value != null) {
          this.getAreaData();
        }
      },
    },
    selectedArea: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.selectedAreaList(val);
          this.getMeterData(val);
          this.getUseEvse(val);
          this.getEvseSoc(val);
          this.settingGarage = false;
        }
      },
    },
  },
};
</script>

<style lang="scss">
#garage_section_wrap, .status-full-screen {
  height: 100vh;
  position: fixed;
  inset: 0;
  background-color: #fff;
  z-index: 999;
  min-width: 1024px;
  overflow-x: auto;
  .garage_section {
    overflow-x: auto;
    width: 100%;
    height: 100%;
  }
  .garage_wrap{
    width: 20%;
    min-width: 350px;
  }
  .fullscreen-exit {z-index: 999;}
}
</style>
