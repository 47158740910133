<template>
  <div class="data_table_wrap">
    <div class="align_center justify-space-between">
      <div class="body-2">
        <b v-if="pageOptions.total <= 0">0</b>
        <b v-else class="primary--text">
          {{
            options.page === 1
            ? items.length
            : ((options.page - 1) * options.itemsPerPage) + items.length
          }}
        </b>
        /
        <span>{{ pageOptions.total }}</span>
      </div>
      <v-btn-toggle
        class="my-2"
        v-if="pageOptions.itemsPerPageOptions != null && pageOptions.itemsPerPageOptions.length > 0"
        @change="updateOptions('itemsPerPage', $event)"
        mandatory
        dense
      >
        <v-btn v-for="number of pageOptions.itemsPerPageOptions" :key="number">
          {{ `${number}${$t('dataTable.itemsPerPageOption')}` }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-data-table
      mobile-breakpoint="0"
      fixed-header
      :headers="headers"
      :items="items"
      :loading="loading"
      :options="options"
      @update:sort-by="sort.sortBy = $event"
      @update:sort-desc="sort.sortDesc = $event"
      disable-pagination
      hide-default-footer
      dense
      class="data_table"
    >
      <template v-slot:item.user="{ item }">
        <v-tooltip top color="black" v-if="item.user != null">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <p>{{ format.makeMaskName(item.user.name) }}</p>
              <p class="smT">
                {{ format.makeMaskPhoneNumber(item.user.phone) }}
              </p>
            </div>
          </template>
          <p>{{ item.user.name }}</p>
          <p class="smT">{{ item.user.phone }}</p>
        </v-tooltip>
        <span v-else>-</span>
      </template>
      <template v-slot:item.chargepoint.cpId="{ item }">
        <div class="d-flex mt-1" v-if="item.chargepoint.model != null">
          <v-chip x-small label class="px-1 mr-1" outlined color="primary">
            {{ item.chargepoint.model.name }}
          </v-chip>
          <v-chip x-small label class="px-1 mr-1" color="warning">
            {{ item.chargepoint.model.kwh }}kW
          </v-chip>
          <v-chip
            x-small
            label
            class="px-1 mr-1"
            :color="item.chargepoint.model.type === 'DC' ? 'success' : 'accent'"
          >
            {{ item.chargepoint.model.type }}
          </v-chip>
        </div>
        <div v-else>
          <v-chip x-small label class="px-1">{{ $t('transaction.emptyModelInfo') }}</v-chip>
        </div>
        <p>{{ item.chargepoint.cpId }}</p>
      </template>
      <template v-slot:item.chargepoint.area.name="{ item }">
        <span v-if="item.chargepoint.area == null">-</span>
        <div v-else>
          <p class="small-text">{{ item.chargepoint.area.name }}</p>
          <p class="x-small-text">{{ item.chargepoint.area.address }}</p>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="align_center">
          <span>{{ $t(`transaction.statuses.${item.status}`) }}</span>
          <v-tooltip
            top
            color="black"
            v-if="
              item.status === 'Charging'
              && $checkPermission(staffInfo, 'charge_logs.write')
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon class="ml-1" v-bind="attrs" v-on="on" @click="stopTransaction(item)">
                <v-icon small color="error">mdi-power-plug-off</v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('transaction.stopTransaction') }}
            </span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.chargedMeter="{ item }">
        {{ item.chargedMeter == null ? '-' : `${item.chargedMeter / 1000}kWh` }}
      </template>
      <template v-slot:item.chargingStartDate="{ item }">
        {{ makeChargingTime(item) }}
      </template>
      <template v-slot:item.chargedPrice="{ item }">
        {{
          item.chargedPrice == null
          ? '-'
          : `${$t(`common.${companyInfo.currencyUnit}`)} ${item.chargedPrice.toLocaleString()}`
        }}
      </template>
      <template v-slot:item.paymentType="{ item }">
        {{ $t(`transaction.paymentTypes.${item.paymentType}`) }}
      </template>
      <template v-slot:item.sessionStartDate="{ item }">
        <div class="align_center">
          <v-chip x-small class="mr-1 px-1 flex-shrink-0" label>
            {{ $t('common.start') }}
          </v-chip>
          {{ time.makeLocalTime(item.sessionStartDate) }}
        </div>
        <div class="align_center" v-if="item.sessionEndDate != null">
          <v-chip x-small class="mr-1 px-1 flex-shrink-0" label color="accent">
            {{ $t('common.end') }}
          </v-chip>
          {{ time.makeLocalTime(item.sessionEndDate) }}
        </div>
      </template>
      <template v-slot:item.sessionEndDate="{ item }">
        {{ item.sessionEndDate == null ? '-' : time.makeLocalTime(item.sessionStartDate) }}
      </template>
      <template v-slot:no-data>
        <p>{{ $t('common.noData') }}</p>
      </template>
    </v-data-table>
    <v-pagination
      small
      depressed
      v-show="pageOptions.pageCount > 0"
      :value="options.page"
      :length="pageOptions.pageCount"
      :total-visible="10"
      @input="updateOptions('page', $event)"
    ></v-pagination>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import time from '@/util/time';
import format from '@/util/format';

export default {
  components: {
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
    items: {
      type: Array,
      default: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    pageOptions: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
      companyInfo: 'auth/companyInfo',
    }),
  },
  data: () => ({
    time,
    format,
    headers: [],
    sort: {
      sortBy: null,
      sortDesc: null,
    },
  }),
  methods: {
    initHeaders() {
      this.headers = [
        {
          text: this.$t('cp.seqNo'),
          sortable: true,
          value: 'seqNo',
        },
        {
          text: this.$t('transaction.userInfo'),
          sortable: false,
          value: 'user',
        },
        {
          text: this.$t('cp.area'),
          align: 'left',
          sortable: true,
          value: 'chargepoint.area.name',
        },
        {
          text: this.$t('user.lastTransactionStatus'),
          align: 'left',
          sortable: true,
          value: 'status',
        },
        {
          text: this.$t('transaction.chargePointInfo'),
          align: 'left',
          sortable: false,
          value: 'chargepoint.cpId',
        },
        {
          text: this.$t('transaction.cpNumber'),
          align: 'left',
          sortable: false,
          value: 'chargepoint.cpCode',
        },
        {
          text: this.$t('transaction.evseNumber'),
          align: 'center',
          sortable: false,
          value: 'connectorId',
        },
        {
          text: this.$t('transaction.chargedMeter'),
          align: 'left',
          sortable: true,
          value: 'chargedMeter',
        },
        {
          text: this.$t('transaction.chargingTime'),
          align: 'left',
          sortable: true,
          value: 'chargingStartDate',
        },
        {
          text: this.$t('transaction.chargedPrice'),
          align: 'left',
          sortable: true,
          value: 'chargedPrice',
        },
        {
          text: this.$t('transaction.paymentType'),
          align: 'left',
          sortable: true,
          value: 'paymentType',
        },
        {
          text: this.$t('transaction.chargingDate'),
          align: 'left',
          sortable: true,
          value: 'sessionStartDate',
        },
      ];
    },
    updateOptions(key, value) {
      let options = {
        ...this.options,
        [key]: value,
      };
      if (key === 'itemsPerPage') {
        options = {
          ...this.options,
          page: 1,
          itemsPerPage: this.pageOptions.itemsPerPageOptions[value],
        };
      }
      this.$emit('update:options', options);
    },
    async stopTransaction(transaction) {
      const callback = async () => {
        this.$dialog.progress(true);
        try {
          await this.$emitter('transaction.stop', {
            transactionId: transaction.transactionId,
            cpId: transaction.chargepoint.cpId,
          }, this.$t('common.updateCompleteMessage'));
          await this.callback();
          this.$dialog.confirm();
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
        this.$dialog.progress(false);
      };
      await this.$dialog.confirm({
        show: true,
        message: this.$t('transaction.stopConfirmMessage'),
        color: 'error',
        btnText: this.$t('common.end'),
        callback,
      });
    },
    makeChargingTime(transaction) {
      const { chargingStartDate, chargingEndDate, sessionEndDate } = transaction;
      if (chargingStartDate == null) return '-';

      const { dayjs } = this.time;
      let currentDate = dayjs();
      if (chargingEndDate) {
        currentDate = dayjs(chargingEndDate);
      } else if (sessionEndDate) {
        currentDate = dayjs(sessionEndDate);
      }

      return this.formatDuration(currentDate.diff(dayjs(chargingStartDate), 'second'));
    },
    formatDuration(seconds) {
      let sign = '';
      const absoluteSeconds = Math.abs(seconds);
      if (seconds < 0) sign = '-';

      const hours = Math.floor(absoluteSeconds / 3600);
      const minutes = Math.floor((absoluteSeconds % 3600) / 60);
      const remainingSeconds = absoluteSeconds % 60;

      const formattedHours = hours > 0 ? `${hours}:` : '';
      const formattedMinutes = `${minutes.toString().padStart(2, '0')}:`;
      const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

      return sign + formattedHours + formattedMinutes + formattedSeconds;
    },
  },
  watch: {
    staffInfo: {
      immediate: true,
      async handler() {
        this.initHeaders();
      },
    },
    '$i18n.locale': {
      handler() {
        this.initHeaders();
      },
    },
    sort: {
      deep: true,
      handler(value) {
        this.options.page = 1;
        if (value.sortBy == null && value.sortDesc == null) {
          this.$emit('update:options', {
            ...this.options,
            sortBy: [],
            sortDesc: [],
          });
        } else if (value.sortBy != null && value.sortDesc != null) {
          this.$emit('update:options', {
            ...this.options,
            sortBy: [String(value.sortBy)],
            sortDesc: [value.sortDesc],
          });
        }
      },
    },
  },
};
</script>
