<template>
  <v-dialog
    v-model="show"
    width="1000"
    @click:outside="init()"
    @keydown="$event.key === 'Escape' ? init() : ''"
  >
    <v-card class="log-wrap" style="width: 100%;">
      <p class="tit">
        {{ $t('cp.msgLog') }}
        <span class="sub ml-1" v-if="cp != null">{{ cp.cpId }}</span>
      </p>
      <div class="align_center justify-space-between mb-1">
        <div class="align_center">
          <v-btn
            icon
            color="black"
            @click="moveDate(-1)"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
            <v-menu
              v-model="datePicker.show"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="fs11 px-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mr-1" size="18">mdi-calendar-range</v-icon>
                  <span style="margin-top: -2px !important">
                    {{ datePicker.date }}
                  </span>
                </v-btn>
              </template>
              <v-date-picker
                v-model="datePicker.date"
                no-title
                @input="datePicker.show = false"
                :locale="$i18n.locale === 'jp' ? 'ja' : $i18n.locale"
              ></v-date-picker>
            </v-menu>
          <v-btn
            icon
            color="black"
            :disabled="nextBtnDisabled"
            @click="moveDate(1)"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <v-btn
          class="px-2"
          color="primary"
          depressed
          :disabled="loading"
          @click="getMsgLog"
          small
        >
          <v-icon small class="mr-1">mdi-refresh</v-icon>
          {{ $t('btn.refresh') }}
        </v-btn>
      </div>
      <v-data-table
        mobile-breakpoint="0"
        fixed-header
        :headers="headers"
        :items="messages"
        :loading="loading"
        disable-pagination
        hide-default-footer
        dense
        class="data_table"
        checkbox-color="primary"
        item-key="_id"
        :options="options"
      >
        <template v-slot:item.msgName="{ item }">
          <span v-if="item.msgName != null">
            {{
              item.msgType === 4 ? 'CALL_ERROR'
              : `${item.msgName}.${item.msgType === 2 ? 'req' : 'conf' }`
            }}
          </span>
          <span v-else class="x-small-text">{{ $t('common.emptyInfo') }}</span>
        </template>
        <template
          v-slot:item.from="{ item }"
          style="width: 200px;"
        >
          <v-chip
            :color="item.from === 'CSMS' ? 'info' : 'success'"
            x-small
            class="px-2"
          >
            {{ item.from === 'CSMS' ? 'CSMS' : 'CP' }}
          </v-chip>
          <v-icon x-small :color="item.from === 'CSMS' ? 'info' : 'success'">
            mdi-arrow-right
          </v-icon>
          <v-chip
            x-small
            outlined
            class="px-2"
          >
            {{ item.from === 'CSMS' ? 'CP' : 'CSMS' }}
          </v-chip>
        </template>
        <template v-slot:item.msgParams="{ item }">
          <p
            v-if="item.msgParams != null"
            class="small-text"
            style="max-width: 500px;"
          >
            {{
              JSON.stringify(item.msgParams)
            }}
          </p>
          <span v-else class="x-small-text">{{ $t('common.emptyInfo') }}</span>
        </template>
        <template v-slot:item.created="{ item }">
          {{ time.makeLocalHMS(item.created) }}
        </template>
        <template v-slot:no-data>
          <p>{{ $t('common.noData') }}</p>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import time from '@/util/time';

export default {
  name: 'DlgMsgLog',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    _id: {
      type: String,
    },
  },
  data: () => ({
    time,
    cp: null,
    messages: [],
    loading: false,
    headers: null,
    from: {
      CSMS: 'CSMS',
      ChargePoint: 'CP',
    },
    datePicker: {
      date: null,
      show: false,
    },
    nextBtnDisabled: true,
    options: {
      page: 1,
      itemsPerPage: 0,
      sortBy: ['created'],
      sortDesc: [true],
    },
  }),
  methods: {
    init() {
      this.$emit('update:show', false);
      this.$emit('update:_id', null);
      this.initData();
    },
    initData() {
      this.messages = [];
      this.cp = null;
      this.datePicker.date = this.time.getDate();
      this.headers = [
        {
          text: this.$t('cp.communicationDirection'),
          align: 'left',
          sortable: true,
          value: 'from',
        },
        {
          text: this.$t('cp.command'),
          align: 'left',
          sortable: true,
          value: 'msgName',
        },
        {
          text: this.$t('common.content'),
          align: 'left',
          sortable: true,
          value: 'msgParams',
        },
        {
          text: this.$t('cp.communicationTime'),
          align: 'left',
          sortable: true,
          value: 'created',
        },
      ];
    },
    async getCp(_id) {
      this.loading = true;
      try {
        const { item } = await this.$emitter('cp.get', {
          _id,
        });
        this.cp = item;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    async getMsgLog() {
      this.loading = true;
      try {
        const { start, end } = this.makeUtcDateRange(this.datePicker.date);
        const { items } = await this.$emitter('msglog.list.get', {
          ...this.options,
          filters: [
            {
              where: 'and',
              key: 'cpId',
              value: this.cp.cpId,
              condition: 'eq',
            },
            {
              where: 'and',
              key: 'startDate',
              value: start,
              condition: 'gte',
            },
            {
              where: 'and',
              key: 'startDate',
              value: end,
              condition: 'lte',
            },
          ],
        });
        if (items.length === 0) this.messages = [];
        else this.messages = items[0].messages.reverse();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    moveDate(dateDiff) {
      const date = this.time.dayjs(this.datePicker.date);
      this.datePicker.date = date.add(dateDiff, 'day').format('YYYY-MM-DD');
    },
    makeUtcDateRange(date) {
      const { dayjs } = this.time;
      const utcOffset = dayjs().utcOffset() / 60;
      const start = dayjs(`${date} 00:00:00`).add(utcOffset * -1, 'hour').format('YYYY-MM-DD HH:mm:ss');
      const end = dayjs(`${date} 23:59:59`).add(utcOffset * -1, 'hour').format('YYYY-MM-DD HH:mm:ss');
      return {
        start,
        end,
      };
    },
  },
  watch: {
    _id: {
      immediate: true,
      async handler(value) {
        if (value != null) {
          this.initData();
          await this.getCp(value);
          await this.getMsgLog();
        }
      },
    },
    'datePicker.date': {
      immediate: true,
      async handler(value) {
        if (this.cp != null) {
          const { dayjs } = this.time;
          const currentDate = dayjs(this.time.getDate());
          const targetDate = dayjs(value);
          if (targetDate.isBefore(currentDate)) this.nextBtnDisabled = false;
          else this.nextBtnDisabled = true;
          await this.getMsgLog();
        }
      },
    },
  },
};
</script>
