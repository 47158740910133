<template>
  <v-dialog
    v-model="show"
    width="auto"
    @click:outside="init()"
    @keydown="$event.key === 'Escape' ? init() : ''"
  >
    <v-card class="log-wrap">
      <div class="tit align_center">
        {{ $t('cp.diagLog') }}
        <div class="sub ml-1 d-flex" v-if="cp != null">
          {{ cp.cpId }}
          <v-tooltip
            top
            color="black"
            v-if="cp.isOnline !== true"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on" v-bind="attrs"
                class="ml-1"
                small
                color="error"
              >
                mdi-alert-circle-outline
              </v-icon>
            </template>
            <span class="small-text">
              <!-- 온라인 상태 -->
              <span>
                {{ $t('cp.online') }}:
                {{
                  cp.isOnline == null || cp.isOnline === ''
                  ? '-' : cp.isOnline
                }}
              </span><br/>
              <!-- 상태 -->
              <span>
                {{ $t('transaction.status') }}:
                {{
                  cp.state.status == null || cp.state.status === ''
                  ? '-' : cp.state.status
                }}
              </span><br/>
              <span>
              <!-- 가용상태 -->
                {{ $t('cp.availability') }}:
                {{
                  cp.state.availability == null || cp.state.availability === ''
                  ? '-' : cp.state.availability
                }}
              </span><br/>
              <!-- 에러정보 -->
              <span>
                {{ $t('cp.errorInfo') }}:
                {{
                  cp.state.error == null || cp.state.error === ''
                  ? '-' : cp.state.error
                }}
              </span><br/>
            </span>
          </v-tooltip>
        </div>
      </div>
      <div class="d-flex align-end justify-end mb-1">
        <v-menu
          v-model="datePicker.show"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-2 mr-2"
              color="success"
              depressed
              v-bind="attrs"
              v-on="on"
              :disabled="cp == null || cp.isOnline !== true"
              small
            >
              <v-icon small class="mr-1">mdi-upload</v-icon>
              {{ $t('cp.diagLogUploadRequest') }}
            </v-btn>
          </template>
          <v-date-picker
            v-model="datePicker.duration"
            :locale="$i18n.locale === 'jp' ? 'ja' : $i18n.locale"
            range
          >
            <div class="d-flex justify-end" style="width: 100%">
              <v-btn
                depressed
                color="primary"
                @click="requestDiagLog"
                :disabled="
                  cp == null
                  || datePicker.duration == null
                  || datePicker.duration.length <= 1
                "
                small
              >
                {{ $t('common.request') }}
              </v-btn>
            </div>
          </v-date-picker>
        </v-menu>
        <v-btn
          class="px-2"
          color="primary"
          depressed
          @click="getDiagLog"
          small
        >
          <v-icon small class="mr-1">mdi-refresh</v-icon>
          {{ $t('btn.refresh') }}
        </v-btn>
      </div>
      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items="logs"
        :loading="loading"
        disable-pagination
        hide-default-footer
        dense
        class="data_table"
        checkbox-color="primary"
        item-key="_id"
        :options="options"
      >
        <template v-slot:item.logFile.name="{ item }">
          <div v-if="item.logFile != null">
            <div class="d-flex">
              <div>
                <p class="small-text">{{ item.logFile.name }}</p>
                <p class="x-small-text">{{ file.byteTrans(item.logFile.size) }}</p>
              </div>
              <v-tooltip
                top
                color="black"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    class="ml-1"
                    @click="fileDownload(item.logFile.no)"
                  >
                    <v-icon color="info">mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('common.download') }}</span>
              </v-tooltip>
            </div>
          </div>
          <span v-else class="x-small-text">{{ $t('common.emptyInfo') }}</span>
        </template>
        <template v-slot:item.startTime="{ item }">
          {{ time.makeDate(item.startTime) }}
          ~ {{ time.makeDate(item.stopTime) }}
        </template>
        <template v-slot:item.updated="{ item }">
          {{ time.makeTime(item.updated) }}
        </template>
        <template v-slot:no-data>
          <p>{{ $t('common.noData') }}</p>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import time from '@/util/time';
import file from '@/util/file';

export default {
  name: 'DlgMsgLog',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    _id: {
      type: String,
    },
  },
  data: () => ({
    time,
    file,
    cp: null,
    logs: [],
    loading: false,
    headers: null,
    datePicker: {
      duration: [],
      show: false,
    },
    options: {
      page: 1,
      itemsPerPage: 0,
      sortBy: ['updated'],
      sortDesc: [true],
    },
  }),
  methods: {
    init() {
      this.$emit('update:show', false);
      this.$emit('update:_id', null);
      this.initData();
    },
    initData() {
      this.logs = [];
      this.cp = null;
      this.datePicker.duration = [];
      this.datePicker.show = false;
      this.headers = [
        {
          text: this.$t('cp.diagDuration'),
          align: 'left',
          sortable: true,
          value: 'startTime',
        },
        {
          text: this.$t('common.fileName'),
          align: 'left',
          sortable: true,
          value: 'logFile.name',
        },
        {
          text: this.$t('common.registration'),
          align: 'left',
          sortable: true,
          value: 'updated',
        },
      ];
    },
    async getCp(_id) {
      this.loading = true;
      try {
        const { item } = await this.$emitter('cp.get', {
          _id,
        });
        this.cp = item;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    async getDiagLog() {
      this.loading = true;
      try {
        const { items } = await this.$emitter('diaglog.list.get', {
          ...this.options,
          filters: [
            {
              where: 'and',
              key: 'cpId',
              value: this.cp.cpId,
              condition: 'eq',
            },
          ],
        });
        this.logs = items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    async requestDiagLog() {
      this.$dialog.progress(true);
      const { startTime, stopTime } = this.makeUtcDateRange(this.datePicker.duration);
      const { _id: cpOid } = this.cp;
      try {
        await this.$emitter('diaglog.request', {
          chargepoint_id: cpOid,
          startTime,
          stopTime,
        });
        this.datePicker.duration = [];
        this.datePicker.show = false;
        this.$dialog.progress(false);
        this.$dialog.alert('success', this.$t('cp.diagLogUploadRequestCompleteMsg'), 10000);
        await this.getDiagLog();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        this.$dialog.progress(false);
      }
    },
    makeUtcDateRange(duration) {
      const { dayjs } = this.time;
      const utcOffset = dayjs().utcOffset() / 60;
      const startTime = dayjs(`${duration[0]} 00:00:00`).add(utcOffset * -1, 'hour').format('YYYY-MM-DD HH:mm:ss');
      const stopTime = dayjs(`${duration[1]} 23:59:59`).add(utcOffset * -1, 'hour').format('YYYY-MM-DD HH:mm:ss');
      return {
        startTime,
        stopTime,
      };
    },
    async fileDownload(no) {
      try {
        const { item } = await this.$emitter('file.read', { no });
        await file.writeFileFromPayload(item);
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
  },
  watch: {
    _id: {
      immediate: true,
      async handler(value) {
        if (value != null) {
          this.initData();
          await this.getCp(value);
          await this.getDiagLog();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
</style>
