<template>
  <div class="section_wrap">
    <SectionHeader
      :loading="loading"
      :refresh="getData"
      :buttons="buttons"
    />
    <SearchFilter
      :radios="radios"
      :selects="selects"
      :filters.sync="options.filters"
      :loading="loading"
      :searchKeys="searchKeys"
      :callback="getData"
      :options.sync="options"
    />
    <UserChargeDataTable
      :items="items"
      :loading="loading"
      :options.sync="options"
      :pageOptions="pageOptions"
      :callback="getData"
    />
  </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapGetters } from 'vuex';
import * as XLSX from 'xlsx';
import time from '@/util/time';
import SearchFilter from '@/components/common/SearchFilter.vue';
import SectionHeader from '@/components/common/SectionHeader.vue';
import UserChargeDataTable from './unit/UserChargeDataTable.vue';

export default {
  name: 'UsersSection',
  components: {
    UserChargeDataTable,
    SearchFilter,
    SectionHeader,
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    loading: false,
    radios: [
      {
        key: 'paymentType',
        i18nKey: 'transaction.paymentType',
        values: ['credit', 'point'],
        conditions: ['eq', 'eq'],
        i18nLabels: ['transaction.paymentTypes.credit', 'transaction.paymentTypes.point'],
      },
      {
        key: 'chargepoint.model.type',
        i18nKey: 'common.AC/DC',
        values: ['AC', 'DC'],
        conditions: ['inc', 'inc'],
        i18nLabels: ['common.AC', 'common.DC'],
      },
    ],
    selects: [
      {
        key: 'status',
        i18nKey: 'user.lastTransactionStatus',
        values: [
          'Accepted',
          'Charging',
          'ChargeStop',
          'ChargeFinished',
          'PaymentCompleted',
          'PaymentRequired',
          'SessionCompleted',
        ],
        conditions: ['eq', 'eq', 'eq', 'eq', 'eq', 'eq', 'eq'],
        i18nLabels: [
          'transaction.statuses.Accepted',
          'transaction.statuses.Charging',
          'transaction.statuses.ChargeStop',
          'transaction.statuses.ChargeFinished',
          'transaction.statuses.PaymentCompleted',
          'transaction.statuses.PaymentRequired',
          'transaction.statuses.SessionCompleted',
        ],
        permission: true,
      },
      {
        key: 'chargepoint.area._id',
        i18nKey: 'area.areaName',
        values: [],
        conditions: [],
        i18nLabels: [],
        isDirectLabel: true,
        permission: true,
      },
    ],
    searchKeys: [
      {
        i18nKey: 'cp.seqNo',
        key: 'seqNo',
        type: 'number',
      },
      {
        i18nKey: 'user.name',
        key: 'user.name',
      },
      {
        i18nKey: 'user.phone',
        key: 'user.phone',
      },
      {
        i18nKey: 'transaction.cpNumber',
        key: 'chargepoint.cpCode',
        type: 'number',
      },
      {
        i18nKey: 'area.areaName',
        key: 'chargepoint.area.name',
      },
    ],
    options: {
      page: 1,
      itemsPerPage: 2,
      sortBy: ['sessionStartDate'],
      sortDesc: [true],
      filters: [],
    },
    pageOptions: {
      total: 0,
      pageCount: 0,
      itemsPerPageOptions: [10, 50],
    },
    items: [],
    buttons: [
      {
        color: 'success',
        icon: 'mdi-microsoft-excel',
        click: 'downloadExcel',
        i18nText: 'btn.excelDownload',
      },
    ],
  }),
  methods: {
    async getData() {
      this.items = [];
      this.loading = true;
      try {
        const {
          itemsPerPage,
          filters,
        } = this.options;
        const { data: { total } } = await this.$emitter('transaction.summary.get', { filters });
        this.pageOptions.total = total;
        this.pageOptions.pageCount = Math.ceil(total / itemsPerPage);
        const { items } = await this.$emitter('transaction.list.get', this.options);
        this.items = items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    async getAreaData() {
      try {
        const { items } = await this.$emitter('area.list.get', {
          itemsPerPage: 0,
          page: 1,
          sortBy: ['created'],
          sortDesc: [false],
        });
        const areaSelectFilter = items.reduce((acc, row) => {
          acc.i18nLabels.push(row.name);
          acc.values.push(row._id);
          acc.conditions.push('eq');
          return acc;
        }, {
          i18nLabels: [],
          values: [],
          conditions: [],
        });
        this.$set(this.selects, 1, {
          ...this.selects[1],
          ...areaSelectFilter,
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    // TODO: 아래 샘플처럼 다른 페이지의 엑셀다운로드도 수정이 필요합니다.
    async downloadExcel() {
      this.$dialog.progress(true);
      try {
        // 1. 데이터 새로 불러옴 (현재 필터 + 전체 페이지)
        const { items } = await this.$emitter('transaction.list.get', {
          ...this.options,
          page: 1,
          itemsPerPage: 0,
        });

        // 2. 데이터가 없을 경우 에러처리
        // eslint-disable-next-line no-throw-literal
        if (items.length <= 0) throw { code: 204 };

        // 3. 출력할 필드의 이름 및 값 정의
        const makeStartEndTime = (transaction) => {
          let result = time.makeLocalTime(transaction.sessionStartDate);
          if (transaction.sessionEndDate != null) {
            result += ` ~ ${time.makeLocalTime(transaction.sessionEndDate)}`;
          }
          return result;
        };
        const convertItems = items.map((row) => ({
          // 번호
          [this.$t('cp.seqNo')]: row.seqNo,
          // 충전기 번호
          [`${this.$t('menu.charger')}-${this.$t('cp.seqNo')}`]: row.chargepoint.cpCode,
          // 충전기 ID
          [`${this.$t('menu.charger')}-${this.$t('cp.cpId')}`]: row.chargepoint.cpId,
          // 충전기명
          [this.$t('cp.cpName')]: row.chargepoint.cpName,

          // 사업장명
          [this.$t('area.areaName')]: row.chargepoint.area.name,
          // 사업장주소
          [this.$t('area.areaAddress')]: row.chargepoint.area.address,

          // 충전상태
          [this.$t('user.lastTransactionStatus')]: this.$t(`transaction.statuses.${row.status}`),

          // evse 번호
          [`Evse ${this.$t('cp.seqNo')}`]: `${row.chargepoint.cpCode}-${row.connectorId}`,

          // 충전량
          [this.$t('transaction.chargedMeter')]: `${row.chargedMeter / 1000}kWh`,
          // 충전금액
          [this.$t('transaction.chargedPrice')]: row.chargedPrice,
          // 충전일시
          [this.$t('transaction.chargingDate')]: makeStartEndTime(row),
        }));
        // 4. 엑셀파일 내보내기
        const excelData = XLSX.utils.json_to_sheet(convertItems);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, excelData, 'transaction_data');
        XLSX.writeFile(workBook, `transaction_data_${time.makeAttachTime(new Date())}.xlsx`);
        this.$dialog.progress(false);
      } catch (error) {
        console.error(error.code);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        this.$dialog.progress(false);
      }
    },
  },
  watch: {
    staffInfo: {
      immediate: true,
      async handler(value) {
        if (value != null) {
          this.getData();
          this.getAreaData();
        }
      },
    },
    options: {
      deep: true,
      async handler() {
        if (this.staffInfo !== null) await this.getData();
      },
    },
  },
};
</script>
