<template>
  <div
    v-show="system.show"
    class="system-bar"
    :style="`background-color: ${$vuetify.theme.themes.light[system.type]}`"
  >
    <v-icon class="msg-icon" size="16">{{ system.icon }}</v-icon>
    <span>{{ system.message }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DlgSystemBar',
  computed: {
    ...mapGetters({
      system: 'dialog/system',
    }),
  },
};
</script>
<style scoped lang="scss">
.system-bar {
  position: fixed;
  top: 0px;
  width: 100vw;
  height: 30px;
  z-index: 500;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  * {
    color: #fff;
  }
  span {
    font-size: .85rem;
    font-weight: bold;
    margin-left: 6px;
  }
}
</style>
