<template>
  <div
    class="top-btn-wrap"
    :class="staffInfo == null && 'top-hidden'"
  >
    <div class="top-btn-group">
      <v-menu
        rounded="lg"
        auto
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="top-btn top-first"
          >
            <v-icon color="primary" size="25">mdi-account-circle-outline</v-icon>
          </div>
        </template>
        <v-list dense>
          <v-list-item to="/mypage" class="list-row pl-3" color="primary">
            <v-icon size="16" class="mr-2">mdi-account-edit</v-icon>
            <span class="list-row">{{ $t('btn.mypage') }}</span>
          </v-list-item>
          <v-list-item @click="signoutMethod" class="list-row pl-3">
            <v-icon size="16" class="mr-2" color="error">mdi-close</v-icon>
            <span
              class="tiny"
              :style="{ color: $vuetify.theme.themes.light.error }"
            >
              {{ $t('btn.logout') }}
            </span>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        rounded="lg"
        auto
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="top-btn top-second"
          >
            <v-icon color="success" size="25">mdi-translate</v-icon>
          </div>
        </template>
        <v-list dense>
          <v-list-item-group
            :value="$i18n.availableLocales.indexOf($i18n.locale)"
            color="success"
          >
            <v-list-item
              v-for="locale in $i18n.availableLocales"
              :key="locale"
              class="list-row pl-3"
              @click="$i18n.locale = locale"
            >
              <span class="list-row">{{ $t(`language.${locale}`, locale) }}</span>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  methods: {
    ...mapActions({
      signout: 'auth/signout',
    }),
    signoutMethod() {
      this.$dialog.confirm({
        show: true,
        callback: async () => {
          await this.signout();
          this.$dialog.confirm();
          this.$dialog.alert('success', this.$t('sign.signOutSuccessMessage'));
          await this.$router.push('/sign');
        },
        message: this.$t('sign.signOutConfirmMessage'),
        color: 'error',
        btnText: this.$t('btn.logout'),
      });
    },
  },
};
</script>

<style scoped lang="scss">
.list-row {
  font-size: .85rem;
  display: flex;
  align-items: center;
  span {
    margin-top: -2px;
  }
}
.top-btn-wrap {
  min-width: 1024px;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  overflow: hidden;
  height: 32px;
  position: relative;
  &.top-hidden {
    .top-btn-group {
      top: -40px;
      opacity: 0;
    }
  }
  .top-btn-group {
    display: flex;
    position: absolute;
    transition: .5s ease-in-out, opacity 1s ease-in-out;
    opacity: 1;
    top: 0px;
    .top-btn {
      border-radius: 0px 0px 6px 6px;
      background-color: #fff;
      border: 1px solid #ccc;
      padding: 3px 30px;
      cursor: pointer;
      transition: .3s ease-in-out;
      display: flex;
      border-top: 0px;
      align-items: center;
      &:hover {
        background-color: #f2f2f2;
      }
      &.top-first {
        border-right: 0px;
        border-radius: 0px 0px 0px 6px;
      }
      &.top-second{
        border-radius: 0px 0px 6px 0px;
      }
    }
  }
}
</style>
