function makeMaskPhoneNumber(phoneNumber) {
  if (phoneNumber.length <= 3) return '***';
  const numberLength = phoneNumber.replace('-', '').length;
  const maskLength = numberLength - 2 > 4 ? 4 : numberLength - 2;
  const startIndex = numberLength - 2 > 4
    ? Math.floor((numberLength - 4) / 2)
    : Math.floor((numberLength - 2) / 2);

  let maskedLength = 0;
  const maskedArray = phoneNumber.split('').map((current, index) => {
    if (current !== '-' && maskedLength < maskLength && index >= startIndex) {
      maskedLength += 1;
      return '*';
    }
    return current;
  });
  return maskedArray.join('');
}

function makeMaskName(name) {
  const { length } = name;
  if (length <= 2) return `${name[0]}*`;
  const maskLength = length - 2 > 4 ? 4 : length - 2;
  const startIndex = length - 2 > 4
    ? Math.round((length - 4) / 2)
    : Math.round((length - 2) / 2);
  let maskedLength = 0;
  const maskedArray = name.split('').map((current, index) => {
    if (current !== '-' && maskedLength < maskLength && index >= startIndex) {
      maskedLength += 1;
      return '*';
    }
    return current;
  });
  return maskedArray.join('');
}

export default {
  makeMaskPhoneNumber,
  makeMaskName,
};
