<template>
  <div class="section_wrap company_wrap">
    <SectionHeader
      :loading="loading"
    />
    <div class="explanation_input_box">
    </div>
    <div class="box_list">
      <v-tabs
        v-model="tabValue"
        height="60"
        active-class="selectTab"
        fixed-tabs
        style="height:10%;"
      >
        <v-tab
          v-for="tab in tabList"
          :key="tab.text"
          style="font-size:16px;"
          class="font-weight-bold"
        >
          {{ $t(`company_setting.${tab.text}`) }}
        </v-tab>
      </v-tabs>
      <div style="height:90%;">
        <v-tabs-items v-model="tabValue" style="height:100%;">
          <v-tab-item
            v-for="tab in tabList"
            :key="tab.text"
            style="height:100%;"
          >
            <CompanyInfo  v-if="tab.text === 'companyInfo'"/>
            <ProtectInfo v-if="tab.text === 'protectInfo'"/>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/common/SectionHeader.vue';
import CompanyInfo from './unit/CompanyInfo.vue';
import ProtectInfo from './unit/ProtectInfo.vue';

export default {
  name: 'CompanySection',
  components: {
    CompanyInfo,
    ProtectInfo,
    SectionHeader,
  },
  data: () => ({
    tabValue: 'companyInfo',
    tabList: [
      { text: 'companyInfo' },
      { text: 'protectInfo' },
    ],
    loading: false,
  }),
};
</script>

<style lang="scss">
.company_wrap{
  height:calc(100% - 32px);
  .box_list{
    height: 80%;
  }
}
</style>
