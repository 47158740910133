<template>
  <div class="accrue-wrap">
    <v-card
      style="height: 100%;"
      elevation="7"
    >
      <p class="monitoring_title">
        {{ $t('dashboard.accrueTable') }}
      </p>
      <div
        style="
          height: calc(100% - 30px);
          padding: 3%;
          overflow: auto;
        "
      >
        <table
          class="accrue_table"
          v-if="selectedArea !== null"
        >
          <thead class="smT">
            <tr>
              <th rowspan="2">{{ $t('dashboard.tabs.sortation') }}</th>
              <th colspan="2">{{ $t('dashboard.tabs.yesterday') }}</th>
              <th colspan="2">{{ $t('dashboard.tabs.today') }}</th>
            </tr>
            <tr>
              <th>
                <p>{{ $t('transaction.chargedMeter') }}</p>
                <p style="font-size: 11px;">(KWh)</p>
              </th>
              <th>%</th>
              <th>
                <p>{{ $t('transaction.chargedMeter') }}</p>
                <p style="font-size: 11px;">(KWh)</p>
              </th>
              <th>%</th>
            </tr>
          </thead>
          <tbody class="smT">
            <tr v-for="(sort, idx) in ['light', 'medium', 'maximum']" :key="idx">
              <th>{{ $t(`price_setting.${sort}Load`) }}</th>
              <td>{{ (yesterdayData[sort] || 0).toLocaleString() }}</td>
              <td>{{ calcRatio(yesterdayData[sort], yesterdayData.total) }}</td>
              <td>{{ (todayData[sort] || 0 ).toLocaleString() }}</td>
              <td>{{ calcRatio(todayData[sort], todayData.total) }}</td>
            </tr>
            <tr>
              <th>{{ $t('common.total') }}</th>
              <td>{{ (yesterdayData.total || 0 ).toLocaleString()}}</td>
              <td>{{ calcTotalRatio(yesterdayData) }}</td>
              <td>{{ (todayData.total || 0).toLocaleString()}}</td>
              <td>{{ calcTotalRatio(todayData) }}</td>
            </tr>
          </tbody>
        </table>
        <p
          v-else
          class="flex_center"
          style="color: #A9A9A9; height: 100%;"
        >
          {{ $t('common.noSelect') }}
        </p>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    selectedArea: {
      type: String,
    },
    yesterdayData: {
      type: Object,
      required: true,
    },
    todayData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
  }),
  methods: {
    calcRatio(data, total) {
      if (data !== 0 && total !== 0) {
        const ratio = (data / total) * 100;
        return +(ratio.toFixed(1));
      }
      return 0;
    },
    calcTotalRatio(data) {
      let totalRatio = 0;
      ['light', 'medium', 'maximum'].forEach((sort) => {
        totalRatio += this.calcRatio(data[sort], data.total);
      });
      return totalRatio;
    },
  },
  watch: {
  },
};
</script>

<style lang="scss">
.accrue-wrap {
    height: 25%;
  }
</style>
