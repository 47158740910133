<template>
  <div class="data_table_wrap">
    <div class="align_center justify-space-between">
      <div class="align_center">
        <div class="body-2">
          <b v-if="pageOptions.total <= 0">0</b>
          <b v-else class="primary--text">
            {{
              options.page === 1
              ? items.length
              : ((options.page - 1) * options.itemsPerPage) + items.length
            }}
          </b>
          /
          <span>{{ pageOptions.total }}</span>
        </div>
      </div>
      <v-btn-toggle
        class="my-2"
        v-if="pageOptions.itemsPerPageOptions != null && pageOptions.itemsPerPageOptions.length > 0"
        @change="updateOptions('itemsPerPage', $event)"
        mandatory
        dense
      >
        <v-btn v-for="number of pageOptions.itemsPerPageOptions" :key="number">
          {{ `${number}${$t('dataTable.itemsPerPageOption')}` }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-data-table
      mobile-breakpoint="0"
      fixed-header
      v-if="items !== null"
      :headers="headers"
      :items="items"
      :loading = "loading"
      :options="options"
      @update:sort-by="sort.sortBy = $event"
      @update:sort-desc="sort.sortDesc = $event"
      disable-pagination
      hide-default-footer
      dense
      class="data_table"
      item-key="_id"
    >
      <template v-slot:item.thumbnail="{ item }">
        <div class="flex_center mb-1">
          <img
            ref="image"
            :src="utilFile.imagePathToSrc(item.thumbnailPath)"
            :alt="item.name"
            style="width:50px; margin-top:5px; cursor: pointer;"
            @click="editImgClick(item)"
          />
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        <div class="align_center">
          <div style="min-width: 150px; max-width: 90%;">
            <p>{{ item.name }}</p>
          </div>
          <v-menu
            v-if="$checkPermission(staffInfo, 'charger_models.write')"
            rounded="lg"
            transition="slide-y-transition"
            offset-x
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    icon
                    class="ml-1"
                    @click="currentCpModelName = item.name, openUpdateName = true"
                  >
                    <v-icon small color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ `${$t('model.name')} ${$t('common.update')}` }}</span>
              </v-tooltip>
            </template>
            <v-list class="d-flex" v-if="openUpdateName">
              <v-text-field
                color="black"
                dense
                flat
                solo
                hide-details
                autofocus
                v-model="currentCpModelName"
                :placeholder="$t('placeholders.cp.modelName')"
                @keydown="$event.key === 'Enter'
                ? editModel(item, { name: currentCpModelName }) : ''"
              />
              <v-btn
                depressed
                class="mr-2"
                color="primary"
                @click="editModel(item, { name: currentCpModelName })"
              >
                {{ $t('common.update') }}
              </v-btn>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:item.description="{ item }">
        <div class="align_center">
          <div style="min-width: 150px; max-width: 90%;">
            <p>{{ item.description }}</p>
          </div>
          <v-menu
            v-if="$checkPermission(staffInfo, 'charger_models.write')"
            rounded="lg"
            transition="slide-y-transition"
            offset-x
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    icon
                    class="ml-1"
                    @click="currentCpModelDescription = item.description, openUpdateDetail = true"
                  >
                    <v-icon small color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ `${$t('model.description')} ${$t('common.update')}` }}</span>
              </v-tooltip>
            </template>
            <v-list class="d-flex" v-if="openUpdateDetail">
              <v-text-field
                color="black"
                dense
                flat
                solo
                hide-details
                autofocus
                v-model="currentCpModelDescription"
                :placeholder="$t('placeholders.cp.description')"
                @keydown="$event.key === 'Enter'
                ? editModel(item, { description: currentCpModelDescription }) : ''"
              />
              <v-btn
                depressed
                class="mr-2"
                color="primary"
                @click="editModel(item, { description: currentCpModelDescription })"
              >
                {{ $t('common.update') }}
              </v-btn>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:item.info="{ item }">
        <div class="flex_center">
          <div style="min-width: 150px; max-width: 90%;">
            <p>{{ item.kwh }}kW / {{ item.type }} / {{ item.evseCount }}</p>
          </div>
            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="$checkPermission(staffInfo, 'charger_models.write')"
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="ml-1"
                  @click="modelInfoDialog = {
                    show: true,
                    title: `
                      ${$t('model.kwh')} / ${$t('point.action')}
                      / Evse ${$t('common.update')}`,
                    type: 'update',
                    _id: item._id,
                    kwh: item.kwh,
                    modelType: item.type,
                    evseCnt: item.evseCount,
                    submit: updateModelInfo,
                  }"
                >
                  <v-icon small color="primary">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{ `${$t('common.update')}` }}</span>
            </v-tooltip>
        </div>
      </template>
      <template v-slot:item.created="{ item }">
        {{ time.makeLocalTime(item.created) }}
      </template>
      <template
        v-slot:item.firmware="{ item }"
        v-if="$checkPermission(staffInfo, 'charger_models.read')"
      >
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="clickedFirm(item)"
            >
              <v-icon small color="primary">mdi-file-check-outline</v-icon>
            </v-btn>
          </template>
          <span>
            {{ `${$t('cp.firmware')} ${$t('common.detail')}` }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <p>{{ $t('common.noData') }}</p>
      </template>
      <template
        v-slot:item.disable="{item}"
        v-if="$checkPermission(staffInfo, 'charger_models.write')"
      >
        <v-btn
          fab
          x-small
          depressed
          icon
          @click="deleteChargerModel(item._id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-pagination
      small
      depressed
      v-show="pageOptions.pageCount > 0"
      :value="options.page"
      :length="pageOptions.pageCount"
      :total-visible="10"
      @input="updateOptions('page', $event)"
    ></v-pagination>
    <DlgCommonWrite
      :show.sync="editDialog.show"
      :title="editDialog.title"
      :sub="editDialog.sub"
      :type="editDialog.type"
      :formCheck="editDialog.formCheck"
      @submit="editDialog.submit"
      width="700"
      style="height:400px;"
    >
      <div
        class="d-flex"
        style="position:relative;"
      >
        <div class="d-flex">
          <v-chip class="mr-2 flex-shrink-0" label color="primary">
            {{ $t('model.originImg') }}
          </v-chip>
          <img
            ref="image"
            :src="utilFile.imagePathToSrc(editDialog.form.path)"
            :alt="editDialog.form.path"
            class="mR10"
            style="width:100px;"
            @mouseover="imgHoverState = true;"
            @mouseout="imgHoverState = false;"
          />
        </div>
      </div>
      <div
        v-if="imgHoverState"
        class="mL10"
        style="position:absolute; top:60px; left:250px; z-index: 99;"
      >
        <img
          :src="utilFile.imagePathToSrc(editDialog.form.path)"
          :alt="editDialog.form.path"
          style="width:200px;"
        />
      </div>
      <div class="align_center mb-4 mT10">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('model.editModelImg') }}
        </v-chip>
        <div class="value">
          <input
            v-show="false"
            type="file"
            ref="fileInput"
            :multiple="maxCount > 1"
            @change="fileChange({
              event: $event,
              maxCount,
              maxSize,
              accepts: ['png', 'jpg', 'zip'],
              target: 'files',
            })"
            style="z-index: 9;"
          />
          <v-btn
            v-if="files.length < maxCount"
            elevation
            dense
            outlined
            @click="fileClick({
              target: 'files',
              ref: 'fileInput',
            })"
          >
            <v-icon class="mr-1">
              mdi-image-outline
            </v-icon>
            {{ $t('common.file') }}
          </v-btn>
          <div class="d-flex flex-wrap" v-if="files.length > 0">
            <div
              class="align_center"
              v-for="(file, index) in files"
              :key="index"
            >
              <img
                v-if="file.name.includes('.png') || file.name.includes('jpg')"
                :src="utilFile.arrayBufferToBase64(files[0].data)"
                style="width:50px"
              />
              <v-chip
                close
                class="ma-1"
                @click:close="fileRemove({
                  target: 'files',
                  file,
                })"
              >
                <strong
                  style="
                    max-width:200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  "
                >
                  {{ file.name }}
                </strong>
                <span>({{ utilFile.byteTrans(file.size) }})</span>
              </v-chip>
            </div>
          </div>
        </div>
      </div>
    </DlgCommonWrite>
    <DlgCommonWrite
      :show.sync="modelInfoDialog.show"
      :title="modelInfoDialog.title"
      :type="modelInfoDialog.type"
      :formCheck="modelInfoDialog.formCheck"
      @submit="modelInfoDialog.submit"
      width="540"
    >
      <div class="pa-5 rounded-lg" style="border: 1px solid #ccc;">
        <v-row class="align-center">
          <v-col cols="3">
            <p
              class="rounded-lg primary text-subtitle-1 text-center white--text py-1"
            >{{ $t('model.kwh') }}</p>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="modelInfoDialog.kwh"
              hide-details
              clearable
              suffix="kW"
              @input="checkedData"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="3">
            <p
              class="rounded-lg primary text-subtitle-1 text-center white--text py-1"
            >{{ $t('point.action') }}</p>
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col
                cols="6"
                v-for="sort, index in type"
                :key="index"
                @click="checkedData(sort)"
                :class="modelInfoDialog.modelType === sort ? 'primary--text font-weight-bold' : ''"
                style="cursor: pointer;"
              >
                {{ sort }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="3">
            <p
              class="rounded-lg primary text-subtitle-1 text-center white--text py-1"
            >Evse</p>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="modelInfoDialog.evseCnt"
              hide-details
              clearable
              suffix="ea"
              @input="checkedData"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </div>
    </DlgCommonWrite>
    <DlgCommonWrite
      :show.sync="fwDialog.show"
      :title="fwDialog.title"
      :sub="fwDialog.sub"
      type="view"
      width="700"
      styles="height: 400px; padding: 0px; background: #fff;"
    >
      <div>
        <div
          style="border:1px solid #E0E0E0; padding:10px;"
          class="mT10"
          v-if="$checkPermission(staffInfo, 'charger_models.write')"
        >
          <v-sheet>
            <v-switch
              hide-details
              inset
              v-model="newFileAdd"
              :label="$t('cp.firmFile')"
            ></v-switch>
          </v-sheet>
          <div v-if="newFileAdd">
            <div
              class="align_center mT10"
              style="width:40%;"
            >
              <v-chip class="mr-2 flex-shrink-0" label color="primary">
                {{ $t('btn.firmware') }}*
              </v-chip>
              <div>
                <v-file-input
                  v-if="fwDialog.show"
                  :placeholder="$t('cp.firmFile')"
                  style="width:400px"
                  @change="firmwareFileUpload"
                  hide-details
                  clear-icon
                  accept=".AppImage,.bin,application/gzip,.tar.gz"
                ></v-file-input>
              </div>
            </div>
            <div
              class="align_center mT10"
              style="width:40%;"
            >
              <v-chip class="mr-2 flex-shrink-0" label color="primary">
                {{ $t('model.type') }}*
              </v-chip>
              <v-select
                :items="fwType"
                style="min-width: 200px;"
                hide-details
                :placeholder="$t('placeholders.cp.fwType')"
                v-model="fwTypeValue"
                item-value="value"
                item-text="text"
                ref="selectMenu"
              >
                <template #item="{ item }">
                  <v-list-item
                    @click="changeType(item)"
                    :disabled="isItemDisabled(item)"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </template>
                <template #selection="{ item }">
                  <span>
                    {{ item.text }}
                  </span>
                </template>
              </v-select>
            </div>
            <div
              class="align_center mT10"
              style="width:40%;"
            >
              <v-chip class="mr-2 flex-shrink-0" label color="primary">
                {{ $t('btn.version') }}*
              </v-chip>
              <v-text-field
                hide-details
                v-model="fwDialog.form.version"
              />
            </div>
            <div
              class="align_center mT10"
              v-if="!onlyFileUpdate"
            >
              <v-chip class="mr-2 flex-shrink-0" label color="primary">
                {{ $t('model.reservationDate') }}
              </v-chip>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
                v-if="!instantBtn"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    style="max-width:150px;"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    :disabled="instantBtn"
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
                  hide-details
                  :locale="changeLocale"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(date);"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-dialog
                ref="dialog"
                v-model="clockmodal"
                :return-value.sync="selectTime"
                persistent
                width="290px"
                v-if="!instantBtn"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    style="max-width:100px;"
                    v-model="selectTime"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    class="mL10"
                    :disabled="instantBtn || date === null"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="clockmodal"
                  v-model="selectTime"
                  full-width
                  format="24hr"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="clockmodal = false"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="saveTime"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-time-picker>
              </v-dialog>
              <v-checkbox
                style="padding:0"
                v-model="instantBtn"
                :label="$t('common.immediately')"
                hide-details
                :disabled="onlyFileUpdate"
              ></v-checkbox>
            </div>
            <div class="align_center mT10">
              <v-checkbox
                style="padding:0"
                v-model="onlyFileUpdate"
                :label="$t('common.onlyAdd')"
                hide-details
                :disabled="instantBtn"
                @click="onlyFileUpdate = true"
              ></v-checkbox>
            </div>
            <div class="d-flex align-center justify-end">
              <v-btn
                color="primary"
                depressed
                @click="fwDialog.submit"
                :disabled="!fwValidChecker()"
              >{{ $t('common.upload') }}</v-btn>
            </div>
          </div>
        </div>
        <h4 class="pt-3">{{ $t('cp.firmwareHistory') }}</h4>
        <v-data-table
          mobile-breakpoint="0"
          :headers="fwHeaders"
          :items="fwItem"
          fixed-header
          disable-pagination
          hide-default-footer
          item-key="_id"
        >
          <template v-slot:item.fileName="{ item }">
            <div>
              {{ item.file.name }}
            </div>
          </template>
          <template v-slot:item.fileVersion="{ item }">
            <div>
              {{ item.version }}
            </div>
          </template>
          <template v-slot:item.download="{ item }">
            <div>
              <v-btn
                icon
                @click="clickedDownload(item.file)"
              >
                <v-icon>mdi-file-download</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:item.application="{ item }">
            <div v-if="item._id">
              <v-menu
                rounded="lg"
                transition="slide-y-transition"
                offset-x
                max-height="300"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip top color="black">
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        icon
                        class="ml-1"
                        @click="clickedFwRow(item)"
                      >
                        <v-icon>
                          mdi-check-circle-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ `${$t('cp.firmware')} ${$t('btn.apply')}` }}</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <div
                    v-if="!newFileAdd"
                    class="align_center pa-1"
                  >
                    <v-menu
                      ref="applyMenu"
                      v-model="applyMenu"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      v-if="!applyinstantBtn"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="applyDate"
                          style="max-width:150px;"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                          :disabled="applyinstantBtn"
                        />
                      </template>
                      <v-date-picker
                        v-model="applyDate"
                        no-title
                        scrollable
                        hide-details
                        :locale="changeLocale"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="applyMenu = false"
                        >
                          {{ $t('btn.cancel') }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.applyMenu.save(applyDate);"
                        >
                          {{ $t('btn.save') }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <v-menu
                      v-if="!applyinstantBtn"
                      ref="dialog"
                      v-model="applyClock"
                      :close-on-content-click="false"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          style="max-width:100px;"
                          v-model="applySelectTime"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                          :disabled="applyinstantBtn || applyDate === null"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="applyClock"
                        v-model="applySelectTime"
                        full-width
                        format="24hr"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="applyClock = false"
                        >
                          {{ $t('btn.cancel') }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="saveApplyTime"
                        >
                          {{ $t('btn.save') }}
                        </v-btn>
                      </v-time-picker>
                    </v-menu>
                    <v-checkbox
                      style="padding:0"
                      class="mL10 mR10"
                      v-model="applyinstantBtn"
                      :label="$t('common.immediately')"
                      hide-details
                    ></v-checkbox>
                    <v-btn
                      color="primary"
                      :disabled="!applyBtnActive"
                      @click="addFirmware"
                    >
                      {{ $t('btn.apply') }}
                    </v-btn>
                  </div>
                </v-list>
              </v-menu>
            </div>
          </template>
          <template v-slot:item.delete = "{ item }">
            <div>
              <v-btn
                icon
                @click="clickedDelete(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:no-data>
            <p>{{ $t('common.noData') }}</p>
          </template>
        </v-data-table>
      </div>
    </DlgCommonWrite>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapMutations, mapGetters } from 'vuex';
import time from '@/util/time';
import utilFile from '@/util/file';
import DlgCommonWrite from '@/components/dialog/DlgCommonWrite.vue';
// import ChargersFirmware from '../../chargers/unit/ChargersFirmware.vue';

export default {
  components: { DlgCommonWrite },
  props: {
    items: {
      type: Array || null,
      default: null,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    pageOptions: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
      companyInfo: 'auth/companyInfo',
      downloadTasks: 'file/downloadTasks',
    }),
  },
  created() {
    this.fwType = [
      { value: 'display', text: this.$t('cp.firmwareType.display') },
      { value: 'embedded', text: this.$t('cp.firmwareType.embedded') },
      { value: 'unify', text: this.$t('cp.firmwareType.unify') },
    ];
  },
  data: () => ({
    utilFile,
    time,
    openUpdateName: false,
    openUpdateDetail: false,
    fwShow: false,
    menu: false,
    applyMenu: false,
    instantBtn: false,
    applyinstantBtn: false,
    onlyFileUpdate: false,
    maxCount: 1,
    maxSize: 10485760,
    files: [],
    fwFiles: [],
    dialog: false,
    headers: [],
    fwHeaders: [],
    type: ['AC', 'DC'],
    evse: [1, 2],
    sort: {
      sortBy: null,
      sortDesc: null,
    },
    currentCpModelName: '',
    currentCpModelDescription: '',
    imgHoverState: false,
    editDialog: {
      method: '',
      show: false,
      title: '',
      sub: '',
      type: 'update',
      formCheck: false,
      submit: () => {},
      form: {
        path: '',
        clickedId: '',
        thumbnail: {},
      },
    },
    modelInfoDialog: {
      show: false,
      title: '',
      type: 'update',
      _id: '',
      kwh: '',
      modelType: '',
      evseCnt: '',
      formCheck: false,
      submit: () => {},
    },
    fwDialog: {
      method: '',
      show: false,
      title: '',
      sub: '',
      submit: () => {},
      form: {
        type: null,
        version: null,
      },
    },
    fwItem: [],
    fwOptions: {
      page: 1,
      itemsPerPage: 0,
      sortBy: ['created'],
      sortDesc: [true],
      filters: [],
    },
    fwType: [],
    date: null,
    applyDate: null,
    selectTime: null,
    applySelectTime: null,
    clockmodal: false,
    applyClock: false,
    fwTypeValue: null,
    firmFile: null,
    fileUploadNo: null,
    selectedId: null,
    makeUTCdate: null,
    initialType: null,
    newFileAdd: false,
    clickedApply: false,
    clickedApplyId: null,
    clickedModelId: null,
    changeLocale: null,
    applyBtnActive: false,
    changeImgActive: false,
  }),
  methods: {
    ...mapMutations({
      confirm: 'dialog/confirm',
    }),

    async firmwareFileUpload(e) {
      if (e && e.name) {
        this.firmFile = await utilFile.readFileToPayload(e);
        this.fileExtension = e.name.substring(e.name.lastIndexOf('.'));
        this.updateSelectValue();
      }
    },
    updateSelectValue() {
      const extensionMapping = {
        '.AppImage': 'display',
        '.bin': 'embedded',
        '.tar.gz': 'unify',
        '.gz': 'unify',
      };

      const selectedValue = extensionMapping[this.fileExtension] || '';
      this.fwTypeValue = selectedValue;
      this.fwDialog.form.type = selectedValue;
    },
    clickedFwRow(item) {
      if (item) {
        this.clickedApply = true;
        this.clickedApplyId = item._id;
      }
    },

    editImgClick(item) {
      this.initEditDialog();
      this.editDialog.show = true;
      this.editDialog.form.path = item.thumbnailPath;
      this.editDialog.form.clickedId = item._id;
      this.editDialog.sub = item.name;
    },

    initEditDialog() {
      this.editDialog = {
        show: false,
        title: this.$t('common.img'),
        sub: null,
        type: 'update',
        formCheck: false,
        submit: this.editModel,
        form: {
          path: '',
          clickedId: '',
          thumbnail: [],
        },
      };
      this.initialType = null;
      this.files = [];
    },

    checkedData(sort) {
      const { kwh, modelType, evseCnt } = this.modelInfoDialog;
      this.modelInfoDialog.modelType = sort;
      if (
        kwh === null || modelType === null
        || evseCnt === null
      ) this.modelInfoDialog.formCheck = false;
      else this.modelInfoDialog.formCheck = true;
    },
    async updateModelInfo() {
      this.$dialog.progress(true);
      try {
        await this.$emitter('model.update', {
          _ids: [{ _id: this.modelInfoDialog._id }],
          kwh: +(this.modelInfoDialog.kwh),
          evseCount: +(this.modelInfoDialog.evseCnt),
          type: this.modelInfoDialog.modelType,
        }, this.$t('common.updateCompleteMessage'));
        this.modelInfoDialog.show = false;
        this.callback();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },

    initFwDialog() {
      this.fwDialog = {
        show: false,
        title: this.$t('cp.firmware'),
        sub: null,
        type: 'upload',
        formCheck: false,
        submit: this.uploadLog,
        form: {
          type: null,
          version: null,
        },
      };
      this.instantBtn = false;
      this.fwTypeValue = null;
      this.firmFile = null;
      this.date = null;
      this.clickedApplyId = null;
      this.newFileAdd = false;
    },
    clickedFirm(data) {
      this.initFwDialog();
      this.fwDialog.show = true;
      this.fwDialog.sub = data.name;
      this.selectedId = data._id;
      this.getFwData(data._id);
      this.clickedModelId = data._id;
      if (this.fwItem.length <= 0) {
        this.newFileAdd = true;
      }

      if (this.$i18n.locale === 'en') {
        this.changeLocale = 'en';
      } else if (this.$i18n.locale === 'ko') {
        this.changeLocale = 'ko';
      } else if (this.$i18n.locale === 'jp') {
        this.changeLocale = 'zh-cn';
      } else {
        this.changeLocale = 'en';
      }
      this.fwDialog.submit = this.uploadLog;
    },

    async clickedDownload(fileData) {
      try {
        await this.$emitter('file.read', { no: fileData.no });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },

    async uploadLog() {
      this.$dialog.progress(true);
      if (this.onlyFileUpdate) {
        const fileNum = await utilFile.fileUploader({
          file: this.firmFile,
          type: 'firmware',
          firmwareType: this.fwTypeValue,
        });
        await this.callback();
        this.fileUploadNo = fileNum.item;
        this.$dialog.alert('success', this.$t('common.applyCompleteMessage'));
        if (this.fileUploadNo !== null) this.addFirmware(this.fileUploadNo);
      } else {
        await this.$dialog.confirm({
          show: true,
          message: this.$t('common.firmwareAddModel', { modelName: this.fwDialog.sub }),
          color: 'primary',
          btnText: this.$t('btn.apply'),
          callback: async () => {
            this.$dialog.confirm();
            const fileNum = await utilFile.fileUploader({
              file: this.firmFile,
              type: 'firmware',
              firmwareType: this.fwTypeValue,
            });
            await this.callback();
            this.fileUploadNo = fileNum.item;
            this.$dialog.alert('success', this.$t('common.applyCompleteMessage'));
            if (this.fileUploadNo !== null) this.addFirmware(this.fileUploadNo);
          },
        });
      }
      this.fwDialog.show = false;
      this.$dialog.progress(false);
    },

    async addFirmware(value) {
      try {
        if (this.clickedApplyId === null && this.fileUploadNo !== null) {
          if (this.date === null && !this.instantBtn) {
            await this.$emitter('model.firmware.add', {
              _id: this.selectedId,
              type: this.fwDialog.form.type,
              version: this.fwDialog.form.version,
              firmwareFileNo: value,
            });
            this.initFwDialog();
          } else if (this.date === null && this.instantBtn) {
            await this.$emitter('model.firmware.add', {
              _id: this.selectedId,
              type: this.fwDialog.form.type,
              version: this.fwDialog.form.version,
              firmwareFileNo: value,
              requestDate: new Date().toUTCString(),
            });
          } else if (this.date !== null && !this.instantBtn) {
            await this.$emitter('model.firmware.add', {
              _id: this.selectedId,
              type: this.fwDialog.form.type,
              version: this.fwDialog.form.version,
              firmwareFileNo: value,
              requestDate: this.makeUTCdate,
            });
          }
        } else if (this.clickedApplyId !== null) {
          if (this.applyDate === null) {
            await this.$dialog.confirm({
              show: true,
              message: this.$t('common.firmwareAddModel', { modelName: this.fwDialog.sub }),
              color: 'primary',
              btnText: this.$t('btn.apply'),
              callback: () => {
                this.$emitter('model.firmware.update', {
                  _id: this.clickedModelId,
                  firmware_id: this.clickedApplyId,
                  requestDate: new Date().toUTCString(),
                });
                this.callback();
                this.$dialog.confirm();
                this.$dialog.alert('success', this.$t('common.applyCompleteMessage'));
              },
            });
          } else {
            await this.$dialog.confirm({
              show: true,
              message: this.$t('common.firmwareAddModel', { modelName: this.fwDialog.sub }),
              color: 'primary',
              btnText: this.$t('btn.apply'),
              callback: () => {
                this.$emitter('model.firmware.update', {
                  _id: this.clickedModelId,
                  firmware_id: this.clickedApplyId,
                  requestDate: this.makeUTCdate,
                });
                this.callback();
                this.$dialog.confirm();
                this.$dialog.alert('success', this.$t('common.applyCompleteMessage'));
              },
            });
          }
        }
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },

    saveTime() {
      this.$refs.dialog.save(this.selectTime);
      const dateClock = new Date(`${this.date}T${this.selectTime}:00`);
      const teeee = dateClock.toISOString();
      this.makeUTCdate = teeee;
    },
    saveApplyTime() {
      this.$refs.dialog.save(this.applySelectTime);
      const dateClock = new Date(`${this.applyDate}T${this.applySelectTime}:00`);
      const teeee = dateClock.toISOString();
      this.makeUTCdate = teeee;
      this.applyBtnActive = true;
    },

    async editModel(model, argument) {
      this.$dialog.progress(true);
      if (!this.changeImgActive) {
        try {
          await this.$emitter('model.update', {
            _ids: [
              { _id: model._id },
            ],
            ...argument,
          });
          this.$dialog.alert('success', this.$t('common.applyCompleteMessage'));
          await this.callback();
          this.openUpdateName = false;
          this.openUpdateDetail = false;
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
      } else {
        try {
          await this.$emitter('model.update', {
            _ids: [
              { _id: this.editDialog.form.clickedId },
            ],
            thumbnail: this.files[0],
          });
          this.$dialog.alert('success', this.$t('common.applyCompleteMessage'));
          await this.callback();
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
        this.changeImgActive = false;
        this.editDialog.show = false;
      }
      this.$dialog.progress(false);
    },

    async deleteChargerModel(item) {
      try {
        await this.$dialog.confirm({
          show: true,
          message: this.$t('common.delete'),
          color: 'error',
          btnText: this.$t('btn.delete'),
          callback: () => {
            this.$dialog.confirm();
            this.$dialog.alert('success', this.$t('common.applyCompleteMessage'));
            this.$emitter('model.delete', {
              _ids: [
                { _id: item },
              ],
            });
            this.callback();
          },
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },

    fileClick({
      target,
      maxCount,
      ref,
    }) {
      this.$refs[ref].value = null;
      if (this[target].length === maxCount) {
        this.$dialog.alert('error',
          `${this.$t('common.fileErrorFront')} ${maxCount} ${this.$t('common.fileErrorBack')}`);
      } else {
        this.$refs[ref].click();
      }
    },
    async fileChange({
      event,
      maxCount,
      maxSize,
      accepts,
      target,
    }) {
      this.$dialog.progress(true);
      const { files: selectedFiles } = event.target;
      if (selectedFiles.length + this[target].length > maxCount) {
        this.$dialog.alert('error',
          `${this.$t('common.fileErrorFront')} ${maxCount} ${this.$t('common.fileErrorBack')}`);
      } else if (selectedFiles.length > 0) {
        const filesArray = Object.values(selectedFiles);
        const invalidFileCheck = filesArray.reduce((acc, file) => {
          let isAccept = false;
          for (const accept of accepts) {
            if (file.name.includes(`.${accept}`)) isAccept = true;
          }
          if (isAccept === false && isAccept.length) acc.accept += 1;
          if (file.size > maxSize) acc.size += 1;
          return acc;
        }, {
          size: 0,
          accept: 0,
        });
        if (invalidFileCheck.accept > 0) {
          const types = accepts.length <= 0 ? accepts[0] : accepts.join(', ');
          this.$dialog.alert('error', `${this.$t('common.fileErrorExtensions')} [${types}]`);
        } else if (invalidFileCheck.size > 0) {
          this.$dialog.alert('error', `
            ${this.$t('common.fileErrorMaximumFront')} ${utilFile.byteTrans(maxSize)} ${this.$t('common.fileErrorMaximumBack')}
          `);
        } else {
          const map = filesArray.map((file) => utilFile.readFileToPayload(file));
          const filePayloads = await Promise.all(map);
          this[target] = [
            ...filePayloads,
            ...this[target],
          ];
          this.editDialog.form.thumbnail = this[target];
        }
      }
      this.$dialog.progress(false);
      this.changeImgActive = true;
    },
    fileRemove({
      target,
      file,
    }) {
      this[target] = this[target].filter((currentFile) => currentFile.name !== file.name);
      this.editDialog.formCheck = false;
    },
    changeType(item) {
      if (!this.isItemDisabled(item)) {
        this.fwTypeValue = item.value;
        this.fwDialog.form.type = item.value;
      }
      this.$refs.selectMenu.blur();
    },
    isItemDisabled(item) {
      if (this.fileExtension === '.AppImage' && item.value !== 'display') {
        return true;
      }
      if (this.fileExtension === '.bin' && item.value !== 'embedded') {
        return true;
      }
      if (
        (this.fileExtension === '.tar.gz' || this.fileExtension === '.gz')
        && item.value !== 'unify') {
        return true;
      }
      return false;
    },
    fwValidChecker() {
      const {
        type,
        version,
      } = this.fwDialog.form;
      if (this.fwDialog.show !== true) return false;
      if (type == null || type === '') return false;
      if (version == null || version === '') return false;
      if (this.firmFile == null) return false;
      return true;
    },
    editValidChecker() {
      // const {
      //   path,
      //   // thumbnail,
      // } = this.editDialog.form;
      if (this.editDialog.show !== true) return false;
      if (this.files.length <= 0) return false;
      return true;
    },

    async clickedDelete(data) {
      this.$dialog.progress(true);
      try {
        await this.$dialog.confirm({
          show: true,
          message: this.$t('common.firmwareDelete'),
          color: 'error',
          btnText: this.$t('btn.delete'),
          callback: () => {
            this.$dialog.confirm();
            this.$dialog.alert('success', this.$t('common.applyCompleteMessage'));
            this.$emitter('firmware.delete', { _ids: [{ _id: data._id }] });
            this.initFwDialog();
          },
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    async getFwData(idValue) {
      try {
        this.fwOptions.filters.push({
          where: 'and',
          condition: 'eq',
          key: 'model_id',
          value: idValue,
        });
        const resultData = await this.$emitter('firmware.list.get', this.fwOptions);
        this.fwItem = resultData.items;
        this.fwOptions.filters.pop();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },

    updateOptions(key, value) {
      let options = {
        ...this.options,
        [key]: value,
      };
      if (key === 'itemsPerPage') {
        options = {
          ...this.options,
          page: 1,
          itemsPerPage: this.pageOptions.itemsPerPageOptions[value],
        };
      }
      this.$emit('update:options', options);
    },

    initHeaders(staffInfo = null) {
      const headers = [
        {
          text: this.$t('common.img'),
          align: 'center',
          sortable: false,
          value: 'thumbnail',
        },
        {
          text: this.$t('model.name'),
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('model.description'),
          sortable: false,
          value: 'description',
          width: '30%',
        },
        {
          text: `${this.$t('model.kwh')} / ${this.$t('point.action')} / ${this.$t('model.evseCount')}`,
          align: 'center',
          value: 'info',
          sortable: false,
          width: '15%',
        },
        {
          text: this.$t('cp.firmware'),
          value: 'firmware',
          width: '7%',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('common.registration'),
          value: 'created',
          width: '10%',
          align: 'center',
        },
      ];
      if (staffInfo != null && staffInfo.permission.charger_models.write) {
        headers.push({
          text: this.$t('btn.delete'),
          value: 'disable',
          sortable: false,
          align: 'center',
        });
      }
      this.headers = headers;
    },
    initFwHeaders(staffInfo = null) {
      const fwHeaders = [
        {
          text: this.$t('cp.firmFileName'),
          align: 'left',
          sortable: false,
          value: 'fileName',
        },
        {
          text: this.$t('btn.version'),
          align: 'left',
          sortable: false,
          value: 'fileVersion',
        },
        {
          text: this.$t('btn.download'),
          align: 'center',
          sortable: false,
          width: '17%',
          value: 'download',
        },
      ];
      if (staffInfo !== null && staffInfo.permission.charger_models.write) {
        fwHeaders.push({
          text: this.$t('btn.apply'),
          align: 'center',
          sortable: false,
          width: '15%',
          value: 'application',
        },
        {
          text: this.$t('btn.delete'),
          align: 'center',
          sortable: false,
          width: '15%',
          value: 'delete',
        });
      }
      this.fwHeaders = fwHeaders;
    },
  },
  watch: {
    staffInfo: {
      immediate: true,
      deep: true,
      async handler(value) {
        this.initHeaders(value);
        this.initFwHeaders(value);
      },
    },
    '$i18n.locale': {
      handler() {
        this.initHeaders(this.staffInfo);
        this.initFwHeaders(this.staffInfo);
      },
    },
    sort: {
      deep: true,
      handler(value) {
        this.options.page = 1;
        if (value.sortBy == null && value.sortDesc == null) {
          this.$emit('update:options', {
            ...this.options,
            sortBy: [],
            sortDesc: [],
          });
        } else if (value.sortBy != null && value.sortDesc != null) {
          this.$emit('update:options', {
            ...this.options,
            sortBy: [value.sortBy],
            sortDesc: [value.sortDesc],
          });
        }
      },
    },
    // 'fwDialog.form': {
    //   deep: true,
    //   handler() {
    //     this.fwDialog.formCheck = this.fwValidChecker();
    //   },
    // },
    'editDialog.form': {
      deep: true,
      handler() {
        this.editDialog.formCheck = this.editValidChecker();
      },
    },
    fwItem: {
      deep: true,
      handler() {
        this.newFileAdd = false;
        // this.fwDialog.formCheck = this.fwValidChecker();
      },
    },
    applyinstantBtn: {
      deep: true,
      handler(v) {
        if (v) {
          this.applyDate = null;
          this.applySelectTime = null;
          this.applyBtnActive = true;
        } else {
          this.applyBtnActive = false;
        }
      },
    },
    newFileAdd: {
      deep: true,
      handler(v) {
        if (v) {
          this.applyDate = null;
          this.applySelectTime = null;
          this.clickedApplyId = null;
        }
      },
    },
    // files: {
    //   deep: true,
    //   handler() {
    //     this.fileChange();
    //   },
    // },
  },
};
</script>

<style lang="scss">
img { transition: transform 0.3s ease;}
</style>
