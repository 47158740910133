<template>
  <div class="section_wrap">
    <SectionHeader
      :loading="loading"
      :buttons="buttons"
      :refresh="getPaymentData"
    />
    <SearchFilter
      :filters.sync="options.filters"
      :loading="loading"
      :selects="selects"
      :searchKeys="searchKeys"
      :options.sync="options"
      :radios="radios"
      :callback="getPaymentData"
      :staffInfo="staffInfo"
    />
    <UserPaymentDataTable
      :items="items"
      :loading="loading"
      :options.sync="options"
      :pageOptions="pageOptions"
      :callback="getPaymentData"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as XLSX from 'xlsx';
import time from '@/util/time';
import SectionHeader from '@/components/common/SectionHeader.vue';
import SearchFilter from '@/components/common/SearchFilter.vue';
import UserPaymentDataTable from './unit/UserPaymentDataTable.vue';

export default {
  name: 'UserPaymentSection',
  components: {
    SectionHeader,
    SearchFilter,
    UserPaymentDataTable,
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    loading: false,
    buttons: [
      {
        color: 'success',
        icon: 'mdi-microsoft-excel',
        click: 'downloadExcel',
        i18nText: 'btn.excelDownload',
      },
    ],
    pageOptions: {
      total: 0,
      pageCount: 0,
      itemsPerPageOptions: [10, 50],
    },
    searchKeys: [
      {
        i18nKey: 'user.name',
        key: 'userInfo.name',
      },
      {
        i18nKey: 'user.payment.paymentCard',
        key: 'vanCardNo',
      },
    ],
    radios: [
      {
        key: 'userInfo.grade',
        i18nKey: 'cp.amountByGrade.level',
        values: [0, 1, 2, undefined],
        conditions: ['eq', 'eq', 'eq', 'none'],
        i18nLabels: ['userGrades.0', 'userGrades.1', 'userGrades.2', 'user.payment.paymentAction.Unknown'],
        isSingle: true,
      },
    ],
    selects: [
      {
        key: 'userInfo.userCard.cardName',
        i18nKey: 'user.payment.cardName',
        values: [
          '신한',
          '현대',
          '비씨',
          '삼성',
          '',
        ],
        conditions: ['eq', 'eq', 'eq', 'eq', 'none'],
        i18nLabels: [
          '신한',
          '현대',
          '비씨',
          '삼성',
          '알수없음',
        ],
        isDirectLabel: true,
        isSingle: true,
        permission: true,
      },
      {
        key: 'action',
        i18nKey: 'user.payment.paymentState',
        values: ['Transaction', 'UserAuth', 'RequestKey', 'DeleteKey', 'PointPurchase', 'Unknown'],
        conditions: ['eq', 'eq', 'eq', 'eq', 'eq', 'eq'],
        i18nLabels: [
          'user.payment.paymentAction.Transaction',
          'user.payment.paymentAction.UserAuth',
          'user.payment.paymentAction.RequestKey',
          'user.payment.paymentAction.DeleteKey',
          'user.payment.paymentAction.PointPurchase',
          'user.payment.paymentAction.Unknown',
        ],
        isSingle: true,
        permission: true,
      },
    ],
    options: {
      page: 1,
      itemsPerPage: 2,
      sortBy: ['created'],
      sortDesc: [true],
      filters: [],
    },
    items: [],
  }),
  methods: {
    async getPaymentData() {
      this.loading = true;
      try {
        const {
          itemsPerPage,
          filters,
        } = this.options;
        const { data: { total } } = await this.$emitter('payment.summary.get', { filters });
        this.pageOptions.total = total;
        this.pageOptions.pageCount = Math.ceil(total / itemsPerPage);
        const resultData = await this.$emitter('payment.list.get', this.options);
        this.items = resultData.items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    async downloadExcel() {
      const list = await this.$emitter('payment.list.get', {
        ...this.options,
        page: 1,
        itemsPerPage: 0,
      });

      const makeExcelData = list.items.map((item) => ({
        name: item.userInfo ? item.userInfo.name : '-',
        grade: item.userInfo.grade ? this.$t(`userGrades.${item.userInfo.grade}`) : '-',
        phone: item.userInfo ? item.userInfo.phone : '-',
        cardName: item.userInfo.userCard ? item.userInfo.userCard.cardName : '-',
        cardNo: item.userInfo.userCard ? item.userInfo.userCard.cardNo : item.vanCardNo,
        action: this.$t(`user.payment.paymentAction.${item.action}`),
        totalAmount: (item.totalAmount).toLocaleString(),
        created: item.created,
      }));

      // Define column mappings
      const columnMappings = {
        name: this.$t('user.name'),
        grade: this.$t('cp.amountByGrade.level'),
        phone: this.$t('user.phone'),
        cardName: this.$t('user.payment.cardName'),
        cardNo: this.$t('idTag.idTag'),
        action: this.$t('user.payment.paymentState'),
        totalAmount: this.$t('user.payment.totalMount'),
        created: this.$t('common.registration'),
      };

      const filteredItems = makeExcelData.map((data) => {
        const filteredItem = {};
        Object.entries(data).forEach(([key, value]) => {
          let newValue = value;
          switch (key) {
            case 'created':
              newValue = time.makeLocalTime(new Date(value));
              break;
            default:
              newValue = value || '-';
              break;
          }
          filteredItem[columnMappings[key] || key] = newValue;
        });
        return filteredItem;
      });

      const excelData = XLSX.utils.json_to_sheet(filteredItems);
      const columnWidths = [
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
      excelData['!cols'] = columnWidths;

      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, excelData, 'payment_log_data');
      XLSX.writeFile(workBook, `payment_log_data_${time.makeAttachTime(new Date())}.xlsx`);
    },
  },
  watch: {
    staffInfo: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value != null) {
          this.getPaymentData();
          if (!value.permission.payment_logs.subPermission.read) {
            this.selects[1].permission = false;
          }
        }
      },
    },
    options: {
      deep: true,
      async handler() {
        if (this.staffInfo !== null) await this.getPaymentData();
      },
    },
    page: {
      handler() {
        this.getPaymentData();
      },
    },
  },
};
</script>
