<template>
  <div
    class="data_table_wrap"
  >
    <div class="align_center justify-space-between">
      <div class="align_center">
        <v-btn
          class="px-2 mr-4"
          color="primary"
          depressed
          :disabled="selectedItems.length <= 0"
          @click="bulkUpdateDlgOpen"
        >
          {{ `${$t('common.bulkEdit')}(${selectedItems.length})` }}
        </v-btn>
        <div class="body-2">
          <b v-if="pageOptions.total <= 0">0</b>
          <b v-else class="primary--text">
            {{
              options.page === 1
              ? items.length
              : ((options.page - 1) * options.itemsPerPage) + items.length
            }}
          </b>
          /
          <span>{{ pageOptions.total }}</span>
        </div>
      </div>
      <div class="align_center">
        <v-btn-toggle
          class="my-2"
          v-if="
            pageOptions.itemsPerPageOptions != null
            && pageOptions.itemsPerPageOptions.length > 0
          "
          @change="updateOptions('itemsPerPage', $event)"
          mandatory
          dense
        >
          <v-btn v-for="number of pageOptions.itemsPerPageOptions" :key="number">
            {{ `${number}${$t('dataTable.itemsPerPageOption')}` }}
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <v-data-table
      mobile-breakpoint="0"
      fixed-header
      :headers="headers"
      :items="items"
      :loading="loading"
      :options="options"
      @update:sort-by="sort.sortBy = $event"
      @update:sort-desc="sort.sortDesc = $event"
      disable-pagination
      hide-default-footer
      dense
      class="data_table"
      show-select
      checkbox-color="primary"
      item-key="_id"
      v-model="selectedItems"
    >
      <template v-slot:item.cpId="{ item }">
        <div class="align_center">
          <div style="min-width:190px;">
            <div class="d-flex mt-1" v-if="item.model != null" >
              <v-chip x-small label class="px-1 mr-1" outlined color="primary">
                {{ item.model.name }}
              </v-chip>
              <v-chip x-small label class="px-1 mr-1" color="warning">
                {{ item.model.kwh }}kW
              </v-chip>
              <v-chip
                x-small
                label
                class="px-1 mr-1"
                :color="item.model.type === 'DC' ? 'success' : 'accent'"
              >
                {{ item.model.type }}
              </v-chip>
              <v-chip
                x-small
                label
                class="px-1 mr-1"
              >
                {{ item.version.ocpp }}
              </v-chip>
            </div>
            <div v-else>
              <v-chip x-small label class="px-1">{{ $t('transaction.emptyModelInfo') }}</v-chip>
            </div>
            <div>
            <p>{{ item.cpId }}</p>
            </div>
          </div>
          <v-menu
            v-if="$checkPermission(staffInfo, 'chargers.write')"
            rounded="lg"
            transition="slide-y-transition"
            offset-x
            :close-on-content-click="false"
            width="300"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    icon
                    class="ml-1"
                    @click="currentCpId = item.cpId, openUpdateId = true"
                  >
                    <v-icon small color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ `${$t('cp.cpId')} ${$t('common.update')}` }}</span>
              </v-tooltip>
            </template>
            <v-list class="d-flex" v-if="openUpdateId">
              <v-text-field
                color="black"
                dense
                flat
                solo
                hide-details
                autofocus
                v-model="currentCpId"
                :placeholder="$t('placeholders.cp.cpId')"
                @keydown="$event.key === 'Enter' ? updateCp(item, { cpId: currentCpId }) : ''"
              />
              <v-btn
                depressed
                class="mr-2"
                color="primary"
                @click="updateCp(item, { cpId: currentCpId })"
                :disabled="!/^(.{12,50})$/.test(currentCpId.trim())"
              >
                {{ $t('common.update') }}
              </v-btn>
            </v-list>
          </v-menu>
          <v-menu
            v-if="$checkPermission(staffInfo, 'chargers.write')"
            rounded="lg"
            transition="slide-y-transition"
            offset-x
            max-height="300"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    icon
                    @click="getModelData"
                  >
                    <v-icon small color="primary">mdi-ev-plug-chademo</v-icon>
                  </v-btn>
                </template>
                <span>{{ `${$t('cp.model')} ${$t('common.update')}` }}</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item
                v-for="model, index in models"
                :key="index"
                @click="updateCp(item, { model_id: model._id })"
              >
                <span
                  class="text-subtitle-2 mr-2"
                  :class="model.name == item.model.name ? 'select_item':''"
                >
                  {{ model.name }}
                </span>
                <v-chip x-small label class="px-1 mr-1" color="warning">
                  {{ model.kwh }}kW
                </v-chip>
                <v-chip
                  x-small
                  label
                  class="px-1 mr-1"
                  :color="model.type === 'DC' ? 'success' : 'accent'"
                >
                  {{ model.type }}
                </v-chip>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:item.area="{ item }">
        <div class="align_center" >
          <div
            v-if="item.area"
            style="min-width:170px;"
          >
            <p class="small-text">{{ item.area.name }}</p>
            <p class="x-small-text">{{ item.area.address }}</p>
          </div>
          <div v-else>{{ $t('common.emptyInfo') }}</div>
          <v-menu
            v-if="$checkPermission(staffInfo, 'chargers.write')"
            rounded="lg"
            transition="slide-y-transition"
            offset-x
            max-height="300"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    icon
                    class="ml-1"
                    @click="getAreaData"
                  >
                    <v-icon small color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ `${$t('cp.area')} ${$t('common.update')}` }}</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item
                v-for="area, index in areas"
                :key="index"
                @click="updateCp(item, { area_id: area._id })"
                class="d-block align_center"
              >
                <div>
                  <p class="small-text">{{ area.name }}</p>
                  <p class="x-small-text">{{ area.address }}</p>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:item.chargeRateMethod="{ item }">
        <div class="align_center py-1">
          <div>
            <div
              v-if="item.chargeRateMethod === 'kwh'"
              class="align_center mb-1"
            >
              <v-chip
                x-small
                label
                class="px-1 mt-1"
                color="info"
              >
                {{ $t('cp.chargeRateMethods.kwh') }}
              </v-chip>
              <v-chip
                x-small
                label
                class="px-1 mt-1 ml-1"
              >
                {{ item.pricetable.name }}
              </v-chip>
            </div>
            <v-chip
              v-else-if="item.chargeRateMethod === 'min'"
              x-small
              label
              class="px-1 mb-1"
              color="success"
            >
              {{ $t('cp.chargeRateMethods.min') }}
            </v-chip>
            <div
              v-for="(row, grade) in makeCpMemberPriceData(item)"
              :key="grade"
              v-show="row > 0"
              style="min-width: 170px;"
            >
              <div class="align_center">
                <v-chip
                  x-small
                  outlined
                  label
                  class="px-1 mr-1"
                >
                  {{
                    grade === 0 ? $t(`user.default`) : $t(`userGrades.${grade - 1}`)
                  }}
                </v-chip>
                <span class="small-text mx-">
                  {{
                    $t(`common.${companyInfo.currencyUnit}`)
                  }}
                </span>
                <span class="smT">{{ row }}</span>
              </div>
            </div>
          </div>
          <!-- 충전 요금_권한이 있을 경우 수정, 아닐 경우 열람 -->
          <v-tooltip
            top color="black"
            v-if="$checkPermission(staffInfo, 'chargers.write')"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="chargeRateDlgOpen(item._id, 'update')"
              >
                <v-icon small color="primary">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>
              {{ `${$t('cp.chargeRate')} ${$t('common.update')}` }}
            </span>
          </v-tooltip>
          <v-tooltip
            top color="black"
            v-else
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="chargeRateDlgOpen(item._id, 'view')"
              >
                <v-icon small color="primary">mdi-text-box</v-icon>
              </v-btn>
            </template>
            <span>
              {{ `${$t('cp.chargeRate')} ${$t('common.view')}` }}
            </span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.state="{ item }">
        <div class="flex_center">
          <v-tooltip
            top
            color="black"
            v-if="
              item.state.availability !== 'Operative'
              || item.state.status !== 'Available'
              || item.isOnline !== true
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on" v-bind="attrs"
                color="error"
              >
                mdi-alert-circle-outline
              </v-icon>
            </template>
            <span class="small-text">
              <!-- 온라인 상태 -->
              <span>
                {{ $t('cp.online') }}:
                {{
                  item.isOnline == null || item.isOnline === ''
                  ? '-' : $t(`common.cp_state.online.${item.isOnline}`)
                }}
              </span><br/>
              <!-- 상태 -->
              <span>
                {{ $t('transaction.status') }}:
                {{
                  item.state.status == null || item.state.status === ''
                  ? '-' : $t(`common.cp_state.state.${(item.state.status).toLowerCase()}`)
                }}
              </span><br/>
              <span>
              <!-- 가용상태 -->
                {{ $t('cp.availability') }}:
                {{
                  item.state.availability == null || item.state.availability === ''
                  ? '-' : $t(`common.cp_state.availability.${item.state.availability}`)
                }}
              </span><br/>
              <!-- 에러정보 -->
              <span>
                {{ $t('cp.errorInfo') }}:
                {{
                  item.state.error == null || item.state.error === ''
                  ? '-' : $t(`common.cp_state.error.${item.state.error}`)
                }}
              </span><br/>
            </span>
          </v-tooltip>
          <v-icon v-else color="success">mdi-check-circle</v-icon>
          <!-- 재시동 -->
          <div
            v-if="item.isOnline && $checkPermission(staffInfo, 'chargers.write')"
          >
            <v-tooltip
              top
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="ml-1"
                  @click="rebootCp(item)"
                >
                  <v-icon small color="black">mdi-power</v-icon>
                </v-btn>
              </template>
              <span>{{ `${$t('menu.charger')} ${$t('cp.reboot')}` }}</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template
        v-slot:item.firmware="{ item }"
        v-if="$checkPermission(staffInfo, 'chargers.read')"
      >
        <div
          class="align_center justify-space-between"
        >
          <div class="text-left">
            <v-tooltip
              v-if="item.stanbyFirmware != null"
              top
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  label
                  color="info"
                  x-small
                  class="px-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p v-if="item.stanbyFirmware.status === 'Idle'">{{ $t('cp.updateIdle') }}</p>
                  <p v-else-if="
                    item.stanbyFirmware.status === 'DownloadFailed'
                    || item.stanbyFirmware.status === 'InstallationFailed'
                  ">업데이트 실패</p>
                  <p v-else>업데이트 중</p>
                </v-chip>
              </template>
              <div class="smT">
                <p>
                  {{ $t('btn.version') }}: {{ item.stanbyFirmware.version }}
                </p>
                <p>
                  {{ $t('common.fileName') }}: {{ item.stanbyFirmware.firmwareFile.name }}
                </p>
                <p>
                  {{ $t('cp.scheduleDate') }}:
                  {{ time.makeLocalTimeMin(item.stanbyFirmware.requestDate) }}
                </p>
              </div>
            </v-tooltip>
            <div v-if="item.firmware != null">
              <div class="align_center">
                <v-chip
                  small label outlined
                  color="primary"
                  class="px-1"
                >
                  {{ item.firmware.version }}
                </v-chip>
              </div>
            </div>
            <v-chip
              v-else
              small label outlined
              class="px-1"
              style="border: none;"
            >
              {{ $t('common.emptyInfo') }}
            </v-chip>
          </div>
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="clickedFirm(item)"
              >
                <v-icon small color="primary">mdi-file-check-outline</v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('common.detail') }}
            </span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.msglogs="{ item }">
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="msglogDialog = {
                _id: item._id,
                show: true,
              }"
            >
              <v-icon small color="primary">mdi-text-box</v-icon>
            </v-btn>
          </template>
          <span>
            {{ `${$t('cp.msgLog')} ${$t('common.view')}` }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.diaglogs="{ item }">
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :class="item"
              icon
              v-bind="attrs"
              v-on="on"
              @click="diaglogDialog = {
                _id: item._id,
                show: true,
              }"
            >
              <v-icon small color="primary">mdi-text-box-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>
            {{ `${$t('cp.diagLog')} ${$t('common.view')}` }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.evse="{ item }">
        <div
          v-for="number in Object.keys(item.evse)"
          :key="number"
        >
          <!-- evse 정보없음 -->
          <v-chip
            v-if="item.evse[number] == null || item.evse[number].evseNumber == null"
            label
            small
            class="px-1 ma-1"
          >
            Evse {{ $t('common.emptyInfo') }}
          </v-chip>
          <div class="align_center" v-else>
            <!-- evseNumber -->
            <p
              class="mR10"
              style="min-width: 50px;"
            >
              {{ item.evse[number].evseNumber }}
            </p>
            <!-- 비정상 -->
            <v-tooltip
              top
              color="black"
              v-if="
                item.evse[number].state.online === false
                || item.evse[number].state.availability !== 'Operative'
                || item.evse[number].state.status.includes(['Unavailable', 'Faulted'])
                || item.state.availability !== 'Operative'
                || item.state.status !== 'Available'
                || item.isOnline !== true
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-on="on" v-bind="attrs"
                  label
                  color="error"
                  outlined
                  small
                  class="px-1 my-1"
                >
                  {{ $t('common.cp_state.state.unavailable') }}
                </v-chip>
              </template>
              <span class="small-text">
                <!-- 온라인 상태 -->
                <span>
                  {{ $t('cp.online') }}:
                  {{
                    item.evse[number].online == null || item.evse[number].online === ''
                    ? '-' : $t(`common.cp_state.online.${item.evse[number].online}`)
                  }}
                </span><br/>
                <!-- 상태 -->
                <span>
                  {{ $t('transaction.status') }}:
                  {{
                    item.evse[number].state.status == null || item.evse[number].state.status === ''
                    ? '-'
                    : $t(`common.cp_state.state.${(item.evse[number].state.status).toLowerCase()}`)
                  }}
                </span><br/>
                <span>
                <!-- 가용상태 -->
                  {{ $t('cp.availability') }}:
                  {{
                    item.evse[number].state.availability == null
                    || item.evse[number].state.availability === ''
                    ? '-'
                    : $t(`common.cp_state.availability.${item.evse[number].state.availability}`)
                  }}
                </span><br/>
                <!-- 에러정보 -->
                <span>
                  {{ $t('cp.errorInfo') }}:
                  {{
                    item.evse[number].state.error == null || item.evse[number].state.error === ''
                    ? '-' : $t(`common.cp_state.error.${item.evse[number].state.error}`)
                  }}
                </span><br/>
              </span>
            </v-tooltip>
            <!-- 사용중 -->
            <v-chip
              v-else-if="
                [
                  'Preparing', 'Charging', 'SuspendedEVSE', 'SuspendedEV', 'Finishing', 'Reserved'
                ].includes(item.evse[number].state.status)
              "
              label
              color="info"
              small
              class="px-1 my-1"
            >
              {{ $t('cp.operation') }}
            </v-chip>
            <!-- 사용가능 -->
            <v-chip
              v-else-if="
                item.evse[number].state.status === 'Available'
              "
              label
              color="success"
              outlined
              small
              class="px-1 my-1"
            >
              {{ $t('common.cp_state.state.available') }}
            </v-chip>
          </div>
        </div>
      </template>
      <template v-slot:item.unCompletedFaultCount="{ item }">
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              style="height: 20px; width: 20px;"
              @click="faultDialog = {
                id: item._id,
                show: true,
                cpId: item.cpId,
              }"
            >
              <v-badge
                :color="item.unCompletedFaultCount > 0 ? 'error' : 'success' "
                :content="item.unCompletedFaultCount > 0
                  ? item.unCompletedFaultCount : '0'"
                overlap
              >
                <v-icon small color="primary">mdi-text-box</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>
            {{ '오류기록 보기' }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.created="{ item }">
        {{ time.makeLocalTime(item.created) }}
      </template>
      <template
        v-slot:item.disable="{item}"
        v-if="$checkPermission(staffInfo, 'chargers.write')"
      >
        <v-btn
          fab
          x-small
          depressed
          icon
          @click="deleteCharger(item._id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template
        v-slot:no-data
        v-if="!fwData.firmware"
      >
        <p>{{ $t('common.noData') }}</p>
      </template>
    </v-data-table>
    <v-pagination
      small
      depressed
      v-show="pageOptions.pageCount > 0"
      :value="options.page"
      :length="pageOptions.pageCount"
      :total-visible="10"
      @input="updateOptions('page', $event)"
    ></v-pagination>
    <DlgCommonWrite
      :show.sync="writeDialog.show"
      :title="writeDialog.title"
      :sub="writeDialog.sub"
      :type="writeDialog.type"
      :formCheck="writeDialog.formCheck"
      @submit="writeDialog.submit"
      width="700"
    >
      <div
        class="d-flex flex-wrap pb-2"
        style="margin-top: -10px"
        v-if="bulkUpdateSelectedItems.length > 0"
      >
        <v-chip
          outlined
          class="mr-2 my-1"
          small
          close
          v-for="item in bulkUpdateSelectedItems"
          :key="item._id"
          @click:close="
            bulkUpdateSelectedItems.length > 1
            ? bulkUpdateSelectedItems = bulkUpdateSelectedItems
              .filter((row) => row._id !== item._id) : ''
          "
        >
          {{ item.cpId }}
        </v-chip>
      </div>
      <div
        class="d-flex flex-wrap pb-2 mt-2"
        v-if="bulkUpdateSelectedItems.length > 0"
      >
        <v-chip
          filter
          small
          class="mr-2 my-1"
          v-for="(value, key) of updateDlgFields"
          :key="key"
          :color="value ? 'success': ''"
          @click="
            !(Object.values(updateDlgFields).filter((row) => row).length === 1 && value)
            ? updateDlgFields[key] = !value
            : ''
          "
        >
          <v-icon left small :color="value? 'white' : 'gray'">mdi-check</v-icon>
          <span v-if="key === 'membershipPrice'">{{ $t('cp.amountByGrade.amountByGrade') }}</span>
          <span v-else>{{ $t(`cp.${key}`) }}</span>
        </v-chip>
      </div>
      <div
        class="align_center mb-3"
        v-if="updateDlgFields.model || updateDlgFields.area"
      >
        <v-card
          style="width: 100%"
          elevation="0"
          outlined
          :class="updateDlgFields.area ? 'mr-1': ''"
          class="pa-3 flex-shirink-0"
          v-if="updateDlgFields.model"
        >
          <p class="pl-1 pb-1">{{ $t('cp.model') }}</p>
          <v-select
            :placeholder="$t('placeholders.cp.model')"
            outlined
            dense
            :items="models"
            item-text="name"
            item-value="_id"
            hide-details
            :value="writeDialog.form.model_id"
            @change="writeDialog.form.model_id = $event"
            :disabled="writeDialog.form.method === 'view'"
          >
            <template #item="{ item }">
              <span class="text-subtitle-2 mr-2">{{ item.name }}</span>
              <v-chip x-small label class="px-1 mr-1" color="warning">
                {{ item.kwh }}kW
              </v-chip>
              <v-chip
                x-small
                label
                class="px-1 mr-1"
                :color="item.type === 'DC' ? 'success' : 'accent'"
              >
                {{ item.type }}
              </v-chip>
            </template>
          </v-select>
        </v-card>
        <v-card
          style="width: 100%"
          elevation="0"
          outlined
          :class="updateDlgFields.model ? 'ml-1': ''"
          class="pa-3 flex-shirink-0"
          v-if="updateDlgFields.area"
        >
          <p class="pl-1 pb-1">{{ $t('cp.area') }}</p>
          <v-select
            :placeholder="$t('placeholders.cp.areaSelect')"
            outlined
            dense
            :items="areas"
            item-text="name"
            item-value="_id"
            hide-details
            :value="writeDialog.form.area_id"
            @change="writeDialog.form.area_id = $event"
            :disabled="writeDialog.form.method === 'view'"
          >
            <template #item="{ item }">
              <div>
                <span class="text-subtitle-2 mr-2">{{ item.name }}</span>
                <p style="font-size: .65rem; color: #888;">{{ item.address }}</p>
              </div>
            </template>
          </v-select>
        </v-card>
      </div>
      <!-- 회원등급별 금액 / 여기 name issue 아님. -->
      <v-card
        style="width: 100%"
        elevation="0"
        outlined
        class="pa-3 mb-3 flex-shirink-0"
        v-if="updateDlgFields.membershipPrice"
      >
        <p class="pl-1 pb-1">{{ $t('cp.amountByGrade.amountByGrade') }}</p>
        <div class="d-flex flex-wrap" style="gap: 10px">
          <v-card
            style="
              width: calc(50% - 5px);
              overflow: hidden;
              transition: .3s ease-in-out;
            "
            disable
            elevation="0"
            class="pa-3"
            outlined
            v-for="(row, grade) in writeDialog.form.membershipPrice"
            :key="grade"
            :style="row.disable ? 'background-color: #f5f5f5' : ''"
          >
            <div class="align_center justify-space-between">
              <v-chip
                label
                small
                class="px-2 mr-2 flex-shrink-0"
                :disabled="row.disable"
                outlined
              >
                {{ $t(`userGrades.${grade}`) }}
              </v-chip>
              <v-switch
                :input-value="!row.disable"
                @change="row.disable = !row.disable"
                dense
                hide-details
                inset
                style="margin-right: -14px;"
                :disabled="writeDialog.form.method === 'view'"
                v-if="grade !== 0"
              />
            </div>
            <div class="align_center mt-2">
              <p
                style="width:72px; text-align: left;"
                class="flex-shrink-0 small-text mr-2 pl-1"
              >
                {{ $t('cp.amountByGrade.method') }}
              </p>
              <v-select
                dense
                hide-details
                outlined
                v-model="row.method"
                :items="memberMethods"
                :placeholder="
                  `${$t('cp.amountByGrade.method')} ${$t('faq.default')}`
                "
                item-value="method"
                :disabled="row.disable || writeDialog.form.method === 'view'"
              >
                <template #item="{ item }">
                  <span class="small-text" v-if="item === 'amount'">
                    {{ $t('cp.amountByGrade.amountAdjust') }}
                  </span>
                  <span class="small-text" v-else>
                    {{ $t(`cp.amountByGrade.${item}`) }}
                  </span>
                </template>
                <template #selection="{ item }">
                  <span class="small-text" v-if="item === 'amount'">
                    {{ $t('cp.amountByGrade.amountAdjust') }}
                  </span>
                  <span class="small-text" v-else>
                    {{ $t(`cp.amountByGrade.${item}`) }}
                  </span>
                </template>
              </v-select>
            </div>
            <div class="align_center mt-2">
              <p
                style="width:72px; text-align: left;"
                class="flex-shrink-0 small-text mr-2 pl-1"
              >
                <span v-if="row.method === 'rate'">
                  {{ $t('cp.amountByGrade.rate') }}(%)
                </span>
                <span v-else>
                  {{
                    `${$t('cp.amountByGrade.amount')}(${$t(`common.${companyInfo.currencyUnit}`)})`
                  }}
                </span>
              </p>
              <v-text-field
                v-model.number="row.value"
                type="number"
                dense
                hide-details
                outlined
                number
                :disabled="row.disable || writeDialog.form.method === 'view'"
              />
            </div>
          </v-card>
        </div>
      </v-card>
      <!-- 충전요금 / 여기 name issue 아님. -->
      <v-card
        style="width: 100%"
        elevation="0"
        outlined
        class="pa-3 flex-shirink-0"
        v-if="updateDlgFields.chargeRate"
      >
        <div class="pl-1 pb-1 align_center justify-space-between">
          {{ $t('cp.chargeRate') }}
          <!-- 과금방식 수정 -->
          <div class="align_center">
            <v-radio-group
              row
              class="align_center mt-2"
              style="margin-bottom: -15px;"
              :value="writeDialog.form.chargeRateMethod"
              @change="writeDialog.form.chargeRateMethod = $event"
              :disabled="writeDialog.form.method === 'view'"
            >
              <v-radio v-for="method in chargeRateMethods"
                :key="method"
                :label="$t(`cp.chargeRateMethods.${method}`)"
                :value="method"
                dense
                flat
              ></v-radio>
            </v-radio-group>
            <!-- 분당 요금 설정 -->
            <v-text-field
              style="margin-bottom: -1px; width: 180px;"
              v-if="writeDialog.form.chargeRateMethod === 'min'"
              :placeholder="
                `${$t('cp.chargeRateMethods.minAmount')}(${
                  $t(`common.${companyInfo.currencyUnit}`)
                })`
              "
              type="number"
              dense
              hide-details
              outlined
              number
              v-model="writeDialog.form.chargeRatePerMin"
              :disabled="writeDialog.form.method === 'view'"
            />
            <!-- 요금표 선택 -->
            <v-select
              style="margin-bottom: -1px; width: 180px;"
              v-else-if="writeDialog.form.chargeRateMethod === 'kwh'"
              dense
              hide-details
              outlined
              v-model="writeDialog.form.pricetable"
              item-text="name"
              :items="pricetables"
              :placeholder="
                `${$t('cp.priceTable.ChargingPriceTable')} ${$t('faq.default')}`
              "
              return-object
              :disabled="writeDialog.form.method === 'view'"
            >
            </v-select>
          </div>
        </div>
        <PriceTable
          v-if="writeDialog.form.chargeRateMethod === 'kwh'"
          class="pt-2"
          :pricetable="writeDialog.form.pricetable"
          :membershipPrice="writeDialog.form.membershipPrice"
        />
      </v-card>
    </DlgCommonWrite>
    <DlgCommonWrite
      :show.sync="fwDialog.show"
      :title="fwDialog.title"
      :sub="fwDialog.sub"
      type="view"
      width="700"
      styles="height: 400px; padding: 0px; background: #fff;"
    >
      <div>
        <div class="align_center">
          <h4>{{ $t('cp.firmwareHistory') }}</h4>
          <v-tooltip
            top
            color="black"
            v-if="fwData.firmware == undefined"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                color="error"
                v-bind="attrs"
                v-on="on"
              >
              mdi-alert-circle-outline
              </v-icon>
            </template>
            <div>
              <span>
                {{ $t('cp.online') }}:
                {{
                  fwData.isOnline == null || fwData.isOnline === ''
                  ? '-' : $t(`common.cp_state.online.${fwData.isOnline}`)
                }}
              </span><br/>
              <div v-if="fwData.state != undefined">
                <span>
                  {{ $t('transaction.status') }}:
                  {{
                    fwData.state.status == null || fwData.state.status === ''
                    ? '-' : $t(`common.cp_state.state.${(fwData.state.status).toLowerCase()}`)
                  }}
                </span><br/>
                <span>
                  {{ $t('cp.availability') }}:
                  {{
                    fwData.state.availability == null || fwData.state.availability === ''
                    ? '-' : $t(`common.cp_state.availability.${fwData.state.availability}`)
                  }}
                </span><br/>
                <span>
                {{ $t('cp.errorInfo') }}:
                {{
                  fwData.state.error == null || fwData.state.error === ''
                  ? '-' : $t(`common.cp_state.error.${fwData.state.error}`)
                }}
              </span><br/>
              </div>
            </div>
          </v-tooltip>
        </div>
        <div
          style="border:1px solid #E0E0E0; padding:10px;"
          class="mT10"
          v-if="fwData.isOnline && $checkPermission(staffInfo, 'chargers.write')"
        >
          <v-sheet>
            <v-switch
              hide-details
              inset
              v-model="newFileAdd"
              :label="$t('cp.firmFile')"
            ></v-switch>
          </v-sheet>
          <div v-if="newFileAdd">
            <div
              class="align_center mT10"
              style="width:40%;"
            >
              <v-chip class="mr-2 flex-shrink-0" label color="primary">
                {{ $t('btn.firmware') }}*
              </v-chip>
              <div>
                <v-file-input
                  v-if="fwDialog.show"
                  :placeholder="$t('cp.firmFile')"
                  style="width:400px"
                  @change="firmwareFileUpload"
                  hide-details
                  clear-icon
                  accept=".AppImage,.bin,application/gzip,.tar.gz"
                ></v-file-input>
              </div>
            </div>
            <div
              class="align_center mT10"
              style="width:40%;"
            >
              <v-chip class="mr-2 flex-shrink-0" label color="primary">
                {{ $t('model.type') }}*
              </v-chip>
              <v-select
                :items="fwType"
                style="min-width: 200px;"
                hide-details
                :placeholder="$t('placeholders.cp.fwType')"
                v-model="fwTypeValue"
                item-value="value"
                item-text="text"
                ref="selectMenu"
              >
                <template #item="{ item }">
                  <v-list-item
                    @click="changeType(item)"
                    :disabled="isItemDisabled(item)"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </template>
                <template #selection="{ item }">
                  <span>
                    {{ item.text }}
                  </span>
                </template>
              </v-select>
            </div>
            <div class="align_center mT10" style="width:40%;">
              <v-chip class="mr-2 flex-shrink-0" label color="primary">
                {{ $t('btn.version') }}*
              </v-chip>
              <v-text-field
                hide-details
                v-model="fwDialog.form.version"
              />
            </div>
            <div
              class="align_center mT10"
              v-if="!onlyFileUpdate"
            >
              <v-chip class="mr-2 flex-shrink-0" label color="primary">
                {{ $t('model.reservationDate') }}
              </v-chip>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
                v-if="!instantBtn"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    style="max-width:150px;"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    :disabled="instantBtn"
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
                  hide-details
                  :locale="changeLocale"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(date);"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-dialog
                ref="dialog"
                v-model="clockmodal"
                :return-value.sync="selectTime"
                persistent
                width="290px"
                v-if="!instantBtn"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    style="max-width:100px;"
                    v-model="selectTime"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    class="mL10"
                    :disabled="instantBtn || date === null"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="clockmodal"
                  v-model="selectTime"
                  full-width
                  format="24hr"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="clockmodal = false"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="saveTime"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-time-picker>
              </v-dialog>
              <v-checkbox
                style="padding:0"
                v-model="instantBtn"
                :label="$t('common.immediately')"
                hide-details
                :disabled="onlyFileUpdate"
              ></v-checkbox>
            </div>
            <div class="align_center mT10">
              <v-checkbox
                style="padding:0"
                v-model="onlyFileUpdate"
                :label="$t('common.onlyAdd')"
                hide-details
                :disabled="instantBtn"
                @click="onlyFileUpdate = true"
              ></v-checkbox>
            </div>
            <div class="d-flex align-center justify-end">
              <v-btn
                color="primary"
                depressed
                @click="fwDialog.submit"
                :disabled="!fwValidChecker()"
              >{{ $t('common.upload') }}</v-btn>
            </div>
          </div>
        </div>
        <v-data-table
          mobile-breakpoint="0"
          :headers="fwHeaders"
          :items="fwItem"
          fixed-header
          disable-pagination
          hide-default-footer
          item-key="_id"
        >
          <template v-slot:item.fileName="{ item }">
            <div>
              {{ item.file.name }}
            </div>
          </template>
          <template v-slot:item.fileVersion="{ item }">
            <div>
              {{ item.version }}
            </div>
          </template>
          <template v-slot:item.application="{ item }">
            <div v-if="item._id">
              <v-menu
                rounded="lg"
                transition="slide-y-transition"
                offset-x
                max-height="300"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip
                    top
                    color="black"
                    v-if="( fwData.stanbyFirmware
                          && fwData.stanbyFirmware.version === item.version)
                          || (fwData.firmware && fwData.firmware.version === item.version)"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-check-circle
                      </v-icon>
                    </template>
                    <span>{{ $t('cp.firmwareCurrent') }}</span>
                  </v-tooltip>
                  <v-tooltip top color="black" v-else>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        :disabled="!(fwData.isOnline
                          && $checkPermission(staffInfo, 'chargers.write'))"
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        icon
                        @click="clickedFwRow(item)"
                      >
                        <v-icon>
                          mdi-check-circle-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ `${$t('cp.firmware')} ${$t('btn.apply')}` }}</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <div
                    v-if="!newFileAdd"
                    class="align_center pa-1"
                  >
                    <v-menu
                      ref="applyMenu"
                      v-model="applyMenu"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      v-if="!applyinstantBtn"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="applyDate"
                          style="max-width:150px;"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                          :disabled="applyinstantBtn"
                        />
                      </template>
                      <v-date-picker
                        v-model="applyDate"
                        no-title
                        scrollable
                        hide-details
                        :locale="changeLocale"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="applyMenu = false"
                        >
                          {{ $t('btn.cancel') }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.applyMenu.save(applyDate);"
                        >
                          {{ $t('btn.save') }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <v-menu
                      v-if="!applyinstantBtn"
                      ref="dialog"
                      v-model="applyClock"
                      :close-on-content-click="false"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          style="max-width:100px;"
                          v-model="applySelectTime"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                          :disabled="applyinstantBtn || applyDate === null"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="applyClock"
                        v-model="applySelectTime"
                        full-width
                        format="24hr"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="applyClock = false"
                        >
                          {{ $t('btn.cancel') }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="saveApplyTime"
                        >
                          {{ $t('btn.save') }}
                        </v-btn>
                      </v-time-picker>
                    </v-menu>
                    <v-checkbox
                      style="padding:0"
                      class="mL10 mR10"
                      v-model="applyinstantBtn"
                      :label="$t('common.immediately')"
                      hide-details
                    ></v-checkbox>
                    <v-btn
                      color="primary"
                      :disabled="!applyBtnActive"
                      @click="addFirmware"
                    >
                      {{ $t('btn.apply') }}
                    </v-btn>
                  </div>
                </v-list>
              </v-menu>
            </div>
          </template>
          <template v-slot:item.download="{ item }">
            <div>
              <v-btn
                icon
                @click="clickedDownload(item.file)"
              >
                <v-icon>mdi-file-download</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:item.delete = "{ item }">
            <div>
              <v-btn
                v-if="fwData.isOnline && $checkPermission(staffInfo, 'chargers.write')"
                icon
                @click="clickedDelete(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:no-data>
            <p>{{ $t('common.noData') }}</p>
          </template>
        </v-data-table>
      </div>
    </DlgCommonWrite>
    <DlgMsgLog
      :show.sync="msglogDialog.show"
      :_id.sync="msglogDialog._id"
    />
    <DlgDiagLog
      :show.sync="diaglogDialog.show"
      :_id.sync="diaglogDialog._id"
    />
    <DlgFaultLog
      :show.sync="faultDialog.show"
      :id.sync="faultDialog.id"
      :cpId="faultDialog.cpId"
      :staffInfo="staffInfo"
    />
  </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapGetters, mapMutations } from 'vuex';
import time from '@/util/time';
import utilFile from '@/util/file';
import DlgCommonWrite from '@/components/dialog/DlgCommonWrite.vue';
import DlgMsgLog from '@/components/dialog/DlgMsgLog.vue';
import DlgDiagLog from '@/components/dialog/DlgDiagLog.vue';
import DlgFaultLog from '@/components/dialog/DlgFaultLog.vue';
import PriceTable from '@/components/charger/chargers/unit/PriceTable.vue';

export default {
  components: {
    PriceTable,
    DlgCommonWrite,
    DlgMsgLog,
    DlgDiagLog,
    DlgFaultLog,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
    items: {
      type: Array,
      default: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    pageOptions: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
      companyInfo: 'auth/companyInfo',
      downloadTasks: 'file/downloadTasks',
    }),
  },
  created() {
    this.fwType = [
      { value: 'display', text: this.$t('cp.firmwareType.display') },
      { value: 'embedded', text: this.$t('cp.firmwareType.embedded') },
      { value: 'unify', text: this.$t('cp.firmwareType.unify') },
    ];
  },
  data: () => ({
    utilFile,
    time,
    openUpdateId: false,
    menu: false,
    applyMenu: false,
    date: null,
    applyDate: null,
    selectTime: null,
    applySelectTime: null,
    makeUTCdate: null,
    clockmodal: false,
    applyClock: false,
    instantBtn: false,
    applyinstantBtn: false,
    newFileAdd: false,
    areas: [],
    fwItem: [],
    models: [],
    pricetables: [],
    headers: [],
    fwHeaders: [],
    currentCpId: '',
    sort: {
      sortBy: null,
      sortDesc: null,
    },
    selectedItems: [],
    bulkUpdateSelectedItems: [],
    updateDlgFields: {
      model: true,
      area: true,
      membershipPrice: true,
      chargeRate: true,
    },
    fwType: [],
    memberMethods: ['rate', 'amount', 'fix'],
    chargeRateMethods: ['kwh', 'min'],
    currentChargeRateDlgItem: null,
    writeDialog: {
      method: '',
      show: false,
      title: '',
      sub: '',
      type: 'update',
      formCheck: false,
      submit: () => {},
      form: {
        method: null,
        area_id: null,
        model_id: null,
        membershipPrice: [
          { method: 'rate', value: 0, disable: true },
          { method: 'rate', value: 0, disable: true },
          { method: 'rate', value: 0, disable: true },
        ],
        chargeRateMethod: 'kwh',
        chargeRatePerMin: 0,
        pricetable: null,
      },
    },
    onlyFileUpdate: false,
    fwTypeValue: null,
    fileUploadNo: null,
    msglogDialog: {
      _id: null,
      show: false,
    },
    diaglogDialog: {
      _id: null,
      show: false,
    },
    faultDialog: {
      id: null,
      show: false,
      cpId: null,
    },
    fwDialog: {
      method: '',
      show: false,
      title: '',
      sub: '',
      submit: () => {},
      form: {
        type: null,
        version: null,
      },
    },
    fwOptions: {
      page: 1,
      itemsPerPage: 0,
      sortBy: ['created'],
      sortDesc: [true],
      filters: [],
    },
    firmFile: null,
    selectedId: null,
    clickedApply: false,
    clickedApplyId: null,
    initialType: null,
    fwData: {},
    changeLocale: null,
    applyBtnActive: false,
    fileExtension: '',
  }),
  methods: {
    ...mapMutations({
      confirm: 'dialog/confirm',
    }),

    async updateCp(cp, argument) {
      this.$dialog.progress(true);
      try {
        await this.$emitter('cp.update', {
          _ids: [
            { _id: cp._id },
          ],
          ...argument,
        }, this.$t('common.updateCompleteMessage'));
        await this.callback();
        this.openUpdateId = false;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    async rebootCp(item) {
      const callback = async () => {
        this.$dialog.progress(true);
        try {
          await this.$emitter('cp.reboot', {
            _id: item._id,
          }, this.$t('common.applyCompleteMessage'));
          await this.callback();
          this.$dialog.confirm();
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
        this.$dialog.progress(false);
      };
      await this.$dialog.confirm({
        show: true,
        message: this.$t('cp.rebootConfirmMessage'),
        color: 'error',
        btnText: this.$t('cp.reboot'),
        callback,
      });
    },
    updateOptions(key, value) {
      let options = {
        ...this.options,
        [key]: value,
      };
      if (key === 'itemsPerPage') {
        options = {
          ...this.options,
          page: 1,
          itemsPerPage: this.pageOptions.itemsPerPageOptions[value],
        };
      }
      this.$emit('update:options', options);
    },
    async bulkUpdateDlgOpen() {
      await this.getModelData();
      await this.getAreaData();
      await this.getPriceTableData();
      this.bulkUpdateSelectedItems = this.selectedItems
        .map((item) => ({ _id: item._id, cpId: item.cpId }));
      this.updateDlgFields = {
        model: true,
        area: true,
        membershipPrice: true,
        chargeRate: true,
      };
      this.writeDialog = {
        show: true,
        title: `${this.$t('menu.charger')} ${this.$t('common.bulkEdit')}`,
        sub: '',
        formCheck: false,
        submit: this.updateCpMultipleFields,
        form: {
          method: 'bulk',
          area_id: null,
          model_id: null,
          membershipPrice: [
            { method: 'rate', value: 100, disable: true },
            { method: 'rate', value: 100, disable: true },
            { method: 'rate', value: 100, disable: true },
          ],
          chargeRateMethod: 'kwh',
          chargeRatePerMin: 0,
          pricetable: null,
        },
      };
    },
    async chargeRateDlgOpen(cpOid, type) {
      this.$dialog.progress(true);
      try {
        if (type === 'update') {
          await this.getModelData();
          await this.getAreaData();
        }
        await this.getPriceTableData();
        const { item } = await this.$emitter('cp.get', {
          _id: cpOid,
        });
        this.currentChargeRateDlgItem = item;
        this.bulkUpdateSelectedItems = [];
        this.updateDlgFields = {
          model: false,
          area: false,
          membershipPrice: true,
          chargeRate: true,
        };
        this.writeDialog = {
          show: true,
          title: `${this.$t('menu.charger')} ${this.$t(`common.${type}`)}`,
          sub: this.currentChargeRateDlgItem.cpName,
          type,
          formCheck: false,
          submit: this.updateCpMultipleFields,
          form: {
            method: type,
            area_id: null,
            model_id: null,
            membershipPrice: this.currentChargeRateDlgItem.membershipPrice,
            chargeRateMethod: this.currentChargeRateDlgItem.chargeRateMethod,
            chargeRatePerMin: this.currentChargeRateDlgItem.chargeRatePerMin,
            pricetable: this.currentChargeRateDlgItem.pricetable,
          },
        };
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    async firmwareFileUpload(e) {
      if (e && e.name) {
        this.firmFile = await utilFile.readFileToPayload(e);
        this.fileExtension = e.name.substring(e.name.lastIndexOf('.'));
        this.updateSelectValue();
      }
    },
    updateSelectValue() {
      const extensionMapping = {
        '.AppImage': 'display',
        '.bin': 'embedded',
        '.tar.gz': 'unify',
        '.gz': 'unify',
      };

      const selectedValue = extensionMapping[this.fileExtension] || '';
      this.fwTypeValue = selectedValue;
      this.fwDialog.form.type = selectedValue;
    },
    updateValidChecker() {
      const {
        method,
        model_id: modelOid,
        area_id: areaOid,
        chargeRateMethod,
        chargeRatePerMin,
        pricetable,
      } = this.writeDialog.form;
      // bulk이고 대상이 없을 경우
      if (method === 'bulk' && this.bulkUpdateSelectedItems.length <= 0) return false;
      const {
        model,
        area,
        chargeRate,
      } = this.updateDlgFields;
      if (model && modelOid == null) return false;
      if (area && areaOid == null) return false;
      if (chargeRate && chargeRateMethod === 'min' && chargeRatePerMin <= 0) return false;
      if (chargeRate && chargeRateMethod === 'kwh' && pricetable == null) return false;
      return true;
    },
    fwValidChecker() {
      const {
        type,
        version,
      } = this.fwDialog.form;
      if (this.fwDialog.show !== true) return false;
      if (type == null || type === '') return false;
      if (version == null || version === '') return false;
      if (this.firmFile == null) return false;
      return true;
    },
    makeUpdateForm() {
      const form = {};
      const {
        model_id: modelOid,
        area_id: areaOid,
        chargeRateMethod,
        chargeRatePerMin,
        membershipPrice,
        pricetable,
      } = this.writeDialog.form;
      const {
        model,
        area,
        chargeRate,
        membershipPrice: membershipPriceBool,
      } = this.updateDlgFields;
      if (model && modelOid != null) form.model_id = modelOid;
      if (area && areaOid != null) form.area_id = areaOid;
      if (membershipPriceBool) form.membershipPrice = membershipPrice;
      if (chargeRate) form.chargeRateMethod = chargeRateMethod;
      if (chargeRate && chargeRateMethod === 'kwh' && pricetable != null) form.pricetable_id = pricetable._id;
      if (chargeRate && chargeRateMethod === 'min' && chargeRatePerMin >= 0.1) form.chargeRatePerMin = Number(chargeRatePerMin);
      return form;
    },
    async updateCpMultipleFields() {
      this.$dialog.progress(true);
      let oIds = [];
      if (this.writeDialog.form.method === 'bulk') {
        oIds = this.bulkUpdateSelectedItems.map((item) => ({ _id: item._id }));
      } else if (this.writeDialog.form.method === 'update') {
        oIds = [{ _id: this.currentChargeRateDlgItem._id }];
      }
      const updateData = this.makeUpdateForm();
      try {
        await this.$emitter('cp.update', {
          _ids: oIds,
          ...updateData,
        }, this.$t('common.updateCompleteMessage'));
        this.selectedItems = [];
        this.bulkUpdateSelectedItems = [];
        this.writeDialog = {
          show: false,
          title: '',
          sub: '',
          type: 'update',
          formCheck: false,
          submit: () => {},
          form: {
            method: '',
            area_id: null,
            model_id: null,
            // membershipPrice: [
            //   { method: 'rate', value: 100, disable: true },
            //   { method: 'rate', value: 100, disable: true },
            //   { method: 'rate', value: 100, disable: true },
            // ],
            chargeRateMethod: 'kwh',
            chargeRatePerMin: 0,
            pricetable: null,
          },
        };
        await this.callback();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    makeCpMemberPriceData(cp) {
      const { membershipPrice, chargeRateMethod } = cp;
      let currentPrice = 0;
      if (chargeRateMethod === 'min') {
        currentPrice = cp.chargeRatePerMin;
      } else {
        const { pricetable } = cp;
        const currentDate = new Date();
        const month = currentDate.getMonth();
        const hour = currentDate.getHours();
        currentPrice = pricetable.data[month].times[hour].price;
      }
      const gradeValues = membershipPrice.map((row) => {
        if (row.disable === false && row.method === 'fix') return row.value;
        if (row.disable === false && row.method === 'rate') return Number((Number(row.value) * (currentPrice / 100)).toFixed(1));
        if (row.disable === false && row.method === 'amount') return Number((Number(row.value) + currentPrice).toFixed(1));
        return 0;
      });
      return [
        currentPrice,
        ...gradeValues,
      ];
    },
    async deleteCharger(item) {
      try {
        await this.$dialog.confirm({
          show: true,
          message: this.$t('common.delete'),
          color: 'error',
          btnText: this.$t('btn.delete'),
          callback: () => {
            const choiceIndex = this.items.indexOf(item);
            this.items.splice(choiceIndex, 1);
            this.$dialog.confirm();
            this.$dialog.alert('success', this.$t('common.applyCompleteMessage'));
            this.$emitter('cp.delete', {
              _ids: [
                { _id: item },
              ],
            });
          },
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },

    saveTime() {
      this.$refs.dialog.save(this.selectTime);
      const dateClock = new Date(`${this.date}T${this.selectTime}:00`);
      const teeee = dateClock.toISOString();
      this.makeUTCdate = teeee;
    },
    saveApplyTime() {
      this.$refs.dialog.save(this.applySelectTime);
      const dateClock = new Date(`${this.applyDate}T${this.applySelectTime}:00`);
      const teeee = dateClock.toISOString();
      this.makeUTCdate = teeee;
      this.applyBtnActive = true;
    },
    async clickedFirm(data) {
      this.initFwDialog();
      this.fwData = data;
      this.fwDialog.show = true;
      await this.getFwData(data.model._id);
      this.selectedId = data._id;
      if (data.cpId) this.fwDialog.sub = data.cpId;
      if (this.fwItem.length <= 0) {
        this.newFileAdd = true;
      }
      if (this.$i18n.locale === 'en') {
        this.changeLocale = 'en';
      } else if (this.$i18n.locale === 'ko') {
        this.changeLocale = 'ko';
      } else if (this.$i18n.locale === 'jp') {
        this.changeLocale = 'zh-cn';
      } else {
        this.changeLocale = 'en';
      }
      this.fwDialog.submit = this.uploadLog;
    },
    initFwDialog() {
      this.fwDialog = {
        show: false,
        title: this.$t('cp.firmware'),
        sub: null,
        submit: this.uploadLog,
        form: {
          type: null,
          version: null,
        },
      };
      this.instantBtn = false;
      this.applyinstantBtn = false;
      this.newFileAdd = false;
      this.fwTypeValue = null;
      this.date = null;
      this.applyDate = null;
      this.selectTime = null;
      this.applySelectTime = null;
      this.clickedApplyId = null;
      this.onlyFileUpdate = false;
    },

    async uploadLog() {
      this.$dialog.progress(true);
      if (this.onlyFileUpdate) {
        const fileNum = await utilFile.fileUploader({
          file: this.firmFile,
          type: 'firmware',
          firmwareType: this.fwTypeValue,
        });
        await this.callback();
        this.fileUploadNo = fileNum.item;
        this.$dialog.alert('success', this.$t('common.applyCompleteMessage'));
        if (this.fileUploadNo !== null) this.addFirmware(this.fileUploadNo);
      } else {
        await this.$dialog.confirm({
          show: true,
          message: this.$t('common.firmwareAdd'),
          color: 'primary',
          btnText: this.$t('btn.apply'),
          callback: async () => {
            this.$dialog.confirm();
            const fileNum = await utilFile.fileUploader({
              file: this.firmFile,
              type: 'firmware',
              firmwareType: this.fwTypeValue,
            });
            await this.callback();
            this.fileUploadNo = fileNum.item;
            this.$dialog.alert('success', this.$t('common.applyCompleteMessage'));
            if (this.fileUploadNo !== null) this.addFirmware(this.fileUploadNo);
          },
        });
      }
      this.fwDialog.show = false;
      this.$dialog.progress(false);
    },

    clickedFwRow(item) {
      if (item) {
        this.clickedApply = true;
        this.clickedApplyId = item._id;
      }
    },
    async clickedDelete(data) {
      this.$dialog.progress(true);
      try {
        await this.$dialog.confirm({
          show: true,
          message: this.$t('common.firmwareDelete'),
          color: 'error',
          btnText: this.$t('btn.delete'),
          callback: () => {
            this.$dialog.confirm();
            this.$dialog.alert('success', this.$t('common.applyCompleteMessage'));
            this.$emitter('firmware.delete', { _ids: [{ _id: data._id }] });
            this.initFwDialog();
          },
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },

    async clickedDownload(fileData) {
      try {
        await this.$emitter('file.read', { no: fileData.no });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    changeType(item) {
      if (!this.isItemDisabled(item)) {
        this.fwTypeValue = item.value;
        this.fwDialog.form.type = item.value;
      }
      this.$refs.selectMenu.blur();
    },
    isItemDisabled(item) {
      if (this.fileExtension === '.AppImage' && item.value !== 'display') {
        return true;
      }
      if (this.fileExtension === '.bin' && item.value !== 'embedded') {
        return true;
      }
      if (
        (this.fileExtension === '.tar.gz' || this.fileExtension === '.gz')
        && item.value !== 'unify') {
        return true;
      }
      return false;
    },
    async addFirmware(value) {
      try {
        if (this.clickedApplyId === null && this.fileUploadNo !== null) {
          if (this.date === null && !this.instantBtn) {
            await this.$emitter('cp.firmware.add', {
              _ids: [{ _id: this.selectedId }],
              type: this.fwDialog.form.type,
              version: this.fwDialog.form.version,
              firmwareFileNo: value,
            });
            this.initFwDialog();
          } else if (this.date === null && this.instantBtn) {
            await this.$emitter('cp.firmware.add', {
              _ids: [{ _id: this.selectedId }],
              type: this.fwDialog.form.type,
              version: this.fwDialog.form.version,
              firmwareFileNo: value,
              requestDate: new Date().toUTCString(),
            });
          } else if (this.date !== null && !this.instantBtn) {
            await this.$emitter('cp.firmware.add', {
              _ids: [{ _id: this.selectedId }],
              type: this.fwDialog.form.type,
              version: this.fwDialog.form.version,
              firmwareFileNo: value,
              requestDate: this.makeUTCdate,
            });
          }
        } else if (this.clickedApplyId !== null) {
          if (this.applyDate === null) {
            await this.$dialog.confirm({
              show: true,
              message: this.$t('common.firmwareAdd'),
              color: 'primary',
              btnText: this.$t('btn.apply'),
              callback: () => {
                this.$emitter('cp.firmware.update', {
                  _ids: [{ _id: this.selectedId }],
                  firmware_id: this.clickedApplyId,
                  requestDate: new Date().toUTCString(),
                });
                this.callback();
                this.$dialog.confirm();
                this.$dialog.alert('success', this.$t('common.applyCompleteMessage'));
              },
            });
          } else {
            await this.$dialog.confirm({
              show: true,
              message: this.$t('common.firmwareAdd'),
              color: 'primary',
              btnText: this.$t('btn.apply'),
              callback: () => {
                this.$emitter('cp.firmware.update', {
                  _ids: [{ _id: this.selectedId }],
                  firmware_id: this.clickedApplyId,
                  requestDate: this.makeUTCdate,
                });
                this.callback();
                this.$dialog.confirm();
                this.$dialog.alert('success', this.$t('common.applyCompleteMessage'));
              },
            });
          }
        }
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    async getModelData() {
      try {
        const { items } = await this.$emitter('model.list.get', {
          itemsPerPage: 0,
          page: 1,
          sortBy: ['created'],
          sortDesc: [false],
        });
        this.models = items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    async getAreaData() {
      try {
        const { items } = await this.$emitter('area.list.get', {
          itemsPerPage: 0,
          page: 1,
          sortBy: ['created'],
          sortDesc: [false],
        });
        this.areas = items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    async getPriceTableData() {
      try {
        const { items } = await this.$emitter('pricetable.list.get', {
          itemsPerPage: 0,
          page: 1,
          sortBy: ['created'],
          sortDesc: [false],
        });
        this.pricetables = items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    async getFwData(idValue) {
      try {
        this.fwOptions.filters.push({
          where: 'and',
          condition: 'eq',
          key: 'model_id',
          value: idValue,
        });
        const resultData = await this.$emitter('firmware.list.get', this.fwOptions);
        this.fwItem = resultData.items;
        this.fwOptions.filters.pop();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },

    initHeaders(staffInfo = null) {
      const headers = [
        {
          text: this.$t('cp.cpCode'),
          align: 'left',
          sortable: true,
          value: 'cpCode',
          width: '3%',
        },
        {
          text: this.$t('cp.cpInfo'),
          align: 'left',
          sortable: true,
          value: 'cpId',
          width: '10%',
        },
        {
          text: this.$t('cp.area'),
          align: 'left',
          sortable: true,
          value: 'area',
          width: '10%',
        },
        {
          text: this.$t('cp.chargeRate'),
          align: 'left',
          sortable: true,
          value: 'chargeRateMethod',
          width: '10%',
        },
        {
          text: 'Evse',
          sortable: false,
          align: 'left',
          value: 'evse',
          width: '10%',
        },
        {
          text: this.$t('cp.cpStatus'),
          align: 'center',
          sortable: false,
          value: 'state',
          width: '8%',
        },
        {
          text: `${this.$t('btn.version')}/${this.$t('cp.firmware')}`,
          align: 'center',
          sortable: false,
          value: 'firmware',
          width: '8%',
        },
        {
          text: this.$t('cp.msgLog'),
          align: 'center',
          sortable: false,
          value: 'msglogs',
          width: '5%',
        },
        {
          text: this.$t('cp.diagLog'),
          align: 'center',
          sortable: false,
          value: 'diaglogs',
          width: '5%',
        },
        {
          text: this.$t('cp.faultLog.title'),
          align: 'center',
          sortable: false,
          value: 'unCompletedFaultCount',
          width: '5%',
        },
        {
          text: this.$t('common.registration'),
          value: 'created',
          align: 'center',
          sortable: true,
          width: '10%',
        },
      ];
      if (staffInfo != null && staffInfo.permission.chargers.write) {
        headers.push({
          text: this.$t('btn.delete'),
          value: 'disable',
          sortable: false,
          align: 'center',
          width: '3%',
        });
      }
      this.headers = headers;
    },
    initFwHeaders() {
      this.fwHeaders = [];
      this.fwHeaders.push({
        text: this.$t('cp.firmFileName'),
        align: 'left',
        sortable: false,
        value: 'fileName',
      },
      {
        text: this.$t('btn.version'),
        align: 'left',
        sortable: false,
        value: 'fileVersion',
      },
      {
        text: this.$t('btn.download'),
        align: 'center',
        sortable: false,
        width: '17%',
        value: 'download',
      },
      {
        text: this.$t('btn.apply'),
        align: 'center',
        sortable: false,
        width: '15%',
        value: 'application',
      });
      if (this.fwData.isOnline && this.$checkPermission(this.staffInfo, 'chargers.write')) {
        this.fwHeaders.push(
          {
            text: this.$t('btn.delete'),
            align: 'center',
            sortable: false,
            width: '15%',
            value: 'delete',
          },
        );
      }
    },
  },
  watch: {
    staffInfo: {
      immediate: true,
      deep: true,
      async handler(value) {
        this.initFwHeaders();
        this.initHeaders(value);
      },
    },
    '$i18n.locale': {
      handler() {
        this.initHeaders(this.staffInfo);
        this.initFwHeaders();
      },
    },
    sort: {
      deep: true,
      handler(value) {
        this.options.page = 1;
        if (value.sortBy == null && value.sortDesc == null) {
          this.$emit('update:options', {
            ...this.options,
            sortBy: [],
            sortDesc: [],
          });
        } else if (value.sortBy != null && value.sortDesc != null) {
          this.$emit('update:options', {
            ...this.options,
            sortBy: [value.sortBy],
            sortDesc: [value.sortDesc],
          });
        }
      },
    },
    bulkUpdateSelectedItems: {
      handler() {
        if (this.writeDialog.form.method === 'bulk') {
          this.writeDialog.formCheck = this.updateValidChecker();
        }
      },
    },
    'writeDialog.form': {
      deep: true,
      handler(value) {
        if (value.method === 'bulk' || value.method === 'update') this.writeDialog.formCheck = this.updateValidChecker();
      },
    },
    // 'fwDialog.form': {
    //   deep: true,
    //   handler() {
    //     this.fwDialog.formCheck = this.fwValidChecker();
    //   },
    // },
    fwItem: {
      deep: true,
      handler(v) {
        this.initFwHeaders();
        if (v.length <= 0) {
          this.newFileAdd = true;
          this.clickedApplyId = null;
          this.clickedApply = false;
        } else {
          this.newFileAdd = false;
        }
        // this.fwDialog.formCheck = this.fwValidChecker();
      },
    },
    applyinstantBtn: {
      deep: true,
      handler(v) {
        if (v) {
          this.applyDate = null;
          this.applySelectTime = null;
          this.applyBtnActive = true;
        } else {
          this.applyBtnActive = false;
        }
      },
    },
    newFileAdd: {
      deep: true,
      handler(v) {
        if (v) {
          this.applyDate = null;
          this.applySelectTime = null;
          this.clickedApplyId = null;
        }
      },
    },
  },
};
</script>

<style lang="scss">
  .price_table {
    border: 1px solid #ddd;
    .v-data-table__wrapper {
      table {
        margin-top: 0px;
      }
    }
  }
  .select_item{
    color:#0069B8;
  }
  .text-start { width: 1%; }
</style>
