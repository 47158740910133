<template>
  <v-dialog
    v-model="show"
    width="auto"
    @click:outside="init()"
    @keydown="$event.key === 'Escape' ? init() : ''"
  >
    <v-card class="log-wrap">
      <p class="tit align_center">
        {{ $t('user.payment.payLog') }}
        <span class="sub ml-1" v-if="cardNum != null">
          {{ cardNum }}
        </span>
      </p>
      <div class="d-flex align-end justify-end mb-1">
        <v-btn
          class="px-2"
          color="primary"
          depressed
          :disabled="loading"
          @click="getDate(_id)"
          small
        >
          <v-icon small class="mr-1">mdi-refresh</v-icon>
          {{ $t('btn.refresh') }}
        </v-btn>
      </div>
      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items="logs"
        :loading="loading"
        disable-pagination
        hide-default-footer
        dense
        class="data_table"
        checkbox-color="primary"
        item-key="_id"
        :options="options"
      >
        <template v-slot:item.created="{ item }">
          {{ time.makeLocalTime(item.created) }}
        </template>
        <template v-slot:item.action="{ item }">
          {{ $t(`user.payment.logAction.${item.action}`) }}
        </template>
        <template v-slot:item.result="{ item }">
          {{ $t(`user.payment.${item.result}`) }}
        </template>
        <template v-slot:item.amount="{ item }">
          <p>
            {{ item.amount }}
            <v-menu
              v-if="
                $checkPermission(staffInfo, 'payment_logs.write')
                && item.amount > 0
                && item.pgIdExists
              "
              rounded="lg"
              transition="slide-y-transition"
              offset-x
              :close-on-content-click="false"
              width="300"
            >
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip top color="black">
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      class="ml-1"
                      @click="currentLogId = item._id"
                      icon
                    >
                      <v-icon>mdi-undo</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('user.payment.logAction.PartialCancel') }}
                  </span>
                </v-tooltip>
              </template>
              <div
                style="background-color: white;"
                class="pa-3"
              >
                <p class="tit align_center">
                  {{ $t('user.payment.logAction.PartialCancel') }}
                </p>
                <div class="align_center mb-2">
                  <v-chip class="mr-2 flex-shrink-0" label color="primary">
                    {{ $t('point.amount') }}
                  </v-chip>
                  <v-text-field
                    hide-details
                    v-model="cancelPrice"
                    type="number"
                    :rules="[cancelPrice < item.amount]"
                  />
                </div>
                <div class="align_center mb-2">
                  <v-chip class="mr-2 flex-shrink-0" label color="primary">
                    {{ $t('point.reason') }}
                  </v-chip>
                  <v-text-field
                    hide-details
                    :value="cancelReason"
                    @change="cancelReason = $event"
                  />
                </div>
                <div class="align_center mb-5">
                  <v-chip class="mr-2 flex-shrink-0" label color="primary">
                    {{ $t('admin_setting.adminName') }}
                  </v-chip>
                  <v-text-field
                    hide-details
                    disabled
                    :value=staffInfo.name
                  />
                </div>
                <div style="display: flex; justify-content: flex-end;">
                  <v-btn
                    color="primary"
                    @click="saveCancel"
                    :disabled="!formcheck"
                  >
                    {{ $t('btn.apply') }}
                  </v-btn>
                </div>
              </div>
            </v-menu>
          </p>
        </template>
        <template v-slot:no-data>
          <p>{{ $t('common.noData') }}</p>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import time from '@/util/time';

export default {
  name: 'DlgPayLog',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    _id: {
      type: String,
    },
    cardNum: {
      type: String,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    time,
    loading: false,
    headers: null,
    options: {
      page: 1,
      itemsPerPage: 0,
      sortBy: ['created'],
      sortDesc: [true],
    },
    logs: [],
    cancelPrice: 0,
    cancelReason: null,
    currentLogId: null,
    formcheck: false,
  }),
  methods: {
    fwValidChecker() {
      if ((this.cancelPrice <= 0)) return false;
      if (this.cancelReason == null || this.cancelReason === '') return false;
      return true;
    },
    init() {
      this.$emit('update:show', false);
      this.$emit('update:_id', null);
      this.initHeaders();
      this.cancelPrice = 0;
      this.cancelReason = null;
    },
    initHeaders() {
      this.headers = [
        {
          text: this.$t('common.registration'),
          sortable: true,
          value: 'created',
        },
        {
          text: this.$t('transaction.status'),
          sortable: true,
          value: 'action',
        },
        {
          text: this.$t('point.reason'),
          sortable: true,
          value: 'reason',
        },
        {
          text: this.$t('user.payment.result'),
          sortable: true,
          value: 'result',
        },
        {
          text: this.$t('point.amount'),
          sortable: true,
          value: 'amount',
        },
      ];
    },
    async getDate(_id) {
      this.loading = true;
      try {
        const { item } = await this.$emitter('payment.get', {
          _id,
        });
        this.logs = item.logs;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    async saveCancel() {
      try {
        await this.$dialog.confirm({
          show: true,
          message: this.$t('common.pgCancel'),
          color: 'primary',
          btnText: this.$t('btn.apply'),
          callback: () => {
            this.$dialog.confirm();
            this.$dialog.alert('success', this.$t('common.applyCompleteMessage'));
            this.$emitter('payment.pg.cancel', {
              _id: this.currentLogId,
              amount: Number(this.cancelPrice),
              reason: this.cancelReason,
            });
            this.init();
          },
        });
        this.callback();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
  },
  watch: {
    staffInfo: {
      immediate: true,
      async handler() {
        this.initHeaders();
      },
    },
    _id: {
      immediate: true,
      async handler(value) {
        if (value != null) {
          this.initHeaders();
          await this.getDate(value);
        }
      },
    },
    cancelPrice: {
      immediate: true,
      handler() {
        this.formcheck = this.fwValidChecker();
      },
    },
    cancelReason: {
      immediate: true,
      handler() {
        this.formcheck = this.fwValidChecker();
      },
    },
  },
};
</script>
