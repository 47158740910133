<template>
  <div class="box_wrap">
    <gmap-map class="map_box" :center="center" :zoom="13">
      <gmap-info-window
        :options="{
          pixelOffset: { width: 0, height: -40 },
        }"
        stye="margin-bottom: 100px;"
        :position="infoWindow.position"
        :opened="infoWindow.show"
        @closeclick="infoWindowInit()"
      >
        <div v-if="infoWindow.area != null">
          <h3 class="mt-1">{{ infoWindow.area.name }}</h3>
          <p class="mb-2">{{ infoWindow.area.address }}</p>
          <div v-if="infoWindow.area.evse.length <= 0">없음</div>
          <div v-else style="max-height: 300px;">
            <div
              v-for="evse in infoWindow.area.evse"
              :key="evse.evseNumber"
              class="mb-1"
            >
              <div class="align_center">
                <v-chip
                  label
                  x-small
                  class="px-1 mr-1"
                  :color="evse.model.type === 'DC' ? 'success' : 'accent'"
                >
                  {{ evse.model.type }}
                </v-chip>
                <!-- 비정상 -->
                <v-tooltip
                  top
                  color="black"
                  v-if="isAbleToCharge(evse) === 2"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-on="on" v-bind="attrs"
                      label
                      color="error"
                      outlined
                      x-small
                      class="px-1"
                    >
                      {{ $t('common.cp_state.state.unavailable') }}
                    </v-chip>
                  </template>
                  <span class="small-text">
                    <!-- 온라인 상태 -->
                    <span>
                      {{ $t('cp.online') }}:
                      {{
                        evse.online == null || evse.online === ''
                        ? '-' : evse.online
                      }}
                    </span><br/>
                    <!-- 상태 -->
                    <span>
                      {{ $t('transaction.status') }}:
                      {{
                        evse.state.status == null || evse.state.status === ''
                        ? '-' : evse.state.status
                      }}
                    </span><br/>
                    <span>
                    <!-- 가용상태 -->
                      {{ $t('cp.availability') }}:
                      {{
                        evse.state.availability == null
                        || evse.state.availability === ''
                        ? '-' : evse.state.availability
                      }}
                    </span><br/>
                    <!-- 에러정보 -->
                    <span>
                      {{ $t('cp.errorInfo') }}:
                      {{
                        evse.state.error == null || evse.state.error === ''
                        ? '-' : evse.state.error
                      }}
                    </span><br/>
                  </span>
                </v-tooltip>
                <!-- 사용중 -->
                <v-chip
                  v-else-if="isAbleToCharge(evse) === 1"
                  label
                  color="info"
                  x-small
                  class="px-1"
                >
                  {{ $t('cp.operation') }}
                </v-chip>
                <!-- 사용가능 -->
                <v-chip
                  v-else-if="isAbleToCharge(evse) === 0"
                  label
                  color="success"
                  outlined
                  x-small
                  class="px-1"
                >
                  {{ $t('common.cp_state.state.available') }}
                </v-chip>
                <h4 class="ml-1 grey--text" v-if="evse.evseNumber == null">
                  {{ $t('common.emptyInfo') }}
                </h4>
                <h4 class="ml-1" v-else>
                  {{ evse.evseNumber }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </gmap-info-window>
      <p :position="center">sdfasdf</p>
      <gmap-marker
        v-for="(mark,idx) in markers"
        :key="idx"
        :position="mark.geolocation.position"
        :clickable="true"
        @click="infoWindowOpen(mark)"
        :icon="
          mark.availableEvseCount > 0 ? normalIcon :
          mark.inUseEvseCount > 0 ? checkIcon : errorIcon
        "
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapGetters } from 'vuex';
import normalImg from '@/assets/images/marker-active.png';
import errorImg from '@/assets/images/marker-error.png';
import checkImg from '@/assets/images/marker-checked.png';
import utilFile from '@/util/file';

export default {
  name: 'MapBox',
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  props: {
    markers: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    utilFile,
    center: {
      lat: 37.4009254,
      lng: 126.9912238,
    },
    normalIcon: {
      url: normalImg,
      scaledSize: { width: 50, height: 50 },
    },
    checkIcon: {
      url: checkImg,
      scaledSize: { width: 50, height: 50 },
    },
    errorIcon: {
      url: errorImg,
      scaledSize: { width: 50, height: 50 },
    },
    infoWindow: {
      show: false,
      position: null,
      area: null,
    },
    iconState: null,
  }),
  methods: {
    infoWindowInit() {
      this.infoWindow = {
        show: false,
        position: null,
        area: null,
      };
    },
    async infoWindowOpen(area) {
      try {
        const { item } = await this.$emitter('area.get', {
          _id: area._id,
        });
        this.infoWindow = {
          show: true,
          position: item.geolocation.position,
          area: item,
        };
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    isAbleToCharge(evse) {
      if (
        evse.chargepointOnline === true
        && evse.chargepointState.availability === 'Operative'
        && evse.state.availability === 'Operative'
        && evse.state.online === true
        && evse.state.status === 'Available'
      ) return 0; // 충전가능
      if (
        evse.chargepointOnline === true
        && evse.chargepointState.availability === 'Operative'
        && evse.state.availability === 'Operative'
        && evse.state.online === true
        && [
          'Preparing', 'Charging', 'SuspendedEVSE', 'SuspendedEV', 'Finishing', 'Reserved',
        ].includes(evse.state.status)
      ) return 1; // 충전중
      return 2; // 충전불가
    },
  },
};
</script>
<style lang="scss" scoped>
.box_wrap {
  height: 100%;
  .map_box{
    width:100%;
    height:100%;
  }
}
</style>
