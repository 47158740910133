<template>
  <ProblemSection/>
</template>

<script>
import ProblemSection from '@/components/community/problem/ProblemSection.vue';

export default {
  name: 'Problem',
  components: { ProblemSection },
};
</script>
