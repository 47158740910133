/* eslint-disable no-await-in-loop */
import Vue from 'vue';
import config from '../config/config.json';
import store from '../store';

const payloadUnit = 1500;

function combineBuffer(buffers) {
  const size = buffers.reduce((total, buf) => (total + buf.byteLength), 0);
  const bytes = new Uint8Array(size);

  let offset = 0;
  buffers.forEach((buf) => {
    bytes.set(new Uint8Array(buf), offset);
    offset += buf.byteLength;
  });

  return bytes;
}

function downloadFile(bytes, filename) {
  const blob = new Blob([bytes]);
  const url = URL.createObjectURL(blob);

  const tag = document.createElement('a');
  tag.href = url;
  tag.download = filename;

  document.body.appendChild(tag);

  tag.style.display = 'none';
  tag.click();
  tag.remove();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 1000);
}

function emitter(eventName, body) {
  return new Promise((resolve, reject) => {
    (new Vue()).$socket.emit(eventName, body, (response) => {
      if (response.result === 'success') {
        resolve(response);
      } else {
        reject(response);
      }
    });
  });
}

export default {
  async readFileToPayload(file) {
    const data = [];
    const buf = await file.arrayBuffer();
    const last = buf.byteLength;
    for (let idx = 0; idx < last; idx += payloadUnit) {
      data.push(buf.slice(idx, idx + payloadUnit));
    }

    return {
      name: file.name,
      size: buf.byteLength,
      data,
    };
  },
  async writeFileFromPayload(payload) {
    const data = combineBuffer(payload.data);
    downloadFile(data, payload.name);
  },
  imagePathToSrc(path) {
    return `${config.server.path}${path.substring(1)}`;
  },
  arrayBufferToBase64(data) {
    const contentType = 'image/png';
    const blob = new Blob(data, { type: contentType });
    return window.URL.createObjectURL(blob);
  },
  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  },
  async fileUploader({ file, type, firmwareType }) {
    const { name, data: _data, size } = file;
    const chunkSize = 100;
    const currentIndex = store.getters['file/uploadTasks'].length;
    for (let i = 0; i < chunkSize; i += 1) {
      const start = i * (_data.length / chunkSize);
      const end = (i + 1) * (_data.length / chunkSize);
      const data = _data.slice(start, end);

      if (i === 0) {
        store.commit('file/uploadTasks', [
          ...store.getters['file/uploadTasks'],
          {
            size,
            name,
          },
        ]);
        await emitter('file.add.progress.start', {
          data,
          size,
          name,
          type,
          firmwareType,
        });
      } else {
        await emitter('file.add.progress', {
          data,
        });
      }
    }
    const result = await emitter('file.add.progress.end', {
      length: _data.length,
      type,
    });
    // 업로드가 완료된 파일 삭제
    store.commit(
      'file/uploadTasks',
      store.getters['file/uploadTasks'].filter((_, i) => i !== currentIndex),
    );
    return result;
  },
  byteTrans(x) {
    const units = ['byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0;
    let n = parseInt(x, 10) || 0;
    while (n >= 1024 && (l + 1)) {
      n /= 1024;
      l += 1;
    }
    return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
  },
};
