<template>
  <div class="data_table_wrap">
    <div class="align_center justify-space-between">
      <div class="align_center">
        <div class="body-2">asdf
          <b v-if="pageOptions.total <= 0">0</b>
          <b v-else class="primary--text">
            {{
              options.page === 1
              ? items.length
              : ((options.page - 1) * options.itemsPerPage) + items.length
            }}
          </b>
          /
          <span>{{ pageOptions.total }}</span>
        </div>
      </div>
      <v-btn-toggle
        class="my-2"
        v-if="pageOptions.itemsPerPageOptions != null && pageOptions.itemsPerPageOptions.length > 0"
        @change="updateOptions('itemsPerPage', $event)"
        mandatory
        dense
      >
        <v-btn v-for="number of pageOptions.itemsPerPageOptions" :key="number">
          {{ `${number}${$t('dataTable.itemsPerPageOption')}` }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-data-table
      mobile-breakpoint="0"
      fixed-header
      v-if="items !== null"
      :headers="headers"
      :items="items"
      :loading = "loading"
      :options="options"
      @update:sort-by="sort.sortBy = $event"
      @update:sort-desc="sort.sortDesc = $event"
      disable-pagination
      hide-default-footer
      dense
      class="data_table"
      item-key="_id"
    >
      <template v-slot:item.name="{ item }">
        <div v-if="Object.keys(item.userInfo).length > 0">
          <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ format.makeMaskName(item.userInfo.name) }}
            </span>
          </template>
          <span>{{ item.userInfo.name }}</span>
        </v-tooltip>
        </div>
        <div v-else>
          <p>{{ $t('common.emptyInfo') }}</p>
        </div>
      </template>
      <template v-slot:item.level="{ item }">
        <p v-if="Object.keys(item.userInfo).length > 0">
          {{$t(`userGrades.${item.userInfo.grade}`)}}
        </p>
        <p v-else>
          -
        </p>
      </template>
      <template v-slot:item.phone="{ item }">
        <div v-if="Object.keys(item.userInfo).length > 0">
          <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ format.makeMaskPhoneNumber(item.userInfo.phone) }}
            </span>
          </template>
          <span>{{ item.userInfo.phone }}</span>
        </v-tooltip>
        </div>
        <div v-else>
          <p>-</p>
        </div>
      </template>
      <template v-slot:item.cardName="{ item }">
        <div v-if="Object.keys(item.userInfo).length > 0">
          <p
            v-if="item.userInfo.userCard.cardName !== ''
            || item.userInfo.userCard.cardName !== null"
          >
            {{ item.userInfo.userCard.cardName }}
          </p>
          <p v-else>
            -
          </p>
        </div>
        <div v-else>
          <p>
            -
          </p>
        </div>
      </template>
      <template v-slot:item.idTag="{ item }">
        <div v-if="Object.keys(item.userInfo).length > 0">
          <p
            v-if="item.userInfo.userCard.cardNo !== ''
            || item.userInfo.userCard.cardNo !== null"
          >
            {{ item.userInfo.userCard.cardNo }}
          </p>
          <p v-else>
            -
          </p>
        </div>
        <div v-else-if="Object.keys(item).includes('vanCardNo')">
          <p>
            {{ item.vanCardNo }}
          </p>
        </div>
        <div v-else>
          <p>
            -
          </p>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <p>
          {{
            item.action == null || item.action === ''
            ? '-' : $t(`user.payment.paymentAction.${item.action}`)
          }}
        </p>
      </template>
      <template
        v-slot:item.payLog="{ item }"
        v-if="$checkPermission(staffInfo, 'payment_logs.write')"
      >
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="Object.keys(item.userInfo).length > 0"
              icon
              v-bind="attrs"
              v-on="on"
              @click="paylogDialog = {
                _id: item._id,
                cardNum: item.userInfo.userCard.cardNo,
                show: true,
              }"
            >
              <v-icon small color="primary">mdi-text-box</v-icon>
            </v-btn>
            <v-btn
              v-if="Object.keys(item.userInfo).length <= 0"
              icon
              v-bind="attrs"
              v-on="on"
              @click="paylogDialog = {
                _id: item._id,
                cardNum: null,
                show: true,
              }"
            >
              <v-icon small color="primary">mdi-text-box</v-icon>
            </v-btn>
          </template>
          <span>
            {{ `${$t('user.payment.payLog')} ${$t('common.view')}` }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.created="{ item }">
        {{ time.makeLocalTime(item.created) }}
      </template>
      <template v-slot:no-data>
        <p>{{ $t('common.noData') }}</p>
      </template>
    </v-data-table>
    <v-pagination
      small
      depressed
      v-show="pageOptions.pageCount > 0"
      :value="options.page"
      :length="pageOptions.pageCount"
      :total-visible="10"
      @input="updateOptions('page', $event)"
    ></v-pagination>
    <DlgPayLog
      :show.sync="paylogDialog.show"
      :cardNum.sync="paylogDialog.cardNum"
      :_id.sync="paylogDialog._id"
      :callback="callback"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import time from '@/util/time';
import format from '@/util/format';
import DlgPayLog from '@/components/dialog/DlgPayLog.vue';

export default {
  components: {
    DlgPayLog,
  },
  props: {
    items: {
      type: Array || null,
      default: null,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    pageOptions: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    time,
    format,
    paylogDialog: {
      _id: null,
      cardNum: null,
      show: false,
    },
  }),
  methods: {
    updateOptions(key, value) {
      let options = {
        ...this.options,
        [key]: value,
      };
      if (key === 'itemsPerPage') {
        options = {
          ...this.options,
          page: 1,
          itemsPerPage: this.pageOptions.itemsPerPageOptions[value],
        };
      }
      this.$emit('update:options', options);
    },

    initHeaders(staffInfo = null) {
      const headers = [
        {
          text: this.$t('user.name'),
          sortable: true,
          value: 'name',
          width: '3%',
        },
        {
          text: this.$t('cp.amountByGrade.level'),
          sortable: true,
          value: 'level',
          width: '3%',
        },
        {
          text: this.$t('user.phone'),
          sortable: false,
          value: 'phone',
          width: '5%',
        },
        {
          text: this.$t('user.payment.cardName'),
          sortable: false,
          value: 'cardName',
          width: '3%',
          align: 'center',
        },
        {
          text: this.$t('idTag.idTag'),
          sortable: false,
          value: 'idTag',
          width: '10%',
        },
        {
          text: this.$t('user.payment.paymentState'),
          value: 'action',
          sortable: false,
          width: '5%',
        },
        {
          text: this.$t('user.payment.totalMount'),
          value: 'totalAmount',
          sortable: false,
          width: '5%',
          align: 'center',
        },
        {
          text: this.$t('common.registration'),
          value: 'created',
          width: '10%',
          align: 'center',
        },
      ];
      if (staffInfo != null && staffInfo.permission.chargers.write) {
        headers.splice(headers.length - 1, 0,
          {
            text: this.$t('user.payment.payLog'),
            align: 'center',
            value: 'payLog',
            sortable: false,
            width: '5%',
          });
      }
      this.headers = headers;
    },
  },
  watch: {
    staffInfo: {
      immediate: true,
      async handler(value) {
        this.initHeaders(value);
      },
    },
    '$i18n.locale': {
      handler() {
        this.initHeaders(this.staffInfo);
      },
    },
    sort: {
      deep: true,
      handler(value) {
        this.options.page = 1;
        if (value.sortBy == null && value.sortDesc == null) {
          this.$emit('update:options', {
            ...this.options,
            sortBy: [],
            sortDesc: [],
          });
        } else if (value.sortBy != null && value.sortDesc != null) {
          this.$emit('update:options', {
            ...this.options,
            sortBy: [value.sortBy],
            sortDesc: [value.sortDesc],
          });
        }
      },
    },
  },
};
</script>
