<template>
  <div class="notice_section_wrap section_wrap">
    <SectionHeader
      :loading="loading"
      :buttons="buttons"
      :refresh="getPostData"
    />
    <SearchFilter
      :filters.sync="options.filters"
      :loading="loading"
      :searchKeys="searchKeys"
      :callback="getPostData"
      :options.sync="options"
    />
    <NoticeDataTable
      :items.sync="items"
      :loading="loading"
      :options.sync="options"
      :pageOptions="pageOptions"
      :callback="getPostData"
    />
    <DlgCommonWrite
      :show.sync="writeDialog.show"
      :title="writeDialog.title"
      :sub="writeDialog.sub"
      :type="writeDialog.type"
      :formCheck="writeDialog.formCheck"
      @submit="writeDialog.submit"
    >
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{$t('common.title')}}*
        </v-chip>
        <div style="width: 100%">
          <v-text-field
            :placeholder="$t('placeholders.board.title')"
            v-model="writeDialog.form.title"
            outlined
            color="black"
            dense
            flat
            solo
            hide-details
            maxlength="64"
          />
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('common.img') }}
        </v-chip>
        <div>
          <input
            v-show="false"
            type="file"
            ref="fileInput"
            :multiple="maxCount > 1"
            @change="fileChange({
              event: $event,
              maxCount,
              maxSize,
              accepts: ['png', 'jpg', 'zip'],
              target: 'files',
            })"
          />
          <v-btn
            v-if="files.length < maxCount"
            elevation
            dense
            outlined
            @click="fileClick({
              target: 'files',
              ref: 'fileInput',
            })"
          >
            <v-icon class="mr-1">
              mdi-image-outline
            </v-icon>
            {{ $t('common.file') }}
          </v-btn>
          <div class="registrationFile" v-if="files.length > 0">
            <div
              class="align_center"
              v-for="(file, index) in files"
              :key="index"
            >
              <img
                v-if="file.name.includes('.png') || file.name.includes('jpg')"
                :src="utilFile.arrayBufferToBase64(files[index].data)"
                style="width:40px"
              />
              <v-chip
                close
                class="mr-3 my-1 ml-1"
                @click:close="fileRemove({
                  target: 'files',
                  file,
                })"
              >
                <strong>{{ file.name }}</strong>
                <span>({{ utilFile.byteTrans(file.size) }})</span>
              </v-chip>
            </div>
          </div>
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{$t('common.content')}}*
        </v-chip>
        <div style="width: 100%">
          <Editor
            ref="editor"
            height="280px"
            initialEditType="wysiwyg"
            :initialValue="null"
            :options="{
              language: 'ko',
            }"
            @change="handleEditorChange"
            :key="writeDialog.show"
          />
        </div>
      </div>
    </DlgCommonWrite>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapGetters } from 'vuex';
import { Editor } from '@toast-ui/vue-editor';
import utilFile from '@/util/file';
import SectionHeader from '../../common/SectionHeader.vue';
import SearchFilter from '../../common/SearchFilter.vue';
import NoticeDataTable from './unit/NoticeDataTable.vue';
import DlgCommonWrite from '../../dialog/DlgCommonWrite.vue';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import '@toast-ui/editor/dist/i18n/ko-kr';

export default {
  name: 'NoticeSection',
  components: {
    SectionHeader,
    SearchFilter,
    NoticeDataTable,
    DlgCommonWrite,
    Editor,
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    utilFile,
    maxCount: 3,
    maxSize: 10485760,
    files: [],
    registrationImg: [],
    loading: false,
    items: [],
    searchKeys: [
      {
        i18nKey: 'common.title',
        key: 'title',
      },
      {
        i18nKey: 'common.writer',
        key: 'writer',
      },
    ],
    buttons: [
      {
        color: 'primary',
        icon: 'mdi-plus',
        click: 'openWriteDialog',
        i18nText: 'notice.newNotice',
        permission: 'notice.write',
      },
    ],
    writeDialog: {
      show: false,
      title: '',
      sub: null,
      type: 'regist',
      formCheck: false,
      submit: () => {},
      form: {
        title: null,
        content: null,
      },
    },
    options: {
      page: 1,
      itemsPerPage: 2,
      sortBy: ['created'],
      sortDesc: [true],
      filters: [
        {
          where: 'and',
          condition: 'eq',
          key: 'boardCode',
          value: 'notice',
        },
      ],
    },
    pageOptions: {
      total: 0,
      pageCount: 0,
      itemsPerPageOptions: [10, 50],
    },
    editorActive: false,
    test: null,
  }),
  methods: {
    async getPostData() {
      this.loading = true;
      try {
        const {
          itemsPerPage,
          filters,
        } = this.options;
        const { data: { total } } = await this.$emitter('post.summary.get', { filters });
        this.pageOptions.total = total;
        this.pageOptions.pageCount = Math.ceil(total / itemsPerPage);
        const resultData = await this.$emitter('post.list.get', {
          page: 1,
          itemsPerPage: 0,
          sortBy: ['created'],
          sortDesc: [true],
          filters,
        });
        this.items = resultData.items;
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.loading = false;
    },
    refreshPostData() {
      this.getPostData();
    },
    async addNotice() {
      this.$dialog.progress(true);
      const {
        title,
      } = this.writeDialog.form;
      const content = this.$refs.editor.invoke('getMarkdown');
      if (this.files.length < 0) {
        try {
          await this.$emitter('post.add', {
            items: [{
              boardCode: 'notice',
              title,
              content,
            }],
          }, `${this.$t('common.registCompleteMessage')}`);
          this.initWriteDialog();
          await this.getPostData();
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
        this.$dialog.progress(false);
      } else {
        try {
          await this.$emitter('post.add', {
            items: [{
              boardCode: 'notice',
              title,
              content,
              files: this.registrationImg,
            }],
          }, `${this.$t('common.registCompleteMessage')}`);
          this.initWriteDialog();
          await this.getPostData();
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
        this.$dialog.progress(false);
      }
    },
    initWriteDialog() {
      this.writeDialog = {
        show: false,
        title: this.$t('notice.newNotice'),
        sub: null,
        type: 'regist',
        formCheck: false,
        submit: () => {},
        form: {
          title: null,
          content: null,
        },
      };
    },
    async openWriteDialog() {
      this.initWriteDialog();
      this.writeDialog.show = true;
      this.writeDialog.submit = this.addNotice;
    },

    handleEditorChange() {
      const editorInstance = this.$refs.editor.invoke('getMarkdown');
      this.editorActive = editorInstance.trim() !== '';
      this.writeDialog.form.content = editorInstance;
    },

    checkNoticeWriteValid(form) {
      const {
        title,
      } = form;
      if (this.writeDialog.show !== true) return false;
      if (title == null) return false;
      if (!this.editorActive) return false;
      return true;
    },

    fileClick({
      target,
      maxCount,
      ref,
    }) {
      this.$refs[ref].value = null;
      if (this[target].length === maxCount) {
        this.$dialog.alert('error', `${this.$t('common.fileErrorFront')} ${maxCount} ${this.$t('common.fileErrorBack')}`);
      } else {
        this.$refs[ref].click();
      }
    },
    async fileChange({
      event,
      maxCount,
      maxSize,
      accepts,
      target,
    }) {
      this.$dialog.progress(true);
      const { files: selectedFiles } = event.target;
      if (selectedFiles.length + this[target].length > maxCount) {
        this.$dialog.alert('error', `${this.$t('common.fileErrorFront')} ${maxCount} ${this.$t('common.fileErrorBack')}`);
      } else if (selectedFiles.length > 0) {
        const filesArray = Object.values(selectedFiles);
        const invalidFileCheck = filesArray.reduce((acc, file) => {
          let isAccept = false;
          for (const accept of accepts) {
            if (file.name.includes(`.${accept}`)) isAccept = true;
          }
          if (isAccept === false && isAccept.length) acc.accept += 1;
          if (file.size > maxSize) acc.size += 1;
          return acc;
        }, {
          size: 0,
          accept: 0,
        });
        if (invalidFileCheck.accept > 0) {
          const types = accepts.length <= 0 ? accepts[0] : accepts.join(', ');
          this.$dialog.alert('error', `${this.$t('common.fileErrorExtensions')} [${types}]`);
        } else if (invalidFileCheck.size > 0) {
          this.$dialog.alert('error', `
            ${this.$t('common.fileErrorMaximumFront')} ${utilFile.byteTrans(maxSize)} ${this.$t('common.fileErrorMaximumBack')}
          `);
        } else {
          const map = filesArray.map((file) => utilFile.readFileToPayload(file));
          const filePayloads = await Promise.all(map);
          this[target] = [
            ...filePayloads,
            ...this[target],
          ];
          this.registrationImg.push(...filePayloads);
        }
      }
      this.$dialog.progress(false);
    },
    fileRemove({
      target,
      file,
    }) {
      this[target] = this[target].filter((currentFile) => currentFile.name !== file.name);
      this.registrationImg = this[target];
    },
  },
  watch: {
    staffInfo: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value != null) {
          this.getPostData();
        }
      },
    },
    options: {
      deep: true,
      async handler() {
        if (this.staffInfo !== null) await this.getPostData();
      },
    },
    'writeDialog.form': {
      deep: true,
      handler(value) {
        this.writeDialog.formCheck = this.checkNoticeWriteValid(value);
      },
    },
    'options.filters': {
      deep: true,
      handler() {
      },
    },
    'writeDialog.show': {
      immediate: true,
      handler(value) {
        if (!value) {
          if (this.writeDialog.form.content !== null) {
            this.writeDialog.form = { title: null, content: null };
          }
        }
      },
    },
  },
};
</script>
