// history 월 이동 함수 (type = prev, next, date = 2023-10, tab = 0 : month 1: year)
export const navigateMonth = (type, date, tab) => {
  if (tab === 0) {
    const [year, month] = date.split('-');
    let searchMonth = type === 'prev' ? +(month) - 1 : +(month) + 1;
    let searchYear = +(year);
    if (type === 'prev') {
      if (searchMonth < 1) {
        searchMonth = 12;
        searchYear -= 1;
      }
    } else if (searchMonth > 12) {
      searchMonth = 1;
      searchYear += 1;
    }
    if (searchYear < 2022) {
      searchYear = 2022;
      searchMonth = 1;
    }
    // padStart(maxLength, 'fillString')는 문자열 길이를 maxLength로 유지하고, 앞에 fillString을 추가하는 함수
    const formattedMonth = searchMonth.toString().padStart(2, '0');
    return `${searchYear}-${formattedMonth}`;
  }
  if (tab === 1) {
    let searchYear = +(date);
    if (type === 'prev') searchYear -= 1;
    else if (type === 'next') searchYear += 1;
    return searchYear < 2022 ? 2022 : searchYear;
  }
  return 0;
};

// 최근 3개월 (date = 2024-03)
export const getPrevMonth = (date) => {
  const currentDate = new Date(date);
  currentDate.setMonth(currentDate.getMonth() - 3);
  return currentDate.toISOString().slice(0, 7);
};
