import i18n from '../i18n';

function makeErrorMessage({ code, name }) {
  if (code == null) return i18n.t('common.errorMessage');
  let message = `${i18n.t('common.errorMessage')}`;

  if ([204, 429, 500].indexOf(code) !== -1) {
    message = i18n.t(`common.errorMessages.${code}`);
  } else if (
    i18n.t(`common.errorMessages.${code}.${name}`)
    !== `common.errorMessages.${code}.${name}`
  ) {
    message = i18n.t(`common.errorMessages.${code}.${name}`);
  }
  return message;
}

export default {
  makeErrorMessage,
};
