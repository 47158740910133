import SocketIO from 'socket.io-client';
import VueSocketIO from 'vue-socket.io';
import store from '../store';
import config from '../config/config.json';
import Error from '../util/error';

const connection = SocketIO(config.server.path, {
  reconnectionDelayMax: 1000,
  reconnectionAttempts: 3,
  transports: ['websocket'],
});

const CommSocket = new VueSocketIO({
  debug: !(process.env.NODE_ENV === 'production'),
  connection,
  vuex: {
    store,
    actionPrefix: 'socket.',
    mutationPrefix: 'socket.',
  },
  options: {
    transports: ['websocket'],
    secure: true,
  },
});

const errorOptions = [
  'error',
  Error.makeErrorMessage({
    code: 500,
  }),
  true,
  null,
];

async function socketDisconnectedCallback() {
  store.commit('auth/staffInfo', null);
  store.commit('dialog/alert', { ...store.getters['dialog/alert'], show: false });
  store.commit('dialog/system', {
    show: true,
    type: errorOptions[0],
    icon: 'mdi-connection',
    message: errorOptions[1],
  });
  setTimeout(() => {
    connection.connect();
  }, 5000);
}

connection.on('connect', async () => {
  store.commit('dialog/progress', true);
  store.commit('dialog/alert', { ...store.getters['dialog/alert'], show: false });
  store.commit('dialog/system', {
    show: false,
    type: 'error',
    icon: null,
    message: null,
  });
  console.info('Socket Connected.');
  await store.dispatch('auth/initialization');
});

connection.io.on('error', async () => {
  store.commit('dialog/progress', true);
  if (!store.getters['dialog/alert'].show && !store.getters['dialog/system'].show) {
    store.dispatch('dialog/alert', errorOptions);
  }
});

connection.io.on('reconnect_failed', async () => { await socketDisconnectedCallback(); });
connection.io.on('disconnect', async () => { await socketDisconnectedCallback(); });

export default CommSocket;
