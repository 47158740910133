<template>
  <UserSection/>
</template>

<script>
import UserSection from '@/components/user/users/UsersSection.vue';

export default {
  name: 'User',
  components: { UserSection },
  methods: {
  },
};
</script>
