<template>
  <div class="data_table_wrap">
    <div class="align_center justify-space-between">
      <div class="body-2">
        <b v-if="pageOptions.total <= 0">0</b>
        <b v-else class="primary--text">
          {{
            options.page === 1
            ? items.length
            : ((options.page - 1) * options.itemsPerPage) + items.length
          }}
        </b>
        /
        <span>{{ pageOptions.total }}</span>
      </div>
      <v-btn-toggle
        class="my-2"
        v-if="pageOptions.itemsPerPageOptions != null && pageOptions.itemsPerPageOptions.length > 0"
        @change="updateOptions('itemsPerPage', $event)"
        mandatory
        dense
      >
        <v-btn v-for="number of pageOptions.itemsPerPageOptions" :key="number">
          {{ `${number}${$t('dataTable.itemsPerPageOption')}` }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-data-table
      mobile-breakpoint="0"
      fixed-header
      :headers="headers"
      :items="items"
      :loading="loading"
      :options="options"
      @update:sort-by="sort.sortBy = $event"
      @update:sort-desc="sort.sortDesc = $event"
      disable-pagination
      hide-default-footer
      dense
      class="data_table"
    >
      <template v-slot:item.name="{ item }">
        <v-chip
          v-if="item.pncConfig.active === true"
          x-small label color="success" class="px-1"
        >
          PNC
        </v-chip>
        <div>
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ format.makeMaskName(item.name) }}
              </span>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.grade="{ item }">
        <span>{{ gradeTexts[item.grade] }}</span>
        <v-menu
          bottom
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="$checkPermission(staffInfo, 'rf_issuance.write')"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small color="primary">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(grade, idx) in gradeTexts.slice(1)"
              :key="idx"
              @click="updateGrade(item._id, idx)"
              :disabled="idx + 1 === item.grade"
            >
              <v-list-item-title>{{ grade }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.phone="{ item }">
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ format.makeMaskPhoneNumber(item.phone) }}
            </span>
          </template>
          <span>{{ item.phone }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.email="{ item }">
        <div>
          <v-chip x-small label outlined class="px-2">{{ item.type }}</v-chip>
          <p>{{ item.email }}</p>
        </div>
      </template>
      <template v-slot:item.address="{ item }">
        <p v-if="item.address == null" class="smT">
          {{ $t('common.unregistered') }}
        </p>
        <div v-else class="smT">
          <p v-if="item.address.zipCode !== null">{{ item.address.zipCode }}</p>
          <p v-if="item.address.address !== null">{{ item.address.address }}</p>
          <p v-if="item.address.detailAddress !== null">{{ item.address.detailAddress }}</p>
        </div>
      </template>
      <template v-slot:item.idTag="{ item }">
        <!-- 멤버쉽 카드 -->
        <div>
          <v-chip x-small label class="px-1">{{ $t('user.idTag') }}</v-chip>
          <div v-if="item.idTag != null">
            {{ item.idTag.idTag }}
            <v-tooltip
              top
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="$checkPermission(staffInfo, 'rf_issuance.write')"
                  icon
                  x-small
                  class="ml-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="writeDialog = {
                    show: true,
                    title: `${$t('user.idTag')} ${$t('common.update')}`,
                    sub: format.makeMaskName(item.name),
                    idTag: item.idTag.idTag,
                    type: 'update',
                    userNo: null,
                    submit: updateIdTag,
                    _id: item.idTag._id,
                  }"
                >
                  <v-icon small color="primary">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>
                {{ `${$t('user.idTag')} ${$t('common.update')}` }}
              </span>
            </v-tooltip>
          </div>
          <div
            v-else-if="$checkPermission(staffInfo, 'rf_issuance.write')"
          >
            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  label
                  small
                  outlined
                  color="info"
                  class="px-2 my-1"
                  @click="writeDialog = {
                    show: true,
                    title: `${$t('user.idTag')} ${$t('common.regist')}`,
                    sub: format.makeMaskName(item.name),
                    idTag: '',
                    type: 'regist',
                    userNo: item.no,
                    submit: addIdTag,
                    _id: null,
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('common.unregistered') }}
                </v-chip>
              </template>
              <span>
                {{ `${$t('user.idTag')} ${$t('common.regist')}` }}
              </span>
            </v-tooltip>
          </div>
        </div>
        <!-- evcc -->
        <div v-if="item.evcc.length > 0" class="my-1">
          <v-chip x-small label class="px-1">Evcc</v-chip>
          <div
            v-for="evcc, i in item.evcc"
            :key="i"
            class="d-flex align-center"
          >
            <span>{{ evcc.idTag }}</span>
            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="$checkPermission(staffInfo, 'rf_issuance.write')"
                  icon
                  x-small
                  class="ml-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteEvcc(evcc._id)"
                >
                  <v-icon small color="error">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>
                {{ `Evcc ${$t('btn.delete')}` }}
              </span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:item.sortCard="{ item }">
        <div v-if="item.cards.length > 0">
          <div v-for="card in item.cards" :key="card._id" class="align_center">
            <p v-if="card.cardName">
              {{ card.cardName }}
              <span class="smT">{{ card.cardNo.slice(0,4) }}</span>
            </p>
            <v-chip
              v-if="card.isDefault"
              label
              x-small
              outlined
              class="ml-1 px-1"
            >
              {{ $t('user.default') }}
            </v-chip>
          </div>
        </div>
        <p v-else class="smT">
          {{ $t('common.unregistered') }}
        </p>
      </template>
      <template v-slot:item.restPoints="{ item }">
        <div class="align_center justify-start">
          <v-icon small color="warning">mdi-file-powerpoint-box-outline</v-icon>
          <span>{{ item.restPoints.toLocaleString() }}</span>
          <v-tooltip top color="black" v-if="$checkPermission(staffInfo, 'users.write')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="openPointDialog(item)"
              >
                <v-icon small color="success">mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            <span>
              {{ `${$t('user.restPoints')} ${$t('common.provision')}/${$t('common.deduction')}` }}
            </span>
          </v-tooltip>
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="getPointHistory(item)">
                <v-icon small color="primary">mdi-text-box</v-icon>
              </v-btn>
            </template>
            <span>
              {{ `${$t('user.restPoints')} ${$t('common.viewHistory')}` }}
            </span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.lastTransactionStatus="{ item }">
        <div class="align_center justify-start">
          <span>{{ $t(`transaction.statuses.${item.lastTransactionStatus}`) }}</span>
          <v-tooltip
            top
            color="black"
            v-if="
              item.lastTransactionStatus != null
              && $checkPermission(staffInfo, 'charge_logs.read')
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-1"
                v-bind="attrs"
                v-on="on"
                @click="getTransactionHistory(item)"
              >
                <v-icon small color="primary">mdi-text-box</v-icon>
              </v-btn>
            </template>
            <span>
              {{ `${$t('user.lastTransactionStatus')} ${$t('common.viewHistory')}` }}
            </span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.created="{ item }">
        {{ time.makeLocalTime(item.created) }}
      </template>
      <template v-slot:item.block="{ item }">
        <v-btn
          @click="blockStatusUpdate(item)"
          :color="item.block === true ? 'success' : 'error'"
          depressed
          small
          class="px-1"
        >
          {{
            item.block === true ? $t('user.unblock') : $t('user.block')
          }}
        </v-btn>
      </template>
      <template v-slot:no-data>
        <p>{{ $t('common.noData') }}</p>
      </template>
    </v-data-table>
    <v-pagination
      small
      depressed
      v-show="pageOptions.pageCount > 0"
      :value="options.page"
      :length="pageOptions.pageCount"
      :total-visible="10"
      @input="updateOptions('page', $event)"
    ></v-pagination>
    <DlgHistory
      :show.sync="logDialog.show"
      :title="logDialog.title"
      :sub="logDialog.sub"
      :headers="logDialog.headers"
      :data="logDialog.data"
      :i18nPath="logDialog.i18nPath"
    />
    <DlgCommonWrite
      :show.sync="writeDialog.show"
      :title="writeDialog.title"
      :sub="writeDialog.sub"
      :type="writeDialog.type"
      :formCheck="writeDialog.formCheck"
      @submit="writeDialog.submit"
      width="540"
    >
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('idTag.idTag') }}
        </v-chip>
        <div style="width: 100%">
          <v-text-field
            v-model="writeDialog.idTag"
            @input="checkIdTagFormat()"
            outlined
            color="black"
            dense
            flat
            solo
            hide-details
          />
        </div>
      </div>
    </DlgCommonWrite>
    <DlgCommonWrite
      :show.sync="pointDialog.show"
      :title="
        `${$t('user.restPoints')} ${$t('common.provision')}/${$t('common.deduction')}`
      "
      :sub="pointDialog.sub"
      type="update"
      :formCheck="
        pointDialog.point !== 0 && checkPoint() === 3 && pointDialog.reason.trim().length > 0
      "
      @submit="updatePoint"
      width="540"
    >
      <div class="align_center mb-1 justify-end">
        <v-chip
          @click="pointDialog.point += 100"
          :disabled="checkPoint(pointDialog.point + 100) !== 3"
          color="success"
          small
          class="mr-1"
        >
          + 100
        </v-chip>
        <v-chip
          @click="pointDialog.point += 1000"
          :disabled="checkPoint(pointDialog.point + 1000) !== 3"
          color="success"
          small
          class="mr-3"
        >
          + 1,000
        </v-chip>
        <v-chip
          @click="pointDialog.point -= 100"
          :disabled="checkPoint(pointDialog.point - 100) !== 3"
          color="error"
          class="mr-1"
          small
        >
          - 100
        </v-chip>
        <v-chip
          @click="pointDialog.point -= 1000"
          :disabled="checkPoint(pointDialog.point - 1000) !== 3"
          color="error"
          small
        >
          - 1,000
        </v-chip>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('point.amount') }}
        </v-chip>
        <div style="width: 100%">
          <v-text-field
            v-model.number="pointDialog.point"
            @change="addjust"
            type="number"
            outlined
            color="black"
            dense
            flat
            solo
            hide-details
          />
        </div>
      </div>
      <div class="align_center mb-4">
        <v-chip class="mr-2 flex-shrink-0" label color="primary">
          {{ $t('point.reason') }}
        </v-chip>
        <div style="width: 100%">
          <v-text-field
            v-model="pointDialog.reason"
            maxLength="128"
            outlined
            color="black"
            dense
            flat
            solo
            hide-details
          />
        </div>
      </div>
      <div class="align_center mb-4 justify-end" v-if="pointDialog.point !== 0">
        <p class="small-text mr-2">
          {{
            Math.abs(pointDialog.point) === pointDialog.point
            ? $t('point.provisionMessage')
            : $t('point.deductionMessage')
          }}:
        </p>
        <p style="font-weight: bold;" class="mb-1">
          {{ (pointDialog.point + pointDialog.restPoints).toLocaleString() }}
        </p>
      </div>
    </DlgCommonWrite>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import time from '@/util/time';
import format from '@/util/format';
import DlgHistory from '@/components/dialog/DlgHistory.vue';
import DlgCommonWrite from '@/components/dialog/DlgCommonWrite.vue';

export default {
  components: {
    DlgHistory,
    DlgCommonWrite,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
    items: {
      type: Array,
      default: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    pageOptions: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
    gradeTexts() {
      return [
        this.$t('userGrades.0'),
        this.$t('userGrades.1'),
        this.$t('userGrades.2'),
      ];
    },
  },
  data: () => ({
    time,
    format,
    headers: [],
    logDialog: {
      show: false,
      title: '',
      sub: null,
      headers: [],
      data: [],
      i18nPath: null,
    },
    sort: {
      sortBy: null,
      sortDesc: null,
    },
    writeDialog: {
      show: false,
      title: '',
      type: 'regist',
      sub: null,
      userNo: null,
      idTag: '',
      _id: null,
      formCheck: false,
      submit: () => {},
    },
    pointDialog: {
      show: false,
      sub: null,
      point: 0,
      restPoints: 0,
      reason: '',
      userNo: null,
    },
  }),
  methods: {
    initHeaders(staffInfo = null) {
      const headers = [
        {
          text: this.$t('user.name'),
          value: 'name',
          width: '8%',
        },
        {
          text: this.$t('user.grade'),
          value: 'grade',
          sortable: false,
          width: '8%',
        },
        {
          text: this.$t('user.phone'),
          value: 'phone',
          sortable: false,
          width: '5%',
        },
        {
          text: this.$t('user.email'),
          value: 'email',
          sortable: false,
          width: '10%',
        },
        {
          text: this.$t('user.address'),
          value: 'address',
          sortable: false,
          width: '15%',
        },
        // TODO: 번역!
        {
          text: this.$t('user.authentication'),
          value: 'idTag',
          sortable: false,
          width: '15%',
        },
        {
          text: this.$t('user.sortCard'),
          value: 'sortCard',
          sortable: false,
          width: '8%',
        },
        {
          text: this.$t('user.restPoints'),
          value: 'restPoints',
          width: '8%',
        },
        {
          text: this.$t('user.lastTransactionStatus'),
          sortable: true,
          value: 'lastTransactionStatus',
          width: '10%',
        },
        {
          text: this.$t('user.created'),
          align: 'center',
          value: 'created',
          width: '10%',
        },
      ];
      if (staffInfo != null && staffInfo.permission.users.write) {
        headers.push({
          text: this.$t('user.block'),
          align: 'center',
          value: 'block',
          sortable: false,
          width: '3%',
        });
      }
      this.headers = headers;
    },
    updateOptions(key, value) {
      let options = {
        ...this.options,
        [key]: value,
      };
      if (key === 'itemsPerPage') {
        options = {
          ...this.options,
          page: 1,
          itemsPerPage: this.pageOptions.itemsPerPageOptions[value],
        };
      }
      this.$emit('update:options', options);
    },
    async blockStatusUpdate(user) {
      try {
        if (!user.block) {
          this.$dialog.confirm({
            show: true,
            message: this.$t('user.updateBlockAskMessage'),
            color: 'error',
            btnText: this.$t('user.block'),
            callback: async () => {
              this.$dialog.progress(true);
              const { _id: oId } = user;
              await this.$emitter('user.block', { _ids: [{ _id: oId }], block: !user.block }, this.$t('common.updateCompleteMessage'));
              this.$dialog.confirm();
              this.$dialog.progress(false);
              await this.callback();
            },
          });
        } else {
          this.$dialog.progress(true);
          const { _id: oId } = user;
          await this.$emitter('user.block', { _ids: [{ _id: oId }], block: !user.block }, this.$t('common.updateCompleteMessage'));
          this.$dialog.confirm();
          this.$dialog.progress(false);
          await this.callback();
        }
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    async getTransactionHistory(user) {
      this.$dialog.progress(true);
      try {
        const { items } = await this.$emitter('transaction.list.get', {
          page: 1,
          itemsPerPage: 0,
          sortBy: ['date.session.start'],
          sortDesc: [true],
          filters: [{
            where: 'and',
            condition: 'eq',
            key: 'userNo',
            value: user.no,
          }],
        });
        this.logDialog = {
          show: true,
          title: `${this.$t('user.lastTransactionStatus')} ${this.$t('common.viewHistory')}`,
          sub: format.makeMaskName(user.name),
          data: items,
          headers: [
            'evseNumber',
            'chargedMeter',
            'chargedPrice',
            'paymentType',
            'sessionStartDate',
            'sessionEndDate',
            'status',
          ],
          i18nPath: 'transaction',
        };
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    async getPointHistory(user) {
      this.$dialog.progress(true);
      try {
        const { items } = await this.$emitter('point.list.get', {
          page: 1,
          itemsPerPage: 0,
          sortBy: ['created'],
          sortDesc: [true],
          filters: [{
            where: 'and',
            condition: 'eq',
            key: 'userNo',
            value: user.no,
          }],
        });
        this.logDialog = {
          show: true,
          title: `${this.$t('user.restPoints')} ${this.$t('common.viewHistory')}`,
          sub: format.makeMaskName(user.name),
          data: items,
          headers: [
            'action',
            'evseNumber',
            'amount',
            'staffName',
            'reason',
            'created',
          ],
          i18nPath: 'point',
        };
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    async addIdTag() {
      this.$dialog.progress(true);
      try {
        await this.$emitter('idtag.add', {
          items: [
            {
              userNo: this.writeDialog.userNo,
              idTag: this.writeDialog.idTag,
            },
          ],
        }, this.$t('common.registCompleteMessage'));
        this.writeDialog.show = false;
        await this.callback();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    async updateIdTag() {
      this.$dialog.progress(true);
      const { _id: oId } = this.writeDialog;
      try {
        await this.$emitter('idtag.update', {
          _id: oId,
          idTag: this.writeDialog.idTag,
        }, this.$t('common.updateCompleteMessage'));
        this.writeDialog.show = false;
        await this.callback();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    checkIdTagFormat() {
      if (
        this.writeDialog.idTag == null
        || this.writeDialog.idTag.length <= 0
        || !/^\d+$/.test(this.writeDialog.idTag)
      ) {
        this.writeDialog.formCheck = false;
      } else {
        this.writeDialog.formCheck = true;
      }
    },
    async openPointDialog(user) {
      const { _id: oId, no } = user;
      this.$dialog.progress(true);
      try {
        const { item } = await this.$emitter('user.get', {
          _id: oId,
        });
        this.pointDialog = {
          show: true,
          sub: `
            ${format.makeMaskName(item.name)}
            (${this.$t('point.rest')} ${this.$t('user.restPoints')}:
            ${item.restPoints.toLocaleString()})
          `,
          point: 0,
          restPoints: item.restPoints,
          reason: '',
          userNo: no,
        };
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    checkPoint(point = this.pointDialog.point) {
      const { restPoints } = this.pointDialog;
      if (point + restPoints < 0) return 0;
      if (point < -100000) return 1;
      if (point > 100000) return 2;
      return 3;
    },
    addjust(number = null) {
      const target = number == null ? this.pointDialog.point : this.pointDialog.point + number;
      switch (this.checkPoint(target)) {
        case 0:
          this.pointDialog.point = this.pointDialog.restPoints * -1;
          break;
        case 1:
          this.pointDialog.point = -100000;
          break;
        case 2:
          this.pointDialog.point = 100000;
          break;
        default:
          this.pointDialog.point = target;
          break;
      }
    },
    async updatePoint() {
      const { point: amount, userNo, reason } = this.pointDialog;
      this.$dialog.progress(true);
      try {
        await this.$emitter('point.add', {
          userNo,
          amount,
          action: 'manual',
          reason,
        }, this.$t('common.applyCompleteMessage'));
        this.pointDialog = {
          show: false,
          sub: null,
          point: 0,
          restPoints: 0,
          reason: '',
          userNo: null,
        };
        this.callback();
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
      this.$dialog.progress(false);
    },
    async updateGrade(id, gradeNo) {
      await this.$emitter('user.update', {
        _ids: [{ _id: id }],
        grade: gradeNo + 1,
      });
      await this.callback();
    },
    async deleteEvcc(_id) {
      const callback = async () => {
        this.$dialog.progress(true);
        try {
          await this.$emitter('idtag.evcc.delete', { _id });
          this.$dialog.alert('success', `${this.$t('common.deleteCompleteMessage')}`);
        } catch (error) {
          console.error(error);
          this.$dialog.alert('error', this.$error.makeErrorMessage(error));
        }
        this.$dialog.confirm();
        this.$dialog.progress(false);
        this.callback();
      };
      this.$dialog.confirm({
        show: true,
        message: this.$t('common.delete'),
        color: 'error',
        btnText: this.$t('btn.delete'),
        callback,
      });
    },
  },
  watch: {
    staffInfo: {
      immediate: true,
      deep: true,
      async handler(value) {
        this.initHeaders(value);
      },
    },
    '$i18n.locale': {
      handler() {
        this.initHeaders(this.staffInfo);
      },
    },
    sort: {
      deep: true,
      handler(value) {
        this.options.page = 1;
        if (value.sortBy == null && value.sortDesc == null) {
          this.$emit('update:options', {
            ...this.options,
            sortBy: [],
            sortDesc: [],
          });
        } else if (value.sortBy != null && value.sortDesc != null) {
          this.$emit('update:options', {
            ...this.options,
            sortBy: [String(value.sortBy)],
            sortDesc: [value.sortDesc],
          });
        }
      },
    },
  },
};
</script>

<style lang="scss">
</style>
