<template>
  <v-dialog
    v-model="show"
    width="auto"
    @click:outside="init()"
    @keydown="$event.key === 'Escape' ? init() : ''"
  >
    <v-card class="log-wrap">
      <p class="tit">
        {{ title }}
        <span class="sub ml-1" v-if="sub !== null">{{ sub }}</span>
      </p>
      <div class="header-wrap" v-if="headers.length > 0">
        <div
          :style="`width: ${800/headers.length}px`"
          v-for="header, index in headers"
          :key="index"
        >
          <span>{{ $t(`${i18nPath}.${header}`) }}</span>
        </div>
      </div>
      <div class="log" v-if="data.length > 0">
        <div
          class="log-row"
          v-for="log, i in data"
          :key="i"
        >
          <div
            :style="`width: ${800/headers.length}px`"
            v-for="header, index in headers"
            :key="index"
          >
            <span v-if="i18nPath === 'transaction' && header === 'status'">
              {{ $t(`transaction.statuses.${log.status}`) }}
            </span>
            <span v-else-if="i18nPath === 'transaction' && header === 'evseNumber'">
              {{ `${log.chargepoint.cpCode}-${log.connectorId}` }}
            </span>
            <span v-else-if="i18nPath === 'transaction' && header === 'paymentType'">
              {{ $t(`transaction.paymentTypes.${log.paymentType}`) }}
            </span>
            <span v-else-if="i18nPath === 'point' && header === 'action'">
              {{ $t(`point.actions.${log.action}`) }}
            </span>
            <span v-else-if="i18nPath === 'point' && header === 'evseNumber'">
              {{
                log.transaction == null
                ? '-'
                : `${log.transaction.chargepoint.cpCode}-${log.transaction.connectorId}`
              }}
            </span>
            <span v-else-if="i18nPath === 'point' && header === 'action'">
              {{ $t(`point.actions.${log.action}`) }}
            </span>
            <span v-else-if="i18nPath === 'point' && header === 'amount'">
              <v-icon
                small
                style="margin-top: -1px"
              >
                mdi-file-powerpoint-box-outline
              </v-icon>
              <span>{{ log.amount.toLocaleString() }}</span>
            </span>
            <span v-else-if="i18nPath === 'point' && header === 'staffName'">
              {{ log.staff == null ? '-' : log.staff.name }}
            </span>
            <span v-else-if="[
              'chargedMeter',
            ].indexOf(header) !== -1">
              {{ log[header] == null ? '-' : `${(log[header] / 1000).toLocaleString()}kWh` }}
            </span>
            <span v-else-if="[
              'chargedPrice',
              'amount'
            ].indexOf(header) !== -1">
              {{ `${$t('common.billSymbol')} ${log[header].toLocaleString()}` }}
            </span>
            <span v-else-if="[
              'sessionStartDate',
              'sessionEndDate',
              'created'
            ].indexOf(header) !== -1">
              {{ log[header] == null ? '-' : time.makeLocalTime(log[header]) }}
            </span>
            <span v-else-if="i18nPath === 'cp.amountByGrade' && header === 'level'">
              {{ i }}
            </span>
            <span v-else-if="i18nPath === 'cp.amountByGrade' && header === 'method'">
              {{ $t(`cp.amountByGrade.${log.method}`) }}
            </span>
            <span v-else-if="i18nPath === 'cp.amountByGrade' && header === 'use'">
              {{ log.disable }}
            </span>
            <span v-else>{{ log[header] == null ? '-' : log[header] }}</span>
          </div>
        </div>
      </div>
      <div class="log" v-else>
        <p class="log-row empty-data">{{ $t('common.noData') }}</p>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import format from '@/util/format';
import time from '@/util/time';

export default {
  name: 'DlgHistory',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    sub: {
      type: String,
      default: null,
    },
    headers: {
      type: Array,
      default: null,
    },
    i18nPath: {
      type: String,
    },
  },
  data: () => ({
    format,
    time,
  }),
  methods: {
    init() {
      this.$emit('update:show', false);
    },
  },
};
</script>

<style scoped lang="scss">
.log-wrap {
  .header-wrap {
    margin: 6px 0px;
    display: flex;
    justify-content: space-between;
    div {
      font-size: .8rem;
      color: #000;
      display: flex;
      justify-content: center;
      span {
        text-align: center;
      }
    }
  }
  .log {
    max-height: 300px;
    overflow: auto;
    background-color: #f2f2f2;
    padding: 5px 0px;

    .log-row {
      &.empty-data {
        text-align: center;
        padding: 10px 0px;
        display: flex;
        justify-content: center;
        font-size: .8rem;
        color: #888;
      }
      margin:0;
      display: flex;
      justify-content: space-between;
      padding-bottom: 3px;
      &+.log-row{
        margin-top: 3px;
        border-top: 1px solid #ddd;
      }
      div {
        font-size: .8rem;
        color: #333;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          text-align: center;
        }
      }
    }
  }
}
</style>
