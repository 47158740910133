<template>
  <div class="popup_section_wrap section_wrap">
    <div class="section_box_wrap">
        <PopupBox/>
    </div>
  </div>
</template>

<script>
import PopupBox from './unit/PopupBox.vue';

export default {
  name: 'PopupSection',
  components: {
    PopupBox,
  },
};
</script>
