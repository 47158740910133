<template>
  <div class="charts-common-wrap ma-2">
    <p class="px-2 pt-2 font-weight-bold charts-title">
      {{ $t(chartTitle) }}
    </p>
    <div class="charts pa-1" style="position: relative;">
      <div class="d-flex align-center justify-space-between px-1">
        <div class="w-50">
          <v-tabs
            v-model="tab"
            background-color="transparent"
          >
          <v-tab v-for="item in tabItems" :key="item">{{ $t(`dashboard.tabs.${item}`) }}</v-tab>
          </v-tabs>
        </div>
        <div class="d-flex align-center w-50">
          <v-btn
            icon
            @click="moveDate('prev')"
            :disabled="isDisabled('prev')"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <p>{{ tab === 0 ? currentMonth : currentYear }}</p>
          <v-btn
            icon
            @click="moveDate('next')"
            :disabled="isDisabled('next')"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <v-tabs-items v-model="tab">
        <apexchart
          height="330"
          :type="chart"
          :options="chartOptions"
          :series="series"
          :class="isExist ? '' : 'exist-chart'"
          >
        </apexchart>
      </v-tabs-items>
      <div v-if="!isExist" class="not-exist-chart">{{ $t('common.noData') }}</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import timeConvert from '@/util/time';
import { navigateMonth } from '@/util/calendar';

export default {
  props: {
    chartTitle: {
      type: String,
      required: true,
    },
    chart: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    form: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    tab: 0,
    tabItems: ['month', 'year'],
    series: [],
    chartOptions: {},
    currentMonth: timeConvert.getMonth2(),
    currentYear: timeConvert.getYear(),
    isExist: false,
  }),
  methods: {
    async getData(tab, date) {
      const currentDate = new Date();
      const params = {
        utcOffset: currentDate.getTimezoneOffset() / 60,
        year: tab === 0 ? +(date.split('-')[0]) : +(date),
        ...((tab === 0) && { month: +(date.split('-')[1]) }),
      };
      const emitter = this.form === 'amount' ? 'dashboard.sales.graph.get' : 'dashboard.meter.graph.get';
      const updatedParams = this.form === 'amount' ? { ...params, paymentAction: 'Transaction' } : params;
      const res = await this.$emitter(emitter, updatedParams);
      const data = res.data.map((item) => (this.form === 'amount' ? item.amount : +((item.amount / 1000).toFixed(1))));
      const index = tab === 0 ? 2 : 1;
      const categories = res.data.map((item) => item.date.split('-')[index]);
      const dataName = this.form === 'amount' ? `${this.$t('dashboard.totalAmount')}` : `${this.$t('cp.priceTable.energy')}`;

      this.series = [{
        name: dataName,
        data,
      }];

      this.chartOptions = {
        ...this.options,
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5,
          },
        },
        xaxis: {
          categories,
        },
        tooltip: {
          x: {
            formatter(val, opts) {
              // val은 현재값, 툴팁에 표시될 데이터 포인트 값
              // dataPointIndex는 해당 시리즈의 데이터 포인트 인덱스
              const tooltipDate = res.data[opts.dataPointIndex].date;
              return tooltipDate.split('-').slice(1).join('/');
            },
          },
        },
        yaxis: {
          labels: {
            formatter(value) {
              return value.toLocaleString();
            },
          },
        },
      };
    },
    moveDate(type) {
      const setDate = this.tab === 0 ? this.currentMonth : this.currentYear;
      this[this.tab === 0 ? 'currentMonth' : 'currentYear'] = navigateMonth(type, setDate, this.tab);
    },
    isDisabled(type) {
      if (type === 'prev') {
        return (this.tab === 0 && this.currentMonth === '2022.01')
          || (this.tab === 1 && this.currentYear === 2022);
      }
      if (type === 'next') {
        const month = (new Date().getMonth() + 1).toString().padStart(2, '0');
        const year = new Date().getFullYear();
        return (this.tab === 0 && this.currentMonth === `${year}.${month}`)
          || (this.tab === 1 && +(this.currentYear) === year);
      }
      return false;
    },
  },
  watch: {
    tab: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.getData(val, val === 0 ? this.currentMonth : this.currentYear);
        }
      },
    },
    currentMonth: {
      handler(val) {
        if (val != null) {
          this.getData(0, val);
        }
      },
    },
    currentYear: {
      handler(val) {
        if (val != null) {
          this.getData(1, val);
        }
      },
    },
    series: {
      handler(val) {
        if (val != null && val.length > 0 && val[0].data != null) {
          this.isExist = val[0].data.some((amount) => amount > 0);
          if (!this.isExist) {
            this.series = [];
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
.charts-common-wrap{
  border: 1px solid #E4E4E4;
  border-radius: 4px;
  .charts-title {
    font-size: 18px;
    height: 30px;
  }
  .exist-chart {
    opacity: 0.5;
  }
  .not-exist-chart {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 17px;
    color: #878787;
    font-weight: bold;
    z-index: 1;
  }
}
.apexcharts-tooltip-text { padding-left: 5px;}
</style>
