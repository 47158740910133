<template>
  <div class="data_table_wrap">
    <div class="align_center justify-space-between">
      <div class="body-2">
        <b v-if="pageOptions.total <= 0">0</b>
        <b v-else class="primary--text">
          {{
            options.page === 1
            ? items.length
            : ((options.page - 1) * options.itemsPerPage) + items.length
          }}
        </b>
        /
        <span>{{ pageOptions.total }}</span>
      </div>
      <v-btn-toggle
        class="my-2"
        v-if="pageOptions.itemsPerPageOptions != null && pageOptions.itemsPerPageOptions.length > 0"
        @change="updateOptions('itemsPerPage', $event)"
        mandatory
        dense
      >
        <v-btn v-for="number of pageOptions.itemsPerPageOptions" :key="number">
          {{ `${number}${$t('dataTable.itemsPerPageOption')}` }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-data-table
      mobile-breakpoint="0"
      style="max-height: 975px;"
      fixed-header
      :headers="headers"
      :items="items"
      :loading="loading"
      disable-pagination
      hide-default-footer
      @update:sort-by="sort.sortBy = $event"
      @update:sort-desc="sort.sortDesc = $event"
      dense
      class="data_table"
    >
      <template v-slot:item.content="{ item }">
        <div
          @click="$router.push(`/community/problem/${item.no}`)"
          style="cursor: pointer;"
        >
          {{ item.content }}
          <v-icon small v-if="item.fileCount > 0">
            mdi-paperclip
          </v-icon>
        </div>
      </template>
      <template v-slot:item.writerInfo="{ item }">
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <span v-if="item.creatorStaffInfo">
                {{ format.makeMaskName(item.creatorStaffInfo.name) }}
              </span>
              <span v-else>
                {{ format.makeMaskName(item.creatorUserInfo.name) }}
              </span>
            </div>
          </template>
          <span v-if="item.creatorStaffInfo">{{ item.creatorStaffInfo.name }}</span>
          <span v-else>{{ item.creatorUserInfo.name }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.created="{ item }">
        {{ time.makeDate(item.created) }}
      </template>
      <template v-slot:no-data>
        <p>{{ $t('common.noData') }}</p>
      </template>
    </v-data-table>
    <v-pagination
      small
      depressed
      v-show="pageOptions.pageCount > 0"
      :value="options.page"
      :length="pageOptions.pageCount"
      :total-visible="10"
      @input="updateOptions('page', $event)"
    ></v-pagination>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapMutations, mapGetters } from 'vuex';
import time from '@/util/time';
import format from '@/util/format';

export default {
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
    items: {
      type: Array,
      default: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    pageOptions: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    viewItem: {
      type: Object || null,
    },
  },
  computed: {
    ...mapGetters({
      staffInfo: 'auth/staffInfo',
    }),
  },
  data: () => ({
    time,
    format,
    headers: [],
    editedIndex: -1,
    clickedItem: {},
  }),
  methods: {
    ...mapMutations({
      confirm: 'dialog/confirm',
    }),
    changePageNum(value) {
      this.$emit('update:page', value);
    },

    close() {
      this.callback();
    },

    async deleteClickedITem(item) {
      this.editedIndex = this.items.indexOf(item);
      try {
        await this.$dialog.confirm({
          show: true,
          message: `${this.$t('common.delete')}`,
          color: 'error',
          btnText: `${this.$t('btn.delete')}`,
          callback: () => {
            this.$dialog.confirm();
            this.$dialog.alert('success', `${this.$t('common.deleteCompleteMessage')}`);
            this.$emitter('post.delete', {
              _ids: [
                { _id: item._id },
              ],
            });
            this.close();
          },
        });
      } catch (error) {
        console.error(error);
        this.$dialog.alert('error', this.$error.makeErrorMessage(error));
      }
    },
    updateOptions(key, value) {
      let options = {
        ...this.options,
        [key]: value,
      };
      if (key === 'itemsPerPage') {
        options = {
          ...this.options,
          page: 1,
          itemsPerPage: this.pageOptions.itemsPerPageOptions[value],
        };
      }
      this.$emit('update:options', options);
    },
    initHeaders() {
      this.headers = [
        {
          text: this.$t('problem.cpNumber'),
          value: 'title',
          sortable: false,
          width: '10%',
        },
        {
          text: this.$t('problem.title'),
          value: 'content',
          sortable: false,
          width: '45%',
        },
        {
          text: this.$t('common.writer'),
          value: 'writerInfo',
          align: 'center',
          sortable: false,
          width: '10%',
        },
        {
          text: this.$t('common.registration'),
          value: 'created',
          align: 'center',
          width: '15%',
        },
      ];
    },
  },
  watch: {
    staffInfo: {
      immediate: true,
      async handler() {
        this.initHeaders();
      },
    },
    '$i18n.locale': {
      handler() {
        this.initHeaders();
      },
    },
    sort: {
      deep: true,
      handler(value) {
        this.options.page = 1;
        if (value.sortBy == null && value.sortDesc == null) {
          this.$emit('update:options', {
            ...this.options,
            sortBy: [],
            sortDesc: [],
          });
        } else if (value.sortBy != null && value.sortDesc != null) {
          this.$emit('update:options', {
            ...this.options,
            sortBy: [value.sortBy],
            sortDesc: [value.sortDesc],
          });
        }
      },
    },
  },
};
</script>
